import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: {},
  shop: {},
  categories: [],
  products: [],
  purchases: [],
  searchPurchases: '',
  notifications: [],
  clients: [],
  searchBarcode: [],
  selectClients: [],
  editProduct: {},
  purchasesLength: 0,
  notificationsLength: 0,
  isNeedCreateShop: false,
  // isNeedCreateShop: true,
  isNeedUpdateShop: false,
  isNeedUpdateCategories: false,
  // isNeedUpdateProducts: false,
  isCleanInput: false,
  userLanguage: '',
  selectedLanguage: {},
  selectedBlocks: [],
  isTopManagerRole: false,
  // isTopManagerRole: true,
  isManagerRole: false,
  // isManagerRole: true,
  userPurchasesTabValue: 0,
  userNotificationsTabValue: 0,
  managers: [],
  lastUpdatedPersonnelView: null,

}

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setShop: (state, action) => {
      state.shop = action.payload;
    },
    getCategories: (state, action) => {
      state.categories = action.payload;
    },
    getProducts: (state, action) => {
      state.products = action.payload;
    },
    getPurchases: (state, action) => {
      state.purchases = action.payload;
    },
    setPurchasesLength: (state, action) => {
      state.purchasesLength = action.payload;
    },
    setNotificationsLength: (state, action) => {
      state.notificationsLength = action.payload;
    },
    getNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    setStatusPurchases: (state, action) => {
      state.purchases = state.purchases.map(el => {
        if (el._id == action.payload._id) {
          el.status = action.payload.status
        } 
        return el
      });
    },
    setSeenPurchases: (state, action) => {
      state.purchases = state.purchases.map(el => {
        if (el._id == action.payload._id) {
          el.isSeen = true
        } 
        return el
      });
    },
    setSeenNotifications: (state, action) => {
      state.notifications = state.notifications.map(el => {
        if (el._id == action.payload._id) {
          el.isSeen = true
        } 
        return el
      });
    },
    setFavoritePurchases: (state, action) => {
      state.purchases = state.purchases.map(el => {
        if (el._id == action.payload._id) {
          el.favorite = action.payload.favorite
        } 
        return el
      });
    },
    setFavoriteNotifications: (state, action) => {
      state.notifications = state.notifications.map(el => {
        if (el._id == action.payload._id) {
          el.favorite = action.payload.favorite
        } 
        return el
      });
    },
    setCategories: (state, action) => {
      state.categories = [...state.categories, action.payload];
    },
    setSubCategories: (state, action) => {
      state.categories = state.categories.map(el => {
        if (el._id == action.payload.parent_id) {
          el.sub_categories.push(action.payload)
        }
        return el
      });
    },
    setUpdataCategory: (state, action) => {
      if (action.payload.parent_id == "null") {
        state.categories = state.categories.map(el => {
          if (el._id == action.payload._id) {
            el = action.payload
          }
          return el
        })
      } else {
        state.categories = state.categories.map(el => {
          el.sub_categories =  el.sub_categories.map(ell => {
            if (ell._id == action.payload._id) {
              ell = action.payload
            }
            return ell
           })
          return el
        })
      }
    },
    setRemoveCategory: (state, action) => {
      state.categories = state.categories.filter(el => el._id !== action.payload);
    },
    setRemoveSubCategory: (state, action) => {
      state.categories = state.categories.map(el => {
        if (el._id == action.payload.categoryId) {
          el.sub_categories = el.sub_categories.filter(subCategories => subCategories._id !== action.payload.subCategoryId)
        } 
        return el
      });
    },
    setProduct: (state, action) => {
      state.products = [...state.products, action.payload];
    },
    setUpdataProduct: (state, action) => {
      state.products = [...state.products.map(el => {
        if (el._id == action.payload._id) {
          el = action.payload
        }
        return el
      })];
    },
    setEditProduct: (state, action) => {
      state.editProduct = action.payload;
    },
    setRemoveProduct: (state, action) => {
      state.products = state.products.filter(el => el._id !== action.payload);
    },
    setIsNeedCreateShop: (state, action) => {
      state.isNeedCreateShop = action.payload;
    },
    setIsNeedUpdateShop: (state, action) => {
      state.isNeedUpdateShop = action.payload;
    },
    setIsNeedUpdateCategories: (state, action) => {
      state.isNeedUpdateCategories = action.payload;
    },
    setIsCleanInput: (state, action) => {
      state.isCleanInput = action.payload;
    },
    setUserLanguage: (state, action) => {
      state.userLanguage = action.payload;
    },
    setSelectedLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
    },
    setSelectedBlocks: (state, action) => {
      state.selectedBlocks = action.payload;
    },
    setIsTopManagerRole: (state, action) => {
      state.isTopManagerRole = action.payload;
    },
    setIsTopManagerRole: (state, action) => {
      state.isManagerRole = action.payload;
    },
    setSearchPurchases: (state, action) => {
      state.searchPurchases = action.payload;
    },
    setClients: (state, action) => {
      state.clients = action.payload;
    },
    setSelectClients: (state, action) => {
      state.selectClients = action.payload;
    },
    setSearchBarcode: (state, action) => {
      state.searchBarcode = action.payload;
    },
    setUserPurchasesTabValue: (state, action) => {
      state.userPurchasesTabValue = action.payload;
    },
    setUserNotificationsTabValue: (state, action) => {
      state.userNotificationsTabValue = action.payload;
    },
    setManagers: (state, action) => {
      state.managers = action.payload;
    },
    setLastUpdatedPersonnelView: (state, action) => {
      state.lastUpdatedPersonnelView = action.payload;
    },
   
  },
})

export const { setLastUpdatedPersonnelView, setManagers, setUserNotificationsTabValue, setUserPurchasesTabValue, setSearchBarcode, setSelectClients, setClients, setPurchasesLength, setNotificationsLength, setUser, setShop, getCategories, getProducts, getPurchases, getNotifications, setStatusPurchases, setFavoritePurchases, setFavoriteNotifications, setSeenPurchases, setSeenNotifications,  setProduct, setUpdataProduct, setCategories, setUpdataCategory, setRemoveCategory, setSubCategories, setRemoveSubCategory, setEditProduct, setRemoveProduct, setIsNeedCreateShop, setIsNeedUpdateShop, setIsNeedUpdateCategories, setIsCleanInput, setUserLanguage, setSelectedLanguage, setSelectedBlocks, setIsTopManagerRole, setIsManagerRole, setSearchPurchases } = userSlice.actions

export default userSlice.reducer
import './ClientView.css';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import MessageClient from '../../components/MessageClient/MessageClient';
import { fetchGetData } from '../../helpers/Utils';

function ClientView() {
    const shop = useSelector(state => state.homeSlice.shop);
    const buyer = useSelector(state => state.homeSlice.buyer);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const { idClient } = useParams();

    useEffect(() => {
        if (!buyer.password?.length) {
            navigate(`/${shop.name}/`)
        } 

        fetchGetData(`${process.env.REACT_APP_BASE_URL}/chats/${shop._id}/all?page=${page}&`)
            .then(res => {
                console.log(res)
                if (res.success && res.data) {
                    // dispatch(getProducts(res.data));
                    // setQuantityAllProducts(res.count % 10 == 0 ? res.count / 10 : Math.ceil(res.count / 10))
                    // setCountProducts(res.count)
                } else {
                    console.log('GET UserProduct:', res)
                }
            })
    }, [])
   
    return (
        <div className="client-view-wrap">
            <div className="client-view container">
                <div className="client-view__title">Ляскаво просимо {idClient}</div> 
                <MessageClient />
            </div>
        </div>
    );
}

export default ClientView;
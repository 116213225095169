import './App.css';
import { BrowserRouter, HashRouter, Route, Routes, useLocation } from 'react-router-dom';
import React, { Suspense } from 'react';
import Preloader from './components/Preloader/Preloader';
import SignInView from './views/SignInView/SignInView';
import Layout from './Layout/Layout';
import SignUpView from './views/SignUpView/SignUpView';
import LandingView from './views/LandingView/LandingView';
import UserCategoriesView from './views/UserCategoriesView/UserCategoriesView';
import LayoutUser from './Layout/LayoutUser';
import UserProductView from './views/UserProductView/UserProductView';
import CreationProduct from './components/CreationProduct/CreationProduct';
import { ToastContainer } from 'react-toastify';
import UserPurchasesView from './views/UserPurchasesView/UserPurchasesView';
import ReadPurchasesView from './views/ReadPurchasesView/ReadPurchasesView';
import UserAnalyticsView from './views/UserAnalyticsView/UserAnalyticsView';
import SearchProductView from './views/SearchProductView/SearchProductView';
import UserShopView from './views/UserShopView/UserShopView';
import UserSettingsView from './views/UserSettingsView/UserSettingsView';
import AdvertisementShopView from './views/AdvertisementShopView/AdvertisementShopView';
import ReadNotificationsView from './views/ReadNotificationsView/ReadNotificationsView';
import UserNotificationsView from './views/UserNotificationsView/UserNotificationsView';
import RecoveryView from './views/RecoveryView/RecoveryView';
import HelpView from './views/HelpView/HelpView';
import BlogsView from './views/BlogsView/BlogsView';
import BlogView from './views/BlogView/BlogView';
import PersonnelView from './views/PersonnelView/PersonnelView';
import VerificationView from './views/VerificationView/VerificationView';
import { setIsTopManagerRole } from './store/userSlice';
import { useSelector } from 'react-redux';
import ShopTemplatesView from './views/ShopTemplatesView/ShopTemplatesView';
import UserIntegrationView from './views/UserIntegrationView/UserIntegrationView';
import PricingView from './views/PricingView/PricingView';
import CustomersView from './views/CustomersView/CustomersView';
import CustomerView from './views/CustomerView/CustomerView';
import PartnersView from './views/PartnersView/PartnersView';
import ProductDetailsView from './views/ProductDetailsView/ProductDetailsView';
import SubscriptionView from './views/SubscriptionView/SubscriptionView';
import ClientView from './views/ClientView/ClientView';
import UserMessageView from './views/UserMessageView/UserMessageView';
import MerchantInstructionView from './views/MerchantInstructionView/MerchantInstructionView';
const HomeView = React.lazy(() => import('./views/HomeView/HomeView'));
const ProductFilterView = React.lazy(() => import('./views/ProductFilterView/ProductFilterView'));
const AboutUsView = React.lazy(() => import('./views/AboutUsView/AboutUsView'));
const PageNotFoundView = React.lazy(() => import('./views/PageNotFoundView/PageNotFoundView'));
const ProductInformationView = React.lazy(() => import('./views/ProductInformationView/ProductInformationView'));
const WishListView = React.lazy(() => import('./views/WishListView/WishListView'));
const ShoppingCartView = React.lazy(() => import('./views/ShoppingCartView/ShoppingCartView'));

function App() {
    const location = useLocation();
    const isTopManagerRole = useSelector(state => state.userSlice.isTopManagerRole);
    const isManagerRole = useSelector(state => state.userSlice.isManagerRole);

    return (
        <div className="app">
            {/* <HashRouter basename={process.env.REACT_APP_BASE_URL} > */}
            {/* <BrowserRouter basename={process.env.REACT_APP_BASE_URL} > */}
            {/* <BrowserRouter basename={`${process.env.REACT_APP_BASE_URL}`} > */}
            {/* <BrowserRouter> */}
                <ToastContainer 
                    position="bottom-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                
                <Suspense fallback={<Preloader />}>
                    <Routes>
                        <Route path="/" element={<LandingView />}/>
                        <Route path="/auth/:userId" element={<LayoutUser />}>
                            {
                                !isTopManagerRole && !isManagerRole ?
                                <>
                                        <Route index element={<UserAnalyticsView />}/>
                                        <Route path="shop" element={<UserShopView />}/>
                                        <Route path="shop/templates" element={<ShopTemplatesView />}/>
                                        <Route path="personnel" element={<PersonnelView />}/>
                                        <Route path="settings" element={<UserSettingsView />}/>
                                        <Route path="subscription" element={<SubscriptionView />}/>
                                        <Route path="integration" element={<UserIntegrationView />}/>
                                    </> : 
                                <Route index element={<UserPurchasesView />}/>
                            }
                            <Route path="customers" element={<CustomersView />}/>
                            <Route path="customers/:idCustomer" element={<CustomerView />}/>
                            <Route path="categories" element={<UserCategoriesView />}/>
                            <Route path="product" element={<UserProductView />}/>
                            <Route path="product/:idProduct" element={<ProductDetailsView />}/>
                            <Route path="product/create" element={<CreationProduct />}/>
                            <Route path="purchases" element={<UserPurchasesView />}/>
                            <Route path="purchases/:idPurchases" element={<ReadPurchasesView />}/>
                            <Route path="notifications" element={<UserNotificationsView />}/>
                            <Route path="notifications/:idNotifications" element={<ReadNotificationsView />}/>
                            <Route path="message" element={<UserMessageView />}/>
    
                        </Route>
                        <Route path="instruction" element={<MerchantInstructionView />}/>
                        {/* <Route path="/partners" element={<PartnersView />}/> */}
                        <Route path="/pricing" element={<PricingView />}/>
                        <Route path="/help" element={<HelpView />}/>
                        <Route path="/blogs" element={<BlogsView />}/>
                        <Route path="/blogs/:idBlog" element={<BlogView />}/>
                        <Route path="/verification" element={<VerificationView />}/>
                        {/* <Route path="/auth/login" element={<SignInView />}/> */}
                        {/* <Route path="/auth/register" element={<SignUpView />}/> */}
                        {/* <Route path="/recovery" element={<RecoveryView />}/> */}

                        <Route path="/:shopName/" element={<Layout />}>
                            <Route index element={<HomeView />}/>
                            <Route path="client/:idClient" element={<ClientView />} />
                            <Route path="category/:productId" element={<ProductFilterView />} />
                            <Route path="product/:id" element={<ProductInformationView />} />
                            <Route path="search" element={<SearchProductView />} />
                            <Route path="wishlist" element={<WishListView />} />
                            <Route path="cart" element={<ShoppingCartView />} />
                            {/* <Route path="about" element={<AboutUsView />} /> */}
                            <Route path="advertisement" element={<AdvertisementShopView />} />
                            <Route path="*" element={<PageNotFoundView />} />
                        </Route>
                    </Routes>
                </Suspense>
            {/* </BrowserRouter> */}
            {/* </HashRouter> */}
        </div>
  );
}

export default App;

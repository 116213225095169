import './UserAnalyticsView.css';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import MoreAnalytics2 from '../../components/MoreAnalytics2/MoreAnalytics2';
import MoreAnalytics1 from '../../components/MoreAnalytics1/MoreAnalytics1';
import ShopTemplatesView from '../ShopTemplatesView/ShopTemplatesView';
import { Box, Tab, Tabs } from '@mui/material';
import mixpanel from 'mixpanel-browser';
mixpanel.init('cbc9e4ee0d40873b5a2e3b5e39eedd33', { debug: true }); 
mixpanel.track('Visit analytics page');

function UserAnalyticsView() {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const isNeedCreateShop = useSelector(state => state.userSlice.isNeedCreateShop);
    const user = useSelector(state => state.userSlice.user);
    const shop = useSelector(state => state.userSlice.shop);
    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        mixpanel.track('Change tab on analytics page');
    };
    
    const getSubTitle = () => {
        const randomNumber = Math.floor(Math.random() * 3);
        switch (randomNumber) {
            case 0:
                return selectedLanguage?.userAnalytics?.userAnalyticsSubTitle1;
            case 1:
                return selectedLanguage?.userAnalytics?.userAnalyticsSubTitle2;
            case 2:
                return selectedLanguage?.userAnalytics?.userAnalyticsSubTitle3;
            default:
                return selectedLanguage?.userAnalytics?.userAnalyticsSubTitle1;
        }
    }

    return (
        <div className="user-analytics">
            {
                isNeedCreateShop ? <ShopTemplatesView />
                :  
                    <div className="user-analytics--wrpa">
                        <h3 className="user-analytics__title">{selectedLanguage?.userAnalytics?.userAnalyticsTitle} {user.first_name},</h3>
                        <h3 className="user-analytics__subtitle">{getSubTitle()}</h3>
                        <Box className="user-analytics__tab-wrap" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tabValue} onChange={handleChange} textColor='inherit'>
                                <Tab label={selectedLanguage?.userAnalytics?.userAnalyticsBtnPage1}/>
                                <Tab label={selectedLanguage?.userAnalytics?.userAnalyticsBtnPage2}/>
                            </Tabs>
                        </Box>
                        {
                            !!shop?._id?.length &&
                                <>
                                    {
                                        tabValue === 0 ? <MoreAnalytics1 /> :
                                        tabValue === 1 ?
                                        <MoreAnalytics2 /> :
                                        <></>
                                    }
                                </>
                        }
                    </div>
            }
        </div>
    );
}

export default UserAnalyticsView;
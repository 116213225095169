import { useEffect, useRef, useState } from 'react';
import './UserMessageView.css';
import { useNavigate, useParams } from 'react-router-dom';
import envelope from './../../assets/images/envelope.svg';
import avatar from './../../assets/images/avatar.svg';
import stars from './../../assets/images/stars.svg';
import plusImg from './../../assets/images/plusImg.svg';
import arrow2 from './../../assets/images/arrow2.svg';
import MessageClient from './../../components/MessageClient/MessageClient';
import { useDispatch, useSelector } from 'react-redux';



function UserMessageView() {
    const user = useSelector(state => state.userSlice.user);
    const [isOpenChat, setIsOpenChat] = useState(false);
    const [openChat, setOpenChat] = useState({});
    const [favoriteId, setFavoriteId] = useState('');
    const [vw, setVw] = useState(window.innerWidth);
    const navigate = useNavigate();
    const userMessageContactsWrap = useRef();

    let chats = [
        {
            _id: '1',
            full_name: 'Lisa F. Bogar',
            client_id: '12212',
            updated_at: '40s',
            last_message: 'asd asd asdsdd sdaasdasd asd asdsd asd',
            countMessage: '10',
            client_phone: '+3806731515',
        },
        {
            _id: '2',
            client_id: '12222',
            full_name: 'Lisa Bogar',
            updated_at: '10s',
            last_message: 'asd sd asd asdsd asd',
            countMessage: '50',
            client_phone: '+380111114515',
        },
        {
            _id: '3',
            client_id: '12332',
            full_name: 'Li55sa Bogar',
            updated_at: '40s',
            last_message: 'asd asd asdsdd sdaasd s s s asd asd asdsd asd',
            countMessage: '',
            client_phone: '+38067000005',
        }
    ]

    useEffect(() => {

        window.addEventListener("resize", handleResize);
  
        return () => window.removeEventListener("resize", handleResize);
    }, [])

    const handleOpenChat = (obj) => {
        setIsOpenChat(true)
        setOpenChat(obj)
    }
    
    const handleCloseChat = () => {
        setIsOpenChat(false)
        setOpenChat({})
    }

    const handleResize = () => {
        setVw(window.innerWidth)
    }

    
    return (
        <div className="user-message">
            <div className="user-message--wrap">
                <div className='user-message__title-wrap'>
                    <div className='user-message__title'>
                        <img className='user-message__title-products-img' src={envelope} alt='img' />
                        Повідомлення&nbsp;(8 Контактів)
                    </div>

                    <button className='user-message__title-btn' onClick={handleCloseChat}>
                        <img className='user-message__title-btn-img' src={plusImg} alt='img' />
                        <span>Закрити чат</span> 
                    </button>
                </div>  

                <div className="user-message__contacts-wrap" ref={userMessageContactsWrap}>
                    <div className={`user-message__contacts-list ${openChat?.full_name?.length && userMessageContactsWrap?.current?.offsetWidth < 650 ? 'user-message__contacts-list--block'  : ''}`}>
                        {
                            !!chats?.length && chats.map(el => (
                                <div className={`user-message__contacts-list-item ${el._id === openChat._id ? 'user-message__contacts-list-item--active' : ''}`} onClick={() => handleOpenChat(el)} key={el._id}>
                                    <img className='user-message__contacts-list-item-img' src={avatar} alt='img' />
                                    <div className="user-message__contacts-list-item-text-wrap">
                                        <div className="user-message__contacts-list-item-text-name-wrap">
                                            <div className="user-message__contacts-list-item-text-name">{el.full_name}</div>
                                            <div className="user-message__contacts-list-item-text-date">{el.updated_at}</div>
                                        </div>
                                        <div className="user-message__contacts-list-item-message-wrap">
                                            <div className="user-message__contacts-list-item-message">{el.last_message}</div>
                                            {
                                                !!el.countMessage?.length && <div className='user-message__contacts-list-item-message-count'>{el.countMessage}</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    <div className={`user-message__contacts-chat-wrap ${vw > 979 || userMessageContactsWrap?.current?.offsetWidth > 650 || openChat?.full_name?.length ? 'user-message__contacts-chat-active' : ''}`}>
                        {
                            !isOpenChat ? <div className="user-message__contacts-chat-none">Щоб розпочати переписку виберіть чат</div>
                            :
                            <div className="user-message__contacts-chat">
                                <div className="user-message__contacts-chat-header">
                                    <div className="user-message__contacts-chat-header-name-wrap">
                                        <button className="user-message__contacts-chat-header-name-btn" onClick={handleCloseChat} ><img src={arrow2} alt='img'/></button>
                                        <img className='user-message__contacts-chat-header-name-img' onClick={() => navigate(`/auth/${user._id}/customers/${openChat.client_id}`)} src={avatar} alt='img' />
                                        <div className='user-message__contacts-chat-header-name--wrap' onClick={() => navigate(`/auth/${user._id}/customers/${openChat.client_id}`)}>
                                            <div className='user-message__contacts-chat-header-name'>{openChat.full_name}</div>
                                            <div className='user-message__contacts-chat-header-phone'>{openChat.client_phone}</div>
                                        </div>
                                    </div>
                                    {/* <img className='user-message__contacts-chat-header-favorite-btn' src={stars} alt='img' /> */}
                                    <svg className='user-message__contacts-chat-header-favorite-btn' onClick={() => setFavoriteId(openChat.client_id)} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        viewBox="0 0 404.204 404.204" xmlSpace="preserve">
                                        <g>
                                            <path style={{fill: `${openChat.client_id === favoriteId ? 'red' : '#A5ABB3'}`}} d="M316.953,395.112c-3.35,0-6.706-1.033-9.567-3.106l-105.279-76.5L96.829,392.001
                                                c-5.706,4.15-13.429,4.15-19.14,0c-5.706-4.145-8.088-11.487-5.912-18.199l40.211-123.771L6.709,173.546
                                                c-5.706-4.15-8.088-11.493-5.912-18.199s8.425-11.243,15.48-11.243h130.135l40.211-123.771c2.176-6.706,8.431-11.243,15.48-11.243
                                                c7.049,0,13.304,4.536,15.48,11.243l40.211,123.771h130.135c7.054,0,13.304,4.536,15.48,11.243s-0.207,14.049-5.912,18.199
                                                l-105.268,76.49l40.211,123.771c2.176,6.706-0.207,14.049-5.912,18.199C323.676,394.078,320.314,395.112,316.953,395.112z
                                                M202.107,279.118c3.356,0,6.717,1.033,9.567,3.106l74.33,53.999l-28.397-87.373c-2.176-6.706,0.207-14.049,5.912-18.199
                                                l74.33-53.999h-91.877c-7.044,0-13.293-4.536-15.48-11.243l-28.386-87.384l-28.386,87.384c-2.187,6.706-8.441,11.243-15.48,11.243
                                                H66.364l74.33,53.999c5.706,4.15,8.088,11.493,5.912,18.199l-28.392,87.373l74.33-53.999
                                                C195.39,280.152,198.757,279.118,202.107,279.118z"/>
                                        </g>
                                    </svg>
                                </div>

                                <div className='user-message__contacts-chat-client-wrap'>
                                    <MessageClient />
                                </div>
                            </div> 
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserMessageView;
import './UserProductView.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts, setEditProduct, setRemoveProduct, setSearchBarcode, setUpdataProduct } from '../../store/userSlice';
import deleteImg from '../../assets/images/deleteImg.svg';
import deleteImgHover from '../../assets/images/deleteImgHover.svg';
import editIcon from './../../assets/images/editIcon.svg';
import editIconHover from './../../assets/images/editIconHover.svg';
import eye from './../../assets/images/eye.svg';
import eyeHover from './../../assets/images/eyeHover.svg';
import eyeHide from './../../assets/images/eyeHide.svg';
import eyeHideHover from './../../assets/images/eyeHideHover.svg';
import products2 from './../../assets/images/products2.svg';
import plus2 from './../../assets/images/plus2.svg';
import menu from './../../assets/images/menu.svg';
import block from './../../assets/images/block.svg';
import PaginationItems from '../../components/PaginationItems/PaginationItems';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import ProductCardUser from '../../components/ProductCardUser/ProductCardUser';
import Preloader from '../../components/Preloader/Preloader';
import { fetchGetData, fetchRequest, handleCategoriesMap, handleShopCurrency, showMessage } from '../../helpers/Utils';
import mixpanel from 'mixpanel-browser';
import { Box, FormControl, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Tooltip } from '@mui/material';
mixpanel.init('cbc9e4ee0d40873b5a2e3b5e39eedd33', { debug: true }); 
mixpanel.track('Visit products page');

function UserProductView() {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const isManagerRole = useSelector(state => state.userSlice.isManagerRole);
    const searchBarcode = useSelector(state => state.userSlice.searchBarcode);
    const user = useSelector(state => state.userSlice.user);
    const shop = useSelector(state => state.userSlice.shop);
    const categories = useSelector(state => state.userSlice.categories);
    const products = useSelector(state => state.userSlice.products);
    const [isModalDelProduct, setIsModalDelProduct] = useState(false);
    const [isModalHideProduct, setIsModalHideProduct] = useState(false);
    const [isModalHideProductError, setIsModalHideProductError] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [hideProduct, setHideProduct] = useState({});
    const [isSortByHide, setIsSortByHide] = useState('-1');
    const [seachName, setSeachName] = useState(searchBarcode);
    const [selectedSort, setSelectedSort] = useState('');
    const [selectedSortPrice, setSelectedSortPrice] = useState('');
    const [selectedPaget, setSelectedPaget] = useState('0');
    const [quantityAllProducts, setQuantityAllProducts] = useState('');
    const [countProducts, setCountProducts] = useState(null);
    const [isOpenSelectCategory, setIsOpenSelectCategory] = useState(false);
    const [isOpenSelectSort, setIsOpenSelectSort] = useState(false);
    const [isCardLook, setIsCardLook] = useState(true);
    const [discounts, setDiscounts] = useState('');
    const [newers, setNewers] = useState('');
    const [selectedSortPriceValue, setSelectedSortPriceValue] = useState('');
    const [tabValue, setTabValue] = useState(0);
    const [isPreloader, setIsPreloader] = useState(true);
    const [vw, setVw] = useState(window.innerWidth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem('token')
    
    useEffect(() => {
        sortCategory()

        window.addEventListener("resize", handleResize);
           
        return () => {
            window.removeEventListener("resize", handleResize);
            dispatch(setSearchBarcode(''));
        };
    }, []);

    useEffect(() => {
        if (seachName?.length == 0 && shop?._id?.length) {
            sortCategory()
        }
    }, [seachName]);
    
    useEffect(() => {
        if (searchBarcode?.length) {
            setSeachName(searchBarcode)
        }
    }, [searchBarcode]);
    
    useEffect(() => {
        sortCategory() 
    }, [ searchBarcode, isSortByHide, shop, selectedSort, selectedPaget, selectedSortPrice, discounts, newers]);
    
    const handleSearchProduct = (e) => {
        mixpanel.track('Search product on products page');
        if(e.key === 'Enter' || e.type == "click"){
            sortCategory()
        }
    }

    const handleResize = () => {
        setVw(window.innerWidth)
    }
    
    const sortCategory = () => {
        mixpanel.track('Sort category on products page'); 
        fetchGetData(`${process.env.REACT_APP_BASE_URL}/products/${shop._id}/all?category_id=${selectedSort}&hide=${isSortByHide}&page=${selectedPaget}&price_filter=${selectedSortPrice}&discounts=${discounts}&newers=${newers}&name=${seachName?.length ? seachName : searchBarcode?.length ? searchBarcode : ''}`)
            .then(res => {
                if (res.success && res.data) {
                    dispatch(getProducts(res.data));
                    setQuantityAllProducts(res.count % 10 == 0 ? res.count / 10 : Math.ceil(res.count / 10))
                    setCountProducts(res.count)
                } else {
                    console.log('GET UserProduct:', res)
                }
            })

        setIsPreloader(false)
    };
   
    const handleChangeSortPrice = (value) => {
        mixpanel.track('Sort by prace on products page'); 
        if (value === '') {
            setSelectedSortPriceValue('')
            return
        }
        if (value === 'new') {
            setNewers('1')
            setSelectedSortPrice('')
        } else {
            setSelectedSortPrice(value)
            setNewers('')
        }
        setSelectedSortPriceValue(value)
        setSelectedPaget('0')
    };

    const handleEditProduct = (obj) => {
        mixpanel.track('Edit product on products page'); 
        dispatch(setEditProduct(obj))
        localStorage.setItem('editProduct', JSON.stringify(obj));
        navigate(`/auth/${user._id}/product/create`)
    }

    const handleDeleteProduct = (id) => {
        mixpanel.track('Delete product on products page'); 
        setIsModalDelProduct(true)
        setDeleteId(id)
    }
    
    const handleHideProduct = (obj) => {
        mixpanel.track('Hide product on products page'); 
        if (obj.hide) {
            fetchGetData(`${process.env.REACT_APP_BASE_URL}/categories/${obj.category_id}?token=${token}`)
                .then(res => {
                    if (res.success && res.data) {
                        if (res.data.hide) {
                            setIsModalHideProductError(true)
                        } else {
                            setIsModalHideProduct(true)
                            setHideProduct(obj)
                        }
                    } else {
                        setIsModalHideProduct(true)
                        setHideProduct(obj)
                        console.log('GET UserProduct:', res)
                    }
                })
        } else {
            setIsModalHideProduct(true)
            setHideProduct(obj)
        }

    }
   
    const handleClose = () => {
        if (isOpenSelectSort || isOpenSelectCategory) {
            setIsOpenSelectSort(false)
            setIsOpenSelectCategory(false)
        }
    }

    const handleChangeSortAdditive = (value) => {
        if (value == 'newPrice') {
            if (discounts?.length) {
                setDiscounts('')
            } else {
                setDiscounts('1')
            }
        } 
        setSelectedPaget('0')
    };
    
    const handleIsHideProductError = (boolean) => {
        setIsModalHideProductError(false)
    }; 

    const handleChangeTabs = (event, newValue) => {
        setTabValue(newValue);
        if (newValue == 0) {
            setIsSortByHide('-1')
        } else if (newValue == 1) {
            setIsSortByHide('1')
        }
    };

    const handleIsDeleteProduct = (boolean) => {
        if (boolean) {
            const data = {
                token: token,
            }
    
            fetchRequest('DELETE', `${process.env.REACT_APP_BASE_URL}/products/${deleteId}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
                .then(res => {
                    if (res.success && res.data) {
                        dispatch(setRemoveProduct(deleteId))
                        setCountProducts(countProducts - 1)
                        showMessage('success', selectedLanguage?.userProduct?.userProductShowMessageDelProduct)
                    } else {
                        console.log('DELETE UserProduct', res)
                    }
                })
        }

        setIsModalDelProduct(false)
        setDeleteId('')
    }

    const handleIsHideProduct = (boolean) => {
        if (boolean) {
            const data = {
                ...hideProduct,
                hide: hideProduct.hide ? false : true,
                token: token,
            }
            
            fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/products/${hideProduct._id}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
                .then(res => {
                    if (res.success && res.data) {
                        dispatch(setRemoveProduct(hideProduct._id))
                        dispatch(setUpdataProduct({...hideProduct, hide: hideProduct.hide ? false : true}))
                        showMessage('success', selectedLanguage?.userProduct?.userProductShowMessageUpdataProduct)
                    } else {
                        console.log('PUT UserProduct', res)
                    }
                })
        }

        setIsModalHideProduct(false)
        setHideProduct({})
    }

    return (
        <div className='user-product' onClick={handleClose}>
            {
                isModalDelProduct && <ModalWindow title={selectedLanguage?.userProduct?.userProductModalDelTitle}  text={selectedLanguage?.userProduct?.userProductModalDelText} handleClick={handleIsDeleteProduct}/>
            }
            {
                isModalHideProduct && <ModalWindow title={hideProduct?.hide ? selectedLanguage?.userProduct?.userProductModalHideTitle2 : selectedLanguage?.userProduct?.userProductModalHideTitle1}  text={hideProduct?.hide ? selectedLanguage?.userProduct?.userProductModalHideText2 : selectedLanguage?.userProduct?.userProductModalHideText1} handleClick={handleIsHideProduct} />
            }
            {
                isModalHideProductError && <ModalWindow title={selectedLanguage?.userProduct?.userProductModalHideErrorTitle}  text={selectedLanguage?.userProduct?.userProductModalHideErrorText} handleClick={handleIsHideProductError} notBtn={true} />
            }

            {
                isPreloader ? <Preloader /> :
                    <div className='user-product--wrap'>

                        <div className='user-product__sub-title-wrap'>
                            <div className='user-product__sub-title'>
                                <img className='user-product__sub-title-products-img' src={products2} alt='img' />
                                {selectedLanguage?.userProduct?.userProductSubTitle1}&nbsp;({countProducts} {selectedLanguage?.userProduct?.userProductSubTitle2})
                            </div>

                            <Box className="user-product__tab-wrap" sx={{  }}>
                                <Tabs value={tabValue} onChange={handleChangeTabs} textColor='inherit'>
                                    <Tab label={selectedLanguage?.userProduct?.userProductSortOptionAll}/>
                                    <Tab label={selectedLanguage?.userProduct?.userProductHidden}/>
                                </Tabs>
                            </Box>

                            <button className='user-product__sub-title-btn' onClick={() => navigate('create')} disabled={isManagerRole}>
                                <img className='user-product__sub-title-btn-img' src={plus2} alt='img' />
                                {selectedLanguage?.userProduct?.userProductCreateBtn}
                            </button>
                        </div>

                        <div className='user-product__filter--wrap'>
                            <div className='user-product__filter-wrap'>
                                <div className='user-product__filter'>
                                    <TextField className='user-product__filter-input' value={seachName} onChange={(e) => setSeachName(e.target.value)} onKeyDown={(e) => handleSearchProduct(e)} label={selectedLanguage?.userProduct?.userProductSearchPlaceholder} variant="outlined" />
                                    <button className='user-product__filter-btn' onClick={(e) => handleSearchProduct(e)}>{selectedLanguage?.userProduct?.userProductSearchBtn}</button>
                                </div>

                                <FormControl className='user-product__price-select' fullWidth>
                                    <InputLabel id="userNotificationsSort">{selectedLanguage?.userProduct?.userProductPriceLabel}</InputLabel>
                                    <Select
                                        labelId="userNotificationsSort"
                                        id="userNotificationsSort"
                                        value={selectedSortPriceValue}
                                        label={selectedLanguage?.userProduct?.userProductPriceLabel}
                                        onChange={(e) => handleChangeSortPrice(e.target.value)}
                                    >
                                        <MenuItem value={''}>{selectedLanguage?.userProduct?.userProductPriceOptions1}</MenuItem>
                                        <MenuItem value={'1'}>{selectedLanguage?.userProduct?.userProductPriceOptions2}</MenuItem>
                                        <MenuItem value={'-1'}>{selectedLanguage?.userProduct?.userProductPriceOptions3}</MenuItem>
                                        <MenuItem value={'new'}>{selectedLanguage?.userProduct?.userProductPriceOptions5}</MenuItem>
                                        <label className="user-product__price-option user-product__price-option-checkbox-wrap" onClick={(e) => e.stopPropagation()} onChange={() => handleChangeSortAdditive('newPrice')}><input type="checkbox" defaultChecked={discounts.length}/>{selectedLanguage?.userProduct?.userProductPriceOptions4}</label>
                                    </Select>
                                </FormControl>

                                <FormControl className='user-product__category-select' fullWidth>
                                    <InputLabel id="userNotificationsSort">{selectedLanguage?.userProduct?.userProductSortLabel}</InputLabel>
                                    <Select
                                        labelId="userNotificationsSort"
                                        id="userNotificationsSort"
                                        value={selectedSort}
                                        label={selectedLanguage?.userProduct?.userProductSortLabel}
                                        onChange={(e) => setSelectedSort(e.target.value)}
                                    >
                                        <MenuItem value=''>{selectedLanguage?.creationProduct?.creationProductCategoryLabel}</MenuItem>
                                        {
                                            !!categories?.length && handleCategoriesMap(categories).map(category => (
                                                    <MenuItem value={category._id} key={category._id}><p className={`${category.parent_id === 'null' || category.parent_id === null ? 'user-product__category-selected' : ''}`}>{category.name}</p></MenuItem>
                                                ))
                                        }
                                    </Select>
                                </FormControl>
                            </div>    
                            <div className='user-product__look-btn-wrap'>
                                <button onClick={() => setIsCardLook(false)} className={`user-product__look-btn user-product__look-btn-left ${!isCardLook ? 'user-product__look-btn-active' : ''}`}><img src={menu} alt='img'/></button>
                                <button onClick={() => setIsCardLook(true)} className={`user-product__look-btn user-product__look-btn-rigth ${isCardLook ? 'user-product__look-btn-active' : ''}`}><img src={block} alt='img'/></button>
                            </div>            
                        </div>   

                        <div className='user-product__cards'>
                            {
                                !!products?.length && !isPreloader ? isCardLook ? products.map(el => (
                                    <ProductCardUser product={el} key={el._id}/>
                                )) : 
                                <div className='user-product__list-cards'>
                                    {
                                        products?.length &&  products.map(el => (
                                            <Tooltip title={<div><ProductCardUser product={el}/></div>} key={el._id} placement={vw < 767 ? 'bottom' : 'left'}>
                                                <div className='user-product__list-card'>
                                                    <div className='user-product__list-card-text'>
                                                        <div className='user-product__list-card-name'>{el.name}</div>
                                                        <div className='user-product__list-card-price-wrap'>
                                                            {!!el?.new_price && 
                                                                <span className='user-product__list-card-new-price'>&nbsp;{el.new_price}{handleShopCurrency(shop.currency)}</span>
                                                            }
                                                            <span className={`user-product__list-card-price ${el.new_price ? 'user-product__list-card-price-old' : ''}`}>&nbsp;{el.price}{handleShopCurrency(shop.currency)} {el.new_price ? '(без знижки)' : ''}</span>
                                                        </div>
                                                        <div className='user-product__list-card-category-wrap'>
                                                            <span className='user-product__list-card-category-title'>{selectedLanguage?.userProduct?.userProductCardCategory}</span>
                                                            <span className='user-product__list-card-category'>&nbsp;{el.category_name}</span>
                                                        </div>
                                                    </div>

                                                    <div className='user-product__card-btn-wrap'>
                                                        <div className='user-product__card-btn--wrap'>
                                                            <button className="user-product__card-btn user-product__card-btn-hide" onClick={() => handleHideProduct(el)} disabled={isManagerRole}>
                                                                <img src={el.hide ? eyeHide : eye} alt='img'/>
                                                            </button>
                                                            <button className="user-product__card-btn user-product__card-btn-hide--hover" onClick={() => handleHideProduct(el)} disabled={isManagerRole}>
                                                                <img src={el.hide ? eyeHideHover : eyeHover} alt='img'/>
                                                            </button>
                                                        </div>

                                                        <div className='user-product__card-btn--wrap'>
                                                            <button className="user-product__card-btn user-product__card-btn-edite" onClick={() => handleEditProduct(el)} disabled={isManagerRole}>
                                                                <img src={editIcon} alt='img'/>
                                                            </button>
                                                            <button className="user-product__card-btn user-product__card-btn-edite--hover" onClick={() => handleEditProduct(el)} disabled={isManagerRole}>
                                                                <img src={editIconHover} alt='img'/>
                                                            </button>
                                                        </div>

                                                        <div className='user-product__card-btn--wrap'>
                                                            <button className="user-product__card-btn user-product__card-btn-delete" onClick={() => handleDeleteProduct(el._id)} disabled={isManagerRole}>
                                                                <img src={deleteImg} alt='img'/>
                                                            </button>
                                                            <button className="user-product__card-btn user-product__card-btn-delete--hover" onClick={() => handleDeleteProduct(el._id)} disabled={isManagerRole}>
                                                                <img src={deleteImgHover} alt='img'/>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tooltip>
                                        ))
                                    }
                                </div> 
                                : <div className='user-product__product-missing'>{selectedLanguage?.userProduct?.userProductProductMissing}</div>
                            }
                        </div>

                        <PaginationItems selectedPaget={selectedPaget} setSelectedPaget={setSelectedPaget} pageRangeDisplayed={5} itemsPerPage={10} quantityAllProducts={quantityAllProducts}/>
                    </div>
            }

        </div>
    );
}

export default UserProductView;
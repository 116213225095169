import './UserShopView.css';
import { useEffect, useState } from 'react';
import shopImg from './../../assets/images/shopImg.svg';
import shopBlocks from './../../assets/images/shopBlocks.svg';
import shopBlocks1 from './../../assets/images/shopBlocks1.svg';
import color from './../../assets/images/color.svg';
import color1 from './../../assets/images/color1.svg';
import templates from './../../assets/images/templates.svg';
import templates1 from './../../assets/images/templates1.svg';
import shopMenu from './../../assets/images/shopMenu.svg';
import shopMenu1 from './../../assets/images/shopMenu1.svg';
import slider from './../../assets/images/slider.svg';
import slider1 from './../../assets/images/slider1.svg';
import shopCard from './../../assets/images/shopCard.svg';
import shopCard1 from './../../assets/images/shopCard1.svg';
import eye2 from './../../assets/images/eye2.svg';
import eyeHide2 from './../../assets/images/eyeHide2.svg';
import save from './../../assets/images/save.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setShop } from '../../store/userSlice';
import CreationShop from '../../components/CreationShop/CreationShop';
import InputNumber from '../../components/InputNumber/InputNumber';
import PromoCode from '../../components/PromoCode/PromoCode';
import ShopTemplatesView from '../ShopTemplatesView/ShopTemplatesView';
import { userTypeStore } from '../../userTypeStore';
import InputColor from '../../components/InputColor/InputColor';
import OrderElements from '../../components/OrderElements/OrderElements';
import { fetchRequest, showMessage } from '../../helpers/Utils';
import { Box, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Switch, Tab, Tabs, TextField } from '@mui/material';
import mixpanel from 'mixpanel-browser';
import MainSlider from '../../components/MainSlider/MainSlider';
import SelectCard from '../../components/SelectCard/SelectCard';
import Layout from '../../Layout/Layout';
mixpanel.init('cbc9e4ee0d40873b5a2e3b5e39eedd33', { debug: true }); 
mixpanel.track('Visit shop page');

function UserShopView() {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const selectedBlocks = useSelector(state => state.userSlice.selectedBlocks);
    const shop = useSelector(state => state.userSlice.shop);
    const [userLanguage, setUserLanguage] = useState('UA');
    const [informationBlockTitle, setInformationBlockTitle] = useState('');
    const [informationBlockText, setInformationBlockText] = useState('');
    const [informationBlockDescription, setInformationBlockDescription] = useState('');
    const [colorTitle, setColorTitle] = useState('#000');
    const [colorText, setColorText] = useState('#000');
    const [colorBackground, setColorBackground] = useState('#fff');
    const [sizeTitle, setSizeTitle] = useState('18');
    const [sizeText, setSizeText] = useState('16');
    const [fontWeightTitle, setFontWeightTitle] = useState('600');
    const [fontWeightText, setFontWeightText] = useState('500');
    const [checkTextAlign, setCheckTextAlign] = useState('start');
    const [shadowTitleX, setShadowTitleX] = useState('');
    const [shadowTitleY, setShadowTitleY] = useState('');
    const [shadowTitleZ, setShadowTitleZ] = useState('');
    const [shadowTitleTransparency, setShadowTitleTransparency] = useState('100');
    const [shadowTextX, setShadowTextX] = useState('');
    const [shadowTextY, setShadowTextY] = useState('');
    const [shadowTextZ, setShadowTextZ] = useState('');
    const [shadowTextTransparency, setShadowTextTransparency] = useState('100');
    const dispatch = useDispatch();
    const [selectTypeStore, setSelectTypeStore] = useState([]);
    const [isPreloader, setIsPreloader] = useState(false);
    const [textInfoTopBlock, setTextInfoTopBlock] = useState('');
    const [bgColorTopBlockHeader, setBgColorTopBlockHeader] = useState('#ffffff');
    const [colorTopBlockHeader, setColorTopBlockHeader] = useState('#000000');
    const [isAnimationTopBlock, setIsAnimationTopBlock] = useState(true);
    const [isBgHeaderTransparent, setIsBgHeaderTransparent] = useState(false);
    const [bgColorHeader, setBgColorHeader] = useState('#4e4848');
    const [colorHeaderText, setColorHeaderText] = useState('#ffffff');
    const [bgNewProductHeader, setBgNewProductHeader] = useState('#f71111');
    const [colorNewProductHeader, setColorNewProductHeader] = useState('#ffffff');
    const [bgSubCategories, setBgSubCategories] = useState('#4e4848');
    const [colorSubCategoriesText, setColorSubCategoriesText] = useState('#ffffff');
    const [typeHeader, setTypeHeader] = useState('1');
    const [bgColorFooter, setBgColorFooter] = useState('#F0F0F2');
    const [colorFooterText, setColorFooterText] = useState('#000000');
    const [subscriptionInformation, setSubscriptionInformation] = useState('');
    const [isSubscriptionHide, setIsSubscriptionHide] = useState(false);
    const [bgColorMain, setBgColorMain] = useState('#ffffff');
    const [colorMainText, setColorMainText] = useState('#000000');
    const [selectBtn, setSelectBtn] = useState('5');
    const [colorTextBtn, setColorTextBtn] = useState('#ffffff');
    const [colorTextBtnHover, setColorTextBtnHover] = useState('#ffffff');
    const [firstColorGradient, setFirstColorGradient] = useState('#060e83');
    const [secondColorGradient, setSecondColorGradient] = useState('#060e83');
    const [firstColorGradientHover, setFirstColorGradientHover] = useState('#060e83');
    const [secondColorGradientHover, setSecondColorGradientHover] = useState('#060e83');
    const token = localStorage.getItem('token')
    
    const [tabValue, setTabValue] = useState(0);
    const [tabGeneralValue, setTabGeneralValue] = useState(0);
    const [tabSettingValue, setTabSettingValue] = useState(0);
    const [isTabSend, setIsTabSend] = useState(false);
    const [isUpdateSlider, setIsUpdateSlider] = useState(false);
    const [selectExample, setSelectExample] = useState("1");
    const [testImg, setTestImg] = useState({});
    const [tokenLinck, setTokenLinck] = useState('');
    const [shopSettingTest, setShopSettingTest] = useState({});
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);

    useEffect(() => {
        const root = document.documentElement; 
        root.style.setProperty(`--colorTextBtn`, colorTextBtn); 
        root.style.setProperty(`--colorTextBtnHover`, colorTextBtnHover); 
        root.style.setProperty(`--firstColorGradient`, firstColorGradient); 
        root.style.setProperty(`--secondColorGradient`, secondColorGradient); 
        root.style.setProperty(`--firstColorGradientHover`, firstColorGradientHover); 
        root.style.setProperty(`--secondColorGradientHover`, secondColorGradientHover); 
    }, [colorTextBtn, colorTextBtnHover, firstColorGradient, secondColorGradient, firstColorGradientHover, secondColorGradientHover])

    useEffect(() => {
        let res = (JSON.parse(localStorage.getItem('userLanguage')));
        if (res?.length) {
            setUserLanguage(res)
        }
    }, [])
    
    useEffect(() => {
        let res = {
            ...shop,
            colorSettings: {
                textInfoTopBlock: textInfoTopBlock, 
                bgColorTopBlockHeader: bgColorTopBlockHeader, 
                isAnimationTopBlock: isAnimationTopBlock, 
                colorTopBlockHeader: colorTopBlockHeader, 
                bgColorHeader: isBgHeaderTransparent ? 'inherit' : bgColorHeader, 
                colorHeaderText: colorHeaderText, 
                bgNewProductHeader: bgNewProductHeader, 
                colorNewProductHeader: colorNewProductHeader, 
                bgSubCategories: bgSubCategories, 
                colorSubCategoriesText: colorSubCategoriesText, 
                typeHeader: typeHeader, 
                bgColorFooter: bgColorFooter, 
                colorFooterText: colorFooterText, 
                subscriptionInformation: subscriptionInformation, 
                isSubscriptionHide: isSubscriptionHide, 
                bgColorMain: bgColorMain, 
                colorMainText: colorMainText, 
                selectBtn: selectBtn, 
                colorTextBtn: colorTextBtn,
                colorTextBtnHover: colorTextBtnHover,
                firstColorGradient: firstColorGradient, 
                secondColorGradient: secondColorGradient, 
                firstColorGradientHover: firstColorGradientHover, 
                secondColorGradientHover: secondColorGradientHover, 
                selectConfigId: '',
            },
        }

        setShopSettingTest(res)
    }, [ bgColorHeader, secondColorGradientHover, firstColorGradientHover, secondColorGradient, firstColorGradient, colorTextBtnHover, colorTextBtn, selectBtn, colorMainText, bgColorMain, isSubscriptionHide, subscriptionInformation, colorFooterText, bgColorFooter, typeHeader, colorSubCategoriesText, bgSubCategories, colorNewProductHeader, bgNewProductHeader, colorHeaderText, isBgHeaderTransparent, textInfoTopBlock, bgColorTopBlockHeader, isAnimationTopBlock, colorTopBlockHeader])

    useEffect(() => {
        if (shop?.informationBlock?.length) {
            setInformationBlockTitle(shop.informationBlock[0].title) 
            setInformationBlockText(shop.informationBlock[0].text) 
            setInformationBlockDescription(shop.informationBlock[0].description) 
            setColorTitle(shop.informationBlock[0].colorTitle) 
            setColorText(shop.informationBlock[0].colorText)
            setColorBackground(shop.informationBlock[0].colorBackground) 
            setSizeTitle(shop.informationBlock[0].sizeTitle)
            setSizeText(shop.informationBlock[0].sizeText)
            setFontWeightTitle(shop.informationBlock[0].fontWeightTitle) 
            setFontWeightText(shop.informationBlock[0].fontWeightText) 
            setShadowTitleX(shop.informationBlock[0].shadowTitleX)
            setShadowTitleY(shop.informationBlock[0].shadowTitleY) 
            setShadowTitleZ(shop.informationBlock[0].shadowTitleZ)
            setShadowTitleTransparency(shop.informationBlock[0].shadowTitleTransparency) 
            setShadowTextX(shop.informationBlock[0].shadowTextX)
            setShadowTextY(shop.informationBlock[0].shadowTextY) 
            setShadowTextZ(shop.informationBlock[0].shadowTextZ)
            setCheckTextAlign(shop.informationBlock[0].textAlign) 
            setShadowTextTransparency(shop.informationBlock[0].shadowTextTransparency) 
        } 
        
        if (shop?._id && shop?.typeStore?.length) {
            setSelectTypeStore([...shop?.typeStore])
        } 
        
        if (shop?._id?.length && shop?.colorSettings) {
            setTextInfoTopBlock(shop?.colorSettings?.textInfoTopBlock?.length ? shop?.colorSettings.textInfoTopBlock : '')
            setBgColorTopBlockHeader(shop?.colorSettings?.bgColorTopBlockHeader?.length ? shop?.colorSettings.bgColorTopBlockHeader : '#ffffff')
            setColorTopBlockHeader(shop?.colorSettings?.colorTopBlockHeader?.length ? shop?.colorSettings.colorTopBlockHeader : '#000000')
            setIsAnimationTopBlock(shop?.colorSettings?.isAnimationTopBlock ? true : false)
            setIsBgHeaderTransparent(shop?.colorSettings?.bgColorHeader == 'inherit' ? true : false)
            setBgColorHeader(shop?.colorSettings?.bgColorHeader?.length ? shop?.colorSettings.bgColorHeader : '#4e4848')
            setColorHeaderText(shop?.colorSettings?.colorHeaderText?.length ? shop?.colorSettings.colorHeaderText : '#ffffff')
            setBgNewProductHeader(shop?.colorSettings?.bgNewProductHeader?.length ? shop?.colorSettings.bgNewProductHeader : '#f71111')
            setColorNewProductHeader(shop?.colorSettings?.colorNewProductHeader?.length ? shop?.colorSettings.colorNewProductHeader : '#ffffff')
            setBgSubCategories(shop?.colorSettings?.bgSubCategories?.length ? shop?.colorSettings.bgSubCategories : '#4e4848')
            setColorSubCategoriesText(shop?.colorSettings?.colorSubCategoriesText?.length ? shop?.colorSettings.colorSubCategoriesText : '#ffffff')
            setTypeHeader(shop?.colorSettings?.typeHeader?.length ? shop?.colorSettings.typeHeader : '1')
            setBgColorFooter(shop?.colorSettings?.bgColorFooter?.length ? shop?.colorSettings.bgColorFooter : '#F0F0F2')
            setColorFooterText(shop?.colorSettings?.colorFooterText?.length ? shop?.colorSettings.colorFooterText : '#000000')
            setIsSubscriptionHide(shop?.colorSettings?.isSubscriptionHide ? true : false)
            setSubscriptionInformation(shop?.colorSettings?.subscriptionInformation?.length ? shop?.colorSettings?.subscriptionInformation : '')
            setBgColorMain(shop?.colorSettings?.bgColorMain?.length ? shop?.colorSettings.bgColorMain : '#ffffff')
            setColorMainText(shop?.colorSettings?.colorMainText?.length ? shop?.colorSettings.colorMainText : '#000000')

            setSelectBtn(shop?.colorSettings?.selectBtn?.length ? shop?.colorSettings.selectBtn : '5')
            setColorTextBtn(shop?.colorSettings?.colorTextBtn?.length ? shop?.colorSettings.colorTextBtn : '#ffffff')
            setColorTextBtnHover(shop?.colorSettings?.colorTextBtnHover?.length ? shop?.colorSettings.colorTextBtnHover : '#ffffff')
            setFirstColorGradient(shop?.colorSettings?.firstColorGradient?.length ? shop?.colorSettings.firstColorGradient : '#060e83')
            setSecondColorGradient(shop?.colorSettings?.secondColorGradient?.length ? shop?.colorSettings.secondColorGradient : '#060e83')
            setFirstColorGradientHover(shop?.colorSettings?.firstColorGradientHover?.length ? shop?.colorSettings.firstColorGradientHover : '#060e83')
            setSecondColorGradientHover(shop?.colorSettings?.secondColorGradientHover?.length ? shop?.colorSettings.secondColorGradientHover : '#060e83')
        }

        if (shop.cardType?.length) {
            setSelectExample(shop.cardType)
        }
    }, [shop])
    
    const handleUpdateOrderElements = () => {
        setIsPreloader(true)

        let data = {
            ...shop,
            selectedBlocks: selectedBlocks,
            token: token,
        }
        sendUpdateShop(data)
    }
    
    const handleCreateBlockInfo = () => {
        setIsPreloader(true)

        mixpanel.track('Create shop on shop page');

        let data = {
            ...shop,
            informationBlock: [{
               title: informationBlockTitle, 
               text: informationBlockText, 
               description: informationBlockDescription, 
               colorTitle: colorTitle, 
               colorText: colorText, 
               colorBackground: colorBackground, 
               sizeTitle: sizeTitle, 
               sizeText: sizeText, 
               fontWeightTitle: fontWeightTitle, 
               fontWeightText: fontWeightText, 
               shadowTitleX: shadowTitleX, 
               shadowTitleY: shadowTitleY, 
               shadowTitleZ: shadowTitleZ, 
               shadowTitleTransparency: shadowTitleTransparency, 
               shadowTextX: shadowTextX, 
               shadowTextY: shadowTextY, 
               shadowTextZ: shadowTextZ, 
               textAlign: checkTextAlign, 
               shadowTextTransparency: shadowTextTransparency, 
            }],
            token: token,
        }
        
        sendUpdateShop(data)
    }
    
    const handleCleanBlockInfo = () => {
        mixpanel.track('Clean block info on shop page');

        setIsPreloader(true)

        setInformationBlockTitle('') 
        setInformationBlockText('') 
        setInformationBlockDescription('') 
        setColorTitle('#000') 
        setColorText('#000')
        setColorBackground('#fff') 
        setSizeTitle('18')
        setSizeText('16')
        setFontWeightTitle('600') 
        setFontWeightText('500') 
        setShadowTitleX('')
        setShadowTitleY('') 
        setShadowTitleZ('')
        setShadowTitleTransparency('100') 
        setShadowTextX('')
        setShadowTextY('') 
        setShadowTextZ('')
        setCheckTextAlign('start') 
        setShadowTextTransparency('100') 
        let data = {
            ...shop,
            informationBlock: [],
            selectedBlocks: [...shop.selectedBlocks.filter(el => el.id !== 5)],
            token: token,
        }

        sendUpdateShop(data)
    }

    const handleCreateBlockAdvertising = () => {
        mixpanel.track('Create advertising block info on shop page');

        if (selectTypeStore?.length) {
            setIsPreloader(true)
            let data = {
                ...shop,
                typeStore: selectTypeStore,
                token: token,
            }

            console.log(data)
            sendUpdateShop(data)
        }
    }
    
    const handleCleanBlockAdvertising = () => {
        mixpanel.track('Clean advertising block info on shop page');

        setIsPreloader(true)
        let data = {
            ...shop,
            typeStore: [],
            selectedBlocks: [...shop.selectedBlocks.filter(el => el.id !== 6)],
            token: token,
        }

        sendUpdateShop(data)
        setSelectTypeStore([])
    }
   
    const handleSelectTypeStore = (id) => {
        mixpanel.track('Select store type on shop page');

        if (selectTypeStore.includes(id)) {
            setSelectTypeStore([...selectTypeStore.filter(el => el !== id)])
        } else {
            setSelectTypeStore([...selectTypeStore, id])
        }
    }
   
    const handleUpdateColorShop = () => {
        mixpanel.track('Update store color on shop page');

        setIsPreloader(true)
        let data = {
            ...shop,
            colorSettings: {
                textInfoTopBlock: textInfoTopBlock, 
                bgColorTopBlockHeader: bgColorTopBlockHeader, 
                isAnimationTopBlock: isAnimationTopBlock, 
                colorTopBlockHeader: colorTopBlockHeader, 
                bgColorHeader: isBgHeaderTransparent ? 'inherit' : bgColorHeader, 
                colorHeaderText: colorHeaderText, 
                bgNewProductHeader: bgNewProductHeader, 
                colorNewProductHeader: colorNewProductHeader, 
                bgSubCategories: bgSubCategories, 
                colorSubCategoriesText: colorSubCategoriesText, 
                typeHeader: typeHeader, 
                bgColorFooter: bgColorFooter, 
                colorFooterText: colorFooterText, 
                subscriptionInformation: subscriptionInformation, 
                isSubscriptionHide: isSubscriptionHide, 
                bgColorMain: bgColorMain, 
                colorMainText: colorMainText, 
                selectBtn: selectBtn, 
                colorTextBtn: colorTextBtn,
                colorTextBtnHover: colorTextBtnHover,
                firstColorGradient: firstColorGradient, 
                secondColorGradient: secondColorGradient, 
                firstColorGradientHover: firstColorGradientHover, 
                secondColorGradientHover: secondColorGradientHover, 
                selectConfigId: '',
            },
            token: token,
        }
        sendUpdateShop(data)
    }

    const handleUpdateCardShop = () => {
        if (shop._id) {
            let data = {
                ...shop,
                cardType: selectExample
            }

            sendUpdateShop(data)
        }
    }
  
    const sendUpdateShop = (data) => {
        mixpanel.track('Update shop on shop page');
        
        fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/shops/${shop._id}?token=${token}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError), () => setIsPreloader(false))
            .then(res => {
                if (res.success && res.data) {
                    console.log('PUT UserShop:', res)
                    dispatch(setShop(data));
                    showMessage('success', selectedLanguage?.creationShop?.creationShopShowSuccessUpdateShop)
                } else {
                    console.log('PUT UserShop:', res)
                }
            })
    }

    const handleCreateLinck = () => {

    }

    const handleTypeHeader = (value) => {
        setTypeHeader(value)
    }

    const hadleSend = () => {
        if (tabValue == 0 && tabGeneralValue === 0) {
            setIsTabSend(!isTabSend)
            return
        }
        if (tabValue == 1 && tabSettingValue === 0) {
            handleUpdateOrderElements()
            return
        }
        if (tabValue == 1 && tabSettingValue === 1) {
            handleUpdateColorShop()
            return
        }
        if (tabValue == 1 && tabSettingValue === 3) {
            handleCreateBlockInfo()
            return
        }
        if (tabValue == 1 && tabSettingValue === 4) {
            handleCreateBlockAdvertising()
            return
        }
        if (tabValue == 1 && tabSettingValue === 5) {
            setIsUpdateSlider(true)
            return
        }
        if (tabValue == 1 && tabSettingValue === 6) {
            handleUpdateCardShop()
            return
        }
    }
    
    return (
        <div className='user-shop'>
            <div className="user-shop__header">
                <div className="user-shop__header-title">
                    <img className="user-shop__header-title-img" src={shopImg} alt='img'/>
                    <div className="user-shop__header-title-text">{selectedLanguage?.userShopView?.userShopTitle}</div>
                </div>
                <Box className="user-shop__header-tab-wrap" sx={{  }}>
                    <Tabs value={tabValue} onChange={(e, value) => setTabValue(value)} textColor='inherit'>
                        <Tab label={selectedLanguage?.userShopView?.userShopTabs1}/>
                        <Tab label={selectedLanguage?.userShopView?.userShopTabs2}/>
                    </Tabs>
                </Box>
                <div className="user-shop__header-btn-wrap">
                    <button className='user-shop__header-btn' onClick={hadleSend}>
                        <img className='user-shop__header-btn-img' src={save} alt='img'/>
                        <p>{selectedLanguage?.userShopView?.userShopBtnSend}</p>
                    </button>
                </div>
            </div>
            {
                tabValue === 0 &&
                    <>
                        <Box className="user-shop__header-tab-general-wrap" sx={{  }}>
                            <Tabs variant={'scrollable'} value={tabGeneralValue} onChange={(e, value) => setTabGeneralValue(value)} textColor='inherit'>
                                <Tab label={selectedLanguage?.userShopView?.userShopTabs3}/>
                                <Tab label={selectedLanguage?.userShopView?.userShopTabs4}/>
                                {/* <Tab label={selectedLanguage?.userShopView?.userShopTabs5}/> */}
                            </Tabs>
                        </Box>
                        {
                            tabGeneralValue === 0 &&
                                <CreationShop isTabSend={isTabSend}/>
                        }
                        {
                            tabGeneralValue === 1 &&
                                <div className="user-shop__section-wrap">
                                        <div className="user-shop__section">
                                            <PromoCode />
                                    </div>
                                </div>
                        }
                        {
                            tabGeneralValue === 2 &&
                                <div className="user-shop__section-wrap">
                                    <div className="user-shop__section">
                                        <div className='user-shop__token-wrap'>
                                            <h3 className='user-shop__section-title'>{selectedLanguage?.userShopView?.userShopTokenTitle}</h3>
                                            <div className='user-shop__token-input-wrap'>
                                                <TextField className='user-shop__token-input' value={tokenLinck} onChange={(e) => setTokenLinck(e.target.value)} label={selectedLanguage?.userIntegration?.userIntegrationChatPlaceholder} variant="outlined" />
                                                <button className='user-shop__token-input-btn' onClick={() => handleCreateLinck()}>{shop?.chat_url?.length ? selectedLanguage?.userIntegration?.userIntegrationChatBtnCreate1 : selectedLanguage?.userIntegration?.userIntegrationChatBtnCreate2}</button>
                                            </div>
                                            <button className='user-shop__token-new-btn'>{selectedLanguage?.userShopView?.userShopTokenCreateBtn}</button>
                                        </div>
                                    </div>
                                </div>
                        }
                    </>
            }
            {
                tabValue === 1 &&
                    <>
                        <Box className="user-shop__header-tab-general-wrap" sx={{  }}>
                            <Tabs variant={'scrollable'} value={tabSettingValue} onChange={(e, value) => setTabSettingValue(value)} textColor='inherit'>
                                <Tab label={<div className="user-shop__header-tab-setting">
                                                <img className='user-shop__header-tab-setting-img' src={shopBlocks} alt='img'/> 
                                                <img className='user-shop__header-tab-setting-img1' src={shopBlocks1} alt='img'/> 
                                                <span>{selectedLanguage?.userShopView?.userShopTemplatesBtn6}</span>
                                            </div>}
                                />
                                <Tab label={<div className="user-shop__header-tab-setting">
                                                <img className='user-shop__header-tab-setting-img' src={color} alt='img'/> 
                                                <img className='user-shop__header-tab-setting-img1' src={color1} alt='img'/> 
                                                <span>{selectedLanguage?.userShopView?.userShopTemplatesBtn1}</span>
                                            </div>}
                                />
                                <Tab label={<div className="user-shop__header-tab-setting">
                                                <img className='user-shop__header-tab-setting-img' src={templates} alt='img'/> 
                                                <img className='user-shop__header-tab-setting-img1' src={templates1} alt='img'/> 
                                                <span>{selectedLanguage?.userShopView?.userShopTemplatesBtn2}</span>
                                            </div>}
                                />
                                <Tab label={<div className="user-shop__header-tab-setting">
                                                <img className='user-shop__header-tab-setting-img' src={shopMenu} alt='img'/> 
                                                <img className='user-shop__header-tab-setting-img1' src={shopMenu1} alt='img'/> 
                                                <span>{selectedLanguage?.userShopView?.userShopTemplatesBtn3}</span>
                                            </div>}
                                />
                                <Tab label={<div className="user-shop__header-tab-setting">
                                                <img className='user-shop__header-tab-setting-img' src={shopMenu} alt='img'/> 
                                                <img className='user-shop__header-tab-setting-img1' src={shopMenu1} alt='img'/> 
                                                <span>{selectedLanguage?.userShopView?.userShopTemplatesBtn4}</span>
                                            </div>}
                                />
                                <Tab label={<div className="user-shop__header-tab-setting">
                                                <img className='user-shop__header-tab-setting-img' src={slider} alt='img'/> 
                                                <img className='user-shop__header-tab-setting-img1' src={slider1} alt='img'/> 
                                                <span>{selectedLanguage?.userShopView?.userShopTemplatesBtn7}</span>
                                            </div>}
                                />
                                <Tab label={<div className="user-shop__header-tab-setting">
                                                <img className='user-shop__header-tab-setting-img' src={shopCard} alt='img'/> 
                                                <img className='user-shop__header-tab-setting-img1' src={shopCard1} alt='img'/> 
                                                <span>{selectedLanguage?.userShopView?.userShopTemplatesBtn8}</span>
                                            </div>}
                                />
                            </Tabs>
                        </Box>
                        {/* ----------------<Вибір карток>--------------------- */}
                        {
                            tabSettingValue === 6 && 
                                <div className="user-shop__section-wrap">
                                    <div className="user-shop__section">
                                        <SelectCard selectExample={selectExample} setSelectExample={setSelectExample}/>
                                    </div>
                                </div>
                        }
                        {/* ----------------<Головний слайдер>--------------------- */}
                        {
                            tabSettingValue === 5 && 
                                <div className="user-shop__section-wrap">
                                    <div className="user-shop__section">
                                        <MainSlider isUpdateSlider={isUpdateSlider} setIsUpdateSlider={setIsUpdateSlider}/>
                                    </div>
                                </div>
                        }
                        {/* ----------------<Блок реклами>--------------------- */}
                        {
                            tabSettingValue === 4 && 
                                <div className="user-shop__section-wrap">
                                    <div className="user-shop__section">
                                        <div className="user-shop__section-title-wrap">
                                            <h3 className="user-shop__section-title">{selectedLanguage?.userShopView?.userShopBlockAdvertisingTitle}</h3>
                                            {
                                                !!shop.typeStore?.length && 
                                                    <div className="user-shop__header-btn-wrap">
                                                        <button className='user-shop__header-btn' onClick={handleCleanBlockAdvertising}>{selectedLanguage?.userShopView?.userShopBlockRemoveBtn}</button>
                                                    </div>
                                            }
                                        </div>
                                        <div className="user-shop__section user-shop__section-advertising">
                                            <div className='user-shop__section-btn-wrap'>
                                                <div className='user-shop__section-btn-add-wrap'>
                                                    <div className="user-shop__section-btn-checkbox-label-wrap">
                                                        <FormControlLabel onChange={() => handleSelectTypeStore(1)} control={<Switch checked={selectTypeStore.includes(1) ? true : false} />} label={selectedLanguage?.userShopView?.userShopBlockAdvertisingCheckboxLabel} />
                                                    </div>
                                                    <h4 className="user-shop__section-btn-checkbox-label-text">{selectedLanguage?.userShopView?.userShopBlockAdvertisingInfo}</h4>
                                                </div>
                                            </div>
                                            <div className="user-shop__section-advertising-checkbox-wrap">
                                                {
                                                    !!userTypeStore[userLanguage]?.length && userTypeStore[userLanguage]?.map((el, i) => (
                                                        <div className="user-shop__section-checkbox" key={el.id + i}>
                                                            <FormControlLabel onChange={() => handleSelectTypeStore(el.id)} control={<Switch checked={selectTypeStore.includes(el.id) ? true : false} />} label={el.name} />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                        {/* ----------------<Блок інформації>--------------------- */}
                        {
                            tabSettingValue === 3 && 
                                <div className="user-shop__section-wrap">
                                    <div className="user-shop__section">
                                        <div className="user-shop__section-input-wrap">
                                            <div className='user-shop__create-wrap'>
                                                <div className='user-shop__create-title user-shop__create-title-name'>{selectedLanguage?.userShopView?.userShopBlockTitle}</div>
                                                <TextField className='user-shop__create-input-information' value={informationBlockTitle} onChange={(e) => setInformationBlockTitle(e.target.value)} label={selectedLanguage?.userShopView?.userShopBlockTitlePlaceholder} multiline variant="outlined" />
                                            </div>
                                            <div className='user-shop__create-wrap'>
                                                <div className='user-shop__create-title user-shop__create-title-name'>{selectedLanguage?.userShopView?.userShopBlockText}</div>
                                                <TextField className='user-shop__create-input-information' value={informationBlockText} onChange={(e) => setInformationBlockText(e.target.value)} label={selectedLanguage?.userShopView?.userShopBlockTextPlaceholder} rows={'5'} multiline variant="outlined" />
                                            </div>
                                            <div className='user-shop__create-wrap'>
                                                <div className='user-shop__create-title user-shop__create-title-name'>{selectedLanguage?.userShopView?.userShopBlockDescription}</div>
                                                <TextField className='user-shop__create-input-information' value={informationBlockDescription} onChange={(e) => setInformationBlockDescription(e.target.value)} label={selectedLanguage?.userShopView?.userShopBlockTextPlaceholder} rows={'5'} multiline variant="outlined" />
                                            </div>

                                            <h3 className='user-shop__section-setting-title'>{selectedLanguage?.userShopView?.userShopSettingColorTitle}</h3>
                                            <div className='user-shop__section-setting-blocks-wrap'>
                                                <div>
                                                    <div>{selectedLanguage?.userShopView?.userShopBlockHeaderColor}</div>
                                                    <InputColor setValue={setColorTitle} value={colorTitle} id={'userShopColorTitle'} name={'colorTitle'}/>
                                                </div>
                                                <div>
                                                    <div>{selectedLanguage?.userShopView?.userShopBlockTextColor}</div>
                                                    <InputColor setValue={setColorText} value={colorText} id={'userShopColorText'} name={'colorText'}/>
                                                </div>
                                                <div>
                                                    <div>{selectedLanguage?.userShopView?.userShopBlockBackgroundColor}</div>
                                                    <InputColor setValue={setColorBackground} value={colorBackground} id={'userShopColorBackground'} name={'colorBackground'}/>
                                                </div>
                                            </div>

                                            <h3 className='user-shop__section-setting-title'>{selectedLanguage?.userShopView?.userShopSettingSizeTitle}</h3>
                                            <div className='user-shop__section-input-number-wrap'>
                                                <InputNumber label={selectedLanguage?.userShopView?.userShopBlockHeaderSize} id={"userShopViewSizeTitle"} name={"userShopViewSizeTitle"} value={sizeTitle} setValue={setSizeTitle} min={'1'}/>
                                                <InputNumber label={selectedLanguage?.userShopView?.userShopBlockHeaderThickness} id={"userShopViewFontWeightTitle"} name={"userShopViewFontWeightTitle"} value={fontWeightTitle} setValue={setFontWeightTitle} min={'100'} max={'900'} step={'100'}/>
                                                <InputNumber label={selectedLanguage?.userShopView?.userShopBlockTextSize} id={"userShopViewSizeText"} name={"userShopViewSizeText"} value={sizeText} setValue={setSizeText} min={'1'}/>
                                                <InputNumber label={selectedLanguage?.userShopView?.userShopBlockTextThickness} id={"userShopViewFontWeightText"} name={"userShopViewFontWeightText"} value={fontWeightText} setValue={setFontWeightText} min={'100'} max={'900'} step={'100'}/>
                                            </div>

                                            <h3 className='user-shop__section-setting-title'>{selectedLanguage?.userShopView?.userShopBlockTextAlign}</h3>
                                            <RadioGroup
                                                row
                                                name="row-radio-buttons-TextAlign"
                                                onChange={(e, value) => setCheckTextAlign(value)}
                                                defaultValue={checkTextAlign}
                                            >
                                                <FormControlLabel value="start" control={<Radio />} label={selectedLanguage?.userShopView?.userShopBlockCheckboxStart} />
                                                <FormControlLabel value="center" control={<Radio />} label={selectedLanguage?.userShopView?.userShopBlockCheckboxCenter} />
                                                <FormControlLabel value="end" control={<Radio />} label={selectedLanguage?.userShopView?.userShopBlockCheckboxEnd} />
                                            </RadioGroup>

                                            <h3 className='user-shop__section-setting-title'>{selectedLanguage?.userShopView?.userShopBlockTitleShadow}</h3>
                                            <div className="user-shop__section-input-number-wrap">
                                                <InputNumber label={selectedLanguage?.userShopView?.userShopBlockShadowX}  id={"userShopViewShadowTitleX"} name={"userShopViewShadowTitleX"} value={shadowTitleX} setValue={setShadowTitleX} />
                                                <InputNumber label={selectedLanguage?.userShopView?.userShopBlockShadowY} id={"userShopViewShadowTitleY"} name={"userShopViewShadowTitleY"} value={shadowTitleY} setValue={setShadowTitleY} />
                                                <InputNumber label={selectedLanguage?.userShopView?.userShopBlockShadowZ} id={"userShopViewShadowTitleZ"} name={"userShopViewShadowTitleZ"} value={shadowTitleZ} setValue={setShadowTitleZ} />
                                                <InputNumber label={selectedLanguage?.userShopView?.userShopBlockShadowTransparency} id={"userShopViewShadowTitleTransparency"} name={"userShopViewShadowTitleTransparency"} value={shadowTitleTransparency} setValue={setShadowTitleTransparency} />
                                            </div>

                                            <h3 className='user-shop__section-setting-title'>{selectedLanguage?.userShopView?.userShopBlockTextShadow}</h3>
                                            <div className="user-shop__section-input-number-wrap">
                                                <InputNumber label={selectedLanguage?.userShopView?.userShopBlockShadowX} id={"userShopViewShadowTextX"} name={"userShopViewShadowTextX"} value={shadowTextX} setValue={setShadowTextX} />
                                                <InputNumber label={selectedLanguage?.userShopView?.userShopBlockShadowY} id={"userShopViewShadowTextY"} name={"userShopViewShadowTextY"} value={shadowTextY} setValue={setShadowTextY} />
                                                <InputNumber label={selectedLanguage?.userShopView?.userShopBlockShadowZ} id={"userShopViewShadowTextZ"} name={"userShopViewShadowTextZ"} value={shadowTextZ} setValue={setShadowTextZ} />
                                                <InputNumber label={selectedLanguage?.userShopView?.userShopBlockShadowTransparency} id={"userShopViewShadowTextTransparency"} name={"userShopViewShadowTextTransparency"} value={shadowTextTransparency} setValue={setShadowTextTransparency} />
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {
                                        !!informationBlockTitle?.length || !!informationBlockText?.length ?
                                            <div>
                                                <div className="user-shop__header-btn-wrap">
                                                    <button className='user-shop__header-btn' onClick={handleCleanBlockInfo}>{selectedLanguage?.userShopView?.userShopBlockRemoveBtn}</button>
                                                </div>
                                                <p className='user-shop__information-title'>{selectedLanguage?.userShopView?.userShopBlockViewTitle}</p>
                                                <div className='user-shop__information-block' style={{background: `${colorBackground}`}}>
                                                    <div 
                                                        className='user-shop__information-block-title' 
                                                        style={{color: `${colorTitle}`, fontSize: `${sizeTitle}px`, fontWeight: `${fontWeightTitle}`, textShadow: `${shadowTitleX}px ${shadowTitleY}px ${shadowTitleZ}px rgb(0 0 0 / ${shadowTitleTransparency}%)`}}
                                                    >
                                                        {informationBlockTitle}
                                                    </div>
                                                    <div 
                                                        className='user-shop__information-block-text' 
                                                        style={{color: `${colorText}`, fontSize: `${sizeText}px`, textAlign: `${checkTextAlign}`, fontWeight: `${fontWeightText}`, textShadow: `${shadowTextX}px ${shadowTextY}px ${shadowTextZ}px rgb(0 0 0 / ${shadowTextTransparency}%)`}}
                                                    >
                                                        {informationBlockText}
                                                    </div>
                                                </div>
                                            </div> : ''
                                    }
                                </div>
                        }
                        {/* ----------------Вибір шаблону--------------------- */}
                        {
                            tabSettingValue === 2 && 
                                <div className="user-shop__section-wrap">
                                    <div className="user-shop__section">
                                        <ShopTemplatesView />
                                    </div>
                                </div>
                        }
                        {/* ----------------Порядок блоків--------------------- */}
                        {
                            tabSettingValue === 0 && 
                                <div className="user-shop__section-wrap">
                                    <div className="user-shop__section">
                                        <div className="user-shop__section-input-wrap">
                                            <div className="user-shop__section-title">{selectedLanguage?.userShopView?.userShopOrderBlocksTitle}</div>
                                            <OrderElements />
                                        </div>
                                    </div>
                                </div>
                        }
                        {/* -------Налаштування вигляду магазину----------- */}
                        {
                            tabSettingValue === 1 && 
                                <div className="user-shop__section-wrap">
                                    
                                    <div className="user-shop__section">
                                        <div className="user-shop__section-input-wrap">
                                            <div className='user-shop__create-wrap'>
                                                <div className='user-shop__create-title-wrap'>
                                                    <div className='user-shop__create-title'>{selectedLanguage?.userShopView?.userShopTopBlocTitle}</div>
                                                    <TextField className='user-shop__create-input-title' value={textInfoTopBlock} onChange={(e) => setTextInfoTopBlock(e.target.value)} fullWidth label={selectedLanguage?.userShopView?.userShopTopBlocPlaceholder1} rows={5} multiline variant="outlined" />
                                                </div>
                                                <div className='user-shop__create-test-wrap'>
                                                    <label className='user-shop__create-test-btn'>
                                                        <input className="user-shop__header-btn-input-upload" onChange={(e) => setTestImg(e.target.files[0])} type="file" accept="image/*" id="orderViewImg"/>
                                                        {selectedLanguage?.userShopView?.userShopLabelUploud}
                                                    </label>
                                                    {
                                                        !!testImg?.name?.length &&
                                                            <img className='user-shop__create-test-img' src={URL.createObjectURL(testImg)} alt='img'/>
                                                    }
                                                </div>
                                            </div>

                                            <div className='user-shop__section-setting-wrap'>
                                                <div className='user-shop__section-setting-1'>
                                                    <h3 className='user-shop__section-setting-title'>{selectedLanguage?.userShopView?.userShopTemplatesBtn6}</h3>
                                                    <div className='user-shop__section-setting-blocks-wrap'>
                                                        <div className='user-shop__section-setting-blocks'>
                                                            <div>{selectedLanguage?.userShopView?.userShopTopBlocIabel2}</div>
                                                            <InputColor setValue={setBgColorTopBlockHeader} value={bgColorTopBlockHeader} id={'userShopBgColorTopBlockHeader'} name={'userShopBgColorTopBlockHeader'}/>
                                                        </div>
                                                        <div>
                                                            <div>{selectedLanguage?.userShopView?.userShopTopBlocIabel3}</div>
                                                            <InputColor setValue={setColorTopBlockHeader} value={colorTopBlockHeader} id={'userShopColorTopBlockHeader'} name={'userShopColorTopBlockHeader'}/>
                                                        </div>
                                                    </div>
                                                    <FormControlLabel onChange={() => setIsAnimationTopBlock(!isAnimationTopBlock)} control={<Switch checked={isAnimationTopBlock ? true : false} />} label={selectedLanguage?.userShopView?.userShopTopBlocIabel4} />

                                                    <h3 className='user-shop__section-setting-title'>{selectedLanguage?.userShopView?.userShopSettingHeaderTitle}</h3>
                                                    <FormControl style={{width: '330px'}}>
                                                        <InputLabel id="demo-simple-select-label">{selectedLanguage?.userShopView?.userShopHeaderSectionLabel6}</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={typeHeader}
                                                            label={selectedLanguage?.userShopView?.userShopHeaderSectionLabel6}
                                                            onChange={(e) => handleTypeHeader(e.target.value)}
                                                        >
                                                            <MenuItem value='1'>{selectedLanguage?.userShopView?.userShopHeaderSectionTypelabel1}</MenuItem>
                                                            <MenuItem value='2'>{selectedLanguage?.userShopView?.userShopHeaderSectionTypelabel2}</MenuItem>
                                                            <MenuItem value='3'>{selectedLanguage?.userShopView?.userShopHeaderSectionTypelabel3}</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <br/>
                                                    <FormControlLabel onChange={() => setIsBgHeaderTransparent(!isBgHeaderTransparent)} control={<Switch checked={isBgHeaderTransparent ? true : false} />} label={selectedLanguage?.userShopView?.userShopHeaderSectionLabel1} />
                                                    <div className='user-shop__section-setting-blocks-wrap'>
                                                        <div className='user-shop__section-setting-blocks'>
                                                            <div>{selectedLanguage?.userShopView?.userShopSettingColorHatBg}</div>
                                                            <div className={isBgHeaderTransparent ? `user-shop__section-header-color-bg` : ''}>
                                                                <InputColor setValue={setBgColorHeader} value={bgColorHeader} id={'userShopBgColorHeader'} name={'bgColorHeader'}/>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div>{selectedLanguage?.userShopView?.userShopSettingColorHatText}</div>
                                                            <InputColor setValue={setColorHeaderText} value={colorHeaderText} id={'userShopColorHeaderText'} name={'colorHeaderText'}/>
                                                        </div>
                                                    </div>
                                                    <div className='user-shop__section-setting-blocks-wrap'>
                                                        <div className='user-shop__section-setting-blocks'>
                                                            <div>{selectedLanguage?.userShopView?.userShopHeaderSectionLabel4}</div>
                                                            <InputColor setValue={setBgSubCategories} value={bgSubCategories} id={'userShopBgSubCategories'} name={'userShopBgSubCategories'}/>
                                                        </div>
                                                        <div>
                                                            <div>{selectedLanguage?.userShopView?.userShopHeaderSectionLabel5}</div>
                                                            <InputColor setValue={setColorSubCategoriesText} value={colorSubCategoriesText} id={'userShopColorSubCategoriesText'} name={'userShopColorSubCategoriesText'}/>
                                                        </div>
                                                    </div>

                                                    <h3 className='user-shop__section-setting-title'>{selectedLanguage?.userShopView?.userShopFavouritesrTitle}</h3>
                                                    <div className='user-shop__section-setting-blocks-colum-wrap'>
                                                        <div className='user-shop__section-setting-blocks'>
                                                            <div>{selectedLanguage?.userShopView?.userShopHeaderSectionLabel2}</div>
                                                            <InputColor setValue={setBgNewProductHeader} value={bgNewProductHeader} id={'userShopBgNewProductHeader'} name={'userShopBgNewProductHeader'}/>
                                                        </div>
                                                        <div>
                                                            <div>{selectedLanguage?.userShopView?.userShopHeaderSectionLabel3}</div>
                                                            <InputColor setValue={setColorNewProductHeader} value={colorNewProductHeader} id={'userShopColorNewProductHeader'} name={'userShopColorNewProductHeader'} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='user-shop__section-setting-2'>
                                                    <h3 className='user-shop__section-setting-title'>{selectedLanguage?.userShopView?.userShopSettingMiddleTitle}</h3>
                                                    <div className='user-shop__section-setting-blocks-wrap'>
                                                        <div className='user-shop__section-setting-blocks'>
                                                            <div>{selectedLanguage?.userShopView?.userShopSettingColorMainBg}</div>
                                                            <InputColor setValue={setBgColorMain} value={bgColorMain} id={'userShopBgColorMain'} name={'bgColorMain'}/>
                                                        </div>
                                                        <div>
                                                            <div>{selectedLanguage?.userShopView?.userShopSettingColorMainText}</div>
                                                            <InputColor setValue={setColorMainText} value={colorMainText} id={'userShopColorMainText'} name={'colorMainText'}/>
                                                        </div>
                                                    </div>
                                                    <h3 className='user-shop__section-setting-title'>{selectedLanguage?.userShopView?.userShopSettingBottomTitle}</h3>
                                                    <div className='user-shop__section-setting-blocks-wrap'>
                                                        <div className='user-shop__section-setting-blocks'>
                                                            <div>{selectedLanguage?.userShopView?.userShopSettingColorFooterBg}</div>
                                                            <InputColor setValue={setBgColorFooter} value={bgColorFooter} id={'userShopBgColorFooter'} name={'bgColorFooter'}/>
                                                        </div>
                                                        <div>
                                                            <div>{selectedLanguage?.userShopView?.userShopSettingColorFooterText}</div>
                                                            <InputColor setValue={setColorFooterText} value={colorFooterText} id={'userShopColorFooterText'} name={'colorFooterText'}/>
                                                        </div>
                                                    </div>
                                                    <FormControlLabel onChange={() => setIsSubscriptionHide(!isSubscriptionHide)} control={<Switch checked={isSubscriptionHide ? true : false} />} label={selectedLanguage?.userShopView?.userShopFooterSectionLabel2} />
                                                    <div className='user-shop__create-wrap'>
                                                        <div className='user-shop__create-title user-shop__create-title-name'>{selectedLanguage?.userShopView?.userShopFooterSectionLabel1}</div>
                                                        <TextField className='user-shop__create-input' value={subscriptionInformation} onChange={(e) => setSubscriptionInformation(e.target.value)} label={selectedLanguage?.userShopView?.userShopFooterSectionPlaceholder1} rows={5} multiline variant="outlined" />
                                                    </div>
                                                </div>
                                            </div>

                                            <h3 className='user-shop__section-setting-title'>{selectedLanguage?.userShopView?.userShopSettingButtomTitle}</h3>
                                            <div className='user-shop__section-setting-blocks-wrap'>
                                                <div className='user-shop__section-setting-blocks'>
                                                    <div>{selectedLanguage?.userShopView?.userShopSettingBtnColorText}</div>
                                                    <InputColor setValue={setColorTextBtn} value={colorTextBtn} id={'userShopColorTextBtn'} name={'colorTextBtn'}/>
                                                </div>
                                                <div>
                                                    <div>{selectedLanguage?.userShopView?.userShopSettingBtnColorTextHover}</div>
                                                    <InputColor setValue={setColorTextBtnHover} value={colorTextBtnHover} id={'userShopColorTextBtnHover'} name={'colorTextBtnHover'}/>
                                                </div>
                                            </div>
                                            <div className='user-shop__section-setting-blocks-wrap'>
                                                <div className='user-shop__section-setting-blocks'>
                                                    <div>{selectedLanguage?.userShopView?.userShopSettingBtnColorGradient1}</div>
                                                    <InputColor setValue={setFirstColorGradient} value={firstColorGradient} id={'userShopFirstColorGradient'} name={'firstColorGradient'}/>
                                                </div>
                                                <div>
                                                    <div>{selectedLanguage?.userShopView?.userShopSettingBtnColorGradient2}</div>
                                                    <InputColor setValue={setSecondColorGradient} value={secondColorGradient} id={'userShopSecondColorGradient'} name={'secondColorGradient'}/>
                                                </div>
                                            </div>
                                            <div className='user-shop__section-setting-blocks-wrap'>
                                                <div className='user-shop__section-setting-blocks'>
                                                    <div>{selectedLanguage?.userShopView?.userShopSettingBtnColorGradientHover1}</div>
                                                    <InputColor setValue={setFirstColorGradientHover} value={firstColorGradientHover} id={'userShopfirstColorGradientHover'} name={'firstColorGradientHover'}/>
                                                </div>
                                                <div>
                                                    <div>{selectedLanguage?.userShopView?.userShopSettingBtnColorGradientHover2}</div>
                                                    <InputColor setValue={setSecondColorGradientHover} value={secondColorGradientHover} id={'userShopSecondColorGradientHover'} name={'secondColorGradientHover'}/>
                                                </div>
                                            </div>
                                            <h3 className='user-shop__section-setting-title'>{selectedLanguage?.userShopView?.userShopButtonsAppearence}</h3>
                                            <div className="user-shop__section-selection-button-wrap">
                                                <div>
                                                    <p className={`user-shop__section-selection-button-select ${selectBtn === '1' ? 'user-shop__section-selection-button-selected' : ''}`}>{selectedLanguage?.userShopView?.userShopSettingBtnSelectTitle}</p>
                                                    <button onClick={() => setSelectBtn('1')} className="app__custom-btn app__btn-1"><span className="app__custom-btn-span">Button 1</span></button>
                                                </div>
                                                <div>
                                                    <p className={`user-shop__section-selection-button-select ${selectBtn === '2' ? 'user-shop__section-selection-button-selected' : ''}`}>{selectedLanguage?.userShopView?.userShopSettingBtnSelectTitle}</p>
                                                    <button onClick={() => setSelectBtn('2')} className="app__custom-btn app__btn-2"><span className="app__custom-btn-span">Button 2</span></button>
                                                </div>
                                                <div>
                                                    <p className={`user-shop__section-selection-button-select ${selectBtn === '3' ? 'user-shop__section-selection-button-selected' : ''}`}>{selectedLanguage?.userShopView?.userShopSettingBtnSelectTitle}</p>
                                                    <button onClick={() => setSelectBtn('3')} className="app__custom-btn app__btn-3"><span className="app__custom-btn-span">Button 3</span></button>
                                                </div>
                                                <div>
                                                    <p className={`user-shop__section-selection-button-select ${selectBtn === '4' ? 'user-shop__section-selection-button-selected' : ''}`}>{selectedLanguage?.userShopView?.userShopSettingBtnSelectTitle}</p>
                                                    <button onClick={() => setSelectBtn('4')} className="app__custom-btn app__btn-4"><span className="app__custom-btn-span">Button 4</span></button>
                                                </div>
                                                <div>
                                                    <p className={`user-shop__section-selection-button-select ${selectBtn === '5' ? 'user-shop__section-selection-button-selected' : ''}`}>{selectedLanguage?.userShopView?.userShopSettingBtnSelectTitle}</p>
                                                    <button onClick={() => setSelectBtn('5')} className="app__custom-btn app__btn-5"><span className="app__custom-btn-span">Button 5</span></button>
                                                </div>
                                                <div>
                                                    <p className={`user-shop__section-selection-button-select ${selectBtn === '6' ? 'user-shop__section-selection-button-selected' : ''}`}>{selectedLanguage?.userShopView?.userShopSettingBtnSelectTitle}</p>
                                                    <button onClick={() => setSelectBtn('6')} className="app__custom-btn app__btn-6"><span className="app__custom-btn-span">Button 6</span></button>
                                                </div>
                                                <div>
                                                    <p className={`user-shop__section-selection-button-select ${selectBtn === '7' ? 'user-shop__section-selection-button-selected' : ''}`}>{selectedLanguage?.userShopView?.userShopSettingBtnSelectTitle}</p>
                                                    <button onClick={() => setSelectBtn('7')} className="app__custom-btn app__btn-7"><span className="app__custom-btn-span">Button 7</span></button>
                                                </div>
                                                <div>
                                                    <p className={`user-shop__section-selection-button-select ${selectBtn === '8' ? 'user-shop__section-selection-button-selected' : ''}`}>{selectedLanguage?.userShopView?.userShopSettingBtnSelectTitle}</p>
                                                    <button onClick={() => setSelectBtn('8')} className="app__custom-btn app__btn-8"><span className="app__custom-btn-span">Button 8</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <button className='user-shop__preview-btn' onClick={() => setIsPreviewOpen(!isPreviewOpen)}>
                                        <img className='user-shop__preview-btn-img' src={isPreviewOpen ? eyeHide2 : eye2} alt='img'/>
                                        <span>{selectedLanguage?.userShopView?.userShopBtnPreview}</span>
                                    </button>

                                    {
                                        isPreviewOpen &&
                                            <div className='user-shop__result-shop-wrap'>
                                                <div className='user-shop__result-shop--wrap'>
                                                    <Layout shopSettingTest={shopSettingTest} />
                                                </div>
                                            </div>
                                    }
                                </div>
                        }
                    </>
            }
        </div>
    );
}

export default UserShopView;

export const languageUser = {
    'UA': {
        signUpView: {
            signUpSliderTitle1: 'Створення інтернет магазину', 
            signUpSliderTitle2: 'Розвиток вашого бізнесу', 
            signUpSliderTitle3: 'Допомого в досягненні мрії', 
            signUpSliderSubTitle1: 'Створення інтернет магазину Створення інтернет магазину', 
            signUpSliderSubTitle2: 'Створення інтернет магазину Створення інтернет магазину', 
            signUpSliderSubTitle3: 'Створення інтернет магазину Створення інтернет магазину', 
            signUpCompanyName: 'Назва фірми', 
            signUpCreateTitle: 'Створити акаунт',
            signUpGoToTitle: 'Перейти на сторінку',
            signUpNameTitle: 'Ім\'я',
            signUpNamePlaceholder: 'Введіть ім\'я...',
            signUpSurnameTitle: 'Фамілія',
            signUpSurnamePlaceholder: 'Введіть прізвище...',
            signUpEmailTitle: 'Емейл',
            signUpEmailPlaceholder: 'Введіть емейл...',
            signUpPasswordTitle: 'Пароль',
            signUpPasswordError: 'Не правельно введений повторний пароль',
            signUpPasswordPlaceholder: 'Введіть пароль...',
            signUpPasswordRepeatTitle: 'Повторіть пароль',
            signUpPasswordRepeatPlaceholder: 'Введіть пароль...',
            signUpCreateBtn: 'Створити',
            signUpReturnToTitle: 'Повернутись на',
            signUpMainPage: 'Головну',
        },
        SignInView: {
            signInSliderTitle1: 'Створення інтернет магазину', 
            signInSliderTitle2: 'Розвиток вашого бізнесу', 
            signInSliderTitle3: 'Допомого в досягненні мрії', 
            signInSliderSubTitle1: 'Створення інтернет магазину Створення інтернет магазину', 
            signInSliderSubTitle2: 'Створення інтернет магазину Створення інтернет магазину', 
            signInSliderSubTitle3: 'Створення інтернет магазину Створення інтернет магазину', 
            signInCompanyName: 'Назва фірми', 
            signInTitle: 'Увійти',
            signInSubTitle: 'Не маєте облікового запису?', 
            signInEmailTitle: 'Емейл',
            signInEmailPlaceholder: 'Введіть емейл...',
            signInPasswordTitle: 'Пароль',
            signInPasswordPlaceholder: 'Введіть пароль...',
            signInLeavePassword: 'Забули пароль?',
            signInLogiBtn: 'Авторизуватися',
            signInReturnToTitle: 'Повернутись на',
            signInMainPage: 'Головну',
        },
        layoutUser: {
            layoutLinkAnalytics: 'Аналітика',
            layoutLinkShop: 'Магазин',
            layoutLinkCategories: 'Категорії',
            layoutLinkProducts: 'Товари',
            layoutLinkOrders: 'Зомовлення',
            layoutLinkMessage: 'Повідомлення',
            layoutLinkNotifications: 'Сповіщення',
            layoutLinkBarcode: 'Сканувати штрих-код',
            layoutLinkPersonnel: 'Персонал',
            layoutLinkVisitShop: 'Перейти в магазин',
            layoutLinkSettings: 'Налаштування',
            layoutLinkSubscription: 'Підписка',
            layoutLinkIntegration: 'Інтеграція',
            layoutLinkCustomers: 'Клієнти',
            layoutLinkExit: 'Вихід',
            layoutModExitTitle: 'Ви впевнені?',
            layoutModExitText: 'Бажаєте покинути особистий кабінет?',
            layoutSearchLabel: 'Пошук замовлень по телефону і назві',
            layoutModSearchBarcodeTitle: 'Введіть штрихкод товару',
            layoutModSearchBarcodeLeftBtn: 'Відмінити',
            layoutModSearchBarcodeRightBtn: 'Пошук',
        },
        userShopView: {
            userShopTitle: 'Налаштування магазину',
            userShopTabs1: 'Загальні налаштування',
            userShopTabs2: 'Налаштування стилю',
            userShopTabs3: 'Магазин',
            userShopTabs4: 'Створити промокод',  
            userShopTabs5: 'API токен',  
            userShopTokenTitle: 'Ваш особистий токен API',  
            userShopTokenCreateBtn: 'Згенерувати новий маркер',  
            userShopSettingColorTitle: 'Кольори',  
            userShopSettingSizeTitle: 'Розмір',  
            userShopLabelUploud: 'Фото для вибору кольора',  
            userShopSettingHeaderTitle: 'Заголовок',  
            userShopFavouritesrTitle: 'Вибране та кошик',  
            userShopSettingMiddleTitle: 'Середина сайту',  
            userShopSettingBottomTitle: 'Нижня частина сайту',  
            userShopSettingButtomTitle: 'Кольори кнопок',  
            userShopButtonsAppearence: 'Зовнішній вигляд кнопки',  
            userShopBtnPreview: 'Попередній перегляд',  
            userShopBtnSend: 'Зберегти зміни',
            userShopTemplatesBtn1: 'Зовнішній вигляд і кольори',
            userShopTemplatesBtn2: 'Готові шаблони',
            userShopTemplatesBtn3: 'Блок інформації',
            userShopTemplatesBtn4: 'Блок реклами',
            userShopTemplatesBtn6: 'Блоки',
            userShopTemplatesBtn7: 'Головний слайдер',
            userShopTemplatesBtn8: 'Вигляд карток',
            userShopOrderBlocksTitle: 'Розташування та порядок блоків',
            userShopTopBlocTitle: 'Інформація над шапкою сайту',
            userShopTopBlocPlaceholder1: 'Інформація...',
            userShopTopBlocIabel2: 'Фон блоку',
            userShopTopBlocIabel3: 'Колір тексту',
            userShopTopBlocIabel4: 'Добавити анімацію',
            userShopHeaderSectionLabel1: 'Прозорий фон шапки',
            userShopHeaderSectionLabel2: 'Колір фону значка наявності товару в обраних і кошику',
            userShopHeaderSectionLabel3: 'Колір тексту значка наявності товару в обраних і кошику',
            userShopHeaderSectionLabel4: 'Колір фону підкатегорій',
            userShopHeaderSectionLabel5: 'Колір тексту підкатегорій',
            userShopHeaderSectionLabel6: 'Тип шапки',
            userShopHeaderSectionTypelabel1: 'бургер і список категорії на одному рівні',
            userShopHeaderSectionTypelabel2: 'тільки бургер',
            userShopHeaderSectionTypelabel3: 'бургер і список категорії нижче рівнем',
            userShopFooterSectionLabel1: 'Додаткова інформація для підписників, над полем підписки',
            userShopFooterSectionLabel2: 'Приховати поле підписки',
            userShopFooterSectionPlaceholder1: 'Інформація...',
            userShopSettingColorHatBg: 'Фон шапки',
            userShopSettingColorHatText: 'Колір тексту шапки',
            userShopSettingColorFooterBg: 'Фон нижньої частини сайту',
            userShopSettingColorFooterText: 'Колір тексту нижньої частини сайту',
            userShopSettingColorMainBg: 'Фону сайту',
            userShopSettingColorMainText: 'Колір тексту сайту',
            userShopSettingBtnColorText: 'Колір тексту',
            userShopSettingBtnColorTextHover: 'Колір тексту при наведені',
            userShopSettingBtnColorGradient1: 'Перший колір градієнту',
            userShopSettingBtnColorGradient2: 'Другий колір градієнту',
            userShopSettingBtnColorGradientHover1: 'Перший колір градієнту при наведенні',
            userShopSettingBtnColorGradientHover2: 'Другий колір градієнту при наведені',
            userShopSettingBtnSelectTitle: 'Вибраний варіант',
            userShopBlockTitle: 'Введіть заголовок',
            userShopBlockTitlePlaceholder: 'Заголовок...',
            userShopBlockText: 'Введіть текст',
            userShopBlockTextPlaceholder: 'Інформація...',
            userShopBlockDescription: 'Опис інформації',
            userShopBlockHeaderColor: 'Колір заголовка',
            userShopBlockTextColor: 'Колір тексту',
            userShopBlockBackgroundColor: 'Колір фону',
            userShopBlockTextAlign: 'Вирівнювання тексту',
            userShopBlockTitleShadow: 'Тінь заголовка',
            userShopBlockTextShadow: 'Тінь тексту',
            userShopBlockHeaderSize: 'Розмір заголовку',
            userShopBlockHeaderThickness: 'Товщина заголовку',
            userShopBlockTextSize: 'Розмір тексту',
            userShopBlockTextThickness: 'Товщина тексту',
            userShopBlockShadowX: 'Тінь по Х',
            userShopBlockShadowY: 'Тінь по Y',
            userShopBlockShadowZ: 'Тінь по Z',
            userShopBlockShadowTransparency: 'Прозорість тіні',
            userShopBlockRemoveBtn: 'Видалити блок інформації',
            userShopBlockViewTitle: 'Вигляд блоку інформації',
            userShopBlockCheckboxStart: 'Початок',
            userShopBlockCheckboxCenter: 'Центер',
            userShopBlockCheckboxEnd: 'Кінець',
            userShopNotFilledText: 'дані не вказано',
            userShopBlockAdvertisingTitle: 'Блок реклами',
            userShopBlockAdvertisingCheckboxLabel: 'Дозволити рекламу магазинів всіх категорій',
            userShopBlockAdvertisingInfo: 'Виберіть ті категорії до яких відноситься ваш магазин. Відповідно в блоці реклами будуть тільки ті магазини, які не мають вибраної такоїж категорії.',
        },
        userCategoriesView: {
            userCategoriesModalDelCatTitle: 'Ви впевнені?',
            userCategoriesModalEditCatName: 'Назва категорії',
            userCategoriesModalDelCatText: 'Всі підкатегорії даної категорії будуть видалені',
            userCategoriesModalDelCatLeftBtn: 'Ні',
            userCategoriesModalDelCatRightBtn: 'Так',
            userCategoriesModalDelSubCatTitle: 'Ви впевнені?',
            userCategoriesModalDelSubCatText: 'Видалити дану категорію',
            userCategoriesModalDelSubCatLeftBtn: 'Ні',
            userCategoriesModalDelSubCatRightBtn: 'Так',
            userCategoriesModalEditSubCatTitle: 'Редагувати категорію?',
            userCategoriesModalEditSubCatText: 'Введіть нову назву',
            userCategoriesModalHideCatTitle1: 'Приховати дану категорію',
            userCategoriesModalHideCatTitle2: 'Відновити дану категорію',
            userCategoriesModalHideCatText: 'всі товари даної категорії і підкатегорії будуть приховані',
            userCategoriesModalEditCatLeftBtn: 'Відмінити',
            userCategoriesModalEditCatRightBtn: 'Підтвердити',
            userCategoriesTitle: 'Категорії',
            userCategoriesErrorCreateLimit: 'Ви дасягнули ліміту категорій',
            userCategoriesErrorCreate: 'Спершу потрібно створити магазин',
            userCategoriesCreateCatBtn: 'Створоти категорію',
            userCategoriesCreateSubCatBtn: 'Добавити підкатегорію',
            userCategoriesCreateCatInfo: 'Для створення категорії, або редагуванні Впишіть її назву і нажміть на кнопку підтвердити. Кількість категорій обмежена до "5". Для коректного відображення картинок, формат картинки повинен бути 1:1. Щоб створити категорію, спершу потрібно створити магазин. Після створення категорії зможете створювати підкатегорії. Якщо в категорії не має підкатегорій і є створені товари, то можливісь створити підкатегорію на неї блокується.',
            userCategoriesShowSuccessCreateCat: 'Категорію створено',
            userCategoriesShowSuccessCreateSubCat: 'Підкатегорію створено',
            userCategoriesShowSuccessDelCat: 'Категорія видалена',
            userCategoriesShowSuccessDelSubCat: 'Підкатегорія видалена',
            userCategoriesShowSuccessEditCat: 'Категорію змінено',
            userCategoriesShowSuccessEditSubCat: 'Назва змінена',
            userCategoriesShowSuccessHideCat: 'Категорія прихована',
        },
        creationShop: {
            creationShopShowSuccessUpdateShop: 'Дані оновлено',
            creationShopDeliveryMethods1: 'Служба доставки',
            creationShopPaymentMethods1: 'Передоплата',
            creationShopTitle1: 'Створення магазину',
            creationShopTitle2: 'Оновлення магазину',
            creationShopInputNameLabel: 'Назва магазину',
            creationShopInputNamePlaceholder: 'Введіть ім\'я магазину...',
            creationShopNameInfo: 'Введіть ім\'я магазину.',
            creationShopInputFacebookPlaceholder: 'Введіть facebook url...',
            creationShopFacebookInfo: 'Введіть url адресу сторінки facebook, щоб Ваші клієнти мали змогу контактувати і стежити за Вашими публікаціями.',
            creationShopInputInstagramPlaceholder: 'Введіть instagram url...',
            creationShopInstagramInfo: 'Введіть url адресу сторінки instagram, щоб Ваші клієнти мали змогу контактувати і стежити за Вашими публікаціями.',
            creationShopInputTelLabel: 'Телефон для контакту',
            creationShopInputTelPlaceholder: 'Телефон...',
            creationShopTelInfo: 'Введіть телефон за яким клієнти зможуть з Вами звязатись. В форматі ХХХ-ХХХ-ХХХХ',
            creationShopInputTel2Label: 'Додатковий телефон для контакту',
            creationShopInputTel2Placeholder: 'Телефон...',
            creationShopTel2Info: 'Введіть додатковий телефон за яким клієнти зможуть з Вами звязатись. В форматі ХХХ-ХХХ-ХХХХ',
            creationShopInputAddressLabel: 'Адреса магазину',
            creationShopInputAddressPlaceholder: 'Адрес...',
            creationShopAddressInfo: 'Введіть адресу Вашого магазину',
            creationShopInputDeliveryLabel: 'Доставка',
            creationShopInputDeliveryPlaceholder: 'Доставка...',
            creationShopDeliveryInfo: 'Введіть способи доставки товару у Вашому магазині',
            creationShopInputPaymentLabel: 'Оплата',
            creationShopInputPaymentPlaceholder: 'Оплата...',
            creationShopPaymentInfo: 'Введіть способи оплати товару у Вашому магазині',
            creationShopInputDescriptionLabel: 'Опис',
            creationShopInputDescriptionPlaceholder: 'Введіть опис...',
            creationShopDescriptionInfo: 'Введіть декілька слів про Ваш магазин.',
            creationShopInputCurrencyLabel: 'Валюта',
            creationShopCurrencyInfo: 'Введіть валюту в якій будуть зазначені ціни на товар, в форматі $ € ₴ ...',
            creationShopInputLanguageLabel: 'Мова',
            creationShopLanguageInfo: 'Виберіть мову на якій буде Ваш магазин',
            creationShopInputLogoLabel: 'Логотип',
            creationShopInputDelLogoLabel: 'Видалити завантажений логотип',
            creationShopLogoInfo: 'Виберіть логотип Вашого магазину',
            creationShopCancelBtn: 'Відмінити',
            creationShopUpdateBtn: 'Оновити',
            creationShopCreateBtn: 'Створити',
            creationShopShowMesSuccessPut: 'Дані оновлено',
            creationShopShowMesSuccessPost: 'Магазин створено',
        },
        userProduct: {
            userProductSubTitle1: 'Продукти',
            userProductSubTitle2: 'шт.',
            userProductModalDelTitle: 'Ви впевнені?',
            userProductModalDelText: 'Видалити даний товар',
            userProductModalHideTitle1: 'Приховати даний товар?',
            userProductModalHideTitle2: 'Відновити даний товар?',
            userProductModalHideText1: 'товар не буде відображатись в магазині',
            userProductModalHideText2: 'товар буде відображатись в магазині',
            userProductModalHideErrorTitle: 'Щоб відновити товар потрібно',
            userProductModalHideErrorText: 'відновити категорію, або змінити категорію',
            userProductCreateBtn: 'Додати Продукт',
            userProductSearchPlaceholder: 'Назва або штрихкод...',
            userProductSearchBtn: 'Пошук',
            userProductPriceLabel: 'Сортувати:',
            userProductPriceOptions1: 'Всі товари',
            userProductPriceOptions2: 'По зростанню ціни',
            userProductPriceOptions3: 'По спадання ціни',
            userProductPriceOptions4: 'Знижки',
            userProductPriceOptions5: 'Новинки',
            userProductNoCategories: 'Без категорій',
            userProductHidden: 'Приховані',
            userProductSortLabel: 'Категорія:',
            userProductSortOptionAll: 'Всі товари',
            userProductCardName: 'Назва товару:',
            userProductCardCategory: 'Категорія:',
            userProductCardPrice: 'Ціна товару:',
            userProductCardNewPrice: 'Ціна зі знижкою:',
            userProductCardColors: 'Доступні кольори:',
            userProductCardSizes: 'Доступні розміра:',
            userProductCardDescription: 'Опис:',
            userProductProductMissing: 'Товари відсутній',
            userProductShowMessageError: 'Сталася помилка',
            userProductShowMessageUpdataProduct: 'Дані оновлено',
            userProductShowMessageDelProduct: 'Дані видалено',
        },
        productCardUser: {
            productCardUserBtnHover1: 'Збільшити кількість товару на складі',
            productCardUserBtnHover2: 'Зменшити кількість товару на складі',
            productCardUserCount1: 'склад',
            productCardUserCount2: 'замовлено',
            productCardUserBtnSee: 'Детальніше',
            productCardUserDiscount: '(без знижки)',
            productCardUserDetailsNone: 'Опис відсутній',
        },
        creationProduct: {
            creationProductTitle1: 'Редагування продукту',
            creationProductTitle2: 'Створення продукту',
            creationProductNameSubTitle: 'Не перевищуйте 20 символів під час введення назви продукту',
            creationProductPriceInputLabel: 'Введіть ціну',
            creationProductSizesInputLabel: 'Введіть розмір',
            creationProductQuantityInputLable: 'Введіть кількість',
            creationProductBarcodeInputLable: 'Введіть штрихкод',
            creationProductCreateImgText: 'Перетягніть зображення сюди або',
            creationProductCreateImgLink: 'натисніть, щоб загрузити',
            creationProductNameInputLabel: 'Введіть назву',
            creationProductNameLabel: 'Назва товару',
            creationProductCategoryTitle: 'Категорія:',
            creationProductCategoryLabel: 'Виберіть категорію товару',
            creationProductCategoryPlaceholder: 'Пошук ...',
            creationProductPriceLabel: 'Ціна',
            creationProductNewPriceLabel: 'Ціна на товар, якщо є знижка',
            creationProductNewPriceCancelBtn: 'Відмінити знижку',
            creationProductDescriptionLabel: 'Опис товару',
            creationProductDescriptionPlaceholder: 'Додайте опис...',
            creationProductColorsLabel: 'Доступні кольори',
            creationProductSizesLabel: 'Доступні розміра',
            creationProductQuantityLable: 'Наявна кількість товару',
            creationProductPicturesLabel: 'Картинки',
            creationProductBarcodeLable: 'Штрихкод товару',
            creationProductWarningText: 'Ці поля обов\'язкові для заповнення',
            creationProductCancelBtn: 'Відмінити',
            creationProductUpdateBtn: 'Оновити',
            creationProductCreateBtn: 'Створити',
            creationProductShowSuccessPut: 'Дані оновлено',
            creationProductShowSuccessPost: 'Товар створено',
            creationProductShowError: 'Сталася помилка',
        },
        userPurchases: {
            userPurchasesExucutorModalTitle1: 'Відмовитись від виконання?',
            userPurchasesExucutorModalTitle2: 'Підписатись на виконання цього замовлення',
            userPurchasesTitle: 'Мої замовлення',
            userPurchasesBtnDownload: 'Скачати всі замовлення',
            userPurchasesModlDownloadTitle: 'Скачати базу даних всіх замовлень?',
            userPurchasesSortTitle: 'Статус:',
            userPurchasesSortOption1: 'Всі',
            userPurchasesSortOption2: 'В процесі',
            userPurchasesSortOption3: 'Виконано',
            userPurchasesSortOption4: 'Відхилено',
            userPurchasesSortExucutorTitle: 'Виконавці:',
            userPurchasesSortExucutorAll: 'Всі',
            userPurchasesFilterTitle: 'Вибрати всі:',
            userPurchasesFilterDateBtnClean: 'Очистити',
            userPurchasesFilterDateBtnSort: 'Сортувати',
            userPurchasesFilterDateSelect: 'Вибрати дні:',
            userPurchasesFilterDateFormat: 'дд.мм.рррр',
            userPurchasesExucutorTitle: 'виконавець:',
            userPurchasesStatusTitle: 'Статус',
            userPurchasesErrorText: 'Повідомлень немає',
            userPurchasesTab1: 'Не прочитані',
            userPurchasesTab2: 'Переглянуті',
            userPurchasesTab3: 'Важливі',
            userPurchasesData: 'Дата',
            userPurchasesCategory: 'Категорія',
            userPurchasesExucutor: 'Виконавець',
            userPurchasesCustomer: 'Замовник',
            userPurchasesCount: 'Сума',
        },
        userNotifications: {
            userNotificationsModDelTitle: 'Видалити повідомлення?',
            userNotificationsTab1: 'Не прочитані',
            userNotificationsTab2: 'Прочитані',
            userNotificationsTab3: 'Обрані',
            userNotificationsToltip1: 'Видалити зі списку вазливих',
            userNotificationsToltip2: 'Добавити до списку вазливих',
            userNotificationsTitle: 'Повідомлення',
            userNotificationsSortTitle: 'Тип повідомлення:',
            userNotificationsSortOption1: 'Всі',
            userNotificationsSortOption2: 'Передзвонити',
            userNotificationsSortOption3: 'Підписки',
            userNotificationsFilterDateBtnClean: 'Очистити',
            userNotificationsFilterDateBtnSort: 'Сортувати',
            userNotificationsFilterDateSelect: 'Вибрати дні:',
            userNotificationsFilterDateFormatFrom: 'від',
            userNotificationsFilterDateFormatTo: 'до',
        },
        selectStatus: {
            selectStatusOption1: 'В процесі',
            selectStatusOption2: 'Виконано',
            selectStatusOption3: 'Відхилено',
            selectStatusShowMesEditPurchases: 'Дані оновлено',
        },
        readPurchasesView: {
            readPurchasesBanTitle: 'Клієнт находиться в списку забанених',
            readPurchasesTitle: 'Деталі замовлення',
            readPurchasesBtnBack: 'Назад',
            readPurchasesMessageUpdate: 'Дані оновлено',
            readPurchasesModDelTitle: 'Ви впевнені?',
            readPurchasesModDelText1: 'Відновити даний товар',
            readPurchasesModDelText2: 'Видалити даний товар',
            readPurchasesModEditTitle: 'Редагувати',
            readPurchasesModEditText: 'Введіть нове значення',
            readPurchasesModEditLeftBtn: 'Відмінити',
            readPurchasesModEditRightBtn: 'Підтвердити',
            readPurchasesStatusTitle: 'Статус:',
            readPurchasesStatusOption1: 'В процесі',
            readPurchasesStatusOption2: 'Виконано',
            readPurchasesStatusOption3: 'Відхилено',
            readPurchasesInfoCommentTitle: 'Коментар',
            readPurchasesTextareaLabel: 'Зробити нотатки',
            readPurchasesTextareaPlaceholder: 'Нотатки...',
            readPurchasesProductsTotalTitle: 'Сума замовлення',
            readPurchasesProductsPromoTotalTitle: 'Використано промокод',
            readPurchasesProductsPromoTotalText: 'Загальна сума',
            readPurchasesCardColorsTitle: 'Замовлені кольори:',
            readPurchasesCardSizeTitle: 'Замовлені розміра:',
            readPurchasesCardCountTitle: 'Кількість:',
            readPurchasesCardSumTitle: 'Сума:',
            readPurchasesCardOrderProductTitle: 'Кількість замовленого товару',
            readPurchasesCardDelProductTitle: 'Кількість видаленого товару',
            readPurchasesCardDelAllProductTitle: 'Замовлений товар видалений. Кількість замовленого товару складала',
        },
        readNotificationsView: {
            readNotificationsBtnBack: 'Назад',
            readNotificationsTitle1: 'Прохання пердзвонити',
            readNotificationsTitleDate: 'Повідомлення відправлене',
            readNotificationsText1: 'Власник телефонного номера',
            readNotificationsText2: 'чекає на дзвінок',
            readNotificationsComment: 'Залишиний коментар:',
            readNotificationsTitle2: 'Підписка',
            readNotificationsText3: 'Власник електронної пошти',
            readNotificationsText4: 'готовий отримувати повідомлення з акціями, знижками і іншою важливою інформацією.',
        },
        orderElements: {
            orderElementsText: 'Щоб добавити блок з контентом створіть товар і поставте галочку. Щоб змінити його порядок перетяніть його',
            orderElementsBlock1: 'Слайдер нові товари',
            orderElementsBlock2: 'Слайдер товарів зі знижками',
            orderElementsBlock3: 'Слайдер популярних товарів',
            orderElementsBlock4: 'Опис магазину',
            orderElementsBlock5: 'Блок інформації',
            orderElementsBlock6: 'Блок реклами',
        },
        userSettingsView: {
            userSettingsModDelShopTitle: 'Видалити магазин?',
            userSettingsModDelShopText: 'Всі дані магазину будут видалені',
            userSettingsModDelShopLabel1: 'Введіть пароль',
            userSettingsModDelShopLabel2: 'Повторіть пароль',
            userSettingsTitle: 'Налаштування',
            userSettingsLanguageTitle: 'Змінити мову',
            userSettingsMessageDelShopS: 'Магазин видалено',
            userSettingsPasswordError: 'Повторний пароль не вірний',
        },
        modalWindow: {
            modalWindowLeftBtn: 'Ні',
            modalWindowRightBtn: 'Так',
        },
        landingView: {
            landingRegistrationTitle: 'Ми зі Львова. Підтримай Український сервіс',
            landingRegistrationBtn: 'РЕЄСТРАЦІЯ',
            landingAnimatedTitle: 'CRM програма для',
           landingAnimatedSubTitle: 'Автоматизація, облік, аналітика, моніторинг, реклама, керування бізнесом і клієнтами',
            landingRegistrationMainBtn: 'СПРОБУВАТИ БЕЗКОШТОВНО',
            landingAnimatedText: 'Миттєвий доступ. Кредитна карта не потрібна',
            landingFavoriteDevices: 'Працює на всіх улюблених пристроях',
            landingBenefitsTitle: 'Переваги',
            landingBenefitsSubTitle1: 'Аналітика',
            landingBenefitsTitle1: 'Кількість замовлень',
            landingBenefitsText1: 'Уся інформація про активність клієнтів та найбільш популярні товари зібрана у зручних графіках за періодами року, місяця, тижня, дня. Ви можете використовувати ці дані для планування роботи магазину.',
            landingBenefitsSubTitle2: 'Зручний інтерфейс',
            landingBenefitsTitle2: 'Кількість замовлень',
            landingBenefitsText2: 'Власний стиль та бренд можна перенести на дизайн сайту. Клієнт зможе замовити товари в один клік і не втратиться в широкому асортименті товарів.',
            landingBenefitsSubTitle3: 'Клієнтська база',
            landingBenefitsTitle3: 'Менеджерам',
            landingBenefitsText3: 'Уся інформація про покупки автоматично синхронізується з базою клієнтів. Просто продовжуйте вести комунікацію із клієнтами і жоден клієнт не буде втрачений.',
            landingScreenTitle: 'Керуйте магазином легко',
            landingScreenSubTitle: 'Завдяки доступному і зручному інтерфейсу Ви закохаєтесь у работу з Вашим магазином. CheckInShop врахує поставки, спише розхідники "як треба" і нагадає про закінчення товару.',
            landingResponseTitle: 'Користь для усієї команди',
            landingResponseContentTitle: 'Власникам',
            landingResponseContentText1: 'Контролюйте бізнес віддалено. Малий магазин чи велика мережа.',
            landingResponseContentText2: 'Аналітика бізнесу, повертаємість клієнтів, ефективність менеджерів.',
            landingResponseContentText3: 'Автоматизація розрахунків залишків на складі, моніторинг фінансів.',
            landingResponseComment: '"Я, як керуюче лице, можу в будь-який момент контролювати все, що відбувається в мене в магазині."',
            landingResponseName: 'Вікторія',
            landingResponseVacancy: 'Власниця онлайн магазину',
            landingBenefitsTitle1: 'Адміністраторам',
            landingBenefitsText11: 'Значна економія часу на рутинних задачах',
            landingBenefitsText12: 'Зручний і зрозумілий інтерфейс користувача',
            landingBenefitsText13: 'Швидкий пошук і робота з клієнтами',
            landingBenefitsTitle2: 'Клієнтам',
            landingBenefitsText21: 'Категорії товарів і послуг',
            landingBenefitsText22: 'Підбір рекомендацій за інтересами',
            landingBenefitsText23: 'Можливість обирати доставку та робити замовлення онлайн',
            landingPossibilitiesTitle: 'Усі можливості',
            landingPossibilities1: 'Опрацювання замовлень',
            landingPossibilities2: 'Статистика',
            landingPossibilities3: 'Розрахунок прибутку',
            landingPossibilities4: 'Фінанси',
            landingPossibilities5: 'Мобільна версія',
            landingPossibilities6: 'Клієнтська база',
            landingPossibilitiesBtn: 'Дізнатися більше',
            landingPartnersTitle: 'Check-in Shop ідеально підходить',
            landingPartners1: 'Магазинам одягу',
            landingPartners2: 'Магазинам косметики',
            landingPartners3: 'Магазинам аксесуарів',
            landingPartners4: 'Магазинам техніки',
            landingPartnersSubTitle: 'Наші партнери',
            landingRatingTitle1: '"Програма чудова. Користуємось вже другий рік. Подобається, що часто оновлюють функціонал"',
            landingRatingName1: 'Оксана Маланчак',
            landingRatingText1: 'Засновниця центру подології «Здоровая стопа»',
            landingRatingTitle2: '"Програма чудова. Користуємось вже другий рік. Подобається, що часто оновлюють функціонал"',
            landingRatingName2: 'Оксана Маланчак',
            landingRatingText2: 'Засновниця центру подології «Здоровая стопа»',
            landingRatingTitle3: '"Програма чудова. Користуємось вже другий рік. Подобається, що часто оновлюють функціонал"',
            landingRatingName3: 'Оксана Маланчак',
            landingRatingText3: 'Засновниця центру подології «Здоровая стопа»',
            landingRatingStarTitle: 'Оцінка від улюблених клієнтів',
            landingRatingStarText: 'Користувацька оцінка',
        },
        motivationBlock: {
            landingMotivationTitle: 'Наведи лад у своєму бізнесі просто зараз',
            landingMotivationBtn: 'СПРОБУВАТИ БЕЗКОШТОВНО',
        },
        headerLanding: {
            headerLandingMenuLink1: 'Можливості',
            headerLandingMenuLink2: 'База знань',
            headerLandingMenuLink3: 'Блог',
            headerLandingMenuLink4: 'Ціни',
            headerLandingMenuLink5: 'Партнери',
            headerLandingContactTitle: 'ЗВ’ЯЗОК',
            headerLandingTryNowBtn: 'Спробувати зараз',
            headerLandingLoginBtn: 'Вхід',
        },
        footerLanding: {
            footerLandingLinkFirstTitle: 'ПРОДУКТ',
            footerLandingLinkSecondTitle: 'ПРО НАС',
            footerLandingLink1: 'Можливості',
            footerLandingLink2: 'База знань',
            footerLandingLink3: 'Блог',
            footerLandingLink4: 'Ціни',
            footerLandingLink5: 'Партнери',
            footerLandingContactTitle: 'ЗВ’ЯЗОК',
            footerLandingSocialTitle: 'Надіслати пропозицію',
            footerLandingBottomText1: 'Всі права захищенно.',
            footerLandingBottomText2: 'Розроблений та підтримується',
        },
        animatedText: {
            animatedText1: 'тих хто хоче продавати',
            animatedText2: 'тих хто хоче заробляти',
        },
        shopTemplates: {
            shopTemplatesTitle: 'Виберіть один із шаблонів',
            shopTemplatesCardTitle: 'Шаблон №',
            shopTemplatesCardBtn: 'Вибрати',
            shopTemplatesModCreateShopTitle: 'Пропустити вибір шаблону?',
            shopTemplatesModSelectNewTemplatesTitle: 'Застосувати даний шаблон?',
            shopTemplatesStep1Title: 'Як називається ваш магазин?',
            shopTemplatesStep1SubTitle: 'Назву можна буде змінити.',
            shopTemplatesStep1Label: 'Введіть ім\'я тут',
            shopTemplatesStep1Label: '',
            shopTemplatesStepBtnNext: 'Вперед',
            shopTemplatesStepBtnBack: 'Назад',
            shopTemplatesStepBtnMiss: 'Пропустити',
            shopTemplatesStepBtnCreate: 'Створити',
            shopTemplatesStepBtnMyCreate: 'Створити самостійно',
            shopTemplatesStep2Title: 'Вкажіть валюту в якій будуть проводитись продажі?',
            shopTemplatesStep2SubTitle: 'Валюту можна буде змінити.',
            shopTemplatesStep2Label: 'Введіть позначення валюти',
            shopTemplatesStep3SubTitle: 'Шаблони можна буде змінити.',
            shopTemplatesCardTitleMy: 'Створіть свій власний',
            shopTemplatesPaymentMethods: 'Отлата карткою',
            shopTemplatesDeliveryMethods: 'Пошта',
        },
        blogsView: {
            blogsViewTitle: 'Інформаційний Блог',
            blogsViewCardTitle1: 'Як відкрити онлайн магазин: 5 простих кроків',
            blogsViewCardText1: 'У сучасному цифровому світі все більше людей обирають зручність та комфорт онлайн шопінгу. Відкриття власного онлайн магазину може бути вигідною та захоплюючою можливістю розвивати свій бізнес. Якщо ви розмірковуєте про створення власного електронного магазину, ось 5 простих кроків, які допоможуть вам розпочати.',
            blogsViewCardTitle2: 'Продаж в умовах кризи: 5 стратегій, які приведуть до успіху',
            blogsViewCardText2: 'Кризові періоди можуть бути викликом для бізнесу, але вони також надають можливість для зростання та інновацій. Якщо ви шукаєте способи просунути свій бізнес під час кризи, ось 5 стратегій, які допоможуть вам досягти успіху.',
            blogsViewCardTitle3: 'Топ 3 поради для початківця-власника інтернет-магазину',
            blogsViewCardText3: 'Як початківець, який має бажання відкрити власний інтернет-магазин, ви стикаєтеся з безліччю питань та викликів. Але не хвилюйтеся! Ось топ 3 поради, які допоможуть вам у розпочатку свого електронного бізнесу.',
            blogsViewCardTitle4: 'Топ 3 переваги CRM: Як впровадження системи управління взаєминами з клієнтами поліпшує ваш бізнес',
            blogsViewCardText4: 'CRM (Customer Relationship Management) - система управління взаєминами з клієнтами - відіграє важливу роль у сучасному бізнесі. Вона допомагає підтримувати та вдосконалювати взаємовідносини з клієнтами, забезпечує ефективну організацію та аналіз даних. Ось топ 3 переваги CRM, які допоможуть вашому бізнесу зрости та розвиватися.',
        },
        blogView: [
            {
                blogImage: 'https://garne.com.ua/upload/data/article_2017/articles_44.jpg',
                blogViewTitle: 'Як відкрити онлайн магазин: 5 простих кроків',
                blogViewSubTitle1: '1. Вибір товару та ніші',
                blogViewText1: "Перш ніж почати будувати свій онлайн магазин, важливо визначитися з товаром або послугою, яку ви будете пропонувати. Ретельно проаналізуйте ринок, з'ясуйте, які товари або послуги популярні та мають попит. Розгляньте свої інтереси та знання, оскільки працювати з продуктом, який вам подобається, буде більш задоволенням. Також оберіть нішу, або специфічну групу клієнтів, до якої ви хочете звертатися. Це допоможе вам вирізнятися на ринку та залучати цільову аудиторію.",
                blogViewSubTitle2: '2. Вибір платформи для онлайн магазину',
                blogViewText2: "Існує багато платформ та CMS (систем керування контентом), які допоможуть вам побудувати свій онлайн магазин безпроблемно. Один з популярних варіантів - Check-in Shop. Він має простий інтерфейс, багато шаблонів дизайну та інструментів для керування продуктами та замовленнями. Інші популярні платформи включають WooCommerce для WordPress, Magento та OpenCart. Оберіть платформу, яка найкраще підходить для ваших потреб та бюджету.",
                blogViewSubTitle3: '3. Маркетинг та просування',
                blogViewText3: "Створивши свій онлайн магазин, настав час зайнятися просуванням. Використовуйте соціальні мережі, контент-маркетинг, рекламні кампанії та інші канали для привернення уваги клієнтів. Забезпечте якісну SEO-оптимізацію, щоб ваш магазин був видимим у пошукових системах. Створюйте цікавий контент, пропонуйте акції та знижки, співпрацюйте з блогерами або інфлюенсерами.",
            },
            {
                blogImage: 'https://www.greatgame.com/hubfs/Templates-2018/Blog%20Templates/5%20Tips%20to%20Keep%20Selling.png',
                blogViewTitle: 'Продаж в умовах кризи: 5 стратегій, які приведуть до успіху',
                blogViewSubTitle1: '1. Аналіз та пристосування до нових потреб клієнтів',
                blogViewText1: 'Перш за все, проведіть детальний аналіз змін, які відбулися в поведінці споживачів під час кризи. Розберіться, які потреби та пріоритети змінилися, які товари або послуги стали особливо важливими для людей. На основі цих відомостей адаптуйте свою продукцію або послуги, щоб відповідати новим потребам ринку.',
                blogViewSubTitle2: '2. Зосередьтеся на онлайн-продажах та електронному маркетингу',
                blogViewText2: 'У кризовий період багато людей шукають товари та послуги в Інтернеті. Зосередьтеся на розвитку свого онлайн-присутності та ефективному використанні електронного маркетингу. Зробіть свій веб-сайт атрактивним та зручним для покупців, використовуйте соціальні мережі, електронну пошту та контент-маркетинг для залучення та утримання клієнтів.',
                blogViewSubTitle3: '3. Запропонуйте акції та знижки',
                blogViewText3: 'У кризовий період багато людей більш уважно стежать за своїми витратами. Залучіть їх увагу, пропонуючи акції, знижки або спеціальні умови на ваші товари або послуги. Це може бути тимчасові знижки, безкоштовна доставка або подарунки до купівлі. Такі заохочення допоможуть збільшити обсяг продажів та привернути нових клієнтів.',
            },
            {
                blogImage: 'https://gorillaaccounting.com/wp-content/uploads/2022/02/coffee-shop-owner.jpg',
                blogViewTitle: 'Топ 3 поради для початківця-власника інтернет-магазину',
                blogViewSubTitle1: '1. Ретельне дослідження та розуміння вашої ніші',
                blogViewText1: "Перш ніж розпочати будь-яку діяльність, важливо ретельно вивчити вашу нішу. Дослідіть вашу цільову аудиторію, з'ясуйте, які товари або послуги вони шукають, та зрозумійте, які проблеми ви можете їм вирішити. Це допоможе вам визначити свої конкурентні переваги та розробити стратегію маркетингу, що буде привертати вашу цільову аудиторію.",
                blogViewSubTitle2: '2. Якісний контент та приваблива візуальна презентація',
                blogViewText2: "У світі електронної торгівлі візуальний аспект має велике значення. Пам'ятайте, що ваші клієнти не можуть фізично спостерігати товар перед покупкою, тому якісні фотографії та описи грають важливу роль. Забезпечте чітке та високоякісне зображення товару, надайте докладний опис його характеристик та переваг. Крім того, використовуйте візуальні елементи, такі як логотип, кольорова схема та дизайн, що відповідають вашому бренду і створюють привабливу атмосферу для клієнтів.",
                blogViewSubTitle3: '3. Забезпечення надійності та зручності покупок',
                blogViewText3: 'Надійність та зручність є ключовими факторами, які впливають на довіру клієнтів до вашого магазину. Подбайте про безпеку платежів та захист персональних даних клієнтів. Встановіть надійну систему оплати та доставки, що відповідає потребам вашої аудиторії. Забезпечте зручну навігацію по вашому сайту та прості форми для замовлень. Не забувайте також про якісну та оперативну підтримку клієнтів, яка відповідає на їх запитання та допомагає вирішувати проблеми.',
            },
            {
                blogImage: 'https://i.ytimg.com/vi_webp/7egQXWg0lw0/maxresdefault.webp',
                blogViewTitle: 'Топ 3 переваги CRM: Як впровадження системи управління взаєминами з клієнтами поліпшує ваш бізнес',
                blogViewSubTitle1: '1. Підвищення рівня задоволеності клієнтів',
                blogViewText1: 'Однією з ключових переваг CRM є можливість поліпшення взаємодії з клієнтами та підвищення рівня їх задоволеності. Система CRM дозволяє відстежувати всі контакти та взаємодії з клієнтами, зберігати їх історію замовлень, запитів та комунікації. Це допомагає створити більш персоналізований підхід до кожного клієнта, швидко реагувати на їх потреби та вимоги, а також забезпечувати якісну підтримку післяпродажного обслуговування. Задоволені клієнти більш схильні до повторних покупок та рекомендацій своїм знайомим, що сприяє збільшенню продажів та росту бізнесу.',
                blogViewSubTitle2: '2. Ефективне управління продажами та прогнозування',
                blogViewText2: 'CRM дозволяє ефективно управляти процесом продажів, відстежувати кроки у воронці продажів та аналізувати дані щодо конверсії та втрат. За допомогою системи CRM ви можете встановити чітку систему управління контактами з потенційними клієнтами, відслідковувати угоди, нагадування про завершення угод та вирішувати поточні проблеми. Крім того, система CRM надає можливість прогнозувати продажі на основі аналізу даних та історії замовлень. Це допомагає вам бути більш стратегічними у плануванні та прийнятті рішень щодо вашого бізнесу.',
                blogViewSubTitle3: '3. Збільшення ефективності маркетингу та залучення нових клієнтів',
                blogViewText3: 'CRM є потужним інструментом для маркетингових кампаній та просування вашого бренду. Ви можете аналізувати дані про клієнтів, їх покупкові звички та інтереси, щоб створювати персоналізовані та цілеспрямовані пропозиції. Також, використовуючи CRM, ви можете автоматизувати процеси маркетингу, такі як розсилки електронних листів, сегментування аудиторії та оцінка ефективності кампаній. Це дозволяє зекономити час та зусилля, покращити співпрацю між відділами маркетингу та продажів, а також привернути нових клієнтів до вашого бренду.',
            }
        ],
        helpView: [
            {
                helpViewTitle: 'База знань Check-in Shop'
            },
            {
                // 1 порядок не мінят. При зміні порядку в масиві потрібно на сторінці HelpView змінити кількість button
                section: '📊 Аналітика',
                question1: 'Сторінка аналітики',
                answer1: 'На даніій сторінці будуть відображені графіки "Кількість виконаних замовлень за рік", "Кількість відвідувачів за рік", "Графік статусів замовлень", "Графік кількості відвідувачів", "Популярні товари", та інша корисна інформація. Доступ до цієї сторінки тільки у власника (створені Менеджери і ТопМенеджери не матимуть доступу до сторінки).',
            },
            {
                // 2
                section: '🗂️ Категорії',
                question1: 'Сторінка категорій',
                answer1: 'На даній сторінці можна створювати, редагувати і видаляти категорії. Кількість головних категорій обмежана до 5. Кількість підкатегорій не обмежена.',
                question2: 'Створення категорій',
                answer2: 'Для створення категорії, або редагуванні Впишіть її назву і нажміть на кнопку підтвердити. Кількість категорій обмежена до "5". Для коректного відображення картинок, формат картинки повинен бути 1:1. Щоб створити категорію, спершу потрібно створити магазин. Після створення категорії зможете створювати підкатегорії. Якщо в категорії не має підкатегорій і є створені товари, то можливісь створити підкатегорію на неї блокується.',
                question3: 'Підкатегорії',
                answer3: 'Їх створення можливе після створення категорії. Кількість підкатегорій не обмежена.',
                question4: 'Видалення, або приховання  категорії',
                answer4: 'Після видалення категорії, всі створені товари які були в ній і в її підкатегоріях не видаляться. Вони отримають статус "Без категорій". Їх можна відфільтрувати на сторінці "Товар" і відредагувати, або видалити.',
            },
            {
                // 3
                section: '🛒 Товари',
                question1: 'Сторінка товару',
                answer1: 'На даній сторінці можна створювати, редагувати, приховувати і видаляти товари.',
                question2: 'Створення товару',
                answer2: 'При створенні товару необхідно обовязково вказати його назву, категорію, і ціну. В поле "Ціна на товар, якщо є знижка" необхідно вписати ціну на товар якщо даний товар хочете продати зі знижкою. Бажаний формат для картинок товару 9:12.',
                question3: 'Пошук товару',
                answer3: 'Щоб знайти необхідний товар потрібно вписати його назву в поле для пошуку і натиснути кнопку "Пошук", або клавішу "Enter". Також товари можна сортувати за категоріями, за цінами з додатковими параметрами для яких необхідно поставити галочку. При пошуку за категоріями і вибору поля "Без категорій" будуть вибрані всі товари які не відображаються в вашому магазині через відсутність категорі, такі товари появляються після видалення категорії.',
            },
            {
                // 4
                section: '💰 Замовлення',
                question1: 'Сторінка замовлень',
                answer1: 'На цій сторінці будуть відображатись ваші замовлення. Сортувати замовлення можна за датою його створення, за його статусом, а також одним з трьох параметрів "Прочитані", "Не прочитані" і "Важливі". Також тут можна відслідковувати статус замовлення і назначати виконавця.',
                question2: 'Замовлення',
                answer2: 'В замовлені буде відображена інформація про замовника, замовлений товар і його кількість. Також в замовлені є можливість записати важливу інформацію у вигляді нотатків. Якщо в замовлені замість замовленого товару вірображається повідомлення "Було замовлено <кількість> товару", це означає, що даний товар вже видалений з магазину.',
                question3: 'Статус замовлення',
                answer3: 'Існує три статуси замовлення "В процесі", "Виконано", і "Відхилено". Кожен статус відображається своїм кольорм. Змінити статус можна на сторінці звмовлення, або в самому замовлені. Кожне нове замовлення автоматично отримує статус "В процесі". Змінити статус може тільки власник магазину, або той Менеджер і ТопМенеджер який взяв це замовлення на виконання.',
            },
            {
                // 5
                section: '⚔️ Персонал',
                question1: 'Сторінка персоналу',
                answer1: 'Сторінка презначена для створення менеджерів, які матимуть доступ до вашого магазину і допомагатимуть вам у продажі і роботі магазину.',
                question2: 'Створення менеджерів',
                answer2: 'Для створення менеджерів необхідно вказати їх електронну адресу, назву і їх тип. На вказану електронну адресу буде відправлено пароли, за яким вони зможуть зареєструватись на сайті щоб отримати доступ до вашого магазину. В особистому кабінеті вони отримують доступ до таких сторінок "Категорії", "Товар", "Замовлення", "Повідомлення". Існує два типи менеджерів "Менеджер" "Топ-менеджер", їх різниця полягає в тому, що "Топ-менеджер" отримує доступ до створення, видалення і редагування товарів в магазині.',
            },
            {
                // 6
                section: 'Налаштування',
                question1: 'Сторінка налаштування',
                answer1: 'На даній сторінці зможете міняти мову особистого кабінету і при необхідності видалити магазин з бази даних. При видалені магазину всі дані даного магазину будуть видалені і їх неможливо буде відновити.',
            },
        ],
        personnelView: {
            personnelViewModDelManagerTitle: 'Видалити цього менеджера?',
            personnelViewModDelManagerLeftBtn: 'Ні',
            personnelViewModDelManagerRightBtn: 'Так',
            personnelViewModEditManagerTitle: 'Виберіть новий тип',
            personnelViewModCreateManagerTitle: 'Створення менеджера',
            personnelViewModEditManagerLeftBtn: 'Відмінити',
            personnelViewModEditManagerRightBtn: 'Оновити',
            personnelViewModCreateManagerRightBtn: 'Створити',
            personnelViewTitle: 'Пресонал',
            personnelViewMenedgerError: 'Список працівників пустий',
            personnelViewError: 'Спершу потрібно створити магазин',
            personnelViewInputLabel: 'Введіть email',
            personnelViewCheckboxTitle: 'Виберіть тип менеджера',
            personnelViewCheckboxLabel1: 'Менеджер',
            personnelViewCheckboxLabel2: 'Топ-менеджер',
            personnelViewCreateBtn: 'Створити працівника',
            personnelViewCreatedDate: 'Добавлено',
            personnelViewShowSuccessCreatePersonnel: 'Створено працівника',
            personnelViewShowSuccessEditPersonnel: 'Статус змінено',
            personnelViewShowSuccessDelPersonnel: 'Менеджера видалено',
        },
        userAnalytics: {
            userAnalyticsTitle: 'Привіт',
            userAnalyticsSubTitle1: 'Схоже це чудовий день.',
            userAnalyticsSubTitle2: 'Новий день, нові можливості.',
            userAnalyticsSubTitle3: 'Ми раді, що ви з нами.',
            userAnalyticsBtnPage1: 'Аналітика за останній місяць',
            userAnalyticsBtnPage2: 'Аналітика за останній рік',
        },
        moreAnalytics2: {
            moreAnalytics2GraphVisitorsLabelInProcess: 'В процесі',
            moreAnalytics2GraphVisitorsLabelDone: 'Виконаних',
            moreAnalytics2GraphVisitorsLabelRejected: 'Відхилених',
            moreAnalytics2GraphVisitorsYearLabel: 'Кількість відвідувачів',
            moreAnalytics2GraphCompletedTitle: 'Графік виконаних замовлень',
            moreAnalytics2GraphCompletedSubTitle: 'Рік:',
            moreAnalytics2GraphVisitorsYearTitle: 'Графік кількості відвідувачів',
            moreAnalytics2GraphVisitorsYearAdvsTitle: 'Кількість відвідувачів з блоку реклами',
            moreAnalytics2ViewTable: 'Таблична форма',
            moreAnalytics2VisitorsYearItemInfo: 'Відвідувачі',
            moreAnalytics2GraphVisitorsYearAdvsInfo: 'відвідали',
            moreAnalytics2GraphYearManagersTitle: 'Табличка успішношті менеджерів',
            moreAnalytics2GraphYearManagersAll: 'Всіх замовлень:',
        },
        moreAnalytics1: {
            moreAnalytics1TopClients: 'Найкращі клієнти',
            moreAnalytics1ModDelProductTitle: 'Ви впевнені',
            moreAnalytics1ModDelProductText: 'Видалити даний товар?',
            moreAnalytics1ModHideProductTitle: 'Приховати даний товар?',
            moreAnalytics1ModHideProductText: 'товар не буде відображатись в магазині',
            moreAnalytics1GraphVisitorsLabelsDay: 'День',
            moreAnalytics1GraphVisitorsLabels: [
                "Січень",
                "Лютий",
                "Березень",
                "Квітень",
                "Травень",
                "Червень",
                "Липень",
                "Серпень",
                "Вересень",
                "Жовтень",
                "Листопад",
                "Грудень",
            ],
            moreAnalytics1GraphProfitYearLabel: 'Кількість прибутку',
            moreAnalytics1GraphStatus2: 'В процесі:',
            moreAnalytics1GraphStatus3: 'Виконано:',
            moreAnalytics1GraphStatus4: 'Відхилено:',
            moreAnalytics1GraphVisitorsQuantity: 'відвідувачів',
            moreAnalytics1GraphVisitorsLabelAll: 'Всіх',
            moreAnalytics1ShowMesDelProduct: 'Товар видалено',
            moreAnalytics1ShowMesHideProduct: 'Товар оновлено',
            moreAnalytics1Error: 'На цій сторінці будуть відображатись графік кількості відвідувачів, графік статусів замовлень, та інша корисна інформація.',
            moreAnalytics1GraphProfitYearTitle: 'Графік прибупку',
            moreAnalytics1GraphVisitorsYearSubTitle: 'Рік:',
            moreAnalytics1GraphProfitYeartext: 'загальна сума',
            moreAnalytics1AllOrderStatus: 'всіх:',
            moreAnalytics1InProcessStatus: 'в процесі:',
            moreAnalytics1DoneStatus: 'виконаних:',
            moreAnalytics1RejectedStatus: 'відхилених:',
            moreAnalytics1GraphStatus1: 'Всіх замовлень:',
            moreAnalytics1GraphVisitorsTitle: 'Графік кількості відвідувачів',
            moreAnalytics1GraphVisitorsSubTitle: 'За місяць:',
            moreAnalytics1TopProductTitle: 'Популярні товари',

        },
        promoCode: {
            promoCodeDelModTitle: 'Видалити цей промокод?',
            promoCodeEditModTitle: 'Введіть нові дані',
            promoCodeEditModLabel1: 'Нова назва',
            promoCodeEditModPlaceholder1: 'Введіть назву...',
            promoCodeEditModLabel2: 'Введіть кількість промокодів',
            promoCodeEditModLabel3: 'Введіть суму знижки',
            promoCodeEditModText: 'Виберіть значення знижки',
            promoCodeCreateLabel1: 'Створіть назву промокоду',
            promoCodeCreateBtn: 'Створити',
            promoCodePromoName: 'назва:',
            promoCodePromoValue: 'знижка:',
            promoCodePromoCount: 'залишилось:',
            promoCodeNewPromoError1: 'Потрібно заповнити всі дані',
            promoCodeNewPromoError2: 'Промокод з такою назвою вже існує',
        },
        userIntegration: {
            userIntegrationMerchantLable1: 'Поле для введення id',
            userIntegrationMerchantLable2: 'Вставте id',
            userIntegrationMerchantText1: '1.Для інтеграції власного google merchant необхідно перейти по силці',
            userIntegrationMerchantText2: 'і зареєструватись.',
            userIntegrationMerchantText3: '2. Після реєстрації отримане id магазину потрібно вставити в поле для введення і натиснути кнопку створити',
            userIntegrationMerchantText4: 'Для детальнішої інструкції натисніть на',
            userIntegrationMerchantText5: 'силку',
            userIntegrationMerchantText6: 'Для видалення google merchant очистіть рядок введення і натисніть кнопку оновити',
            userIntegrationTitle: 'Інтеграція',
            userIntegrationChatTitle: 'Інтенрація чату',
            userIntegrationChatText1: 'Для інтеграції власного чату необхідно перейти по силці',
            userIntegrationChatText2: 'і зареєструватись. Получену силку після реєстрації необхідно вставити в поле для введення і натиснути "Створити".',
            userIntegrationChatPlaceholder: 'Вставте силку..',
            userIntegrationChatBtnCreate1: 'Оновити',
            userIntegrationChatBtnCreate2: 'Створити',
            userIntegrationChatError: 'Введені дані не вірні. Некоректна силка.',
            userIntegrationChatResText: 'Чат створений. Введена силка:',
            userIntegrationModDelChat: 'Видалити чат?',
            userIntegrationChatCreateTitle: 'Вставте рядок',
            userIntegrationChatCreateSubTitle: 'Напишіть рядок для інтеграції нижче.',
        },
        pricingView: {
            pricingViewTitle: 'Підберіть план, який ідеально підійде саме вам',
            pricingViewSubTitle: 'Ціна за один онлайн магазин',
            pricingViewMonthBtn1: 'Помісячно',
            pricingViewMonthBtn2: 'За рік',
            pricingViewItemTitle1: 'Стартап',
            pricingViewItemTitle2: 'Базовий',
            pricingViewItemTitle3: 'Професійний',
            pricingViewItemSubTitle1: 'Для невеликих магазинів',
            pricingViewItemSubTitle2: 'Для готового бізнесу',
            pricingViewItemSubTitle3: 'Для приватних стартапів',
            pricingViewItemText1: 'До 5 працівників',
            pricingViewItemText2: 'Місячна аналітика',
            pricingViewItemText3: 'Клієнтська база',
            pricingViewItemText4: 'Категоризація',
            pricingViewItemText5: 'Фідбеки від користувачів',
            pricingViewItemText6: 'Внутрішня реклама',
            pricingViewItemText7: 'Необмежено працівників',
            pricingViewItemText8: 'Промо коди',
            pricingViewItemText9: 'Розсилка по email',
            pricingViewItemText10: 'Скачати звіти по продажах',
            pricingViewItemText11: 'Інтеграція чату',
            pricingViewItemText12: 'Місячна та річна аналітика',
            pricingViewItemText13: 'Скачувати список усіх клієнтів',
            pricingViewItemText14: 'Інтеграція з телеграм ботом',
            pricingViewItemText15: 'Пріоритетна лайв підтримка',
            pricingViewItemText16: 'Інтеграція з WayForPay',
            pricingViewItemBtn: 'Спробувати зараз',
            pricingViewItemSubTitle4: 'Ви можете спробувати користуватися безкоштовно протягом першого місяця.',
            pricingViewInfoTitle: 'Всі тарифи включають',
            pricingViewQuestionTitle: 'Часті питання',
            pricingViewQuestion1: 'Що означає безкоштовний період?',
            pricingViewQuestion2: 'Боюсь що не зможу самостійно розібратись. Ви зможете мені допомогти?',
            pricingViewQuestion3: 'Чи зможу я потім перейти на інший тарифний план?',
            pricingViewQuestionText1: 'Після реєстрації ви отримуєте місяць роботи сервісу на тарифі Професійний. Протягом цього часу ми допоможемо Вам ввести створити усі товари. Також відповімо на всі питання і проведемо навчання персоналу. Ви зможете перейти на інший тариф в будь який день, якщо ви захочете оплатити підписку швидше.',
            pricingViewQuestionText2: 'Звичайно! Ми готові допомогти вам з усіма питаннями, що виникають у вас. Ми можемо надати вам підтримку і пояснення щодо будь-яких проблем чи запитань, з якими ви зіткнулися. Не соромтеся звертатися до нас з будь-якими питаннями або проблемами, і ми зробимо все можливе, щоб вам допомогти.',
            pricingViewQuestionText3: 'Так, зазвичай ви можете перейти на інший тарифний план у майбутньому. Якщо ваші потреби зростатимуть і ви будете потребувати більше ресурсів або додаткових функцій, вам можуть пропонувати перехід на більш продуктивний або розширений тарифний план.',
            pricingViewQuestion4: 'Що означає безкоштовний період?',
            pricingViewQuestion5: 'Що означає безкоштовний період?',
            pricingViewQuestion6: 'Що означає безкоштовний період?',
            pricingViewQuestionText4: 'Після реєстрації ви отримуєте 7 безкоштовних днів роботи сервісу на тарифі Профі. Протягом цього часу ми допоможемо Вам ввести прайс товарів і послуг, клієнтів і записи. Також відповімо на всі питання і проведемо навчання персоналу. Ви зможете перейти на інший тариф в будь який день, а всі невикористані дні будуть додані до вашої підписки, якщо ви захочете оплатити підписку швидше.',
            pricingViewQuestionText5: 'Після реєстрації ви отримуєте 7 безкоштовних днів роботи сервісу на тарифі Профі. Протягом цього часу ми допоможемо Вам ввести прайс товарів і послуг, клієнтів і записи. Також відповімо на всі питання і проведемо навчання персоналу. Ви зможете перейти на інший тариф в будь який день, а всі невикористані дні будуть додані до вашої підписки, якщо ви захочете оплатити підписку швидше.',
            pricingViewQuestionText6: 'Після реєстрації ви отримуєте 7 безкоштовних днів роботи сервісу на тарифі Профі. Протягом цього часу ми допоможемо Вам ввести прайс товарів і послуг, клієнтів і записи. Також відповімо на всі питання і проведемо навчання персоналу. Ви зможете перейти на інший тариф в будь який день, а всі невикористані дні будуть додані до вашої підписки, якщо ви захочете оплатити підписку швидше.',
        },
        subscriptionView: {
            subscriptionViewTitle: 'Виберіть свій план',
            subscriptionViewBtnClose: 'Скасувати',
        },
        customersView: {
            customersViewModalDownloadTitle: 'Скачати базу даних всіх клієнтів?',
            customersViewModalWriteTitle: 'Напишіть повідомлення і виберіть отримувачів',
            customersViewModalLeftBtn: 'Відмінити',
            customersViewModaRightBtn: 'Відправити',
            customersViewModaInput1: 'Заголовок',
            customersViewModaInput2: 'Текст повідомлення',
            customersViewModaInput3: 'Пошук клієнтів',
            customersViewModaInput4: 'Вибрати всіх',
            customersViewBtnSearch: 'Пошук',
            customersViewError: 'Список пустий',
            customersViewBtnWrite: 'Повідомити клієнта',
            customersViewBtnDownload: 'Скачати всіх клієнтів',
            customersViewTitle: 'Клієнти',
            customersViewCountCustomers: 'Кількість клієнтів:',
            customersViewSearchCustomers: 'Пошук клієнтів',
            customersViewShowSuccess1: 'Повідомлення відправлено',
        },
        customerCard: {
            customerCardModalBanTitle: 'Змінити статус цього клієнта?',
            customerCardShowSuccess2: 'Дані змінено',
            customerCardTitleTel: 'Телефон',
            customerCardTitleMeil: 'Емейл',
            customerCardTitleCount: 'Покупок',
            customerCardTitleCosts: 'Сума покупок',
            customerCardBtnBanned1: 'Розбанити',
            customerCardBtnBanned2: 'Забанити',
            customerCardLastOrder: 'Останнє замовлення',
        },
        customerView: {
            customerViewModlTitle: 'Змінити статус цього клієнта?',
            customerViewPurchasesTitleDate: 'Дата:',
            customerViewPurchasesRegistrDate: 'Дата Реєстрації',
            customerViewPurchasesTitleStatus: 'Статус',
            customerViewBtnBanned1: 'Розбанити',
            customerViewBtnBanned2: 'Забанити',
            customerViewShowSuccess1: 'Дані змінено',
        },
        partnersView: {
            partnersViewMainTitle: 'Одні з найкращих інтернет магазинів',
            partnersViewMainText: '"Ознайомтесь з нашими кращими магазинами та дізнайтеся більше про їхні продукти, натиснувши на будь-який з логотипів і перейшовши на відповідні сторінки!"',
            partnersViewBanner: '"Створіть свій власний інтернет-магазин і розкрийте свій бізнесовий потенціал! Реєстрація на нашому сайті займе всього кілька хвилин і відкриє перед вами безмежні можливості для розвитку вашого бізнесу в онлайн-світі."',
            partnersViewCommentTitle: 'Відгуки наших клієнтів',
            partnersViewCommentAuthorTitle: 'Автор',
            partnersViewCommentName1: 'Махайло Подоляк',
            partnersViewCommentText1: 'Дуже задоволений своїм інтернет-магазином! Сайт дуже зручний і простий у використанні. Можливість налаштувати категорії товарів та здійснювати продажі онлайн дозволяє мені з легкістю управляти своїм бізнесом.',
            partnersViewCommentName2: 'Макс Назаров',
            partnersViewCommentName3: 'Іван Чепурний',
            partnersViewCommentName4: 'Олкна Кулик',
            partnersViewCommentName5: 'Ірина Синяк',
            partnersViewCommentText2: 'Я так задоволений, що реєструвався на цьому сайті для створення свого інтернет-магазину. Процес налаштування був швидким і простим. Тепер я можу продавати свої товари в Інтернеті та залучати нових клієнтів.',
            partnersViewCommentText3: 'Я дуже задоволений своїм інтернет-магазином, який я створив на цьому сайті. Сайт має безкоштовний план, який дозволяє мені почати продажі без великих витрат. Мої клієнти дуже задоволені зручним інтерфейсом та швидкою доставкою.',
            partnersViewCommentText4: 'Я дуже рада, що використовую цей сайт для створення мого інтернет-магазину. Як новачок у бізнесі, мені дуже потрібна була допомога зі створенням власного сайту. Тепер я можу легко залучати нових клієнтів та збільшувати свій дохід.',
            partnersViewCommentText5: 'Я використовую цей сайт для створення свого інтернет-магазину вже кілька місяців і дуже задоволений результатом. Я отримала багато замовлень з різних країн, завдяки зручному інтерфейсу та швидкій доставці. Цей сайт дійсно допоміг мені розширити свій бізнес.',
            partnersViewCommentBtn1: 'Подобається',
            partnersViewCommentBtn2: 'Відповісти',
        },
        selectCard: {
            selectCardTitle: 'Одні з найкращих інтернет магазинів',
            selectCardBtn: 'Вибрати',
            selectCardSelected: 'Вибране',
            selectCardError: 'Спершу потрібно створити магазин',
        },
        mainSlider: {
            mainSliderTitle: 'Завантажте картинки для головного сладера',
        },
    },
    'ENG': {
        signUpView: {
            signUpSliderTitle1: 'Creation of an online store', 
            signUpSliderTitle2: 'Development of your business', 
            signUpSliderTitle3: 'Help in achieving the dream', 
            signUpSliderSubTitle1: 'Creating an online store Creating an online store', 
            signUpSliderSubTitle2: 'Creating an online store Creating an online store', 
            signUpSliderSubTitle3: 'Creating an online store Creating an online store', 
            signUpCompanyName: 'Company name',
            signUpCreateTitle: 'Create account',
            signUpGoToTitle: 'Go to page',
            signUpNameTitle: 'Name',
            signUpNamePlaceholder: 'Enter a name...',
            signUpSurnameTitle: 'Surname',
            signUpSurnamePlaceholder: 'Enter last name...',
            signUpEmailTitle: 'Mail',
            signUpEmailPlaceholder: 'Enter your email...',
            signUpPasswordTitle: 'Password',
            signUpPasswordError: 'The repeated password is not entered correctly',
            signUpPasswordPlaceholder: 'Enter your password...',
            signUpPasswordRepeatTitle: 'Repeat the password',
            signUpPasswordRepeatPlaceholder: 'Enter your password...',
            signUpCreateBtn: 'Create',
            signUpReturnToTitle: 'Return to',
            signUpMainPage: 'Main page',
        },
        SignInView: {
            signInSliderTitle1: 'Creation of an online store', 
            signInSliderTitle2: 'Development of your business', 
            signInSliderTitle3: 'Help in achieving the dream', 
            signInSliderSubTitle1: 'Creating an online store Creating an online store', 
            signInSliderSubTitle2: 'Creating an online store Creating an online store', 
            signInSliderSubTitle3: 'Creating an online store Creating an online store', 
            signUpCompanyName: 'Company name',
            signInTitle: 'Sign in',
            signInSubTitle: 'Don\'t have an account?',
            signInEmailTitle: 'Mail',
            signInEmailPlaceholder: 'Enter your email...',
            signInPasswordTitle: 'Password',
            signInPasswordPlaceholder: 'Enter your password...',
            signInLeavePassword: 'Forgot your password?',
            signInLogiBtn: 'Log in',
            signInReturnToTitle: 'Return to',
            signInMainPage: 'Main page',
        },
        layoutUser: {
            layoutLinkAnalytics: 'Analytics',
            layoutLinkShop: 'Shop',
            layoutLinkCategories: 'Categories',
            layoutLinkProducts: 'Products',
            layoutLinkOrders: 'Orders',
            layoutLinkMessage: 'Messages',
            layoutLinkNotifications: 'Notifications',
            layoutLinkBarcode: 'Scan Bar Code',
            layoutLinkPersonnel: 'Personnel',
            layoutLinkVisitShop: 'Go to the shop',
            layoutLinkSettings: 'Settings',
            layoutLinkSubscription: 'Subscription',
            layoutLinkIntegration: 'Integration',
            layoutLinkCustomers: 'Customers',
            layoutLinkExit: 'Exit',
            layoutModExitText: 'Do you want to leave your personal account?',
            layoutModExitTitle: 'Are you sure?',
            layoutSearchLabel: 'Search for orders by phone and name',
            layoutModSearchBarcodeTitle: 'Enter the barcode of the product',
            layoutModSearchBarcodeLeftBtn: 'Cancel',
            layoutModSearchBarcodeRightBtn: 'Search',
        },
        userShopView: {
            userShopTitle: 'Shop Settings',
            userShopTabs1: 'General Settings',
            userShopTabs2: 'Style Customisation',
            userShopTabs3: 'Shop',
            userShopTabs4: 'Create a promo code',
            userShopTabs5: 'API token',
            userShopTokenTitle: 'Your personal API token',  
            userShopTokenCreateBtn: 'Generete New Token',
            userShopSettingColorTitle: 'Colors',  
            userShopSettingSizeTitle: 'Size',  
            userShopLabelUploud: 'Photo for color selection',  
            userShopSettingHeaderTitle: 'Header',  
            userShopFavouritesrTitle: 'Favourites & Cart',  
            userShopSettingMiddleTitle: 'Middle of the site',  
            userShopSettingBottomTitle: 'Bottom of the site',  
            userShopSettingButtomTitle: 'Button Colors',  
            userShopButtonsAppearence: 'Buttons Appearence',  
            userShopBtnPreview: 'Preview',  
            userShopBtnSend: 'Save Changes',
            userShopTemplatesBtn1: 'Appearance and colors',
            userShopTemplatesBtn2: 'Ready Templates',
            userShopTemplatesBtn3: 'Block of information',
            userShopTemplatesBtn4: 'Advertising block',
            userShopTemplatesBtn6: 'Blocks',
            userShopTemplatesBtn7: 'Main slider',
            userShopTemplatesBtn8: 'Card View',
            userShopOrderBlocksTitle: 'Location and order of blocks',
            userShopTopBlocTitle: 'Information above the site header',
            userShopTopBlocPlaceholder1: 'Information...',
            userShopTopBlocIabel2: 'Block background',
            userShopTopBlocIabel3: 'Text color',
            userShopTopBlocIabel4: 'Add animation',
            userShopHeaderSectionLabel1: 'Transparent background of the cap',
            userShopHeaderSectionLabel2: 'The background color of the icon of the product in the favorites and the cart',
            userShopHeaderSectionLabel3: 'Text color of the product availability icon in favorites and cart',
            userShopHeaderSectionLabel4: 'Background color of subcategories',
            userShopHeaderSectionLabel5: 'Subcategory text color',
            userShopHeaderSectionLabel6: 'Type of cap',
            userShopHeaderSectionTypelabel1: 'Category list on the same level with logo',
            userShopHeaderSectionTypelabel2: 'Just a burger',
            userShopHeaderSectionTypelabel3: 'Category list below logo',
            userShopFooterSectionLabel1: 'Additional information for subscribers, above the subscription field',
            userShopFooterSectionLabel2: 'Hide subscription field',
            userShopFooterSectionPlaceholder1: 'Information...',
            userShopSettingColorHatBg: 'Hat background',
            userShopSettingColorHatText: 'Cap text color',
            userShopSettingColorFooterBg: 'The background of the lower part of the site',
            userShopSettingColorFooterText: 'The color of the text of the lower part of the site',
            userShopSettingColorMainBg: 'Site background',
            userShopSettingColorMainText: 'Website text color',
            userShopSettingBtnColorText: 'Text color',
            userShopSettingBtnColorTextHover: 'The color of the text is indicated',
            userShopSettingBtnColorGradient1: 'The first color of the gradient',
            userShopSettingBtnColorGradient2: 'The second color of the gradient',
            userShopSettingBtnColorGradientHover1: 'The first color of the gradient when hovering',
            userShopSettingBtnColorGradientHover2: 'The second color of the gradient is given',
            userShopSettingBtnSelectTitle: 'Selected option',
            userShopBlockTitle: 'Enter a title',
            userShopBlockTitlePlaceholder: 'Title...',
            userShopBlockText: 'Enter the text',
            userShopBlockTextPlaceholder: 'Information...',
            userShopBlockDescription: 'Description of information',
            userShopBlockHeaderColor: 'Header color',
            userShopBlockTextColor: 'Text color',
            userShopBlockBackgroundColor: 'Background color',
            userShopBlockTextAlign: 'Text alignment',
            userShopBlockTitleShadow: 'Title shadow',
            userShopBlockTextShadow: 'Text shadow',
            userShopBlockHeaderSize: 'Header size',
            userShopBlockHeaderThickness: 'Header thickness',
            userShopBlockTextSize: 'Text size',
            userShopBlockTextThickness: 'Text thickness',
            userShopBlockShadowX: 'Shadow by X',
            userShopBlockShadowY: 'Shadow by Y',
            userShopBlockShadowZ: 'Shadow by Z',
            userShopBlockShadowTransparency: 'Shadow transparency',
            userShopBlockRemoveBtn: 'Delete block of information',
            userShopBlockViewTitle: 'View of the block of information',
            userShopBlockCheckboxStart: 'Start',
            userShopBlockCheckboxCenter: 'Center',
            userShopBlockCheckboxEnd: 'End',
            userShopNotFilledText: 'data not specified',
            userShopBlockAdvertisingTitle: 'Advertising block',
            userShopBlockAdvertisingCheckboxLabel: 'Allow advertising of shops of all categories',
            userShopBlockAdvertisingInfo: 'Select the categories to which your store belongs. Accordingly, only those stores that do not have the same category selected will be in the advertising block.',
        },  
        userCategoriesView: {
            userCategoriesModalDelCatTitle: 'Are you sure?',
            userCategoriesModalEditCatName: 'Category name',
            userCategoriesModalDelCatText: 'All subcategories of this category will be deleted',
            userCategoriesModalDelCatLeftBtn: 'No',
            userCategoriesModalDelCatRightBtn: 'Yes',
            userCategoriesModalDelSubCatTitle: 'Are you sure?',
            userCategoriesModalDelSubCatText: 'Delete this category',
            userCategoriesModalDelSubCatLeftBtn: 'No',
            userCategoriesModalDelSubCatRightBtn: 'Yes',
            userCategoriesModalEditSubCatTitle: 'Edit category?',
            userCategoriesModalEditSubCatText: 'Enter a new name',
            userCategoriesModalHideCatTitle1: 'Hide this category',
            userCategoriesModalHideCatTitle2: 'Restore this category',
            userCategoriesModalHideCatText: 'all products of this category and subcategory will be hidden',
            userCategoriesModalEditCatLeftBtn: 'Cancel',
            userCategoriesModalEditCatRightBtn: 'Confirm',
            userCategoriesTitle: 'Categories',
            userCategoriesErrorCreateLimit: 'You have reached the category limit',
            userCategoriesErrorCreate: 'First you need to create a store',
            userCategoriesCreateCatBtn: 'Create a category',
            userCategoriesCreateSubCatBtn: 'Add Sub-category',
            userCategoriesCreateCatInfo: 'To create or edit a category, enter its name and click the confirm button. The number of categories is limited to "5". For the correct display of pictures, the picture format must be 1:1. To create a category, you must first create a store. After creating a category, you can create subcategories. If the category has no subcategories and there are already created products, the ability to create a subcategory for it is blocked.',
            userCategoriesShowSuccessCreateCat: 'The category has been created',
            userCategoriesShowSuccessCreateSubCat: 'Subcategory created',
            userCategoriesShowSuccessDelCat: 'Category deleted',
            userCategoriesShowSuccessDelSubCat: 'Subcategory removed',
            userCategoriesShowSuccessEditCat: 'The category has been changed',
            userCategoriesShowSuccessEditSubCat: 'The name has been changed',
            userCategoriesShowSuccessHideCat: 'The category is hidden',
        },
        creationShop: {
            creationShopShowSuccessUpdateShop: 'The data has been updated',
            creationShopDeliveryMethods1: 'Delivery service',
            creationShopPaymentMethods1: 'Prepayment',
            creationShopTitle1: 'Creating a store',
            creationShopTitle2: 'Store update',
            creationShopInputNameLabel: 'Name of the store',
            creationShopInputNamePlaceholder: 'Enter a store name...',
            creationShopNameInfo: 'Enter a store name...',
            creationShopInputFacebookPlaceholder: 'Enter facebook url...',
            creationShopFacebookInfo: 'Enter the url address of the facebook page so that your customers can contact and follow your publications.',
            creationShopInputInstagramPlaceholder: 'Enter instagram url...',
            creationShopInstagramInfo: 'Enter the url address of the Instagram page so that your customers can contact and follow your posts.',
            creationShopInputTelLabel: 'Phone number for contact',
            creationShopInputTelPlaceholder: 'Phone...',
            creationShopTelInfo: 'Enter the phone number by which customers can contact you. In the format XXX-XXX-XXXX',
            creationShopInputTel2Label: 'Additional phone for communication',
            creationShopInputTel2Placeholder: 'Phone...',
            creationShopTel2Info: 'Enter an additional phone number where customers can contact you. In the format XXX-XXX-XXXX',
            creationShopInputAddressLabel: 'Store address',
            creationShopInputAddressPlaceholder: 'Address...',
            creationShopAddressInfo: 'Enter the address of your store',
            creationShopInputDeliveryLabel: 'Delivery',
            creationShopInputDeliveryPlaceholder: 'Delivery...',
            creationShopDeliveryInfo: 'Enter the methods of delivery of goods in your store',
            creationShopInputPaymentLabel: 'Payment',
            creationShopInputPaymentPlaceholder: 'Payment...',
            creationShopPaymentInfo: 'Enter the payment methods for the goods in your store',
            creationShopInputDescriptionLabel: 'Description',
            creationShopInputDescriptionPlaceholder: 'Enter a description...',
            creationShopDescriptionInfo: 'Enter a few words about your store.',
            creationShopInputCurrencyLabel: 'Currency',
            creationShopCurrencyInfo: 'Enter the currency in which product prices will be indicated, in the format $ € ₴ ...',
            creationShopInputLanguageLabel: 'Language',
            creationShopLanguageInfo: 'Choose the language in which your store will be',
            creationShopInputLogoLabel: 'Logo',
            creationShopInputDelLogoLabel: 'Remove downloaded logo',
            creationShopLogoInfo: 'Choose your store logo',
            creationShopCancelBtn: 'Cancel',
            creationShopUpdateBtn: 'Update',
            creationShopCreateBtn: 'Create',
            creationShopShowMesSuccessPut: 'The data has been updated',
            creationShopShowMesSuccessPost: 'The store has been created',
        },
        userProduct: {
            userProductModalDelTitle: 'Are you sure?',
            userProductModalDelText: 'Remove this product',
            userProductModalHideTitle1: 'Hide this product?',
            userProductModalHideTitle2: 'Restore this product?',
            userProductModalHideText1: 'the product will not be displayed in the store',
            userProductModalHideText2: 'the product will be displayed in the store',
            userProductModalHideErrorTitle: 'You need to restore the product',
            userProductModalHideErrorText: 'restore category or change category',
            userProductSubTitle1: 'Products',
            userProductSubTitle2: 'Items',
            userProductCreateBtn: 'Add Product',
            userProductSearchPlaceholder: 'Name or barcode...',
            userProductSearchBtn: 'Search',
            userProductPriceLabel: 'Sort:',
            userProductPriceOptions1: 'All product',
            userProductPriceOptions2: 'As the price increases',
            userProductPriceOptions3: 'As the price drops',
            userProductPriceOptions4: 'Discounts',
            userProductPriceOptions5: 'Novelty',
            userProductNoCategories: 'No categories',
            userProductHidden: 'Hidden',
            userProductSortLabel: 'Category',
            userProductSortOptionAll: 'All product',
            userProductCardName: 'Product name:',
            userProductCardCategory: 'Category:',
            userProductCardPrice: 'Product price:',
            userProductCardNewPrice: 'Price with discount:',
            userProductCardColors: 'Available colors:',
            userProductCardSizes: 'Available sizes:',
            userProductCardDescription: 'Description:',
            userProductProductMissing: 'Products are missing',
            userProductShowMessageError: 'An error occurred',
            userProductShowMessageUpdataProduct: 'The data has been updated',
            userProductShowMessageDelProduct: 'Data deleted',
        },
        productCardUser: {
            productCardUserBtnHover1: 'Increase the amount of goods in stock',
            productCardUserBtnHover2: 'Reduce the amount of goods in the warehouse',
            productCardUserCount1: 'storage',
            productCardUserCount2: 'reserved',
            productCardUserBtnSee: 'More details',
            productCardUserDiscount: '(without discount)',
            productCardUserDetailsNone: 'There is no description',
        },
        creationProduct: {
            creationProductTitle1: 'Product editing',
            creationProductTitle2: 'Product creation',
            creationProductNameSubTitle: 'Do not exceed 20 characters when entering the product name',
            creationProductPriceInputLabel: 'Enter the price',
            creationProductSizesInputLabel: 'Enter the size',
            creationProductQuantityInputLable: 'Enter the quantity',
            creationProductBarcodeInputLable: 'Enter the barcode',
            creationProductCreateImgText: 'Drag the image here or',
            creationProductCreateImgLink: 'click to download',
            creationProductNameInputLabel: 'Enter a name',
            creationProductNameLabel: 'Product name',
            creationProductCategoryTitle: 'Category:',
            creationProductCategoryLabel: 'Select a product category',
            creationProductCategoryPlaceholder: 'Search...',
            creationProductPriceLabel: 'Price',
            creationProductNewPriceLabel: 'The price of the product, if there is a discount',
            creationProductNewPriceCancelBtn: 'Cancel the discount',
            creationProductDescriptionLabel: 'Product description',
            creationProductDescriptionPlaceholder: 'Add some description...',
            creationProductColorsLabel: 'Available colors',
            creationProductSizesLabel: 'Available sizes',
            creationProductPicturesLabel: 'Pictures',
            creationProductQuantityLable: 'Available quantity of goods',
            creationProductBarcodeLable: 'Product barcode',
            creationProductWarningText: 'These fields are mandatory',
            creationProductCancelBtn: 'Cancel',
            creationProductUpdateBtn: 'Update',
            creationProductCreateBtn: 'Create',
            creationProductShowSuccessPut: 'The data has been updated',
            creationProductShowError: 'An error occurred',
            creationProductShowSuccessPost: 'The product has been created',
        },
        userPurchases: {
            userPurchasesExucutorModalTitle1: 'Opt out?',
            userPurchasesExucutorModalTitle2: 'Sign up for this order',
            userPurchasesTitle: 'My orders',
            userPurchasesBtnDownload: 'Download all orders',
            userPurchasesModlDownloadTitle: 'Download a database of all orders?',
            userPurchasesSortTitle: 'Status:',
            userPurchasesSortOption1: 'All',
            userPurchasesSortOption2: 'In process',
            userPurchasesSortOption3: 'Done',
            userPurchasesSortOption4: 'Rejected',
            userPurchasesSortExucutorTitle: 'Executants',
            userPurchasesSortExucutorAll: 'All',
            userPurchasesFilterTitle: 'Select all:',
            userPurchasesFilterDateBtnClean: 'Clean up',
            userPurchasesFilterDateBtnSort: 'Sort',
            userPurchasesFilterDateSelect: 'Select days:',
            userPurchasesFilterDateFormat: 'dd.mm.yyyy',
            userPurchasesExucutorTitle: 'executant:',
            userPurchasesStatusTitle: 'Status',
            userPurchasesErrorText: 'There are no messagesis empty',
            userPurchasesTab1: 'Not read',
            userPurchasesTab2: 'Revised',
            userPurchasesTab3: 'Important',
            userPurchasesData: 'Date',
            userPurchasesCategory: 'Category',
            userPurchasesExucutor: 'Executant',
            userPurchasesCustomer: 'Customer',
            userPurchasesCount: 'Sum',
        },
        userNotifications: {
            userNotificationsModDelTitle: 'Delete message?',
            userNotificationsTab1: 'Not read',
            userNotificationsTab2: 'Read',
            userNotificationsTab3: 'Chosen',
            userNotificationsToltip1: 'Remove from the list of important',
            userNotificationsToltip2: 'Add to the list of important',
            userNotificationsTitle: 'Messages',
            userNotificationsSortTitle: 'Message type:',
            userNotificationsSortOption1: 'All',
            userNotificationsSortOption2: 'Call back',
            userNotificationsSortOption3: 'Subscriptions',
            userNotificationsFilterDateBtnClean: 'Clean up',
            userNotificationsFilterDateBtnSort: 'Sort',
            userNotificationsFilterDateSelect: 'Select days:',
            userNotificationsFilterDateFormatFrom: 'from',
            userNotificationsFilterDateFormatTo: 'to',
        },
        selectStatus: {
            selectStatusOption1: 'In process',
            selectStatusOption2: 'Done',
            selectStatusOption3: 'Rejected',
            selectStatusShowMesEditPurchases: 'The data has been updated',
        },
        readPurchasesView: {
            readPurchasesBanTitle: 'The client is on the banned list',
            readPurchasesTitle: 'Order Details',
            readPurchasesBtnBack: 'Back',
            readPurchasesMessageUpdate: 'The data has been updated',
            readPurchasesModDelTitle: 'Are you sure?',
            readPurchasesModDelText1: 'Restore this product',
            readPurchasesModDelText2: 'Remove this product',
            readPurchasesModEditTitle: 'Edit',
            readPurchasesModEditText: 'Enter a new value',
            readPurchasesModEditLeftBtn: 'Cancel',
            readPurchasesModEditRightBtn: 'Confirm',
            readPurchasesStatusTitle: 'Status:',
            readPurchasesStatusOption1: 'In process',
            readPurchasesStatusOption2: 'Done',
            readPurchasesStatusOption3: 'Rejected',
            readPurchasesInfoCommentTitle: 'Comment',
            readPurchasesTextareaLabel: 'Make notes',
            readPurchasesTextareaPlaceholder: 'Notes...',
            readPurchasesProductsTotalTitle: 'Order amount',
            readPurchasesProductsPromoTotalTitle: 'Promo code used',
            readPurchasesProductsPromoTotalText: 'total',
            readPurchasesCardColorsTitle: 'Ordered colors:',
            readPurchasesCardSizeTitle: 'Ordered sizes:',
            readPurchasesCardCountTitle: 'Quantity:',
            readPurchasesCardSumTitle: 'Sum:',
            readPurchasesCardOrderProductTitle: 'Quantity of ordered product',
            readPurchasesCardDelProductTitle: 'The number of removed products',
            readPurchasesCardDelAllProductTitle: 'The ordered product has been removed. The quantity of ordered goods was',
        },
        readNotificationsView: {
            readNotificationsBtnBack: 'Back',
            readNotificationsTitle1: 'Please call back',
            readNotificationsTitleDate: 'Message sent',
            readNotificationsText1: 'The owner of the phone number',
            readNotificationsText2: 'waiting for a call',
            readNotificationsComment: 'Leave a comment:',
            readNotificationsTitle2: 'Subscription',
            readNotificationsText3: 'Email owner',
            readNotificationsText4: 'ready to receive messages with promotions, discounts and other important information.',
        },
        orderElements: {
            orderElementsText: 'To add a content block, create a product and check the box. To change its order, drag it',
            orderElementsBlock1: 'Slider new products',
            orderElementsBlock2: 'Slider of products with discounts',
            orderElementsBlock3: 'Slider of popular products',
            orderElementsBlock4: 'Description of the store',
            orderElementsBlock5: 'Block of information',
            orderElementsBlock6: 'Advertising block',
        },
        
        userSettingsView: {
            userSettingsModDelShopTitle: 'Delete store?',
            userSettingsModDelShopText: 'All store data will be deleted',
            userSettingsModDelShopLabel1: 'Enter your password',
            userSettingsModDelShopLabel2: 'Repeat the password',
            userSettingsTitle: 'Settings',
            userSettingsLanguageTitle: 'Change the language',
            userSettingsMessageDelShopS: 'The store has been deleted',
            userSettingsPasswordError: 'The repeated password is not valid',
        },
        modalWindow: {
            modalWindowLeftBtn: 'No',
            modalWindowRightBtn: 'Yes',
        },
        landingView: {
            landingRegistrationTitle: 'We are from Lviv. Support the Ukrainian service',
            landingRegistrationBtn: 'REGISTRATION',
            landingAnimatedTitle: 'CRM program for',
            landingAnimatedSubTitle: 'Automation, accounting, business and customer management',
            landingRegistrationMainBtn: 'TRY IT FOR FREE',
            landingAnimatedText: 'Instant access. No credit card required',
            landingFavoriteDevices: 'Works on all your favorite devices',
            landingBenefitsTitle: 'Benefits',
            landingBenefitsSubTitle1: 'Analytics',
            landingBenefitsTitle1: 'Number of orders',
            landingBenefitsText1: 'All information about customer activity and the most popular products is collected in convenient schedules by periods of the year, month, week, and day. You can use this data to plan store operations.',
            landingBenefitsSubTitle2: 'Convenient interface',
            landingBenefitsTitle2: 'Number of orders',
            landingBenefitsText2: 'Your own style and brand can be transferred to the site design. The client will be able to order goods in one click and will not get lost in a wide range of goods.',
            landingBenefitsSubTitle3: 'Client base',
            landingBenefitsTitle3: 'Managers',
            landingBenefitsText3: 'All information about purchases is automatically synchronized with the customer database. Just keep communicating with customers and no customer will be lost.',
            landingScreenTitle: 'Manage your store easily',
            landingScreenSubTitle: 'Thanks to the accessible and convenient interface, you will fall in love with working with your store. CheckInShop will take into account deliveries, write off consumables "as needed" and remind you about the end of the product.',
            landingResponseTitle: 'Benefits for the whole team',
            landingResponseContentTitle: 'To the owners',
            landingResponseContentText1: 'Control your business remotely. A small store or a large chain.',
            landingResponseContentText2: 'Business analytics, customer returns, manager efficiency.',
            landingResponseContentText3: 'Automation of calculations of balances in the warehouse, monitoring of finances.',
            landingResponseComment: '"As a manager, I can control everything that happens in my store at any time."',
            landingResponseName: 'Victoria',
            landingResponseVacancy: 'Shop owner',
            landingBenefitsTitle1: 'Administrators',
            landingBenefitsText11: 'Significant time savings on routine tasks',
            landingBenefitsText12: 'Convenient and clear user interface',
            landingBenefitsText13: 'Quick search and work with customers',
            landingBenefitsTitle2: 'Clients',
            landingBenefitsText21: 'Categories of goods and services',
            landingBenefitsText22: 'Selection of recommendations based on interests',
            landingBenefitsText23: 'Ability to choose delivery and order online',
            landingPossibilitiesTitle: 'All possibilities',
            landingPossibilities1: 'Order processing',
            landingPossibilities2: 'Statistics',
            landingPossibilities3: 'Calculation of profit',
            landingPossibilities4: 'Finances',
            landingPossibilities5: 'Mobile version',
            landingPossibilities6: 'Client base',
            landingPossibilitiesBtn: 'Learn more',
            landingPartnersTitle: 'Check-in Shop is a perfect fit',
            landingPartners1: 'Clothing stores',
            landingPartners2: 'Cosmetics stores',
            landingPartners3: 'Accessories stores',
            landingPartners4: 'Tech stores',
            landingPartnersSubTitle: 'Our partners',
            landingRatingTitle1: '"The program is great. We have been using it for the second year. I like that they often update the functionality"',
            landingRatingName1: 'Oksana Malanchak',
            landingRatingText1: 'Founder of the podiatry center "Healthy Foot"',
            landingRatingTitle2: '"The program is great. We have been using it for the second year. I like that they often update the functionality"',
            landingRatingName2: 'Oksana Malanchak',
            landingRatingText2: 'Founder of the podiatry center "Healthy Foot"',
            landingRatingTitle3: '"The program is great. We have been using it for the second year. I like that they often update the functionality"',
            landingRatingName3: 'Oksana Malanchak',
            landingRatingText3: 'Founder of the podiatry center "Healthy Foot"',
            landingRatingStarTitle: 'Rating from favorite customers',
            landingRatingStarText: 'User rating',
        },
        motivationBlock: {
            landingMotivationTitle: 'Get your business in order right now',
            landingMotivationBtn: 'TRY IT FOR FREE',
        },
        headerLanding: {
            headerLandingMenuLink1: 'Opportunities',
            headerLandingMenuLink2: 'Knowledge base',
            headerLandingMenuLink3: 'Blog',
            headerLandingMenuLink4: 'Prices',
            headerLandingMenuLink5: 'Partners',
            headerLandingContactTitle: 'COMMUNICATION',
            headerLandingTryNowBtn: 'Try it now',
            headerLandingLoginBtn: 'Login',
        },
        footerLanding: {
            footerLandingLinkFirstTitle: 'PRODUCT',
            footerLandingLinkSecondTitle: 'ABOUT US',
            footerLandingLink1: 'Opportunities',
            footerLandingLink2: 'Knowledge base',
            footerLandingLink3: 'Blog',
            footerLandingLink4: 'Prices',
            footerLandingLink5: 'Partners',
            footerLandingContactTitle: 'COMMUNICATION',
            footerLandingSocialTitle: 'Send an offer',
            footerLandingBottomText1: 'All rights reserved.',
            footerLandingBottomText2: 'Developed and maintained by',
        },
        animatedText: {
            animatedText1: 'those who want to sell',
            animatedText2: 'those who want to earn',
        },
        shopTemplates: {
            shopTemplatesTitle: 'Choose one of the templates',
            shopTemplatesCardTitle: 'Template №',
            shopTemplatesCardBtn: 'choose',
            shopTemplatesModCreateShopTitle: 'Skip template selection?',
            shopTemplatesModSelectNewTemplatesTitle: 'Apply this template?',
            shopTemplatesStep1Title: 'What is the name of your store?',
            shopTemplatesStep1SubTitle: 'The name can be changed.',
            shopTemplatesStepBtnNext: 'Next',
            shopTemplatesStepBtnBack: 'Back',
            shopTemplatesStepBtnMiss: 'Miss',
            shopTemplatesStepBtnCreate: 'Create',
            shopTemplatesStepBtnMyCreate: 'Create independently',
            shopTemplatesStep2Title: 'Specify the currency in which sales will be made?',
            shopTemplatesStep2SubTitle: 'The currency can be changed.',
            shopTemplatesStep2Label: 'Enter the currency designation',
            shopTemplatesStep3SubTitle: 'Templates can be changed.',
            shopTemplatesCardTitleMy: 'Create your own',
            shopTemplatesPaymentMethods: 'Payment by card',
            shopTemplatesDeliveryMethods: 'Mail',
        },
        blogsView: {
            blogsViewTitle: 'Informational Blog',
            blogsViewCardTitle1: 'How to Open an Online Store: 5 Simple Steps',
            blogsViewCardText1: 'In the modern digital world, more and more people are opting for the convenience and comfort of online shopping. Opening your own online store can be a lucrative and exciting opportunity to grow your business. If you are considering creating your own e-commerce store, here are 5 simple steps to help you get started.',
            blogsViewCardTitle2: 'Selling in Crisis: 5 Strategies for Success',
            blogsViewCardText2: 'Crisis periods can be challenging for businesses, but they also provide opportunities for growth and innovation. If you are looking for ways to promote your business during a crisis, here are 5 strategies that will help you achieve success.',
            blogsViewCardTitle3: 'Top 3 Tips for Beginner Online Store Owners',
            blogsViewCardText3: "As a beginner who wants to open their own online store, you may encounter numerous questions and challenges. But don't worry! Here are the top 3 tips that will help you kickstart your e-commerce business.",
            blogsViewCardTitle4: 'Top 3 Benefits of CRM: How Implementing Customer Relationship Management Enhances Your Business',
            blogsViewCardText4: 'CRM (Customer Relationship Management) plays a vital role in modern businesses. It helps maintain and improve customer relationships, ensures efficient organization and analysis of data. Here are the top 3 benefits of CRM that will help your business grow and thrive.'
        },
        blogView: [
            {
                blogImage: 'https://garne.com.ua/upload/data/article_2017/articles_44.jpg',
                blogViewTitle: 'How to Start an Online Store: 5 Simple Steps',
                blogViewSubTitle1: '1. Choosing the Product and Niche',
                blogViewText1: 'Before starting your online store, it is important to decide on the product or service you will offer. Analyze the market and determine which products or services are popular and in demand. Consider your interests and knowledge, as working with a product you like will be more enjoyable. Also, choose a niche or a specific group of customers that you want to target. This will help you stand out in the market and attract your target audience.',
                blogViewSubTitle2: '2. Choosing an Online Store Platform',
                blogViewText2: 'There are many platforms and content management systems (CMS) that can help you build your online store smoothly. One popular option is Check-in Shop. It has a user-friendly interface, a variety of design templates, and tools for managing products and orders. Other popular platforms include WooCommerce for WordPress, Magento, and OpenCart. Choose a platform that best suits your needs and budget.',
                blogViewSubTitle3: '3. Marketing and Promotion',
                blogViewText3: "Once you have created your online store, it's time to focus on marketing and promotion. Use social media, content marketing, advertising campaigns, and other channels to attract customers. Ensure quality SEO optimization to make your store visible in search engines. Create engaging content, offer promotions and discounts, collaborate with bloggers or influencers.",
            },
            {
                blogImage: 'https://www.greatgame.com/hubfs/Templates-2018/Blog%20Templates/5%20Tips%20to%20Keep%20Selling.png',
                blogViewTitle: 'Selling in Times of Crisis: 5 Strategies for Success',
                blogViewSubTitle1: '1. Analyzing and Adapting to New Customer Needs',
                blogViewText1: 'First and foremost, conduct a detailed analysis of the changes in consumer behavior during the crisis. Understand the shifting needs and priorities, and identify which products or services have become particularly important to people. Based on this information, adapt your offerings to meet the new market needs.',
                blogViewSubTitle2: '2. Focus on Online Sales and Digital Marketing',
                blogViewText2: 'During a crisis period, many people turn to the internet to find products and services. Focus on developing your online presence and effectively utilizing digital marketing. Make your website attractive and user-friendly, utilize social media, email marketing, and content marketing to attract and retain customers.',
                blogViewSubTitle3: '3. Offer Promotions and Discounts',
                blogViewText3: 'During a crisis period, people are more conscious of their spending. Capture their attention by offering promotions, discounts, or special conditions for your products or services. It could be temporary discounts, free shipping, or gifts with purchase. Such incentives will help increase sales volume and attract new customers.',
            },
            {
                blogImage: 'https://gorillaaccounting.com/wp-content/uploads/2022/02/coffee-shop-owner.jpg',
                blogViewTitle: 'Top 3 Tips for Beginner Online Store Owners',
                blogViewSubTitle1: '1. Thoroughly Research and Understand Your Niche',
                blogViewText1: 'Before starting any business, it is crucial to thoroughly research your niche. Study your target audience, find out what products or services they are looking for, and understand the problems you can solve for them. This will help you identify your competitive advantages and develop a marketing strategy that appeals to your target audience.',
                blogViewSubTitle2: '2. Quality Content and Appealing Visual Presentation',
                blogViewText2: 'In the world of e-commerce, the visual aspect plays a significant role. Keep in mind that your customers cannot physically examine the product before purchase, so high-quality photos and descriptions are crucial. Provide clear and high-resolution product images, detailed descriptions of their features and benefits. Additionally, use visual elements such as videos or infographics to enhance the presentation of your products.',
                blogViewSubTitle3: '3. Focus on Customer Service and Building Trust',
                blogViewText3: 'Customer service is paramount for the success of your online store. Provide multiple channels for customer support, such as live chat, email, or phone, and ensure prompt and helpful responses. Build trust with your customers by displaying customer reviews and testimonials. Offer secure payment options and clearly communicate your refund and return policies. Positive customer experiences and trust will lead to repeat purchases and word-of-mouth recommendations.',
            },
            {
                blogImage: 'https://i.ytimg.com/vi_webp/7egQXWg0lw0/maxresdefault.webp',
                blogViewTitle: "Top 3 Advantages of CRM: How Implementing Customer Relationship Management Improves Your Business",
                blogViewSubTitle1: "1. Increased Customer Satisfaction",
                blogViewText1: "One of the key advantages of CRM is the ability to enhance customer interaction and increase their satisfaction levels. CRM systems allow you to track all contacts and interactions with customers, store their order history, inquiries, and communication. This helps create a more personalized approach to each customer, enables quick response to their needs and requirements, and ensures quality post-sales support. Satisfied customers are more likely to make repeat purchases and recommend your business to others, leading to increased sales and business growth.",
                blogViewSubTitle2: "2. Effective Sales Management and Forecasting",
                blogViewText2: "CRM enables effective sales process management by tracking the stages in the sales pipeline and analyzing data on conversion and loss. With CRM, you can establish a clear system for managing contacts with potential customers, track deals, receive reminders for deal completion, and address current issues. Additionally, CRM systems provide the capability to forecast sales based on data analysis and order history. This helps you become more strategic in planning and making decisions for your business.",
                blogViewSubTitle3: "3. Enhanced Marketing Efficiency and Customer Acquisition",
                blogViewText3: "CRM is a powerful tool for marketing campaigns and promoting your brand. You can analyze customer data, their purchasing habits, and interests to create personalized and targeted offers. Moreover, by utilizing CRM, you can automate marketing processes such as email campaigns, audience segmentation, and campaign performance evaluation. This saves time and effort, improves collaboration between marketing and sales departments, and attracts new customers to your brand."
            }                
        ],
        helpView: [
            {
                helpViewTitle: 'Knowledge base Check-in Shop'
            },
            {
                section: '📊 Analytics',
                question1: 'Analytics page',
                answer1: 'This page will display the graphs "Number of completed orders per year", "Number of visitors per year", "Order status graph", "Schedule of the number of visitors", "Popular products", and other useful information. Only the owner has access to this page (created Managers and TopManagers will not have access to the page).',
            },
            {
                section: '🗂️ Categories',
                question1: 'Categories page',
                answer1: 'On this page you can create, edit and delete categories. The number of main categories is limited to 5. The number of subcategories is not limited.',
                question2: 'Creating categories',
                answer2: 'To create or edit a category, enter its name and click the confirm button. The number of categories is limited to "5". For the correct display of pictures, the picture format must be 1:1. To create a category, you must first create a store. After creating a category, you can create subcategories. If the category has no subcategories and there are already created products, the ability to create a subcategory for it is blocked.',
                question3: 'Subcategories',
                answer3: 'Their creation is possible after creating a category. The number of subcategories is not limited.',
                question4: 'Deleting or hiding a category',
                answer4: 'After deleting a category, all created products that were in it and in its subcategories will not be deleted. They will receive the status "Uncategorized". They can be filtered on the "Product" page and edited or deleted.',
            },
            {
                section: '🛒 Products',
                question1: 'Product page',
                answer1: 'On this page you can create, edit, hide and delete products.',
                question2: 'Product creation',
                answer2: 'When creating a product, it is necessary to specify its name, category, and price. In the field "Price of the product, if there is a discount", you must enter the price of the product if you want to sell this product with a discount. The preferred format for product images is 9:12.',
                question3: 'Product search',
                answer3: 'To find the desired product, you need to enter its name in the search field and press the "Search" button or the "Enter" key. You can also sort products by categories, by prices with additional options that need to be ticked. When searching by category and selecting the "No categories" field, all products that are not displayed in your store due to the lack of a category will be selected, such products appear after removing the category.',
            },
            {
                section: '💰 Orders',
                question1: 'Orders page',
                answer1: 'Your orders will be displayed on this page. You can sort the order by the date of its creation, by its status, as well as by one of the three parameters "Read", "Unread" and "Important". You can also track the status of the order and appoint an executor here.',
                question2: 'Order',
                answer2: 'Information about the customer, the ordered product and its quantity will be displayed in the order. It is also possible to record important information in the form of notes in the order. If the message "<quantity> of goods was ordered" appears in the order instead of the ordered product, it means that this product has already been removed from the store.',
                question3: 'Order status',
                answer3: 'There are three order statuses "In Process", "Completed" and "Rejected". Each status is displayed in its own color. You can change the status on the order page or in the order itself. Each new order automatically receives the status "In process". Only the owner of the store, or the Manager and Top Manager who took this order for fulfillment, can change the status.',
            },
            {
                section: '⚔️ Personnel',
                question1: 'Personnel page',
                answer1: 'The page is designed to create managers who will have access to your store and help you in sales and operation of the store.',
                question2: 'Creation of managers',
                answer2: 'To create managers, it is necessary to specify their email address, name and their type. Passwords will be sent to the specified email address, with which they will be able to register on the site to access your store. In their personal account, they get access to such pages as "Categories", "Products", "Orders", "Messages". There are two types of managers "Manager" "Top Manager", their difference is that "Top Manager" gets access to create, delete and edit products in the store.',
            },
            {
                section: 'Settings',
                question1: 'Settings page',
                answer1: 'On this page, you can change the language of your personal account and, if necessary, delete the store from the database. When a store is deleted, all data of this store will be deleted and it will be impossible to recover them.',
            },
        ],
        personnelView: {
            personnelViewModDelManagerTitle: 'Remove this manager?',
            personnelViewModDelManagerLeftBtn: 'No',
            personnelViewModDelManagerRightBtn: 'Yes',
            personnelViewModEditManagerTitle: 'Select a new type',
            personnelViewModCreateManagerTitle: 'Creating a manager',
            personnelViewCreateBtn: 'Add Worker',
            personnelViewCreatedDate: 'Added',
            personnelViewModEditManagerLeftBtn: 'Cancel',
            personnelViewModEditManagerRightBtn: 'Update',
            personnelViewModCreateManagerRightBtn: 'Create',
            personnelViewTitle: 'Personnel',
            personnelViewMenedgerError: 'The list of employees is empty',
            personnelViewError: 'First you need to create a store',
            personnelViewInputLabel: 'Enter email',
            personnelViewCheckboxTitle: 'Select the type of manager',
            personnelViewCheckboxLabel1: 'Manager',
            personnelViewCheckboxLabel2: 'Top manager',
            personnelViewShowSuccessCreatePersonnel: 'An employee has been created',
            personnelViewShowSuccessEditPersonnel: 'Status changed',
            personnelViewShowSuccessDelPersonnel: 'The manager has been removed',
        },
        userAnalytics: {
            userAnalyticsTitle: 'Hi',
            userAnalyticsSubTitle1: 'Looks like a great day.',
            userAnalyticsSubTitle2: 'New day, new opportunities.',
            userAnalyticsSubTitle3: 'We are glad to have you with us.',
            userAnalyticsBtnPage1: 'Analytics for the last month',
            userAnalyticsBtnPage2: 'Analytics for the last year',
        },
        moreAnalytics2: {
            moreAnalytics2GraphVisitorsLabelInProcess: 'In process',
            moreAnalytics2GraphVisitorsLabelDone: 'Done',
            moreAnalytics2GraphVisitorsLabelRejected: 'Rejected',
            moreAnalytics2GraphVisitorsYearLabel: 'Number of visitors',
            moreAnalytics2GraphCompletedTitle: 'Schedule of fulfilled orders',
            moreAnalytics2GraphCompletedSubTitle: 'Year:',
            moreAnalytics2GraphVisitorsYearTitle: 'The graph of the number of visitors',
            moreAnalytics2GraphVisitorsYearAdvsTitle: 'The number of visitors from the advertising block',
            moreAnalytics2ViewTable: 'Table view',
            moreAnalytics2VisitorsYearItemInfo: 'Visitors:',
            moreAnalytics2GraphVisitorsYearAdvsInfo: 'visited',
            moreAnalytics2GraphYearManagersAll: 'All orders:',
            moreAnalytics2GraphYearManagersTitle: 'Table of success of managers',
        },
        moreAnalytics1: {
            moreAnalytics1TopClients: 'Top clients',
            moreAnalytics1ModDelProductTitle: 'Are you sure',
            moreAnalytics1ModDelProductText: 'Delete this product?',
            moreAnalytics1ModHideProductTitle: 'Hide this product?',
            moreAnalytics1ModHideProductText: 'the product will not be displayed in the store',
            moreAnalytics1GraphVisitorsLabelsDay: 'Day',
            moreAnalytics1GraphVisitorsLabels: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ],
            moreAnalytics1GraphProfitYearLabel: 'Amount of profit',
            moreAnalytics1GraphStatus2: 'In process:',
            moreAnalytics1GraphStatus3: 'Done:',
            moreAnalytics1GraphStatus4: 'Rejected:',
            moreAnalytics1GraphVisitorsQuantity: 'visitors',
            moreAnalytics1GraphVisitorsLabelAll: 'All',
            moreAnalytics1ShowMesDelProduct: 'The product has been removed',
            moreAnalytics1ShowMesHideProduct: 'The product has been updated',
            moreAnalytics1Error: 'This page will display a graph of the number of visitors, a graph of order statuses, and other useful information.',
            moreAnalytics1GraphProfitYearTitle: 'Arrival schedule',
            moreAnalytics1GraphVisitorsYearSubTitle: 'Year:',
            moreAnalytics1GraphProfitYeartext: 'total',
            moreAnalytics1AllOrderStatus: 'everyone:',
            moreAnalytics1InProcessStatus: 'in process:',
            moreAnalytics1DoneStatus: 'done:',
            moreAnalytics1RejectedStatus: 'rejected:',
            moreAnalytics1GraphStatus1: 'All orders:',
            moreAnalytics1GraphVisitorsTitle: 'The graph of the number of visitors',
            moreAnalytics1GraphVisitorsSubTitle: 'Per month:',
            moreAnalytics1TopProductTitle: 'Popular products',

        },
        promoCode: {
            promoCodeDelModTitle: 'Remove this promo code?',
            promoCodeEditModTitle: 'Enter new data',
            promoCodeEditModLabel1: 'New name',
            promoCodeEditModPlaceholder1: 'Enter a name...',
            promoCodeEditModLabel2: 'Enter the number of promo codes',
            promoCodeEditModLabel3: 'Enter the discount amount',
            promoCodeEditModText: 'Select a discount value',
            promoCodeCreateLabel1: 'Create a promo code name',
            promoCodeCreateBtn: 'Create',
            promoCodePromoName: 'name:',
            promoCodePromoValue: 'discount::',
            promoCodePromoCount: 'remains:',
            promoCodeNewPromoError1: 'You need to fill in all the data',
            promoCodeNewPromoError2: 'A promo code with that name already exists',
        },
        userIntegration: {
            userIntegrationMerchantLable1: 'Field for entering id',
            userIntegrationMerchantLable2: 'Insert id',
            userIntegrationMerchantText1: '1. To integrate your own google merchant, you need to go through the link',
            userIntegrationMerchantText2: 'and register.',
            userIntegrationMerchantText3: '2. After registration, you need to insert the received store id into the input field and click the create button',
            userIntegrationMerchantText4: 'For more detailed instructions, click on',
            userIntegrationMerchantText5: 'link',
            userIntegrationMerchantText6: 'To remove google merchant, clear the input line and click the refresh button',
            userIntegrationTitle: 'Integration',
            userIntegrationChatTitle: 'Internal chat',
            userIntegrationChatText1: 'To integrate your own chat, you need to go through the steps',
            userIntegrationChatText2: 'and register. After registration, you need to insert the received password into the input field and click "Create".',
            userIntegrationChatPlaceholder: 'Insert the string..',
            userIntegrationChatBtnCreate1: 'Update',
            userIntegrationChatBtnCreate2: 'Create',
            userIntegrationChatError: 'The entered data is not valid. Incorrect thread.',
            userIntegrationChatResText: 'The chat has been created. Inserted wire:',
            userIntegrationModDelChat: 'Delete chat?',
            userIntegrationChatCreateTitle: 'Insert a row',
            userIntegrationChatCreateSubTitle: 'Write a string to integrate below.',
        },
        pricingView: {
            pricingViewQuestion6: 'What does the free period mean?',
            pricingViewQuestion5: 'What does the free period mean?',
            pricingViewQuestion4: 'What does the free period mean?',
            pricingViewQuestionText6: 'After registration, you get 7 free days of service at the Profi rate. During this time, we will help you enter the price of goods and services, customers and records. We will also answer all questions and conduct staff training. You can switch to a different plan at any time, and any unused days will be added to your subscription if you want to pay for your subscription sooner.',
            pricingViewQuestionText5: 'After registration, you get 7 free days of service at the Profi rate. During this time, we will help you enter the price of goods and services, customers and records. We will also answer all questions and conduct staff training. You can switch to a different plan at any time, and any unused days will be added to your subscription if you want to pay for your subscription sooner.',
            pricingViewQuestionText4: 'After registration, you get 7 free days of service at the Profi rate. During this time, we will help you enter the price of goods and services, customers and records. We will also answer all questions and conduct staff training. You can switch to a different plan at any time, and any unused days will be added to your subscription if you want to pay for your subscription sooner.',
            pricingViewTitle: 'Choose the plan that is perfect for you',
            pricingViewSubTitle: 'Price for one online shop',
            pricingViewMonthBtn1: 'Monthly',
            pricingViewMonthBtn2: 'In a year',
            pricingViewItemTitle1: 'Startup',
            pricingViewItemTitle2: 'Base',
            pricingViewItemTitle3: 'Professional',
            pricingViewItemSubTitle1: 'For small stores',
            pricingViewItemSubTitle2: 'For established businesses',
            pricingViewItemSubTitle3: 'For private startups',
            pricingViewItemText1: 'Up to 5 employees',
            pricingViewItemText2: 'Monthly analytics',
            pricingViewItemText3: 'Client base',
            pricingViewItemText4: 'Categorization',
            pricingViewItemText5: 'Feedback from users',
            pricingViewItemText6: 'Internal advertising',
            pricingViewItemText7: 'Unlimited employees',
            pricingViewItemText8: 'Promo codes',
            pricingViewItemText9: 'Sending by email',
            pricingViewItemText10: 'Download sales reports',
            pricingViewItemText11: 'Chat integration',
            pricingViewItemText12: 'Monthly and annual analytics',
            pricingViewItemText13: 'Download a list of all clients',
            pricingViewItemText14: 'Integration with Telegram bot',
            pricingViewItemText15: 'Priority live support',
            pricingViewItemText16: 'Integration with WayForPay',
            pricingViewItemBtn: 'Try it now',
            pricingViewItemSubTitle4: 'You can try using it for free for the first month.',
            pricingViewInfoTitle: 'All tariffs include',
            pricingViewQuestionTitle: 'Frequently asked questions',
            pricingViewQuestion1: 'What does the free period mean?',
            pricingViewQuestion2: "I'm afraid I won't be able to figure it out on my own. Can you help me?",
            pricingViewQuestion3: 'Can I switch to another pricing plan later?',
            pricingViewQuestionText1: 'After registration, you will have a month of service on the Professional plan. During this time, we will assist you in setting up all your products, answer any questions, and provide training for your staff. You can switch to another plan at any time if you wish to subscribe sooner.',
            pricingViewQuestionText2: 'Certainly! We are ready to help you with any questions you may have. We can provide support and explanations for any issues or inquiries you encounter. Feel free to reach out to us with any questions or problems, and we will do our best to assist you.',
            pricingViewQuestionText3: 'Yes, typically you can switch to another pricing plan in the future. If your needs grow and you require more resources or additional features, you may be offered the option to upgrade to a more advanced or expanded pricing plan.'
        },
        subscriptionView: {
            subscriptionViewTitle: 'Choose your plan',
            subscriptionViewBtnClose: 'Cancel',
        },
        customersView: {
            customersViewModalDownloadTitle: 'Download a database of all customers?',
            customersViewModalWriteTitle: 'Write a message and select recipients',
            customersViewModalLeftBtn: 'Cancel',
            customersViewModaRightBtn: 'Send',
            customersViewModaInput1: 'Title',
            customersViewModaInput2: 'Message text',
            customersViewModaInput3: 'Search for customers',
            customersViewModaInput4: 'Select all',
            customersViewBtnSearch: 'Search',
            customersViewError: 'The list is empty',
            customersViewBtnWrite: 'Notice customer',
            customersViewBtnDownload: 'Download all clients',
            customersViewTitle: 'Customers',
            customersViewCountCustomers: 'Number of customers:',
            customersViewSearchCustomers: 'Search for customers',
            customersViewShowSuccess1: 'The message has been sent',
        },
        customerCard: {
            customerCardShowSuccess2: 'The data has been changed',
            customerCardModalBanTitle: 'Change the status of this customer?',
            customerCardTitleTel: 'Phone',
            customerCardTitleMeil: 'Email',
            customerCardTitleCount: 'Shopping',
            customerCardTitleCosts: 'Amount of purchases',
            customerCardBtnBanned1: 'Banish',
            customerCardBtnBanned2: 'To ban',
            customerCardLastOrder: 'Last Order',
        },
        customerView: {
            customerViewModlTitle: 'Change the status of this customer?',
            customerViewPurchasesTitleDate: 'Date:',
            customerViewPurchasesRegistrDate: 'Registration Date',
            customerViewPurchasesTitleStatus: 'Status',
            customerViewBtnBanned1: 'Banish',
            customerViewBtnBanned2: 'To ban',
            customerViewShowSuccess1: 'The data has been changed',
        },
        partnersView: {
            partnersViewMainTitle: 'One of the best online stores',
            partnersViewMainText: '"Check out our top stores and learn more about their products by clicking on any of the logos and going to their respective pages!"',
            partnersViewBanner: '"Create your own online store and reveal your business potential! Registration on our site will take only a few minutes and will open up unlimited opportunities for your business development in the online world."',
            partnersViewCommentTitle: 'Reviews of our customers',
            partnersViewCommentAuthorTitle: 'Author',
            partnersViewCommentName1: 'Makhailo Podolyak',
            partnersViewCommentText1: 'Very satisfied with my online store! The site is very convenient and easy to use. The ability to set up product categories and sell online allows me to manage my business with ease.',
            partnersViewCommentName2: 'Max Nazarov',
            partnersViewCommentName3: 'Ivan Chepurny',
            partnersViewCommentName4: 'Olkna Kulyk',
            partnersViewCommentName5: 'Iryna Sinyak',
            partnersViewCommentText2: 'I am so pleased that I registered with this site to start my online store. The setup process was quick and easy. Now I can sell my products online and attract new customers.',
            partnersViewCommentText3: 'I am very happy with my online store that I have created on this site. The site has a free plan that allows me to start selling without spending a lot of money. My customers are very satisfied with the user-friendly interface and fast delivery.',
            partnersViewCommentText4: 'I am very happy to use this site to create my online store. As a newbie in the business, I really needed help with creating my own website. Now I can easily attract new clients and increase my income.',
            partnersViewCommentText5: 'I\'ve been using this site to build my online store for a few months now and I\'m very happy with the results. I received many orders from different countries, thanks to the user-friendly interface and fast delivery. This site has really helped me grow my business.',
            partnersViewCommentBtn1: 'Like',
            partnersViewCommentBtn2: 'Answer',
        },
        selectCard: {
            selectCardTitle: 'Choose one of the options',
            selectCardBtn: 'Choose',
            selectCardSelected: 'Selected',
            selectCardError: 'First you need to create a store',
        },
        mainSlider: {
            mainSliderTitle: 'Download images for the main slider',
        },
    },
}


import './HelpView.css';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import HeaderLanding from '../../components/HeaderLanding/HeaderLanding';
import FooterLanding from '../../components/FooterLanding/FooterLanding';


function HelpView() {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    
    useEffect(() => {
        let acc = document.getElementsByClassName("help-view__accordion");
        let i;

        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function() {
                this.classList.toggle("help-view__active");
                let help__panel = this.nextElementSibling;
                if (help__panel.style.maxHeight) {
                    help__panel.style.maxHeight = null;
                } else {
                    help__panel.style.maxHeight = help__panel.scrollHeight + "px";
                } 
            });
        }
    }, [])
    
    return (
        <div className='help-view-wrap'>
            <HeaderLanding />
            
            {
                selectedLanguage?.helpView?.length &&
                    <div className='help-view container'>
                        <div className='help-view__title'>{selectedLanguage?.helpView[0]?.helpViewTitle?.length ? selectedLanguage?.helpView[0].helpViewTitle : ''}</div>
                        
                        {/* Аналітика */}
                        <div className='help-view__sub-title'>{selectedLanguage?.helpView[1]?.section}</div>

                        <button className="help-view__accordion">{selectedLanguage?.helpView[1]?.question1}</button>
                        <div className="help__panel">
                            <p className="help__panel-text">{selectedLanguage?.helpView[1]?.answer1}</p>
                        </div>

                        {/* Категорії */}
                        <div className='help-view__sub-title'>{selectedLanguage?.helpView[2]?.section}</div>

                        <button className="help-view__accordion">{selectedLanguage?.helpView[2]?.question1}</button>
                        <div className="help__panel">
                            <p className="help__panel-text">{selectedLanguage?.helpView[2]?.answer1}</p>
                        </div>

                        <button className="help-view__accordion">{selectedLanguage?.helpView[2]?.question2}</button>
                        <div className="help__panel">
                            <p className="help__panel-text">{selectedLanguage?.helpView[2]?.answer2}</p>
                        </div>

                        <button className="help-view__accordion">{selectedLanguage?.helpView[2]?.question3}</button>
                        <div className="help__panel">
                            <p className="help__panel-text">{selectedLanguage?.helpView[2]?.answer3}</p>
                        </div>

                        <button className="help-view__accordion">{selectedLanguage?.helpView[2]?.question4}</button>
                        <div className="help__panel">
                            <p className="help__panel-text">{selectedLanguage?.helpView[2]?.answer4}</p>
                        </div>
                        
                        {/* Товар */}
                        <div className='help-view__sub-title'>{selectedLanguage?.helpView[3]?.section}</div>

                        <button className="help-view__accordion">{selectedLanguage?.helpView[3]?.question1}</button>
                        <div className="help__panel">
                            <p className="help__panel-text">{selectedLanguage?.helpView[3]?.answer1}</p>
                        </div>

                        <button className="help-view__accordion">{selectedLanguage?.helpView[3]?.question2}</button>
                        <div className="help__panel">
                            <p className="help__panel-text">{selectedLanguage?.helpView[3]?.answer2}</p>
                        </div>

                        <button className="help-view__accordion">{selectedLanguage?.helpView[3]?.question3}</button>
                        <div className="help__panel">
                            <p className="help__panel-text">{selectedLanguage?.helpView[3]?.answer3}</p>
                        </div>
                        
                        {/* Замовлення */}
                        <div className='help-view__sub-title'>{selectedLanguage?.helpView[4]?.section}</div>

                        <button className="help-view__accordion">{selectedLanguage?.helpView[4]?.question1}</button>
                        <div className="help__panel">
                            <p className="help__panel-text">{selectedLanguage?.helpView[4]?.answer1}</p>
                        </div>

                        <button className="help-view__accordion">{selectedLanguage?.helpView[4]?.question2}</button>
                        <div className="help__panel">
                            <p className="help__panel-text">{selectedLanguage?.helpView[4]?.answer2}</p>
                        </div>

                        <button className="help-view__accordion">{selectedLanguage?.helpView[4]?.question3}</button>
                        <div className="help__panel">
                            <p className="help__panel-text">{selectedLanguage?.helpView[4]?.answer3}</p>
                        </div>
                        
                        {/* Персонал */}
                        <div className='help-view__sub-title'>{selectedLanguage?.helpView[5]?.section}</div>

                        <button className="help-view__accordion">{selectedLanguage?.helpView[5]?.question1}</button>
                        <div className="help__panel">
                            <p className="help__panel-text">{selectedLanguage?.helpView[5]?.answer1}</p>
                        </div>

                        <button className="help-view__accordion">{selectedLanguage?.helpView[5]?.question2}</button>
                        <div className="help__panel">
                            <p className="help__panel-text">{selectedLanguage?.helpView[5]?.answer2}</p>
                        </div>
                        
                        {/* Налаштування */}
                        {/* <div className='help-view__sub-title'>{selectedLanguage?.helpView[5]?.section}</div>

                        <button className="help-view__accordion">{selectedLanguage?.helpView[5]?.question1}</button>
                        <div className="help__panel">
                            <p className="help__panel-text">{selectedLanguage?.helpView[5]?.answer1}</p>
                        </div> */}

                      
                    </div>
            }
            
            <FooterLanding />
        </div>
    );
}

export default HelpView;
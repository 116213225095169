import './PaginationItems.css';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';

function PaginationItems({selectedPaget, setSelectedPaget, pageRangeDisplayed, quantityAllProducts, itemsPerPage}) {
    const selectedLanguage = useSelector(state => state.homeSlice.selectedLanguage);
    const [pageOffset, setPageOffset] = useState(null); 
    const [pageCount, setPageCount] = useState(0); // скільки сторінок 
    const [itemOffset, setItemOffset] = useState(0);  // скільки на сторінці
    
    useEffect(() => {
        setItemOffset(itemsPerPage)
        setPageCount(quantityAllProducts);
    }, [quantityAllProducts, itemsPerPage]);
    
    const handlePageClick = (event) => {
        setSelectedPaget(event.selected)
        setPageOffset(event.selected)
    };

    return (
        <div className="pagination-items conteaner">
            <ReactPaginate
                    breakLabel="..."
                    nextLabel={selectedLanguage?.pagination?.paginationNextBtn}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={pageRangeDisplayed}
                    pageCount={pageCount}
                    previousLabel={selectedLanguage?.pagination?.paginationPrevBtn}
                    forcePage={+selectedPaget}
                    previousClassName='pagination-items__paginate-previous-btn'
                    nextClassName='pagination-items__paginate-next-btn'
                    pageClassName='pagination-items__paginate-li-wrap'
                    pageLinkClassName='pagination-items__paginate-li-link'
                    activeClassName='pagination-items__paginate-item-active'
                    containerClassName='pagination-items__container-paginate'
                    nextLinkClassName='pagination-items__paginate-next-btn-link'
                    previousLinkClassName='pagination-items__paginate-previous-btn-link'
                />
        </div>
    );
}

export default PaginationItems;
import './Header.css';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import HeartBtn from '../HeartBtn/HeartBtn';
import CartBtn from '../CartBtn/CartBtn';
import MobileMenu from '../MobileMenu/MobileMenu';
import DropDownMenu from '../DropDownMenu/DropDownMenu';
import SearchBtn from '../SearchBtn/SearchBtn';
import { setSelectedLanguage } from '../../store/homeSlice';
import { languageShop } from '../../languageShop';

function Header() {
    const shop = useSelector(state => state.homeSlice.shop);
    const categories = useSelector(state => state.homeSlice.categories);
    const selectedLanguage = useSelector(state => state.homeSlice.selectedLanguage);
    const dispatch = useDispatch();
    const [userLanguage, setUserLanguage] = useState('UA');
    console.log(shop)
    useEffect(() => {
        if (shop?.language?.length) {
            let shopLanguage = localStorage.getItem('shopLanguage');
            if (shopLanguage?.length) {
                setUserLanguage(shopLanguage)
            } else {
                setUserLanguage(shop.language)
            }
        }
    }, [shop])

    const handleUserLanguage = (str) => {
        setUserLanguage(str)
        localStorage.setItem('shopLanguage', str);
        dispatch(setSelectedLanguage(languageShop[str]));
    }

    return (
        <div className="header">
            {
                shop?.colorSettings?.textInfoTopBlock?.length > 0 && 
                    <div className="header__top-block-wrap">
                        <div className="header__top-block">
                            {
                                shop?.colorSettings?.isAnimationTopBlock ? <>
                                    <p className="header__top-block-line">{shop?.colorSettings?.textInfoTopBlock}</p>
                                    <p className="header__top-block-line">{shop?.colorSettings?.textInfoTopBlock}</p>
                                </> : <div className="header__top-block-line2">{shop?.colorSettings?.textInfoTopBlock}</div>
                            }
                        </div>
                    </div>
            }
            
            <div className={`header--wrap--${shop?.colorSettings?.typeHeader?.length ? shop?.colorSettings?.typeHeader : '1'} container`}>
                <div className="header__mobile-menu-wrap">
                    <MobileMenu />
                    {
                        shop?.logo?.length > 0 ? <NavLink className='header__logo' to={`/${shop.name}`}><img className='header__logo-img' src={shop.logo} alt='img'/></NavLink>
                            : <NavLink className='header__no-logo' to={`/${shop.name}`}>{selectedLanguage?.header?.headerBtn}</NavLink>
                    }

                </div>

                <div className={`header__menu-wrap--${shop?.colorSettings?.typeHeader?.length ? shop?.colorSettings?.typeHeader : '1'}`}>
                    <ul className="header__menu">
                        {
                          !!categories.length &&  categories?.map(category => (
                                <li className="header__menu-link-wrap" key={category._id}>
                                    <NavLink 
                                        className="header__menu-link" 
                                        to={`/${shop.name}/category/${category._id}`}
                                    >
                                        {category.name}
                                    </NavLink>
                                    {
                                        !!category?.sub_categories?.length &&
                                            <div className="header__menu-link-dropdown">
                                                <ul className="header__menu-link-dropdown-wrap">
                                                    {
                                                    category?.sub_categories.map(subCategories => (<li key={subCategories._id}><NavLink className="header__menu-sublink-dropdown" to={`/${shop.name}/category/${subCategories._id}`}>{subCategories.name}</NavLink></li>))
                                                    }
                                                </ul>
                                            </div>
                                    }
                                </li>
                            ))
                        }
                    </ul>
                </div>
                
                <div className="header__wrap-btn">
                    <div className="header__search-btn-wrap">
                        <SearchBtn />
                    </div>
                    <HeartBtn />
                    <CartBtn />
                    <div className='header__language-selection-wrap'>
                        <div className='header__language-selection'>
                            {userLanguage}
                        </div>
                        <ul className='header__language-selection-drop'>
                            <li onClick={() => handleUserLanguage('UA')} className='header__language-selection-drop-item'>UA</li>
                            <li onClick={() => handleUserLanguage('ENG')} className='header__language-selection-drop-item'>ENG</li>
                        </ul>
                    </div>
                </div>
            </div>
            <DropDownMenu />
        </div>
    );
}

export default Header;
import './LandingView.css';
import { useSelector } from 'react-redux';
import AnimatedText from '../../components/AnimatedText/AnimatedText';
import man1 from '../../assets/images/man1.svg';
import man2 from '../../assets/images/man2.svg';
import calendar from '../../assets/images/calendar.svg';
import statistics from '../../assets/images/statistics.svg';
import calculation from '../../assets/images/calculation.svg';
import finances from '../../assets/images/finances.svg';
import phone from '../../assets/images/phone.svg';
import phone2 from '../../assets/images/phone2.svg';
import rating from '../../assets/images/rating.svg';
import HeaderLanding from '../../components/HeaderLanding/HeaderLanding';
import FooterLanding from '../../components/FooterLanding/FooterLanding';
import mixpanel from 'mixpanel-browser';
import MotivationBlock from '../../components/MotivationBlock/MotivationBlock';

mixpanel.init('cbc9e4ee0d40873b5a2e3b5e39eedd33', { debug: true }); 
mixpanel.track('Visit landing page');

function LandingView() {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    
    return (
        <div className='landing-view hidden'>
            <HeaderLanding />

            <div className='landing__registration'>
                <span className='landing__registration-title'>{selectedLanguage?.landingView?.landingRegistrationTitle}</span>
                <a className='landing__registration-btn' href='https://auth.welcomenocode.com/?app=checkinshop'>{selectedLanguage?.landingView?.landingRegistrationBtn}</a>
            </div>

            <div className='landing__main-wrap'>
                <div className='landing__main container'>
                    <div className='landing__main-title'>{selectedLanguage?.landingView?.landingAnimatedTitle}</div>
                    <AnimatedText />
                    <div className='landing__main-sub-title'>{selectedLanguage?.landingView?.landingAnimatedSubTitle}</div>
                    <a className='landing__main-btn' href='https://auth.welcomenocode.com/?app=checkinshop'>{selectedLanguage?.landingView?.landingRegistrationMainBtn}</a>
                    <div className='landing__main-sub-text'>{selectedLanguage?.landingView?.landingAnimatedText}</div>
                </div>
            </div>

            {/* <div className='landing__example-wrap'>
                <div className='landing__example container'>
                    <img className='landing__example-notebook-img' src={notebook} alt='img'/>
                    <div className='landing__example-tablet-wrap'>
                        <img className='landing__example-tablet-img' src={tabletMin} alt='img'/>
                        <img className='landing__example-phone-img' src={phoneMin} alt='img'/>
                    </div>
                    <div className='landing__example-devices-title'>{selectedLanguage?.landingView?.landingFavoriteDevices}</div>
                    <img className='landing__example-devices-img' src={devices} alt='img'/>
                </div>
            </div> */}
            
            <div className='landing__advantage-wrap'>
                <div className='landing__advantage container'>
                    <div className='landing__advantage-title'>{selectedLanguage?.landingView?.landingBenefitsTitle}</div>
                    
                    <div className='landing__advantage-item'>
                        <div className='landing__advantage-item-1' />
                        <div className='landing__advantage-item-info'>
                            <div className='landing__advantage-item-info-sub-title'>{selectedLanguage?.landingView?.landingBenefitsSubTitle1}</div>
                            <div className='landing__advantage-item-info-title'>{selectedLanguage?.landingView?.landingBenefitsTitle1}</div>
                            <div className='landing__advantage-item-info-text'>{selectedLanguage?.landingView?.landingBenefitsText1}</div>
                        </div>
                    </div>
                    <div className='landing__advantage-item landing__advantage-item--reverse'>
                    <div className='landing__advantage-item-2' />
                        <div className='landing__advantage-item-info'>
                            <div className='landing__advantage-item-info-sub-title'>{selectedLanguage?.landingView?.landingBenefitsSubTitle2}</div>
                            <div className='landing__advantage-item-info-title'>{selectedLanguage?.landingView?.landingBenefitsTitle2}</div>
                            <div className='landing__advantage-item-info-text'>{selectedLanguage?.landingView?.landingBenefitsText2}</div>
                        </div>
                    </div>
                    <div className='landing__advantage-item'>
                    <div className='landing__advantage-item-3' />
                        <div className='landing__advantage-item-info'>
                            <div className='landing__advantage-item-info-sub-title'>{selectedLanguage?.landingView?.landingBenefitsSubTitle3}</div>
                            <div className='landing__advantage-item-info-title'>{selectedLanguage?.landingView?.landingBenefitsTitle3}</div>
                            <div className='landing__advantage-item-info-text'>{selectedLanguage?.landingView?.landingBenefitsText3}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='landing__screen-wrap'>
                <div className='landing__screen container'>
                    <div className='landing__screen-title'>{selectedLanguage?.landingView?.landingScreenTitle}</div>
                    <div className='landing__screen-sub-title'>{selectedLanguage?.landingView?.landingScreenSubTitle}</div>
                </div>
                <div className='landing__screen-img' />
            </div>
            
            <div className='landing__response-wrap'>
                <div className='landing__response container'>
                    <div className='landing__response-title'>{selectedLanguage?.landingView?.landingResponseTitle}</div>
                    <div className='landing__response-content'>
                        <div className='landing__response-content-img' />
                        <div className='landing__response-content-text'>
                            <div className='landing__response-content-text-title'>{selectedLanguage?.landingView?.landingResponseContentTitle}</div>
                            <ul className='landing__response-content-text-list'>
                                <li className='landing__response-content-text-list-li'>{selectedLanguage?.landingView?.landingResponseContentText1}</li>
                                <li className='landing__response-content-text-list-li'>{selectedLanguage?.landingView?.landingResponseContentText2}</li>
                                <li className='landing__response-content-text-list-li'>{selectedLanguage?.landingView?.landingResponseContentText3}</li>
                            </ul>
                            <div className='landing__response-content-line'></div>
                            <div className='landing__response-content-comment'>{selectedLanguage?.landingView?.landingResponseComment}</div>
                            <div className='landing__response-content-text-name'>{selectedLanguage?.landingView?.landingResponseName}</div>
                            <div className='landing__response-content-text-vacancy'>{selectedLanguage?.landingView?.landingResponseVacancy}</div>
                        </div>
                    </div>

                    <div className='landing__response-benefits-wrap'>
                        <div className='landing__response-benefits'>
                            <img className='landing__response-benefits-img1' src={man1} alt='img'/>
                            <div className='landing__response-benefits-title'>{selectedLanguage?.landingView?.landingBenefitsTitle1}</div>
                            <ul  className='landing__response-benefits-list'>
                                <li  className='landing__response-benefits-list-li'>{selectedLanguage?.landingView?.landingBenefitsText11}</li>
                                <li  className='landing__response-benefits-list-li'>{selectedLanguage?.landingView?.landingBenefitsText12}</li>
                                <li  className='landing__response-benefits-list-li'>{selectedLanguage?.landingView?.landingBenefitsText13}</li>
                            </ul>
                        </div>

                        <div className='landing__response-benefits'>
                            <img className='landing__response-benefits-img2' src={man2} alt='img'/>
                            <div className='landing__response-benefits-title'>{selectedLanguage?.landingView?.landingBenefitsTitle2}</div>
                            <ul  className='landing__response-benefits-list'>
                                <li  className='landing__response-benefits-list-li'>{selectedLanguage?.landingView?.landingBenefitsText21}</li>
                                <li  className='landing__response-benefits-list-li'>{selectedLanguage?.landingView?.landingBenefitsText22}</li>
                                <li  className='landing__response-benefits-list-li'>{selectedLanguage?.landingView?.landingBenefitsText23}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className='landing__possibilities-wrap'>
                <div className='landing__possibilities container'>
                    <div className='landing__possibilities-title'>{selectedLanguage?.landingView?.landingPossibilitiesTitle}</div>
                    <div className='landing__possibilities-items'>
                        <div className='landing__possibilities-item-wrap'>
                            <div className='landing__possibilities-item'>
                                <img className='landing__possibilities-item-img landing__possibilities-item-img--1' src={calendar} alt='img'/>
                                <div className='landing__possibilities-item-text'>{selectedLanguage?.landingView?.landingPossibilities1}</div>
                            </div>
                            
                            <div className='landing__possibilities-item'>
                                <img className='landing__possibilities-item-img landing__possibilities-item-img--2' src={statistics} alt='img'/>
                                <div className='landing__possibilities-item-text'>{selectedLanguage?.landingView?.landingPossibilities2}</div>
                            </div>
                            
                            <div className='landing__possibilities-item'>
                                <img className='landing__possibilities-item-img landing__possibilities-item-img--3' src={calculation} alt='img'/>
                                <div className='landing__possibilities-item-text'>{selectedLanguage?.landingView?.landingPossibilities3}</div>
                            </div>
                        </div>
                        
                        <div className='landing__possibilities-item-wrap'>
                            <div className='landing__possibilities-item'>
                                <img className='landing__possibilities-item-img landing__possibilities-item-img--4' src={finances} alt='img'/>
                                <div className='landing__possibilities-item-text'>{selectedLanguage?.landingView?.landingPossibilities4}</div>
                            </div>
                            
                            <div className='landing__possibilities-item'>
                                <img className='landing__possibilities-item-img landing__possibilities-item-img--5' src={phone} alt='img'/>
                                <div className='landing__possibilities-item-text'>{selectedLanguage?.landingView?.landingPossibilities5}</div>
                            </div>
                            
                            <div className='landing__possibilities-item'>
                                <img className='landing__possibilities-item-img landing__possibilities-item-img--6' src={phone2} alt='img'/>
                                <div className='landing__possibilities-item-text'>{selectedLanguage?.landingView?.landingPossibilities6}</div>
                            </div>
                        </div>
                    </div>
                    <a className='landing__possibilities-btn' href='https://auth.welcomenocode.com/?app=checkinshop'>{selectedLanguage?.landingView?.landingPossibilitiesBtn}</a>
                </div>
            </div>

            <div className='landing__partners-wrap'>
                <div className='landing__partners container'>
                    <div className='landing__partners-title'>{selectedLanguage?.landingView?.landingPartnersTitle}</div>
                    <div className='landing__partners-items'>
                        <div className='landing__partners-item'>
                            <div className='landing__partners-item-img-1'/>
                            <div className='landing__partners-item-text'>{selectedLanguage?.landingView?.landingPartners1}</div>
                        </div>
                        
                        <div className='landing__partners-item'>
                            <div className='landing__partners-item-img-2'/>
                            <div className='landing__partners-item-text'>{selectedLanguage?.landingView?.landingPartners2}</div>
                        </div>
                        
                        <div className='landing__partners-item'>
                            <div className='landing__partners-item-img-3'/>
                            <div className='landing__partners-item-text'>{selectedLanguage?.landingView?.landingPartners3}</div>
                        </div>
                        
                        <div className='landing__partners-item'>
                            <div className='landing__partners-item-img-4'/>
                            <div className='landing__partners-item-text'>{selectedLanguage?.landingView?.landingPartners4}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='landing__rating-wrap'>
                <div className='landing__rating container'>
                    <div className='landing__rating-star'>
                        <div className='landing__rating-star-img-wrap'>
                            <div className='landing__rating-star-img-text'>4.9/5</div>
                            <img className='landing__rating-star-img' src={rating} alt='img'/>
                        </div>
                        <div className='landing__rating-star-text-wrap'>
                            <div className='landing__rating-star-text-title'>{selectedLanguage?.landingView?.landingRatingStarTitle}</div>
                            <div className='landing__rating-star-text'>{selectedLanguage?.landingView?.landingRatingStarText}</div>
                        </div>
                    </div>
                </div>
            </div>

            <MotivationBlock />

            <FooterLanding />
        </div>
    );
}

export default LandingView;
import './InputColor.css';
import { memo, useEffect, useRef, useState } from 'react';
import ColorPicker from 'react-best-gradient-color-picker'


function InputColor({setValue, value}) {
    const [isOpen, setIsOpen] = useState(false);
    const componentRef = useRef(null);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
        document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (componentRef.current && !componentRef.current.contains(event.target)) {
        setIsOpen(false);
        }
    };

    return (
        <div className="input-color--wrap" ref={componentRef}>
            <button className="input-color__button" onClick={() => setIsOpen(!isOpen)}>
                <div className="input-color__button-color" style={{background: `${value}`}}></div>
                <div className="input-color__button-color-text">{value}</div>
            </button>
            {
                isOpen && 
                    <div className="input-color__label">
                            <ColorPicker value={value} onChange={setValue} />
                    </div>
            }
        </div>
    );
}

export default memo(InputColor);
import './PersonnelView.css';

import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { fetchGetData, fetchRequest, showMessage, validEmail } from '../../helpers/Utils';
import deleteImg from './../../assets/images/deleteImg.svg';
import editIcon from './../../assets/images/editIcon.svg';
import integration2 from './../../assets/images/integration2.svg';
import managerImg from './../../assets/images/managerImg.svg';
import topManedger from './../../assets/images/topManedger.svg';
import avatar from './../../assets/images/avatar.svg';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import { TextField } from '@mui/material';
import Preloader from '../../components/Preloader/Preloader';
import { setManagers, setLastUpdatedPersonnelView } from '../../store/userSlice';


function PersonnelView() {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const shop = useSelector(state => state.userSlice.shop);
    const managers = useSelector(state => state.userSlice.managers);
    const lastUpdatedPersonnelView = useSelector(state => state.userSlice.lastUpdatedPersonnelView);
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [role, setRole] = useState('manager');
    const [newRole, setNewRole] = useState('');
    const [isDeleteManager, setIsDeleteManager] = useState(false);
    const [isCreateManager, setIsCreateManager] = useState(false);
    const [isEditManager, setIsEditManager] = useState(false);
    const [deleteManager, setDeleteManager] = useState({});
    const [editManager, setEditManager] = useState({});
    const [isPreloader, setIsPreloader] = useState(false);
    const dispatch = useDispatch();
    const token = localStorage.getItem('token')

    useEffect(() => {
        if (shop?._id) {
            if (!lastUpdatedPersonnelView || new Date().getTime() - lastUpdatedPersonnelView > 300000 || !managers?.length) {
                getManagers()
            }
        }
    }, [])
    
    const getManagers = () => {
        setIsPreloader(true)
        fetchGetData(`https://zx8hsmle2j.execute-api.eu-central-1.amazonaws.com/production/api/v1/roles/${shop._id}/asid?token=${token}`)
            .then(res => {
                if (res.success && res?.data) {
                    dispatch(setManagers(res.data))
                    dispatch(setLastUpdatedPersonnelView(new Date().getTime()))
                } else {
                    console.log('GET PersonnelView', res)
                }
                setIsPreloader(false)
            })
    }

    const handleCreatePersonnel = (boolean) => {
        if (boolean) {
            const token = localStorage.getItem('token')
            if (email?.length && shop?._id && validEmail(email) && fullName?.length && token.length) {
                let data = {
                    associative_id: shop._id, 
                    role_email: email,
                    full_name: fullName,
                    role: role,
                    email_subject: `You are new ${shop.name} manager!`,
                    email_text: 'Hello, congratulations',
                    sender: 'info@check-in.shop',
                }
    
                fetchRequest('POST', `https://zx8hsmle2j.execute-api.eu-central-1.amazonaws.com/production/api/v1/roles?token=${token}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
                    .then(res => {
                        if (res.success && res?.data) {
                            setManagers([...managers, res.data])
                            showMessage('success', selectedLanguage?.personnelView?.personnelViewShowSuccessCreatePersonnel)
                        } else {
                            console.log('POST PersonnelView', res)
                        }
                    })
            }
        }

        setEmail('')
        setFullName('')
        setIsCreateManager(false)
    }

    const handleIsEditManager = (obj) => {
        setIsEditManager(true)
        setEditManager(obj)
    }
   
    const handleIsDeleteManager = (obj) => {
        setIsDeleteManager(true)
        setDeleteManager(obj)
    }

    const handleEditManager = (boolean) => {
        if (boolean) {
            let data = {
                associative_id: shop._id,
                role_email: editManager.email,
                email_subject: "You are removed from Dnipro1 as manager!",
                email_text: "Hello, dan",
                sender: "info@check-in.shop",
                new_role: newRole,
            }

            fetchRequest('PUT', `https://zx8hsmle2j.execute-api.eu-central-1.amazonaws.com/production/api/v1/roles?token=${token}`, data)
                .then(res => {
                    if (res.success) {
                        let res = managers.map(el => {
                           if (el.email == editManager.email) {
                               el.role = editManager.role == "manager" ? "topManager" : "manager"
                           }
                           return el
                        })
                        setManagers(res)
                        showMessage('success', selectedLanguage?.personnelView?.personnelViewShowSuccessEditPersonnel)
                    } else {
                        console.log('PUT PersonnelView', res)
                    }
                })
        } 

        setIsEditManager(false)
        setEditManager({})
        setNewRole('')
    }

    const handleDeleteManager = (boolean) => {
        if (boolean) {
            let data = {
                associative_id: shop._id,
                role_email: deleteManager.email,
                email_subject: "You are removed from Dnipro1 as manager!",
                email_text: "Hello, dan",
                sender: "info@check-in.shop"
            }

            fetchRequest('DELETE', `https://zx8hsmle2j.execute-api.eu-central-1.amazonaws.com/production/api/v1/roles?token=${token}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
                .then(res => {
                    if (res.success) {
                        setManagers(managers.filter(el => el.email !== deleteManager.email))
                        showMessage('success', selectedLanguage?.personnelView?.personnelViewShowSuccessDelPersonnel)
                    } else {
                        console.log('DELETE PersonnelView', res)
                        showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError)
                    }
                })
        } 

        setIsDeleteManager(false)
        setDeleteManager({})
    }

    return (
        <div className="personnel">

            {
                isDeleteManager && <ModalWindow title={selectedLanguage?.personnelView?.personnelViewModDelManagerTitle}  text={''} handleClick={handleDeleteManager} leftBtn={selectedLanguage?.personnelView?.personnelViewModDelManagerLeftBtn} rightBtn={selectedLanguage?.personnelView?.personnelViewModDelManagerRightBtn}></ ModalWindow>
            }
            {
                isEditManager && <ModalWindow title={selectedLanguage?.personnelView?.personnelViewModEditManagerTitle}  text={''} handleClick={handleEditManager} leftBtn={selectedLanguage?.personnelView?.personnelViewModEditManagerLeftBtn} rightBtn={selectedLanguage?.personnelView?.personnelViewModEditManagerRightBtn}>
                        <div className="personnel__edit-checkbox-wrap">
                            <input className="personnel__edit-checkbox" onChange={() => setNewRole('manager')} type="radio" id='personnelRole1' name='personnelRole' defaultChecked={editManager.role == 'manager' ? true : false}/>
                            <label className="personnel__edit-input-label" htmlFor='personnelRole1'>{selectedLanguage?.personnelView?.personnelViewCheckboxLabel1}</label>
                            <input className="personnel__edit-checkbox" onChange={() => setNewRole('topManager')} type="radio" id='personnelRole2' name='personnelRole' defaultChecked={editManager.role == 'topManager' ? true : false}/>
                            <label className="personnel__edit-input-label" htmlFor='personnelRole2'>{selectedLanguage?.personnelView?.personnelViewCheckboxLabel2}</label>
                        </div>
                    </ ModalWindow>
            }
            
            {
                isCreateManager && <ModalWindow title={selectedLanguage?.personnelView?.personnelViewModCreateManagerTitle} handleClick={handleCreatePersonnel} leftBtn={selectedLanguage?.personnelView?.personnelViewModEditManagerLeftBtn} rightBtn={selectedLanguage?.personnelView?.personnelViewModCreateManagerRightBtn}>
                        <div className="personnel__create">

                            <TextField className='personnel__create-email' value={email} onChange={(e) => setEmail(e.target.value)} label={selectedLanguage?.personnelView?.personnelViewInputLabel} variant="outlined" />
                            <TextField className='personnel__create-email' value={fullName} onChange={(e) => setFullName(e.target.value)} label="I\'мя та фамілію" variant="outlined" />

                            <div className="personnel__create-checkbox-title">{selectedLanguage?.personnelView?.personnelViewCheckboxTitle}</div>
                            <div className="personnel__create-checkbox-wrap">
                                <input className="personnel__create-checkbox" onChange={() => setRole('manager')} defaultChecked={role == 'manager' ? true : false} type="radio" id="personnelType1" name="personnelType" />
                                <label className="personnel__create-input-label" htmlFor="personnelType1">{selectedLanguage?.personnelView?.personnelViewCheckboxLabel1}</label>
                                <input className="personnel__create-checkbox" onChange={() => setRole('topManager')} defaultChecked={role == 'topManager' ? true : false} type="radio" id="personnelType2" name="personnelType" />
                                <label className="personnel__create-input-label" htmlFor="personnelType2">{selectedLanguage?.personnelView?.personnelViewCheckboxLabel2}</label>
                            </div>
                        </div>
                    </ ModalWindow>
            }

            <div className="personnel--wrap">

                <div className="personnel__header">
                    <div className="personnel__header-title">
                        <img className="personnel__header-title-img" src={integration2} alt='img'/>
                        <div className="personnel__header-title-text">{selectedLanguage?.layoutUser?.layoutLinkPersonnel}</div>
                    </div>
                    <button className='personnel__header-btn' onClick={() => setIsCreateManager(true)}>{selectedLanguage?.personnelView?.personnelViewCreateBtn}</button>
                </div>

                <div className="personnel__managers">
                    {
                        isPreloader ? <Preloader /> : !!managers?.length ? managers.map(el => (
                            <div className="personnel__manager" key={el?.email}>
                                <div className="personnel__manager-wrap">
                                    <div className="personnel__manager-name-wrap">
                                        <img className='personnel__manager-name-img' src={avatar} alt='img'/>
                                        <div>
                                            <div className="personnel__manager-name">{el.full_name}</div>
                                            <div className="personnel__manager-name-role">{el.role}</div>
                                        </div>
                                    </div>

                                    <div className="personnel__manager-email-wrap">
                                        <div className="personnel__manager-email-text">Email</div>
                                        <div className="personnel__manager-email">{el.email}</div>
                                    </div>

                                    <div className={`personnel__manager-status ${el.status === 'offline' ? 'personnel__manager-status-offline' : 'personnel__manager-status-online'}`}>{el.status}</div>
                                    <div className="personnel__manager-role-wrap">
                                        <div className="personnel__manager-role-img-wrap">
                                            <img className="personnel__manager-role-img" src={el.role == 'manager' ? managerImg : topManedger} alt='img'/>
                                        </div>
                                        <div className={`personnel__manager-role--${el.role}`}>{el.role}</div>
                                    </div>
                                    <div className="personnel__manager-created-wrap">
                                        <div className="personnel__manager-created-text">{selectedLanguage?.personnelView?.personnelViewCreatedDate}</div>
                                        <div className="personnel__manager-created">{new Date(el.created_at).toLocaleDateString()}</div>
                                    </div>
                                </div>

                                <div className='personnel__manager-btn-wrap'>
                                    <img className='personnel__manager-edit-btn' onClick={() => handleIsEditManager(el)} src={editIcon} alt='img'/>
                                    <img className='personnel__manager-del-btn' onClick={() => handleIsDeleteManager(el)} src={deleteImg} alt='img'/>
                                </div>
                            </div>
                        ))
                        :
                        <div className='personnel__manager-error'>{selectedLanguage?.personnelView?.personnelViewMenedgerError}</div>
                    }
                </div>
                {
                    !shop._id && <div className='personnel__error'>{selectedLanguage?.personnelView?.personnelViewError}</div>
                }
            </div>
        </div>
    );
}

export default PersonnelView;
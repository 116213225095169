import './SubscriptionView.css';
import {  useState } from 'react';
import { useSelector } from 'react-redux';
import check3 from '../../assets/images/check3.svg';
import star from '../../assets/images/star.svg';


function SubscriptionView({handleCloseModal}) {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const userLanguage = useSelector(state => state.userSlice.userLanguage);
    const [tab, setTab] = useState(0);
    const [selectedSubscription, setSelectedSubscription] = useState(1);
   
    const handlePrice = (num) => {
        if (num == "1") {
            return tab === 1 ? userLanguage === 'UA'? '400' : '30' : userLanguage === 'UA'? '320' : '20'
        }
        if (num == "2") {
            return tab === 1 ? userLanguage === 'UA'? '800' : '50' : userLanguage === 'UA'? '640' : '40'
        }
        if (num == "3") {
            return tab === 1 ? userLanguage === 'UA'? '1300' : '70' : userLanguage === 'UA'? '1040' : '50'
        }
    }
   
    return (
        <div className='subscription-view'>
            <div className='subscription-view__title-wrap'>
                <div className='subscription-view__title'>{selectedLanguage?.subscriptionView?.subscriptionViewTitle}</div>
                <button className='subscription-view__btn-close' onClick={() => handleCloseModal(true)}>X</button>
            </div>

            <div className='subscription-view__tabs--wrap'>
                <div className='subscription-view__tabs-wrap'>
                    <div className={`subscription-view__tab ${tab === 0 ? 'subscription-view__tab--active' : ''}`} onClick={() => setTab(0)}>{selectedLanguage?.pricingView?.pricingViewMonthBtn2}</div>
                    <div className={`subscription-view__tab ${tab === 1 ? 'subscription-view__tab--active' : ''}`} onClick={() => setTab(1)}>{selectedLanguage?.pricingView?.pricingViewMonthBtn1}</div>
                </div>
            </div>

            <div className='subscription-view__cards-wrap'>
                <div className='subscription-view__card'>
                    <div className='subscription-view__card--wrap'>
                        <div className='subscription-view__card-title-wrap'>
                            <div className='subscription-view__card-title'>{selectedLanguage?.pricingView?.pricingViewItemTitle1}</div>
                        </div>
                        <div className='subscription-view__card-price-wrap'>
                            <div className='subscription-view__card-price'>{handlePrice('1')} {userLanguage === 'UA' ? 'грн/мс' : 'USD/month'}</div>
                        </div>
                        <div className='subscription-view__info-item-wrap'>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText1}</div>
                            </div>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText2}</div>
                            </div>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText3}</div>
                            </div>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText4}</div>
                            </div>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText5}</div>
                            </div>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText6}</div>
                            </div>
                        </div>
                    </div>
                    <button className={`subscription-view__card-btn-by ${selectedSubscription === 0 ? 'subscription-view__card-btn-by--active' : ''}`} onClick={() => setSelectedSubscription(0)}>Спробуйте {selectedLanguage?.pricingView?.pricingViewItemTitle1}</button>
                </div>
                <div className='subscription-view__card'>
                    <div className='subscription-view__card--wrap'>
                        <div className='subscription-view__card-title-wrap'>
                            <div className='subscription-view__card-title'>{selectedLanguage?.pricingView?.pricingViewItemTitle2}</div>
                            <div className='subscription-view__card-popular-wrap'>
                                <img className='subscription-view__card-popular-img' src={star} alt='img'/> 
                                <p>Most Popular</p>
                            </div>
                        </div>
                        <div className='subscription-view__card-price-wrap'>
                            <div className='subscription-view__card-price'>{handlePrice('2')} {userLanguage === 'UA' ? 'грн/мс' : 'USD/month'}</div>
                        </div>
                        <div className='subscription-view__info-item-wrap'>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText7}</div>
                            </div>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText12}</div>
                            </div>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText3}</div>
                            </div>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText4}</div>
                            </div>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText5}</div>
                            </div>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText6}</div>
                            </div>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText8}</div>
                            </div>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText9}</div>
                            </div>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText10}</div>
                            </div>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText11}</div>
                            </div>
                        </div>
                    </div>
                    <button className={`subscription-view__card-btn-by ${selectedSubscription === 1 ? 'subscription-view__card-btn-by--active' : ''}`} onClick={() => setSelectedSubscription(1)}>Спробуйте {selectedLanguage?.pricingView?.pricingViewItemTitle1}</button>
                </div>
                <div className='subscription-view__card'>
                    <div className='subscription-view__card--wrap'>
                        <div className='subscription-view__card-title-wrap'>
                            <div className='subscription-view__card-title'>{selectedLanguage?.pricingView?.pricingViewItemTitle3}</div>
                        </div>
                        <div className='subscription-view__card-price-wrap'>
                            <div className='subscription-view__card-price'>{handlePrice('3')} {userLanguage === 'UA' ? 'грн/мс' : 'USD/month'}</div>
                        </div>
                        <div className='subscription-view__info-item-wrap'>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText7}</div>
                            </div>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText12}</div>
                            </div>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText3}</div>
                            </div>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText4}</div>
                            </div>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText5}</div>
                            </div>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText6}</div>
                            </div>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText8}</div>
                            </div>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText9}</div>
                            </div>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText10}</div>
                            </div>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText11}</div>
                            </div>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText13}</div>
                            </div>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText14}</div>
                            </div>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText15}</div>
                            </div>
                            <div className='subscription-view__info-item-text-wrap'>
                                <img className='subscription-view__info-item-text-img' src={check3} alt='img'/>
                                <div className='subscription-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText16}</div>
                            </div>
                        </div>
                    </div>
                    <button className={`subscription-view__card-btn-by ${selectedSubscription === 2 ? 'subscription-view__card-btn-by--active' : ''}`} onClick={() => setSelectedSubscription(2)}>Спробуйте {selectedLanguage?.pricingView?.pricingViewItemTitle1}</button>
                </div>
            </div>

            <button className='subscription-view__card-btn-close' onClick={() => handleCloseModal(true)}>{selectedLanguage?.subscriptionView?.subscriptionViewBtnClose}</button>
        </div>
    );
}

export default SubscriptionView;
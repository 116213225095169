import { useEffect, useState } from 'react';
import './CreationShop.css';
import deleteImg from './../../assets/images/deleteImg.svg';
import editIcon from './../../assets/images/editIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setIsNeedCreateShop, setIsNeedUpdateShop, setShop } from '../../store/userSlice';
import { fetchRequest, showMessage } from '../../helpers/Utils';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import close2 from '../../assets/images/close2.svg';
import { useNavigate } from 'react-router-dom';
import ModalWindow from '../ModalWindow/ModalWindow';
import InputPassword from '../InputPassword/InputPassword';
import { currencySigns } from '../../helpers/Config';

function CreationShop({isTabSend}) {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const user = useSelector(state => state.userSlice.user);
    const shop = useSelector(state => state.userSlice.shop);
    const [name, setName] = useState('');
    const [facebook_url, setFacebook_url] = useState('');
    const [instagram_url, setInstagram_url] = useState('');
    const [contact_number, setContact_number] = useState('');
    const [contact_number_two, setContact_number_two] = useState('');
    const [location, setLocation] = useState('');
    const [deliveryMethods, setDeliveryMethods] = useState([]);
    const [newDeliveryMethods, setNewDeliveryMethods] = useState('');
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [newPaymentMethods, setNewPaymentMethods] = useState('');
    const [descriptionShop, setDescriptionShop] = useState('');
    const [checkedLanguage, setCheckedLanguage] = useState('UA');
    const [currency, setCurrency] = useState('UAH');
    const [logo, setLogo] = useState({});
    const [logoObj, setLogoObj] = useState({});
    const [delLogo, setDelLogo] = useState(false);
    const [isDeleteShop, setIsDeleteShop] = useState(false);
    const [deleteShopPassword1, setDeleteShopPassword1] = useState('');
    const [deleteShopPassword2, setDeleteShopPassword2] = useState('');
    const [deleteShopPasswordError, setDeleteShopPasswordError] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = localStorage.getItem('token')

    useEffect(() => {
        if (!shop?.deliveryMethods?.length) {
            setDeliveryMethods([selectedLanguage?.creationShop?.creationShopDeliveryMethods1]);
        }
        if (!shop?.paymentMethods?.length) {
            setPaymentMethods([selectedLanguage?.creationShop?.creationShopPaymentMethods1]);
        }

        setName(shop.name?.length ? shop.name : '');
        setFacebook_url(shop.facebook_url?.length ? shop.facebook_url : '');
        setInstagram_url(shop.instagram_url?.length ? shop.instagram_url : '');
        setContact_number(shop.contact_number?.length ? shop.contact_number : '');
        setContact_number_two(shop.contact_number_two?.length ? shop.contact_number_two : '');
        setLocation(shop.location?.length ? shop.location : '');
        setCurrency(shop.currency?.length ? shop.currency : 'UAH');
        setLogo(shop.logo?.length ? shop.logo : '');
        setCheckedLanguage(shop.language?.length ? shop.language : 'UA');
        setDeliveryMethods(shop.deliveryMethods?.length ? [...shop.deliveryMethods] : []);
        setPaymentMethods(shop.paymentMethods?.length ? [...shop.paymentMethods] : []);
        setDescriptionShop(shop.descriptionShop?.length ? shop.descriptionShop : '');
    }, [])
    
    useEffect(() => {
        handleSend()
    }, [isTabSend])

    useEffect(() => {
        if (delLogo) {
            handleSend()
        }
    }, [delLogo])

    useEffect(() => {
        setName(shop.name?.length ? shop.name : '');
        setFacebook_url(shop.facebook_url?.length ? shop.facebook_url : '');
        setInstagram_url(shop.instagram_url?.length ? shop.instagram_url : '');
        setContact_number(shop.contact_number?.length ? shop.contact_number : '');
        setContact_number_two(shop.contact_number_two?.length ? shop.contact_number_two : '');
        setLocation(shop.location?.length ? shop.location : '');
        setCurrency(shop.currency?.length ? shop.currency : 'UAH');
        setLogo(shop.logo?.length ? shop.logo : '');
        setCheckedLanguage(shop.language?.length ? shop.language : '');
        setDeliveryMethods(shop.deliveryMethods?.length ? [...shop.deliveryMethods] : []);
        setPaymentMethods(shop.paymentMethods?.length ? [...shop.paymentMethods] : []);
        setDescriptionShop(shop.descriptionShop?.length ? shop.descriptionShop : '');
    }, [shop])


    const handleSetDeliveryMethods = () => {
        if (newDeliveryMethods?.length) {
            setDeliveryMethods([...deliveryMethods, newDeliveryMethods])
            setNewDeliveryMethods('')
        }
    }
    
    const handleSetPaymentMethods = () => {
        if (newPaymentMethods?.length) {
            setPaymentMethods([...paymentMethods, newPaymentMethods])
            setNewPaymentMethods('')
        }
    }
   
    const handleDeleteMethod = (el) => {
        setDeliveryMethods([...deliveryMethods.filter(ell => ell !== el)])
    }
    
    const handleDeletePayment = (el) => {
        setPaymentMethods([...paymentMethods.filter(ell => ell !== el)])
    }
    
    const handleSend = () => {
        let formData = new FormData();
        if (delLogo) {
            formData.append('file', null);
            formData.append('logo', '');
        } else if (logoObj.name?.length) {
            formData.append('file', logoObj);
            formData.append('logo', logo);
        } else {
            formData.append('logo', logo);
        }
        
        formData.append('name', name);
        formData.append('facebook_url', facebook_url);
        formData.append('instagram_url', instagram_url);
        formData.append('contact_number', contact_number);
        formData.append('contact_number_two', contact_number_two);
        formData.append('location', location);
        formData.append('owner_id', user._id);
        formData.append('currency', currency);
        formData.append('language', checkedLanguage);
        formData.append('descriptionShop', descriptionShop);
        
        if (deliveryMethods.length) {
            deliveryMethods.map(deliveryMethods => {
                formData.append('deliveryMethods[]', deliveryMethods)
            })
        } else {
            formData.append('deliveryMethods[]', 'Пошта')
        }
        if (paymentMethods.length) {
            paymentMethods.map(paymentMethods => {
                formData.append('paymentMethods[]', paymentMethods)
            })
        } else {
            formData.append('paymentMethods[]', 'Отлата карткою')
        }
        if (shop?.banners?.length) {
            shop.banners.map(img => {
                formData.append('banners', img)
            })
        } else {
            formData.append('banners', [])
        }

        if (name?.length > 0) {
            if (shop?._id?.length) {
                formData.append('_id', shop._id);
                fetch(`${process.env.REACT_APP_BASE_URL}/shops/${shop._id}?token=${token}`, {
                    method: 'PUT',
                    body: formData,
                })
                    .then(res => res.json())
                    .then(res => {
                        if (res.success && res.data) {
                            dispatch(setShop(res.data));
                            setDelLogo(false)
                            showMessage('success', selectedLanguage?.creationShop?.creationShopShowMesSuccessPut)
                        } else {
                            console.log('PUT CreationShop:', res)
                        }
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError)
                    })
                    .finally(() => {
                        dispatch(setIsNeedUpdateShop(false)) 
                    });
            } else {
                formData.append('creationTime', new Date().toLocaleString());
                fetch(`${process.env.REACT_APP_BASE_URL}/shops/?token=${token}`, {
                    method: 'POST',
                    body: formData,
                })
                    .then(res => res.json())
                    .then(res => {
                        if (res.success && res.data) {
                            dispatch(setShop(res.data))
                            showMessage('success', selectedLanguage?.creationShop?.creationShopShowMesSuccessPost)
                        } else {
                            console.log('POST CreationShop', res)
                        }
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError)
                    })
                    .finally(() => {
                        dispatch(setIsNeedCreateShop(false)); 
                    });
            }
        }
    }

    const handleChangeNumber = (fun, value) => {
        if  (/^\d+$/.test(value) || value === '') {
            fun(value)
        } 
    };
    
    const handleImageUpload = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file.name?.length) {
            setLogoObj(file) 
        }
    };

    const handleDeleteShop = (boolean) => {
        if (boolean) {
            if (deleteShopPassword1 == deleteShopPassword2) {
                const data = {
                    associative_id: user._id,
                    email_subject: `You are removed from ${shop.name} as manager!`,
                    email_text: `Hello, there you are removed as ...`,
                    sender: 'info@check-in.shop'
                }

                fetchRequest('DELETE', `https://zx8hsmle2j.execute-api.eu-central-1.amazonaws.com/production/api/v1/roles/all?token=${token}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
                    .then(res => {
                        if (res.success) {
                            deleteShop()
                        } else {
                            console.log('DELETE UserSettingsView', res)
                            setDeleteShopPasswordError(res?.message)
                        }
                    })
                } else {
                    setDeleteShopPasswordError(selectedLanguage?.userSettingsView?.userSettingsPasswordError)
                }
            } else {
            setDeleteShopPassword1('')
            setDeleteShopPassword2('')
            setDeleteShopPasswordError('')
            setIsDeleteShop(false)
        }
    }

    const deleteShop = () => {
        const data = {
            email: user.email, 
            password: deleteShopPassword1,
        }

        fetchRequest('POST', `${process.env.REACT_APP_BASE_URL}/shops/${shop._id}?token=${token}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
            .then(res => {
                if (res.success && res.data) {
                    setIsDeleteShop(false)
                    dispatch(setShop({}))
                    dispatch(setIsNeedCreateShop(true)); 
                    localStorage.removeItem('auth');
                    navigate('/')
                    showMessage('success', selectedLanguage?.userSettingsView?.userSettingsMessageDelShopS)
                } else {
                    console.log('POST UserSettingsView', res)
                    setDeleteShopPasswordError(res?.message)
                }
            })
    }

    return (
        <div className="creation-shop">
            {
                isDeleteShop && <ModalWindow title={selectedLanguage?.userSettingsView?.userSettingsModDelShopTitle} text={selectedLanguage?.userSettingsView?.userSettingsModDelShopText} handleClick={handleDeleteShop}>
                        <InputPassword setValue={setDeleteShopPassword1} value={deleteShopPassword1} id={'UserSettingsViewdeleteShopPassword1'} name={'UserSettingsViewdeleteShopPassword1'} label={selectedLanguage?.userSettingsView?.userSettingsModDelShopLabel1} />
                        <InputPassword setValue={setDeleteShopPassword2} value={deleteShopPassword2} id={'UserSettingsViewdeleteShopPassword2'} name={'UserSettingsViewdeleteShopPassword2'} label={selectedLanguage?.userSettingsView?.userSettingsModDelShopLabel2} />
                        {
                            deleteShopPasswordError?.length > 0 && <div className='user-settings__password-error'>{deleteShopPasswordError}</div>
                        }
                    </ModalWindow>
            }
            <div className="creation-shop--wrpa container">

                <div className='creation-shop__create--wrap'>
                    <div className='creation-shop__create-wrap'>
                        <div className='creation-shop__create-title creation-shop__create-title-name'>{selectedLanguage?.creationShop?.creationShopInputNameLabel}</div>
                        <TextField className='creation-shop__create-input' value={name} onChange={(e) => setName(e.target.value)} label={selectedLanguage?.creationShop?.creationShopInputNamePlaceholder} variant="outlined" />
                        <div className='creation-shop__create-name-sub-title'>{selectedLanguage?.creationProduct?.creationProductNameSubTitle}</div>
                    </div>
                    
                    <div className='creation-shop__create-wrap'>
                        <div className='creation-shop__create-title creation-shop__create-title-name'>{selectedLanguage?.creationShop?.creationShopInputTelLabel}</div>
                        <TextField className='creation-shop__create-input' value={contact_number} onChange={(e) => handleChangeNumber(setContact_number, e.target.value)} label={selectedLanguage?.creationShop?.creationShopInputTelPlaceholder} variant="outlined" />
                    </div>
                    
                    <div className='creation-shop__create-wrap'>
                        <div className='creation-shop__create-title creation-shop__create-title-name'>{selectedLanguage?.creationShop?.creationShopInputTel2Label}</div>
                        <TextField className='creation-shop__create-input' value={contact_number_two} onChange={(e) => handleChangeNumber(setContact_number_two, e.target.value)} label={selectedLanguage?.creationShop?.creationShopInputTel2Placeholder} variant="outlined" />
                    </div>
                    
                    <div className='creation-shop__create-wrap'>
                        <div className='creation-shop__create-title creation-shop__create-title-name'>Facebook</div>
                        <TextField className='creation-shop__create-input' value={facebook_url} onChange={(e) => setFacebook_url(e.target.value)} label={selectedLanguage?.creationShop?.creationShopInputFacebookPlaceholder} variant="outlined" />
                    </div>
                    
                    <div className='creation-shop__create-wrap'>
                        <div className='creation-shop__create-title creation-shop__create-title-name'>Instagram</div>
                        <TextField className='creation-shop__create-input' value={instagram_url} onChange={(e) => setInstagram_url(e.target.value)} label={selectedLanguage?.creationShop?.creationShopInputInstagramPlaceholder} variant="outlined" />
                    </div>
                    
                    <div className='creation-shop__create-wrap'>
                        <div className='creation-shop__create-title creation-shop__create-title-name'>{selectedLanguage?.creationShop?.creationShopInputAddressLabel}</div>
                        <TextField className='creation-shop__create-input' value={location} onChange={(e) => setLocation(e.target.value)} label={selectedLanguage?.creationShop?.creationShopInputAddressPlaceholder} rows={5} multiline variant="outlined" />
                    </div>

                    <div className='creation-shop__create-wrap'>
                        <div className='creation-shop__create-title'>{selectedLanguage?.creationShop?.creationShopInputDeliveryLabel}</div>
                        <div className='creation-shop__btn-cancel-sizes-wrap'>
                            <TextField className='creation-shop__create-input' value={newDeliveryMethods} onChange={(e) => setNewDeliveryMethods(e.target.value)} label={selectedLanguage?.creationShop?.creationShopInputDeliveryPlaceholder} variant="outlined" />
                            <button onClick={handleSetDeliveryMethods} className='creation-shop__create-sizes-btn'>+</button>
                        </div>
                        {
                            !!deliveryMethods?.length && 
                                <ul className='creation-shop__create-result-sizes-wrap'>
                                    {
                                        deliveryMethods.map(el => (<li className='creation-shop__create-result-sizes' key={el}>
                                            <div className='creation-shop__create-result-sizes-text'>{el}</div>
                                            <button onClick={() => handleDeleteMethod(el)} disabled={deliveryMethods?.length < 2}><img className='creation-shop__create-result-sizes-btn' src={close2} alt='img'/></button> 
                                        </li>))
                                    }
                                </ul> 
                        }
                    </div>
                    
                    <div className='creation-shop__create-wrap'>
                        <div className='creation-shop__create-title'>{selectedLanguage?.creationShop?.creationShopInputPaymentLabel}</div>
                        <div className='creation-shop__btn-cancel-sizes-wrap'>
                            <TextField className='creation-shop__create-input' value={newPaymentMethods} onChange={(e) => setNewPaymentMethods(e.target.value)} label={selectedLanguage?.creationShop?.creationShopInputPaymentPlaceholder} variant="outlined" />
                            <button onClick={handleSetPaymentMethods} className='creation-shop__create-sizes-btn'>+</button>
                        </div>
                        {
                            !!paymentMethods?.length && 
                                <ul className='creation-shop__create-result-sizes-wrap'>
                                    {
                                        paymentMethods.map(el => (<li className='creation-shop__create-result-sizes' key={el}>
                                            <div className='creation-shop__create-result-sizes-text'>{el}</div>
                                            <button onClick={() => handleDeletePayment(el)} disabled={paymentMethods?.length < 2}><img className='creation-shop__create-result-sizes-btn' src={close2} alt='img'/></button> 
                                        </li>))
                                    }
                                </ul> 
                        }
                    </div>

                    <div className='creation-shop__create-wrap'>
                        <div className='creation-shop__create-title creation-shop__create-title-name'>{selectedLanguage?.creationShop?.creationShopInputCurrencyLabel}</div>
                        <FormControl className='creation-product__create-category-select' fullWidth>
                            <InputLabel id="userNotificationsSort">{selectedLanguage?.creationShop?.creationShopInputCurrencyLabel}</InputLabel>
                            <Select
                                labelId="userNotificationsSort"
                                id="creationShopInputCurrencyLabel"
                                value={currency}
                                label={selectedLanguage?.creationShop?.creationShopInputCurrencyLabel}
                                onChange={(e) => setCurrency(e.target.value)}
                            >
                                {
                                    !!currencySigns?.length && currencySigns.map(el => (
                                            <MenuItem value={el.value} key={el.title}>{el.title} {el.value}</MenuItem>
                                        ))
                                }
                            </Select>
                        </FormControl>
                    </div>
                    
                    <div className='creation-shop__create-wrap'>
                        <div className='creation-shop__create-title creation-shop__create-title-name'>{selectedLanguage?.creationShop?.creationShopInputLanguageLabel}</div>
                        <label className="creation-shop__section-input-check-label">UA
                            <input onChange={() => setCheckedLanguage('UA')} type="radio" checked={checkedLanguage == 'UA'} name="radio"/>
                        </label>
                        <label className="creation-shop__section-input-check-label">ENG
                            <input onChange={() => setCheckedLanguage('ENG')} type="radio" checked={checkedLanguage == 'ENG'} name="radio"/>
                        </label>
                    </div>

                    <div className='creation-shop__create-wrap'>
                        <div className='creation-shop__create-title creation-shop__create-title-name'>{selectedLanguage?.creationShop?.creationShopInputDescriptionLabel}</div>
                        <TextField className='creation-shop__create-input' value={descriptionShop} onChange={(e) => setDescriptionShop(e.target.value)} label={selectedLanguage?.creationShop?.creationShopInputDescriptionPlaceholder} rows={5} multiline variant="outlined" />
                    </div>

                    <button className='creation-shop__delete-btn' onClick={() => setIsDeleteShop(true)}>Delete Store</button>
                </div>

                <div className='creation-shop__create-img--wrap'>
                    <div className='creation-shop__create-title creation-shop__create-title-name'>{selectedLanguage?.creationShop?.creationShopInputLogoLabel}</div>
                    {
                        !logoObj?.name?.length && !shop?.logo?.length &&
                            <div
                                className='creation-shop__create-img-wrap'
                                onDrop={(e) => handleImageUpload(e)}
                                onDragOver={(e) => e.preventDefault()}
                            >   
                                <div className='creation-shop__create-img-text-wrap'>
                                    <p>{selectedLanguage?.creationProduct?.creationProductCreateImgText}</p>
                                    <input className="creation-shop__create-img-link-upload-input" onChange={(e) => setLogoObj(e.target.files[0])} type="file" accept="image/*" id="creationProductUserImages"/>
                                    <label className='creation-shop__create-img-link-upload-lable' htmlFor='creationProductUserImages'>{selectedLanguage?.creationProduct?.creationProductCreateImgLink}</label>
                                </div>
                            </div>
                    }
                    {
                        !!logoObj?.name?.length && 
                            <div className='creation-shop__section-logo-img-wrap'>
                                <img className='creation-shop__section-logo-img' src={URL.createObjectURL(logoObj)} alt='img'/>
                                <img className='creation-shop__section-logo-img-delete' onClick={() => setLogoObj({})} src={deleteImg} alt='img'/>
                                <label>
                                    <img className='creation-shop__section-logo-img-edite' src={editIcon} alt='img'/>
                                    <input className="creation-shop__create-img-link-upload-input" onChange={(e) => setLogoObj(e.target.files[0])} type="file" accept="image/*" id="creationProductUserImages"/>
                                </label>
                            </div>
                    }
                    {
                        !!shop?.logo?.length && !logoObj?.name?.length && 
                            <div className='creation-shop__section-logo-img-wrap'>
                                <img className='creation-shop__section-logo-img' src={shop.logo} alt='img'/>
                                <img className='creation-shop__section-logo-img-delete' onClick={() => setDelLogo(true)} src={deleteImg} alt='img'/>
                                <label>
                                    <img className='creation-shop__section-logo-img-edite' src={editIcon} alt='img'/>
                                    <input className="creation-shop__create-img-link-upload-input" onChange={(e) => setLogoObj(e.target.files[0])} type="file" accept="image/*" id="creationProductUserImages"/>
                                </label>
                            </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default CreationShop;
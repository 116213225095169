
export const languageShop = {
    'UA': {
        header: {
            headerBtn: 'Головна',
        },
        heartBtn: {
            heartBtn: 'Обрані',
        },
        cartBtn: {
            cartBtn: 'Кошик',
        },
        searchBtn: {
            placeholderSearch: 'Пошук',
        },
        footer: {
            modalCallMeTitle: 'Вкажіть свій телефон',
            modalCallMeText: 'Ми обов\'язково передзвонимо',
            modalCallMeLeftBtn: 'Відмінити',
            modalCallMeRightBtn: 'Підтвердити',
            modalCallMeTelLabel: 'Телефон для контакту',
            modalCallMeTelPlaceholder: 'Телефон...',
            modalCallMeCommentLabel: 'Залиште коментар',
            modalCallMeCommentPlaceholder: 'Коментар...',
            catalogTitle: 'Каталог',
            contactTitle: 'Контакт',
            callMeBack: 'Передзвоніть мені',
            subscribeNews : 'Підпишіться на новини та акції',
            subscribeBtn : 'Підписка',
            licenseText1 : 'Всі права захищенно.',
            licenseText2 : 'Розроблений та підтримується',
            showMessageError: 'Сталася помилка',
            showMessageCreationNotifications: 'Повідомлення відправлено',
        },
        homePage: {
            homeName: 'Головна сторінка',
            homeInfoTitle: 'Інтернет-магазин',
            titleSwiperDiscounts: 'Знижки',
            titleSwiperNew: 'Новинки',
            titleSwiperAdvertising: 'Інші цікаві товари',
            homeCategoriesTitle: 'Переглянути категорії',
        },
        categoriesPage: {
            categoriesSortTitle: 'Сортувати:',
            categoriesSortOption0: 'Всі товари',
            categoriesSortOption1: 'По зростанню ціни',
            categoriesSortOption2: 'По спаданню ціни',
            categoriesSortOption3: 'Знижки',
            categoriesSortOption4: 'Новинки',
            categoriesError: 'В цій категорії не має товарів',
        },
        productPage: {
            productNameTitle: 'Назва:',
            productPriceTitle: 'Ціна:',
            productSizeTitle: 'Доступні розміра: ',
            productColorsTitle: 'Доступні кольори: ',
            productDescriptionTitle: 'Опис',
            productBtnWishListAdd: 'Добавити в обрані',
            productBtnWishListNotAdd: 'Видалити з обраних',
            productBtnCartAdd: 'Добавити в кошик',
            productBtnCartNotAdd: 'Видалити з кошика',
            productDeliveryTitle: 'Способи доставки:',
            productPaymentTitle: 'Способи оплати:',
            productSwiperTitle: 'Подібні продукти',
            productLastTitle: 'Переглянуті продукти',
            productCopiBtn1: 'Посилання скопійоване',
            productCopiBtn2: 'Скопіювати посилання на товар',
        },
        wishlistPage: {
            wishlistName: 'Обрані',
            wishlistError: 'У вас немає обраних товарів!!!',
        },
        cartPage: {
            cartName: 'Кошик',
            cartSizes: 'Розміра:',
            cartColors: 'Кольори:',
            cartNameTitle: 'Назва:',
            cartPriceTitle: 'Ціна:',
            cartTotalPriceTitle: 'Загальна ціна:',
            cartQuantityTitle: 'Введіть кількість',
            cartTotalTitle: 'Загальна сума:',
            cartTotalPromoTitle: 'Сума знижки за промокодом',
            cartBtnBuy: 'Оформити замовлення',
            cartError: 'Ваш кошик пустий!!!',
            cartFormTitle: 'Контактна інформація',
            cartFormWarning: 'Ці поля обов\'язкові для заповнення',
            cartFormName: 'Прізвище та ім\'я',
            cartFormPhone: 'Телефон',
            cartFormMail: 'Ел. пошта',
            cartFormDelivery: 'Спосіб доставки:',
            cartFormPayment: 'Спосіб оплати:',
            cartFormAddress: 'Адреса:',
            cartFormComment: 'Коментар:',
            cartFormPromo: 'Використати прооомокод',
            cartFormPromoBtn: 'Використати',
            cartFormPromoPlaceholder: 'Введіть промокод...',
            cartFormUsePromo: 'Введений промокод',
            cartFormCheckbox: 'Даю згоду на обробку даних',
            cartFormSubmitError: 'Обовязкові поля не заповнені!!!',
            cartPurchaseHistory: 'Історія покупок',
            cartPurchasedProduct: 'Куплений товар:',
            showMessageError: 'Сталася помилка',
            showMessageUsePromo: 'Промокод застосовано',
            showMessagePurchase: 'Покупка оформлена',
        },
        notFoundPage: {
            notFoundTitle: 'Сторінка не знайдена',
            notFoundGetBackTitle: 'Повернутись на головну сторінку:',
        },
        pagination: {
            paginationNextBtn: 'вперед',
            paginationPrevBtn: 'назад',
        },
        searchPage: {
            searchErrorText: 'Товару з такою назвою не знайдено',
        },
        modalWindow: {
            modalWindowLeftBtn: 'Ні',
            modalWindowRightBtn: 'Так',
        },
        loginBtn: {
            loginBtn: 'Вхід',
        },
    },
    'ENG': {
        header: {
            headerBtn: 'Main',
        },
        heartBtn: {
            heartBtn: 'Chosen',
        },
        cartBtn: {
            cartBtn: 'Basket',
        },
        searchBtn: {
            placeholderSearch: 'Search',
        },
        footer: {
            modalCallMeText: 'We will definitely call you back',
            modalCallMeText: 'We will definitely caLeftBtnou back',
            modalCallMeLeftBtn: 'Cancel',
            modalCallMeRightBtn: 'Confirm',
            modalCallMeTitle: 'Enter your phone number',
            modalCallMeTelLabel: 'Phone number for contact',
            modalCallMeTelPlaceholder: 'Phone...',
            modalCallMeCommentLabel: 'Leave a comment',
            modalCallMeCommentPlaceholder: 'Comment...',
            catalogTitle: 'Catalogue',
            contactTitle: 'Contact',
            callMeBack: 'Call me back',
            subscribeNews : 'Subscribe to news and promotions',
            subscribeBtn : 'Subscription',
            licenseText1 : 'All rights reserved.',
            licenseText2 : 'Developed and maintained by the',
            showMessageError: 'An error occurred',
            showMessageCreationNotifications: 'The message has been sent',
        },
        homePage: {
            homeName: 'Main page',
            homeInfoTitle: 'Internet-shope',
            titleSwiperDiscounts: 'Discounts',
            titleSwiperNew: 'Novelty',
            titleSwiperAdvertising: 'Other interesting products',
            homeCategoriesTitle: 'View categories',
        },
        categoriesPage: {
            categoriesSortTitle: 'Sort:',
            categoriesSortOption0: 'All products',
            categoriesSortOption1: 'As the price increases',
            categoriesSortOption2: 'As the price drops',
            categoriesSortOption3: 'Discounts',
            categoriesSortOption4: 'Novelty',
            categoriesError: 'There are no products in this category',
        },
        productPage: {
            productNameTitle: 'Name:',
            productPriceTitle: 'Price:',
            productSizeTitle: 'Available sizes: ',
            productColorsTitle: 'Available colors: ',
            productDescriptionTitle: 'Description',
            productBtnWishListAdd: 'Add to favorites',
            productBtnWishListNotAdd: 'Remove from favorites',
            productBtnCartAdd: 'Add to cart',
            productBtnCartNotAdd: 'Remove from cart',
            productDeliveryTitle: 'Delivery methods:',
            productPaymentTitle: 'Payment methods:',
            productSwiperTitle: 'Similar products',
            productLastTitle: 'Reviewed products',
            productCopiBtn2: 'Copy the product link',
            productCopiBtn1: 'The link is copied',
        },
        wishlistPage: {
            wishlistName: 'Chosen',
            wishlistError: 'You have no selected products!!!',
        },
        cartPage: {
            cartName: 'Basket',
            cartSizes: 'Sizes:',
            cartColors: 'Colors:',
            cartNameTitle: 'Name:',
            cartPriceTitle: 'Price:',
            cartTotalPriceTitle: 'Total price:',
            cartQuantityTitle: 'Enter the quantity',
            cartTotalTitle: 'Total:',
            cartTotalPromoTitle: 'The size of the discount according to the promo code',
            cartBtnBuy: 'To order',
            cartError: 'Your basket is empty!!!',
            cartFormTitle: 'Contact information',
            cartFormWarning: 'These fields are mandatory',
            cartFormName: 'Name and surname',
            cartFormPhone: 'Phone',
            cartFormMail: 'E. mail',
            cartFormDelivery: 'Delivery method:',
            cartFormPayment: 'Payment method:',
            cartFormAddress: 'Address:',
            cartFormComment: 'Comment:',
            cartFormPromo: 'Use a promo code',
            cartFormPromoBtn: 'Use',
            cartFormPromoPlaceholder: 'Enter promo code...',
            cartFormUsePromo: 'Promo code entered',
            cartFormCheckbox: 'I consent to data processing',
            cartFormSubmitError: 'Mandatory fields are not filled!!!',
            cartPurchaseHistory: 'Purchase history',
            cartPurchasedProduct: 'Purchased product:',
            showMessageError: 'An error occurred',
            showMessageUsePromo: 'Promo code applied',
            showMessagePurchase: 'Purchase completed',
        },
        notFoundPage: {
            notFoundTitle: 'Page not found',
            notFoundGetBackTitle: 'Return to the main page:',
        },
        pagination: {
            paginationNextBtn: 'go >',
            paginationPrevBtn: '< back',
        },
        searchPage: {
            searchErrorText: 'No product with this name was found',
        },
        modalWindow: {
            modalWindowLeftBtn: 'No',
            modalWindowRightBtn: 'Yes',
        },
        loginBtn: {
            loginBtn: 'Exit',
        },
    },
  
}


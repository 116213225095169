import './SwiperCards.css';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { useState, useEffect, memo } from 'react';
import ProductCard from '../ProductCard/ProductCard';
import { useSelector } from 'react-redux';

function SwiperCards({title='', products, isAdvertising=false}) {
    const shop = useSelector(state => state.homeSlice.shop);
    const [vw, setVw] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleResize = () => {
        setVw(window.innerWidth)
    }

    return (
        <div className="swiper-cards">
            <div className="swiper-cards--wrap container">
                <div className="swiper-cards__title-wrap">
                    <h2 className="swiper-cards__title">{title}</h2>
                </div>

                <Swiper
                    slidesPerView={vw > 500 ? vw > 768 ? 3 : 2 : 1}
                    spaceBetween={30}
                    slidesPerGroup={vw > 500 ? vw > 768 ? 3 : 2 : 1}
                    loop={true}
                    loopFillGroupWithBlank={true}
                    pagination={{
                    clickable: true,
                    }}
                    navigation={true}
                    initialSlide='1'
                    modules={[Navigation]}
                    className={`mySwiper mySwiper-swiper ${shop.cardType === '3' ? 'mySwiper-swiper-type-3' : ''}`}
                >
                    {
                        products.map((product, i) => (
                            <SwiperSlide key={product._id + i}><ProductCard product={product} cardType={shop.cardType} isAdvertising={isAdvertising}/></SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </div>
    );
}

export default memo(SwiperCards);
import './OrderElements.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedBlocks } from '../../store/userSlice';
import arrow from '../../assets/images/arrow.svg';
import eyeHide from '../../assets/images/eyeHide.svg';
import eye from '../../assets/images/eye.svg';

function OrderElements() {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const shop = useSelector(state => state.userSlice.shop);
    const dispatch = useDispatch();
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [items, setItems] = useState([]);
    const [initialArr2, setInitialArr2] = useState([]);
    const [initialArr, setInitialArr] = useState(
        [
            { id: 1, name: selectedLanguage?.orderElements?.orderElementsBlock1, isChecked: false},
            { id: 2, name: selectedLanguage?.orderElements?.orderElementsBlock2, isChecked: false },
            { id: 3, name: selectedLanguage?.orderElements?.orderElementsBlock3, isChecked: false },
        ]
    );

    useEffect(() => {
        if (selectedLanguage?.orderElements) {
            setInitialArr2([
                { id: 1, isChecked: false, name: selectedLanguage?.orderElements?.orderElementsBlock1},
                { id: 2, isChecked: false, name: selectedLanguage?.orderElements?.orderElementsBlock2},
                { id: 3, isChecked: false, name: selectedLanguage?.orderElements?.orderElementsBlock3},
                { id: 4, isChecked: false, name: selectedLanguage?.orderElements?.orderElementsBlock4},
                { id: 5, isChecked: false, name: selectedLanguage?.orderElements?.orderElementsBlock5},
                { id: 6, isChecked: false, name: selectedLanguage?.orderElements?.orderElementsBlock6},
            ]);
        }
    }, [selectedLanguage])
    
    useEffect(() => {
        if (shop?._id && shop?.selectedBlocks?.length > 0 && initialArr2?.length) {
            let arr = shop?.selectedBlocks.map(el => ({...el, name: initialArr2.filter(ell => ell.id === el.id)[0].name}))

            if (!shop?.selectedBlocks?.some(el => el.id == 4) && shop?.descriptionShop?.length) {
                arr = [...arr, { id: 4, name: selectedLanguage?.orderElements?.orderElementsBlock4, isChecked: false }]
            } else if (!shop?.descriptionShop?.length) {
                arr = [...arr.filter(el => el.id !==  4)]
            }
            if (!shop?.selectedBlocks?.some(el => el.id == 5) && shop?.informationBlock?.length) {
                arr = [...arr, { id: 5, name: selectedLanguage?.orderElements?.orderElementsBlock5, isChecked: false }]
            } else if (!shop?.informationBlock?.length) {
                arr = [...arr.filter(el => el.id !==  5)]
            }
            if (!shop?.selectedBlocks?.some(el => el.id == 6) && shop?.typeStore?.length) {
                arr = [...arr, { id: 6, name: selectedLanguage?.orderElements?.orderElementsBlock6, isChecked: false }]
            } else if (!shop?.typeStore?.length) {
                arr = [...arr.filter(el => el.id !==  6)]
            }
            setItems(arr)
        } else if (shop?._id && !shop?.selectedBlocks?.length) {
            let arr = [...initialArr]
            if (shop?.descriptionShop?.length) {
                arr = [...arr, { id: 4, name: selectedLanguage?.orderElements?.orderElementsBlock4, isChecked: false }]
            } 
            if (shop?.informationBlock?.length) {
                arr = [...arr, { id: 5, name: selectedLanguage?.orderElements?.orderElementsBlock5, isChecked: false }]
            }
            if (shop?.typeStore?.length) {
                arr = [...arr, { id: 6, name: selectedLanguage?.orderElements?.orderElementsBlock6, isChecked: false }]
            }
            setItems(arr)
        }
    }, [shop, initialArr2])

    useEffect(() => {
        let res = items.map(el => ({isChecked: el.isChecked, id: el.id}))
        dispatch(setSelectedBlocks(res))
    }, [items])
 
    const handleOrder = (id) => {
        let res = items.map(el => {
            if (el.id == id) {
                el = {...el, isChecked: !el.isChecked}
            }
            return el
        })
        setItems(res)
    }
    
    const handleNewPosition = (obj, i, num) => {
        if ((i === 0 && num === -1) || ((i + 1) === items.length && num === 1)) {
            return
        }

        let filteredItems = items.filter(el => el.id !== obj.id);
        filteredItems.splice(i + num, 0, obj);
        setItems([...filteredItems]);
    }

    return (
        <div className="order-elements">
            <div className="order-elements--wrap">
                {items?.length > 0 && items.map((item, i) => (
                    <div
                        className='order-elements__box'
                        data-index={i}
                        key={item.name}
                        draggable="true"
                        onDragStart={e => setFrom(Number(e.currentTarget.dataset.index))}
                        onDragOver={e => {
                            e.preventDefault();
                            setTo(Number(e.currentTarget.dataset.index));
                        }}
                        onDragEnd={() => {
                            let arr = [...items];
                            arr.splice(to, 0, arr.splice(from, 1)[0]);
                            setItems(arr);
                            setFrom(null);
                            setTo(null);
                        }}
                    >   
                        <div className="order-elements__label">
                            {i + 1}
                            <img className="order-elements__label-img" onClick={() => handleOrder(item.id)} src={item.isChecked ? eye : eyeHide} alt='img'/>
                            <p>{item.name}</p>
                        </div>
                        <div className="order-elements__btn-wrap">
                            <img className="order-elements__btn order-elements__btn-up" onClick={() => handleNewPosition(item, i, -1)} src={arrow} alt='img'/>
                            <img className="order-elements__btn order-elements__btn-down" onClick={() => handleNewPosition(item, i, +1)} src={arrow} alt='img'/>
                        </div>
                    </div>
                ))}
            </div>
            <div className="order-elements__text">{selectedLanguage?.orderElements?.orderElementsText}</div>
        </div>
    );
}

export default OrderElements;
import './MainSlider.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import upload from '../../assets/images/upload.svg';
import deleteImg from '../../assets/images/deleteImg.svg';
import { showMessage } from '../../helpers/Utils';
import { setShop } from '../../store/userSlice';


const MainSlider = ({isUpdateSlider=false, setIsUpdateSlider=null}) => {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const shop = useSelector(state => state.userSlice.shop);
    const [sliderImages, setSliderImages] = useState([]);
    const [oldImages, setOldImages] = useState([]);
    const dispatch = useDispatch();
    const token = localStorage.getItem('token')
    
    useEffect(() => {
        if (shop?.banners?.length) {
            setOldImages(shop.banners)
        }
    }, [])
    
    useEffect(() => {
        if (shop?.banners?.length) {
            setOldImages(shop.banners)
        }
    }, [shop])
    
    useEffect(() => {
        if (isUpdateSlider) {
            handleUpdateSlider()
        }
    }, [isUpdateSlider])

    const uploadPhoto = (image_file) => {
        if (image_file?.name?.length) {
            setSliderImages([...sliderImages, image_file]) 
        }
    }

    const handleImageUpload = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file.name?.length) {
            setSliderImages([...sliderImages, file]) 
        }
    };

    const handleDeleteImages = (name) => {
        setSliderImages([...sliderImages.filter(el => el.name !== name)])
    }

    const handleDeleteOldImages = (url) => {
        setOldImages([...oldImages.filter(el => el !== url)])
    }

    const  handleUpdateSlider = () => {
        let formData = new FormData();
        if (oldImages.length) {
            oldImages.map(img => {
                formData.append('banners', img)
            })
        } else {
            formData.append('banners', [])
        }
        if (sliderImages.length) {
            sliderImages.map(img => {
                formData.append('bannerFiles', img)
            })
        } else {
            formData.append('bannerFiles', [])
        }

        fetch(`${process.env.REACT_APP_BASE_URL}/shops/${shop._id}?token=${token}`, {
            method: 'PUT',
            body: formData,
        })
            .then(res => res.json())
            .then(res => {
                console.log(res)
                if (res.success && res.data) {
                    dispatch(setShop(res.data));
                    setOldImages([])
                    setSliderImages([])
                    showMessage('success', selectedLanguage?.creationShop?.creationShopShowMesSuccessPut)
                } else {
                    console.log('PUT CreationShop:', res)
                }
                if (setIsUpdateSlider) {
                    setIsUpdateSlider(false)
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError)
            })
    }
  
    return (
        <div className='main-slider'>
            <div className='main-slider__title'>{selectedLanguage?.mainSlider?.mainSliderTitle}</div>
            <div
                className='main-slider__create-img-wrap'
                onDrop={(e) => handleImageUpload(e)}
                onDragOver={(e) => e.preventDefault()}
            >   
                <div className='main-slider__create-img-text-wrap'>
                    <img className='main-slider__create-img' src={upload} alt='img'/>
                    <p>{selectedLanguage?.creationProduct?.creationProductCreateImgText}</p>
                    <input className="main-slider__create-img-link-upload-input" onChange={(e) => uploadPhoto(e.target.files[0])} type="file" accept="image/*" id="creationProductUserImages"/>
                    <label className='main-slider__create-img-link-upload-lable' htmlFor='creationProductUserImages'>{selectedLanguage?.creationProduct?.creationProductCreateImgLink}</label>
                </div>
            </div>

            <div className='main-slider__result'>
                {
                    sliderImages?.length > 0 && 
                        <ul className='main-slider__result-list-wrap'>
                            {
                                sliderImages.map(el => (<li className='main-slider__result-list' key={el.name}>
                                    <img className='main-slider__result-list-img' src={URL.createObjectURL(el)} alt='img'/>
                                    <img className='main-slider__result-list-btn' onClick={() => handleDeleteImages(el.name)} src={deleteImg} alt='img'/>
                                </li>))
                            }
                        </ul> 
                }
                {
                    oldImages?.length > 0 && 
                        <ul className='main-slider__result-list-wrap'>
                            {
                                oldImages.map(el => (<li className='main-slider__result-list' key={el}>
                                    <img className='main-slider__result-list-img' src={el} alt='img'/>
                                    <img className='main-slider__result-list-btn' onClick={() => handleDeleteOldImages(el)} src={deleteImg} alt='img'/>
                                </li>))
                            }
                        </ul> 
                }
            </div>
        </div>
    );
  }
  
export default MainSlider;

import './ProductDetailsView.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setEditProduct } from '../../store/userSlice';
import noPhotos from '../../assets/images/noPhotos.svg';
import ColorResult from '../../components/ColorResult/ColorResult';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import { fetchGetData, handleShopCurrency } from '../../helpers/Utils';
import Preloader from '../../components/Preloader/Preloader';


function ProductDetailsView() {
    const user = useSelector(state => state.userSlice.user);
    const shop = useSelector(state => state.userSlice.shop);
    const [productDetails, setProductDetails] = useState({});
    const [isPreloader, setIsPreloader] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { idProduct } = useParams();
    
    useEffect(() => {
        getUserProduct()
    }, []);

    const handleEditProduct = (obj) => {
        dispatch(setEditProduct(obj))
        localStorage.setItem('editProduct', JSON.stringify(obj));
        navigate(`/auth/${user._id}/product/create`)
    }
    
    const getUserProduct = () => {
        setIsPreloader(true)
        fetchGetData(`${process.env.REACT_APP_BASE_URL}/products/${idProduct}`)
            .then(res => {
                if (res.success && res.data) {
                    setProductDetails(res.data)
                } else {
                    console.log('GET ProductDetailsView:', res)
                }
                setIsPreloader(false)
            })
    };

    return (
        <div className='product-details-view-wrap'>
            <div className='product-details-view container'>
                {
                   isPreloader ? <Preloader /> : !!productDetails._id?.length && 
                    <>
                        <div className="product-details-view__swiper-wrap">
                            <Swiper
                                spaceBetween={90}
                                // slidesPerGroup={50}
                                pagination={{
                                    type: "fraction",
                                }}
                                navigation={true}
                                modules={[Navigation, Pagination]}
                                className="mySwiper product-details-view__swiper"
                                >
                                {
                                    !!productDetails?.images_URL?.length ? productDetails?.images_URL.map(image => <SwiperSlide key={image}><img className="product-details-view__swiper-img" src={image} alt='img'/></SwiperSlide>)
                                    : <img className="product-details-view__swiper-img-none" src={noPhotos} alt='img'/> 
                                }
                            </Swiper>
                        </div>

                        <div className='product-details-view__info-wrap'>
                            <span  className='product-details-view__info-title'>Назва товару:&nbsp;</span>
                            <span  className='product-details-view__info-text'>{productDetails?.name}</span>
                        </div>
                        <div className='product-details-view__info-wrap'>
                            <span  className='product-details-view__info-title'>Категорія:&nbsp;</span>
                            <span  className='product-details-view__info-text'>{productDetails?.category_name}</span>
                        </div>
                        <div className='product-details-view__info-wrap'>
                            <span  className='product-details-view__info-title'>Ціна:&nbsp;</span>
                            <span  className='product-details-view__info-text'>{productDetails?.price}{handleShopCurrency(shop?.currency)}</span>
                        </div>
                        <div className='product-details-view__info-wrap'>
                            <span  className='product-details-view__info-title'>Ціна зі знижкою:&nbsp;</span>
                            <span  className='product-details-view__info-text'>{productDetails?.new_price}{handleShopCurrency(shop?.currency)}</span>
                        </div>
                        <div className='product-details-view__info-wrap'>
                            <span  className='product-details-view__info-title'>Опис:&nbsp;</span>
                            <span  className='product-details-view__info-text'>{productDetails?.details}</span>
                        </div>
                        <div className='product-details-view__info-wrap'>
                            <span  className='product-details-view__info-title'>Кольори:&nbsp;</span>
                            <span  className='product-details-view__info-text'>
                                {
                                    !!productDetails.colors?.length && productDetails.colors.map(el => (
                                        <ColorResult color={el} key={el}/>
                                    ))
                                }
                            </span>
                        </div>
                        <div className='product-details-view__info-wrap'>
                            <span  className='product-details-view__info-title'>Розміра:&nbsp;</span>
                            <span  className='product-details-view__info-text'>{productDetails.sizes?.join(', ')}</span>
                        </div>
                        <div className='product-details-view__info-wrap'>
                            <span  className='product-details-view__info-title'>Штрихкод товару:&nbsp;</span>
                            <span  className='product-details-view__info-text'>{productDetails.bar_code}</span>
                        </div>
                        <div className='product-details-view__info-wrap'>
                            <span  className='product-details-view__info-title'>На складі:&nbsp;</span>
                            <span  className='product-details-view__info-text'>{productDetails.quantity_in_stock}</span>
                        </div>
                        <div className='product-details-view__info-wrap'>
                            <span  className='product-details-view__info-title'>Замовлено:&nbsp;</span>
                            <span  className='product-details-view__info-text'>{productDetails.number_of_orders}</span>
                        </div>

                        <div className="product-details-view__edite-btn-wrap">
                            <button className="product-details-view__edite-btn" onClick={() => handleEditProduct(productDetails)}>Редагувати</button>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

export default ProductDetailsView;
import './AdvertisementShopView.css';
import { useSelector } from 'react-redux';

function AdvertisementShopView() {
    const shop = useSelector(state => state.homeSlice.shop);

    return (
        <div className='advertisement-shop'>
            {
                !!shop?.informationBlock?.length && !!shop?.informationBlock[0]?.description?.length && <div>{shop.informationBlock[0].description}</div>
            }
        </div>
    );
}

export default AdvertisementShopView;
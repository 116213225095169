import './MoreAnalytics1.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import close from '../../assets/images/close.svg';
import arrows from './../../assets/images/arrows.svg';
import check from './../../assets/images/check.svg';
import box from './../../assets/images/box.svg';
import Preloader from '../Preloader/Preloader';
import Chart from 'chart.js/auto';
import { setRemoveProduct, setUpdataProduct } from '../../store/userSlice';
import ModalWindow from '../ModalWindow/ModalWindow';
import { fetchGetData, fetchRequest, handleShopCurrency, showMessage } from '../../helpers/Utils';
import { backgroundColorVisitorsGraph, borderColorVisitorsGraph } from '../../helpers/Config';
import CustomerCard from '../CustomerCard/CustomerCard';
import ProductCardUser from '../ProductCardUser/ProductCardUser';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

function MoreAnalytics1() {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const isNeedCreateShop = useSelector(state => state.userSlice.isNeedCreateShop);
    const shop = useSelector(state => state.userSlice.shop);
    const [vw, setVw] = useState(window.innerWidth);
    const [isModalDelProduct, setIsModalDelProduct] = useState(false);
    const [isModalHideProduct, setIsModalHideProduct] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [labelsDay, setLabelsDay] = useState('День');
    const [fullYear, setFullYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().toLocaleString('default', { month: 'long' }));
    const [isGraf2, setIsGraf2] = useState(false);
    const [isGraf6, setIsGraf6] = useState(false);
    const [isPreloader, setIsPreloader] = useState(true);
    const [hideProduct, setHideProduct] = useState({});
    const [topProducts, setTopProducts] = useState([]);
    const [clients, setClients] = useState([]);
    const [visitors, setVisitors] = useState([]);
    const [purchases, setPurchases] = useState({});
    const dispatch = useDispatch();
    let { swaper } = useParams();
    const token = localStorage.getItem('token')

    useEffect(() => {
        if (shop?._id) {
            if (selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphVisitorsLabelsDay?.length) {
                setLabelsDay(selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphVisitorsLabelsDay)
            }

            fetchGetData(`${process.env.REACT_APP_BASE_URL}/analytics/${shop._id}/first?token=${token}`, () => setIsPreloader(false))
                .then(res => {
                    if (res.success && res.data) {
                        // setAnalytics(res.data)
                        setClients(res.data.clients)
                        setVisitors(res.data.visitors)
                        setPurchases(res.data.purchases)
                        setIsPreloader(false)
                        setTopProducts(res.data.top_products)
                    } else {
                        console.log('GET MoreAnalytics1', res)
                    }
                })
        } else {
            setIsPreloader(false)
        }

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, [])

    useEffect(() => {
        // if (!analyticsError()) {
        // графік прибутку за рік
        if (shop?.profit?.length && [...shop?.profit.filter(el => el !== 0)].length && !isGraf6 && document.getElementById('moreAnalytics1AnalyticsProfitYear') !== null) {
            setIsGraf6(true)
            let barChartDataProfitYear = {
                labels: [...selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphVisitorsLabels],
                datasets: [
                    {
                        label: selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphVisitorsSubTitle,
                        backgroundColor: "yellow",
                        borderColor: "orange",
                        borderWidth: 1,
                        data: [...shop.profit]
                    },
                ]
            };

            let chartOptionsProfitYear = {
                legend: {
                    position: "top"
                },
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true
                    }
                },
                indexAxis: `${vw < 368 ? 'y' : ''}`,
            }

            const profitYear = new Chart(
                document.getElementById('moreAnalytics1AnalyticsProfitYear'),
                {
                    type: "bar",
                    data: barChartDataProfitYear,
                    options: chartOptionsProfitYear
                }
            );
        }

        // графік відвідувачів
        if (visitors?.length && !isGraf2 && document.getElementById('moreAnalytics1VisitorsGraph') !== null && isVisitors()) {
            setIsGraf2(true)

            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            const d = new Date();

            const dataVisitors = {
                // labels: [...visitors.map(el => `${labelsDay} ${el.day}`)],
                labels: [...visitors.map(el => `${el.day}/0${d.getMonth()}`)],
                datasets: [{
                    label: selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphVisitorsLabelAll,
                    data: [...visitors.map(el => el.quantity)],
                    backgroundColor: backgroundColorVisitorsGraph,
                    borderColor: borderColorVisitorsGraph,
                    borderWidth: 2
                }]
            };

            const configVisitors = {
                //type: 'bar',
                type: 'line',
                data: dataVisitors,
                label: false,
                // options: {
                //     maintainAspectRatio: false,
                //     scales: {
                //         y: {
                //             beginAtZero: true
                //         }
                //     },
                //     indexAxis: `${vw < 500 ? 'y' : ''}`,
                // },
                options: {
                    plugins: {
                      title: {
                        display: true,
                        text: selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphVisitorsTitle
                      }
                    },
                    scales: {
                      y: {
                        stacked: true
                      }
                    },
                    animations: {
                        y: {
                          easing: 'easeInOutElastic',
                          from: (ctx) => {
                            if (ctx.type === 'data') {
                              if (ctx.mode === 'default' && !ctx.dropped) {
                                ctx.dropped = true;
                                return 0;
                              }
                            }
                          }
                        }
                    }
                  },
            };

            const visitorsChart = new Chart(
                document.getElementById('moreAnalytics1VisitorsGraph'),
                configVisitors
            );
        }

    }, [visitors])

    const isVisitors = () => {
        return [...visitors.filter(el => el.quantity !== 0)]?.length > 0 ? true : false
    }

    const analyticsError = () => {
        return (!!shop?.profit?.length && [...shop?.profit.filter(el => el !== 0)].length) || !!purchases?.total_all || (visitors?.length && isVisitors()) || !!topProducts?.length ? false : true
    }

    const handleResize = () => {
        setVw(window.innerWidth)
    }

    const handleIsDeleteProduct = (boolean) => {
        if (boolean) {
            const data = {
                token: token,
            }

            fetchRequest('DELETE', `${process.env.REACT_APP_BASE_URL}/products/${deleteId}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
                .then(res => {
                    if (res.success && res.data) {
                        dispatch(setRemoveProduct(deleteId))
                        showMessage('success', selectedLanguage?.moreAnalytics1?.moreAnalytics1ShowMesDelProduct)
                    } else {
                        console.log('DELETE MoreAnalytics1', res)
                    }
                })
        }

        setIsModalDelProduct(false)
        setDeleteId('')
    }

    const handleIsHideProduct = (boolean) => {
        if (boolean) {
            const data = {
                hide: hideProduct.hide ? false : true,
                token: token,
            }

            fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/products/${hideProduct._id}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
                .then(res => {
                    if (res.success && res.data) {
                        dispatch(setUpdataProduct({ ...hideProduct, hide: hideProduct.hide ? false : true }))
                        showMessage('success', selectedLanguage?.moreAnalytics1?.moreAnalytics1ShowMesHideProduct)
                    } else {
                        console.log('PUT MoreAnalytics1', res)
                    }
                })
        }

        setIsModalHideProduct(false)
        setHideProduct({})
    }

    return (
        <div className="more-analytics1">

            {
                isModalDelProduct && <ModalWindow title={selectedLanguage?.moreAnalytics1?.moreAnalytics1ModDelProductTitle} text={selectedLanguage?.moreAnalytics1?.moreAnalytics1ModDelProductText} handleClick={handleIsDeleteProduct} />
            }
            {
                isModalHideProduct && <ModalWindow title={selectedLanguage?.moreAnalytics1?.moreAnalytics1ModHideProductTitle} text={selectedLanguage?.moreAnalytics1?.moreAnalytics1ModHideProductText} handleClick={handleIsHideProduct} />
            }

            {
                isPreloader ? <Preloader /> : isNeedCreateShop ?
                    <div>
                        <div className="more-analytics1__analytics-error">{selectedLanguage?.moreAnalytics1?.moreAnalytics1Error}</div>
                    </div>
                    :
                    <div className="more-analytics1--wrpa">
                        {
                            analyticsError() ? <div className="more-analytics1__error">Аналітика відсутня. Створіть категорії, товари і почніть продавати.</div>
                                :
                                <>
                                    {
                                        purchases &&
                                        <div className="more-analytics1__purchases">
                                            <div className="more-analytics1__purchases-all">
                                                <div className='more-analytics1__purchases-all-img-wrap'>
                                                    <img className='more-analytics1__purchases-all-img' src={box} alt='img' />
                                                </div>
                                                <div className='more-analytics1__purchases-all-text-wrap'>
                                                    <div className='more-analytics1__purchases-all-text'>{selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphStatus1}</div>
                                                    <div className='more-analytics1__purchases-all-text-count-wrap'>
                                                        <div className='more-analytics1__purchases-all-text-count'>{purchases.total_all}</div>
                                                        <div className='more-analytics1__purchases-all-text-count'>/</div>
                                                        <div className='more-analytics1__purchases-all-text-count'>{purchases.total_month}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="more-analytics1__purchases-all">
                                                <div className='more-analytics1__purchases-all-img-wrap'>
                                                    <img className='more-analytics1__purchases-all-img' src={arrows} alt='img' />
                                                </div>
                                                <div className='more-analytics1__purchases-all-text-wrap'>
                                                    <div className='more-analytics1__purchases-all-text'>{selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphStatus2}</div>
                                                    <div className='more-analytics1__purchases-all-text-count-wrap'>
                                                        <div className='more-analytics1__purchases-all-text-count'>{purchases.in_process_all}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="more-analytics1__purchases-all">
                                                <div className='more-analytics1__purchases-all-img-wrap'>
                                                    <img className='more-analytics1__purchases-all-img' src={check} alt='img' />
                                                </div>
                                                <div className='more-analytics1__purchases-all-text-wrap'>
                                                    <div className='more-analytics1__purchases-all-text'>{selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphStatus3}</div>
                                                    <div className='more-analytics1__purchases-all-text-count-wrap'>
                                                        <div className='more-analytics1__purchases-all-text-count'>{purchases.done_all}</div>
                                                        <div className='more-analytics1__purchases-all-text-count'>/</div>
                                                        <div className='more-analytics1__purchases-all-text-count'>{purchases.done_month}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="more-analytics1__purchases-all">
                                                <div className='more-analytics1__purchases-all-img-wrap'>
                                                    <img className='more-analytics1__purchases-all-img' src={close} alt='img' />
                                                </div>
                                                <div className='more-analytics1__purchases-all-text-wrap'>
                                                    <div className='more-analytics1__purchases-all-text'>{selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphStatus4}</div>
                                                    <div className='more-analytics1__purchases-all-text-count-wrap'>
                                                        <div className='more-analytics1__purchases-all-text-count'>{purchases.rejected_all}</div>
                                                        <div className='more-analytics1__purchases-all-text-count'>/</div>
                                                        <div className='more-analytics1__purchases-all-text-count'>{purchases.rejected_month}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        !!visitors?.length && isVisitors() &&
                                        <div className="more-analytics1__visitors-wrap">
                                            {/* <h4 className="more-analytics1__visitors-title">{selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphVisitorsTitle}</h4> */}
                                            <div className="more-analytics1__visitors">
                                                <div className="more-analytics1__visitors-info">
                                                    {/* <div className="more-analytics1__visitors-info-title">{selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphVisitorsSubTitle}&nbsp;{month}&nbsp;{fullYear}</div> */}
                                                </div>
                                                <div className="more-analytics1__visitors-graph">
                                                    <canvas className="more-analytics1__visitors-graph-canvas" id="moreAnalytics1VisitorsGraph"></canvas>
                                                </div>

                                                {/* <Accordion className='more-analytics1__accordion-result'>
                                                    <AccordionSummary
                                                        expandIcon={<p>&#94;</p>}
                                                        aria-controls="panel1a-visitors"
                                                        id="panel1a-visitors"
                                                    >
                                                        <Typography>Переглянути табличку</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            <p className="more-analytics1__visitors-tabl">
                                                                {
                                                                    visitors?.map((el, i) => (<p className="more-analytics1__visitors-tabl-item" key={el.day + i}>
                                                                        {labelsDay}&nbsp;{el.day} {selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphVisitorsQuantity} {el.quantity}
                                                                    </p>))
                                                                }
                                                            </p>
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion> */}
                                            </div>
                                        </div>
                                    }

                                    {
                                        !!clients?.length &&
                                        <div className='more-analytics1__customers'>
                                            <div className='more-analytics1__customers-title-wrap'>
                                                <div className='more-analytics1__customers-title-name'>{selectedLanguage?.moreAnalytics1?.moreAnalytics1TopClients}</div>
                                            </div>
                                            {
                                                clients.map((el, i) => (
                                                    <CustomerCard customer={el} key={el._id + i}/>
                                                ))
                                            }
                                        </div>
                                    }

                                    { // доробити графік прибутку за рік
                                        !!shop?.profit?.length && !![...shop?.profit.filter(el => el !== 0)].length && <div className="more-analytics1__profit-year-wrap">
                                            <h4 className="more-analytics1__profit-year-title">{selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphProfitYearTitle}</h4>
                                            {/* <div className="more-analytics1__profit-year-sub-title">{selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphVisitorsYearSubTitle}&nbsp;{fullYear}</div> */}
                                            <div className="more-analytics1__profit-year">
                                                <div className="more-analytics1__profit-year-graph">
                                                    <canvas className="more-analytics1__profit-year-graph-canvas" id="moreAnalytics1AnalyticsProfitYear"></canvas>
                                                </div>
                                                <Accordion className='more-analytics1__accordion-result'>
                                                    <AccordionSummary
                                                        expandIcon={<p>&#94;</p>}
                                                        aria-controls="panel1a-profit"
                                                        id="panel1a-profit"
                                                    >
                                                        <Typography>Переглянути табличку</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            <p className="more-analytics1__profit-year-tabl">
                                                                {
                                                                    shop?.profit.map((el, i) => (<p className="more-analytics1__profit-year-tabl-item" key={el + i}>
                                                                        {
                                                                            selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphVisitorsLabels?.length &&
                                                                            <p className="more-analytics1__profit-year-tabl-item-info"><b>{selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphVisitorsLabels[i]}</b></p>
                                                                        }
                                                                        <p className="more-analytics1__profit-year-tabl-item-info"><b>{selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphProfitYeartext}</b> {el}{handleShopCurrency(shop.currency)}</p>
                                                                    </p>))
                                                                }
                                                            </p>
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                        </div>
                                    }

                                    {  //   top_products
                                        !!topProducts?.length && <div className="more-analytics1__top-product"  ref={swaper}>
                                            <div className='more-analytics1__customers-title-wrap'>
                                                <div className='more-analytics1__customers-title-name'>{selectedLanguage?.moreAnalytics1?.moreAnalytics1TopProductTitle}</div>
                                            </div>
                                            <div className='more-analytics1__top-product-cards-wrap'>
                                                {
                                                    topProducts.map((el, i) => (
                                                        <div key={el._id + i}>
                                                            <ProductCardUser product={el}/>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    }
                                </>
                        }
                    </div>
            }
        </div>
    );
}

export default MoreAnalytics1;
import './BlogsView.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HeaderLanding from '../../components/HeaderLanding/HeaderLanding';
import FooterLanding from '../../components/FooterLanding/FooterLanding';
import mixpanel from 'mixpanel-browser';
mixpanel.init('cbc9e4ee0d40873b5a2e3b5e39eedd33', { debug: true }); 
mixpanel.track('Visit blogs page');

function BlogsView() {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const navigate = useNavigate();

    const handleOpenBlog = (id) => {
        navigate(`/blogs/${id}`)
    };
    
    return (
        <div className='blogs-view-wrap'>
            <HeaderLanding />

                <div className='blogs-view container'>
                    <div className='blogs-view__title'>{selectedLanguage?.blogsView?.blogsViewTitle}</div>

                    <div className='blogs-view__cards'>
                        <div className='blogs-view__card' onClick={() => handleOpenBlog(1)}>
                            <img className='blogs-view__card-img' src={'https://garne.com.ua/upload/data/article_2017/articles_44.jpg'} alt='img'/>
                            <div className='blogs-view__card-title'>{selectedLanguage?.blogsView?.blogsViewCardTitle1}</div>
                            <div className='blogs-view__card-text'>{selectedLanguage?.blogsView?.blogsViewCardText1}</div>
                        </div>

                        <div className='blogs-view__card' onClick={() => handleOpenBlog(2)}>
                            <img className='blogs-view__card-img' src={'https://www.greatgame.com/hubfs/Templates-2018/Blog%20Templates/5%20Tips%20to%20Keep%20Selling.png'} alt='img'/>
                            <div className='blogs-view__card-title'>{selectedLanguage?.blogsView?.blogsViewCardTitle2}</div>
                            <div className='blogs-view__card-text'>{selectedLanguage?.blogsView?.blogsViewCardText2}</div>
                        </div>

                        <div className='blogs-view__card' onClick={() => handleOpenBlog(3)}>
                            <img className='blogs-view__card-img' src={'https://gorillaaccounting.com/wp-content/uploads/2022/02/coffee-shop-owner.jpg'} alt='img'/>
                            <div className='blogs-view__card-title'>{selectedLanguage?.blogsView?.blogsViewCardTitle3}</div>
                            <div className='blogs-view__card-text'>{selectedLanguage?.blogsView?.blogsViewCardText3}</div>
                        </div>

                        <div className='blogs-view__card' onClick={() => handleOpenBlog(4)}>
                            <img className='blogs-view__card-img' src={'https://i.ytimg.com/vi_webp/7egQXWg0lw0/maxresdefault.webp'} alt='img'/>
                            <div className='blogs-view__card-title'>{selectedLanguage?.blogsView?.blogsViewCardTitle4}</div>
                            <div className='blogs-view__card-text'>{selectedLanguage?.blogsView?.blogsViewCardText4}</div>
                        </div>
                    </div>
                </div>

            <FooterLanding />
        </div>
    );
}

export default BlogsView;
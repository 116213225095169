export const backgroundColorVisitorsGraph = [
    'rgba(95, 65, 178, 1)',
    'rgba(95, 65, 178, 1)'
    // 'rgba(255, 99, 132, 0.2)',
    // 'rgba(255, 159, 64, 0.2)',
    // 'rgba(255, 205, 86, 0.2)',
    // 'rgba(75, 192, 192, 0.2)',
    // 'rgba(54, 162, 235, 0.2)',
    // 'rgba(153, 102, 255, 0.2)',
    // 'rgba(201, 203, 207, 0.2)',
    // 'rgba(255, 99, 132, 0.2)',
    // 'rgba(255, 159, 64, 0.2)',
    // 'rgba(255, 205, 86, 0.2)',
    // 'rgba(75, 192, 192, 0.2)',
    // 'rgba(54, 162, 235, 0.2)',
    // 'rgba(153, 102, 255, 0.2)',
    // 'rgba(201, 203, 207, 0.2)',
    // 'rgba(255, 99, 132, 0.2)',
    // 'rgba(255, 159, 64, 0.2)',
    // 'rgba(255, 205, 86, 0.2)',
    // 'rgba(75, 192, 192, 0.2)',
    // 'rgba(54, 162, 235, 0.2)',
    // 'rgba(153, 102, 255, 0.2)',
    // 'rgba(201, 203, 207, 0.2)',
    // 'rgba(255, 99, 132, 0.2)',
    // 'rgba(255, 159, 64, 0.2)',
    // 'rgba(255, 205, 86, 0.2)',
    // 'rgba(75, 192, 192, 0.2)',
    // 'rgba(54, 162, 235, 0.2)',
    // 'rgba(153, 102, 255, 0.2)',
    // 'rgba(201, 203, 207, 0.2)',
    // 'rgba(54, 162, 235, 0.2)',
    // 'rgba(153, 102, 255, 0.2)',
    // 'rgba(201, 203, 207, 0.2)',
];

export const borderColorVisitorsGraph = [
    'rgba(95, 65, 178, 1)',
    'rgba(95, 65, 178, 1)'
    // 'rgb(255, 99, 132)',
    // 'rgb(255, 159, 64)',
    // 'rgb(255, 205, 86)',
    // 'rgb(75, 192, 192)',
    // 'rgb(54, 162, 235)',
    // 'rgb(153, 102, 255)',
    // 'rgb(201, 203, 207)',
    // 'rgb(255, 99, 132)',
    // 'rgb(255, 159, 64)',
    // 'rgb(255, 205, 86)',
    // 'rgb(75, 192, 192)',
    // 'rgb(54, 162, 235)',
    // 'rgb(153, 102, 255)',
    // 'rgb(201, 203, 207)',
    // 'rgb(255, 99, 132)',
    // 'rgb(255, 159, 64)',
    // 'rgb(255, 205, 86)',
    // 'rgb(75, 192, 192)',
    // 'rgb(54, 162, 235)',
    // 'rgb(153, 102, 255)',
    // 'rgb(201, 203, 207)',
    // 'rgb(255, 99, 132)',
    // 'rgb(255, 159, 64)',
    // 'rgb(255, 205, 86)',
    // 'rgb(75, 192, 192)',
    // 'rgb(54, 162, 235)',
    // 'rgb(153, 102, 255)',
    // 'rgb(201, 203, 207)',
    // 'rgb(54, 162, 235)',
    // 'rgb(153, 102, 255)',
    // 'rgb(201, 203, 207)',
];

export const backgroundColorStatusGraph = [
    '#007aff36',
    'rgb(7 158 27 / 25%)',
    '#e72f0b3b'
]

export const currencySigns = [
    {title: '₴', value: 'UAH'},
    {title: '$', value: 'USD'},
    {title: '€', value: 'EUR'},
    {title: '£', value: 'GBP'},
    {title: 'Zł', value: 'PLN'},
    {title: '₣', value: 'FRF'},
    {title: 'BTC', value: 'BTC'},
]
import './BarcodeScanner.css';
import React, { useEffect, useRef, useState } from 'react';
import Quagga from 'quagga';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSearchBarcode } from '../../store/userSlice';


const BarcodeScanner = ({setIsScanner, isScanner, closeModal}) => {
    const user = useSelector(state => state.userSlice.user);
    const [cameraId, setCameraId] = useState([]);
    const [selectCamera, setSelectCamera] = useState('');
    const [currentCameraIndex, setCurrentCameraIndex] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const videoRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!navigator.mediaDevices?.enumerateDevices) {
            console.log("enumerateDevices() not supported.");
        } else {
            navigator.mediaDevices
                .enumerateDevices()
                .then((devices) => {
                    let res = devices.filter(el => el.kind === "videoinput").map(device => device.deviceId)
                    if (res?.length) {
                        setCameraId(res)
                        setSelectCamera(res.length >= 2 ? res[1] : res[0])
                        setCurrentCameraIndex(res.length >= 2 ? 1 : 0)
                        startScan()
                    }
                })
                .catch((err) => {
                    console.error(`${err.name}: ${err.message}`);
                });
        }

        window.addEventListener("resize", handleResize);

        return () => {
            stopScan()
            window.removeEventListener("resize", handleResize);
        }
    }, [])

    useEffect(() => {
        startScan()
    }, [selectCamera])

    const getNextCamera = () => {
        if (currentCameraIndex === cameraId.length - 1) {
            setSelectCamera(cameraId[0]);
        } else {
            setSelectCamera(cameraId[currentCameraIndex + 1]);
            setCurrentCameraIndex(currentCameraIndex + 1);
        }
        stopScan()
    };

    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    };

    const handleScan = (result) => {
        console.log(result.codeResult.code);
        if (result.codeResult.code) {
            dispatch(setSearchBarcode(result.codeResult.code))
            navigate(`/auth/${user._id}/product`)
            closeModal()
            stopScan()
            Quagga.offProcessed()
            Quagga.stop()
        }
    }
  
    const startScan = () => {
        setIsScanner(true)
        Quagga.init({
            inputStream: {
                name: 'Live',
                type: 'LiveStream',
                target: videoRef.current,
                constraints: {
                    width: windowWidth < 640 ? windowWidth - 34 : 640,
                    height: windowHeight - 250,
                    facingMode: 'environment',
                    deviceId: selectCamera,
                },
            },
            decoder: {
                readers: ['ean_reader'],
            },
      }, (err) => {
        if (err) {
            console.error(err);
            return;
        }
        Quagga.start();
      });
  
        Quagga.onDetected(handleScan);
    }
  
    const stopScan = () => {
        Quagga.stop();
    }
  
    return (
        <div className={`barcode-scanner ${!isScanner ? 'barcode-scanner-none' : ''}`}>
            <div className='barcode-scanner__btn-wrap'>
                <button className='barcode-scanner__btn' onClick={startScan}>Сканувати</button>
                {
                    isScanner &&
                    <button className='barcode-scanner__btn' onClick={stopScan}>Відмінити сканер</button>
                }
                {
                    cameraId?.length >= 2 &&
                    <button className='barcode-scanner__btn' onClick={getNextCamera}>Переключити камеру</button>
                }
            </div>
            <div ref={videoRef} id="barcode-scanner"></div>
        </div>
    );
  }
  
export default BarcodeScanner;

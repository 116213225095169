import './SearchProductView.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import ProductCard from '../../components/ProductCard/ProductCard';
import PaginationItems from '../../components/PaginationItems/PaginationItems';
import { setSearchProductsName } from '../../store/homeSlice';
import { fetchGetData } from '../../helpers/Utils';

function SearchProductView() {
    const selectedLanguage = useSelector(state => state.homeSlice.selectedLanguage);
    const searchProductsName = useSelector(state => state.homeSlice.searchProductsName);
    const shop = useSelector(state => state.homeSlice.shop);
    const [searchProducts, setSearchProducts] = useState([]);
    const [searchNoResults, setSearchNoResults] = useState(false);
    const [selectedPaget, setSelectedPaget] = useState('0');
    const [quantityAllProducts, setQuantityAllProducts] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
            
       return () => dispatch(setSearchProductsName(''));
    }, [])

    useEffect(() => {
        if (searchProductsName?.length) {
            fetchGetData(`${process.env.REACT_APP_BASE_URL}/products/${shop._id}/all?name=${searchProductsName}`)
                .then(res => {
                    if (res.success && res.data) {
                        if (res.data?.length) {
                            setSearchProducts([...res.data])
                            setSearchNoResults(false)
                            setQuantityAllProducts(res.count % 10 == 0 ? res.count / 10 : Math.ceil(res.count / 10))
                        } else {
                            setSearchNoResults(true)
                        }
                    } else {
                        console.log('GET SearchProduct:', res)
                    }
                })
        }
    }, [searchProductsName])

    return (
        <div className='search-product'>
            <div className='search-product--wrap container'>
                <div className='search-product__cards'>
                    {
                        !!searchProducts?.length && !searchNoResults ? searchProducts.map(el => (
                                <ProductCard product={el} key={el._id}/>    
                                )) : <h3  className='search-product__error-text'>
                                        {selectedLanguage?.searchPage?.searchErrorText}
                                     </h3>
                    }
                </div>
                {
                    !!searchProducts?.length && 
                        <PaginationItems selectedPaget={selectedPaget} setSelectedPaget={setSelectedPaget} pageRangeDisplayed={5} itemsPerPage={10} quantityAllProducts={quantityAllProducts}/>
                }
            </div>
        </div>
    );
}

export default SearchProductView;
import './HeaderLanding.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { setSelectedLanguage, setUserLanguage } from '../../store/userSlice';
import { languageUser } from '../../languageUser';
import logoLanding from '../../assets/images/logoLanding.png';
import MobileMenuLanding from '../MobileMenuLanding/MobileMenuLanding';

function HeaderLanding() {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const userLanguage = useSelector(state => state.userSlice.userLanguage);
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        let res = (JSON.parse(localStorage.getItem('userLanguage')));
        if (res?.length) {
            dispatch(setSelectedLanguage(languageUser[res]));
            dispatch(setUserLanguage(res));
        } else {
            dispatch(setSelectedLanguage(languageUser['ENG']));
            dispatch(setUserLanguage('ENG'));
            localStorage.setItem('userLanguage', JSON.stringify('ENG'));
        }

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, [])

    useEffect(() => {
        if (!isOpenMenu) {
            document.body.style.overflow = "unset"
        } else {
            document.body.style.overflow = "hidden" 
        }
    }, [isOpenMenu])

    const handleResize = () => {
        if (window.innerWidth > 768) {
            setIsOpenMenu(false)
        }
    };
  
    const handleOpenMenu = () => {
        setIsOpenMenu(!isOpenMenu)
    };

    const handleChangeLanguage = (str) => {
        dispatch(setUserLanguage(str));
        dispatch(setSelectedLanguage(languageUser[str]));
        localStorage.setItem('userLanguage', JSON.stringify(str));
    }

    return (
        <div className='header-landing-wrap'>
            <div className='header-landing container'>
                <NavLink to={'/'}><img className='header-landing__logo' src={logoLanding}/></NavLink>

                <MobileMenuLanding handleOpenMenu={handleOpenMenu} isOpenMenu={isOpenMenu} />

                <div className={`header-landing__drop-menu ${isOpenMenu ? 'header-landing__drop-menu--active' : ''}`}>
                    <div className='header-landing__drop-menu-link-wrap'>
                        <NavLink className='header-landing__drop-menu-link' onClick={handleOpenMenu} to={'/'}>{selectedLanguage?.headerLanding?.headerLandingMenuLink1}</NavLink>
                        <NavLink className='header-landing__drop-menu-link' onClick={handleOpenMenu} to={'/help'}>{selectedLanguage?.headerLanding?.headerLandingMenuLink2}</NavLink>
                        <NavLink className='header-landing__drop-menu-link' onClick={handleOpenMenu} to={'/blogs'}>{selectedLanguage?.headerLanding?.headerLandingMenuLink3}</NavLink>
                        <NavLink className='header-landing__drop-menu-link' onClick={handleOpenMenu} to={'/pricing'}>{selectedLanguage?.headerLanding?.headerLandingMenuLink4}</NavLink>
                        {/* <NavLink className='header-landing__drop-menu-link' onClick={handleOpenMenu} to={'/partners'}>{selectedLanguage?.headerLanding?.headerLandingMenuLink5}</NavLink> */}

                        <a className='header-landing__drop-menu-link' href='https://auth.welcomenocode.com/?app=checkinshop'>{selectedLanguage?.headerLanding?.headerLandingLoginBtn}</a>

                        <div className='header-landing__language-selection-wrap'>
                            <div className='header-landing__language-selection header-landing__language-wrap'>
                                {userLanguage}
                            </div>
                            <ul className='header-landing__language-selection-drop'>
                                <li onClick={() => handleChangeLanguage('UA')} className='header-landing__language-selection-drop-item'>UA</li>
                                <li onClick={() => handleChangeLanguage('ENG')} className='header-landing__language-selection-drop-item'>ENG</li>
                            </ul>
                        </div>

                        <div className='header-landing__drop-menu-contact-title'>{selectedLanguage?.headerLanding?.headerLandingContactTitle}</div>
                        <a className='header-landing__drop-menu-link' href="tel:+380631893988">+380631893988</a>
                        <a className='header-landing__drop-menu-link-tel' href="help@welcomenocode.com">help@welcomenocode.com</a>
                    </div>
                </div>
                    
                <div className='header-landing__menu-wrap'>
                    <NavLink to={'/'}>{selectedLanguage?.headerLanding?.headerLandingMenuLink1}</NavLink>
                    <NavLink to={'/help'}>{selectedLanguage?.headerLanding?.headerLandingMenuLink2}</NavLink>
                    <NavLink to={'/blogs'}>{selectedLanguage?.headerLanding?.headerLandingMenuLink3}</NavLink>
                    <NavLink to={'/pricing'}>{selectedLanguage?.headerLanding?.headerLandingMenuLink4}</NavLink>
                    {/* <NavLink to={'/partners'}>{selectedLanguage?.headerLanding?.headerLandingMenuLink5}</NavLink> */}
                </div>

                <div className='header-landing__btn-wrap'>
                    <a className='header-landing__try-now-btn' href='https://auth.welcomenocode.com/?app=checkinshop'>{selectedLanguage?.headerLanding?.headerLandingTryNowBtn}</a>
                    <a className='header-landing__login-btn' href='https://auth.welcomenocode.com/?app=checkinshop'>{selectedLanguage?.headerLanding?.headerLandingLoginBtn}</a>

                    <div className='header-landing__language-selection-wrap'>
                        <div className='header-landing__language-selection'>
                            {userLanguage}
                        </div>
                        <ul className='header-landing__language-selection-drop'>
                            <li onClick={() => handleChangeLanguage('UA')} className='header-landing__language-selection-drop-item'>UA</li>
                            <li onClick={() => handleChangeLanguage('ENG')} className='header-landing__language-selection-drop-item'>ENG</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeaderLanding;
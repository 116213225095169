import './LoginBtn.css';
import { useDispatch, useSelector } from 'react-redux';
import ModalWindow from '../ModalWindow/ModalWindow';
import { useState } from 'react';
import { TextField } from '@mui/material';
import { fetchGetData, fetchRequest, showMessage, validTelephon } from '../../helpers/Utils';
import { useNavigate } from 'react-router-dom';
import { setBuyer, setChatBuyer } from '../../store/homeSlice';

function LoginBtn() {
    const selectedLanguage = useSelector(state => state.homeSlice.selectedLanguage);
    const buyer = useSelector(state => state.homeSlice.buyer);
    const shop = useSelector(state => state.homeSlice.shop);
    const [isLoginModal, setIsLoginModal] = useState(false);
    const [isClient, setIsClient] = useState(false);
    const [isNewClient, setIsNewClient] = useState(false);
    const [isErrorPassword, setIsErrorPassword] = useState(false);
    const [isNewClientPassword, setIsNewClientPassword] = useState(false);
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogin = (boolean) => {
        if (boolean) {
            if (phone?.length && !password?.length && !isNewClient && !isClient) {
                // перевірка по номеру чи є клієнт в базі
                fetchGetData(`${process.env.REACT_APP_BASE_URL}/clients/${shop._id}/phone?phone=${phone}`)
                    .then(res => {
                        console.log(res)
                        if (res.success && res.data && res.data.password.length) {
                            //клієнт є в базі
                            setIsClient(true)
                            dispatch(setBuyer(res.data));
                            
                        } else if (res.success && res.data && !res.password?.length) {
                            console.log('GET ReadPurchases:', res)
                            //клієнта є в  базі без пароля
                            
                            dispatch(setBuyer(res.data));
                            setName(res.data.full_name)
                            setIsNewClient(true)
                            setIsNewClientPassword(true)
                            //відправити запит на створення клієнта 
                            
                        } else {
                            //клієнта немає в  базі
                            setIsNewClient(true)
                        }
                    })
            } else if (phone?.length && password?.length && !isNewClient && !isNewClientPassword) {
                // перевірка пароля і переадресація на сторінку клієнта
                if (buyer.password === password) {
                    // пароль вірний
                    navigate(`/${shop.name}/client/${buyer.full_name.split(' ').join('')}`)
                    handleCloseModal()
                } else {
                    // пароль не вірний
                    setIsErrorPassword(true)
                }
                        
                    
            } else if (phone?.length && password?.length && name?.length && !isClient && !isNewClientPassword && isNewClient) {
                //запит на створення клієнта 
                console.log('/запит на створення клієнта ')
                let data = {
                    full_name: name,
                    phone: phone,
                    password: password,
                    shop_id: shop._id
                }
                // https://ud1wvrqeg3.execute-api.eu-central-1.amazonaws.com/production/api
                fetchRequest('POST', `${process.env.REACT_APP_BASE_URL}/clients/`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
                    .then(res => {
                        console.log(res)
                        if (res.success && res.data) {
                            handleCreateChat(res.data)
                            // navigate(`/${shop.name}/client/${res.data.full_name.split(' ').join('')}`)
                            // dispatch(setBuyer(res.data));
                            // showMessage('success', selectedLanguage?.readPurchasesView?.readPurchasesMessageUpdate)
                        } else {
                            console.log('PUT ReadPurchasesView:', res)
                        }
                    })

                handleCloseModal()

            } else if (phone?.length && password?.length && name?.length && isNewClientPassword) {
                console.log('/запит на оновлення клієнта ')
                //запит на оновлення клієнта 
                let data = {
                    ...buyer,
                    password: password
                }
                https://ud1wvrqeg3.execute-api.eu-central-1.amazonaws.com/production/api
                fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/clients/${buyer._id}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
                    .then(res => {
                        console.log(res)
                        if (res.success && res.data) {
                            handleCreateChat(data)
                            // navigate(`/${shop.name}/client/${buyer.full_name}`)
                            // dispatch(setBuyer({...buyer, password: password}));
                            // showMessage('success', selectedLanguage?.readPurchasesView?.readPurchasesMessageUpdate)
                        } else {
                            console.log('PUT ReadPurchasesView:', res)
                        }
                    })  

                handleCloseModal()
            }
        } else {
            handleCloseModal()
        }
    }
    
    const handleCloseModal = () => {
        setIsLoginModal(false)
        setIsClient(false)
        setIsErrorPassword(false)
        setIsNewClient(false)
        setIsNewClientPassword(false)
        setPhone('')
        setPassword('')
        setName('')
    }

    const handleCreateChat = (client) => {
        console.log('/запит на чату ')
        let data = {
            client_id: client._id,
            shop_id: shop._id
        }
        // https://ud1wvrqeg3.execute-api.eu-central-1.amazonaws.com/production/api
        fetchRequest('POST', `https://ud1wvrqeg3.execute-api.eu-central-1.amazonaws.com/production/api/chats`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
            .then(res => {
                console.log('production/api/chats', res)
                if (res.success && res.data) {
                    navigate(`/${shop.name}/client/${client.full_name.split(' ').join('')}`)
                    dispatch(setChatBuyer(res.data));
                    dispatch(setBuyer(client));
                    showMessage('success', selectedLanguage?.readPurchasesView?.readPurchasesMessageUpdate)
                } else {
                    console.log('PUT ReadPurchasesView:', res)
                }
        })
    }

    const handleClickLogin = () => {
        if (buyer.password?.length) {
            navigate(`/${shop.name}/client/${buyer.full_name.split(' ').join('')}`)
        } else {
            setIsLoginModal(!isLoginModal)
        }
    }

    // const handleCheckMessage = () => {
    //     setInterval(() => {

    //     }, 40000);
    // }
  
    return (
        <div className="login-btn" onClick={handleClickLogin}>
            {
                // isLoginModal && <ModalWindow title={ isClient ? 'Введіть пароль' : 'Введіть номер телефону'} handleClick={handleLogin} leftBtn='Відмінити' rightBtn='Підтвердити' >
                isLoginModal && <ModalWindow title={ (!isClient && !isNewClient) ? 'Введіть номер телефону' : isClient ? 'Введіть пароль' : isNewClient ? 'Введіть дані для реєстрації' : ''} handleClick={handleLogin} leftBtn='Відмінити' rightBtn='Підтвердити' >
                    {
                        isClient && 
                            <>
                                <TextField className='login-btn__login-modal-input' value={password} onChange={(e) => setPassword(e.target.value)} label='Пароль' variant="outlined" />
                                {
                                    isErrorPassword && <div className='login-btn__login-modal-error'>Введений пароль не вірний!!!</div>
                                }
                            </>
                    }
                    {
                        !isClient && !isNewClient &&
                            <TextField className='login-btn__login-modal-input' value={phone} onChange={(e) => validTelephon(e.target.value, setPhone)} label='Телефон' variant="outlined" />
                    }
                    {
                        isNewClient && 
                            <div className='login-btn__login-modal-input-wrap'>
                                <TextField className='login-btn__login-modal-input' value={name} onChange={(e) => setName(e.target.value)} label="Прізвище і і'мя" variant="outlined" />
                                <TextField className='login-btn__login-modal-input' value={phone} onChange={(e) => validTelephon(e.target.value, setPhone)} label='Телефон' variant="outlined" />
                                <TextField className='login-btn__login-modal-input' value={password} onChange={(e) => setPassword(e.target.value)} label='Пароль' variant="outlined" />
                            </div>
                    }
                </ModalWindow>
            }

            <svg className="login-btn__img" fill={shop?.colorSettings?.colorHeaderText ? shop?.colorSettings?.colorHeaderText : '#ffffff'} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 512 512" xmlSpace="preserve">
                <g>
                    <g>
                        <path d="M327.287,246.852l-74.931-76.595c-4.941-5.06-13.039-5.146-18.099-0.205c-5.06,4.941-5.146,13.056-0.205,18.099
                            l53.854,55.057H12.8C5.734,243.2,0,248.934,0,256c0,7.066,5.734,12.8,12.8,12.8h275.098l-53.845,55.057
                            c-4.941,5.043-4.855,13.158,0.205,18.099c5.06,4.941,13.158,4.855,18.099-0.205l75.128-76.8
                            C332.424,259.908,332.339,251.793,327.287,246.852z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M499.2,0H166.4c-7.066,0-12.8,5.734-12.8,12.8V192h25.6V25.6h307.2v460.8H179.2V320h-25.6v179.2
                            c0,7.066,5.734,12.8,12.8,12.8h332.8c7.066,0,12.8-5.734,12.8-12.8V12.8C512,5.734,506.266,0,499.2,0z"/>
                    </g>
                </g>
            </svg>
            <p className="login-btn__text">{selectedLanguage?.loginBtn?.loginBtn}</p>
        </div>
    );
}

export default LoginBtn;
import './SelectCard.css';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ProductCard from '../ProductCard/ProductCard';
import photo1 from '../../assets/images/photo1.webp';

function SelectCard({ selectExample, setSelectExample}) {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const shop = useSelector(state => state.userSlice.shop);
    const productExample = {_id:"63c06cb57a8dce398ed4b66a", shop_id: "", category_id:"63c06abdd97bc36c0827b841",name:"EQUILIBRI",price:"$649",new_price:'$550',images:[],images_URL:[photo1],details:"Nightgown with thin straps, which are adjustable in length, the length of the shirt is up to the knees, the back is open, the fabric is silk, Armani",colors:["Білий","Чорний"],sizes:["S","M","L"],date:"1673555125763",__v:"0"}

    useEffect(() => {
        return () => {
            if (shop.cardType?.length) {
                setSelectExample(shop.cardType)
            }
        } 
    }, [])

    return (
        <div className="select-card">
            {
                !!shop._id?.length ?
                    <div>
                        <div className="select-card__title">{selectedLanguage?.selectCard?.selectCardTitle}</div>
                        <div className="select-card__result-wrap">
                            <div className="select-card__result-select">
                                <ProductCard product={productExample} cardType={'3'} isSelectCardType={true}/>
                                <div className='select-card__result-btn-wrap'>
                                    <button className='select-card__result-btn' onClick={() => setSelectExample('3')}>{selectedLanguage?.selectCard?.selectCardBtn}</button>
                                    <div className={`select-card__result-select-card ${selectExample === '3' ? '' : 'select-card__result-select-card-none'}`}> 
                                        <svg className='select-card__result-select-card-img' viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M10.0001 0.166748C4.84808 0.166748 0.666748 4.34808 0.666748 9.50008C0.666748 14.6521 4.84808 18.8334 10.0001 18.8334C15.1521 18.8334 19.3334 14.6521 19.3334 9.50008C19.3334 4.34808 15.1521 0.166748 10.0001 0.166748ZM7.47075 13.5041L4.12008 10.1534C3.94531 9.97904 3.8471 9.7423 3.8471 9.49541C3.8471 9.24853 3.94531 9.01179 4.12008 8.83742C4.48408 8.47342 5.07208 8.47342 5.43608 8.83742L8.13341 11.5254L14.5547 5.10408C14.9187 4.74008 15.5067 4.74008 15.8707 5.10408C16.2347 5.46808 16.2347 6.05608 15.8707 6.42008L8.78675 13.5041C8.43208 13.8681 7.83475 13.8681 7.47075 13.5041Z" fill="#4C6FFF"/>
                                        </svg>    
                                        <p>{selectedLanguage?.selectCard?.selectCardSelected}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="select-card__result-select">
                                <ProductCard product={productExample} cardType={'1'} isSelectCardType={true}/>
                                <div className='select-card__result-btn-wrap'>
                                    <button className='select-card__result-btn' onClick={() => setSelectExample('1')}>{selectedLanguage?.selectCard?.selectCardBtn}</button>
                                    <div className={`select-card__result-select-card ${selectExample === '1' ? '' : 'select-card__result-select-card-none'}`}> 
                                        <svg className='select-card__result-select-card-img' viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M10.0001 0.166748C4.84808 0.166748 0.666748 4.34808 0.666748 9.50008C0.666748 14.6521 4.84808 18.8334 10.0001 18.8334C15.1521 18.8334 19.3334 14.6521 19.3334 9.50008C19.3334 4.34808 15.1521 0.166748 10.0001 0.166748ZM7.47075 13.5041L4.12008 10.1534C3.94531 9.97904 3.8471 9.7423 3.8471 9.49541C3.8471 9.24853 3.94531 9.01179 4.12008 8.83742C4.48408 8.47342 5.07208 8.47342 5.43608 8.83742L8.13341 11.5254L14.5547 5.10408C14.9187 4.74008 15.5067 4.74008 15.8707 5.10408C16.2347 5.46808 16.2347 6.05608 15.8707 6.42008L8.78675 13.5041C8.43208 13.8681 7.83475 13.8681 7.47075 13.5041Z" fill="#4C6FFF"/>
                                        </svg>    
                                        <p>{selectedLanguage?.selectCard?.selectCardSelected}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="select-card__result-select">
                                <ProductCard product={productExample} cardType={'2'} isSelectCardType={true}/>
                                <div className='select-card__result-btn-wrap'>
                                    <button className='select-card__result-btn' onClick={() => setSelectExample('2')}>{selectedLanguage?.selectCard?.selectCardBtn}</button>
                                    <div className={`select-card__result-select-card ${selectExample === '2' ? '' : 'select-card__result-select-card-none'}`}> 
                                        <svg className='select-card__result-select-card-img' viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M10.0001 0.166748C4.84808 0.166748 0.666748 4.34808 0.666748 9.50008C0.666748 14.6521 4.84808 18.8334 10.0001 18.8334C15.1521 18.8334 19.3334 14.6521 19.3334 9.50008C19.3334 4.34808 15.1521 0.166748 10.0001 0.166748ZM7.47075 13.5041L4.12008 10.1534C3.94531 9.97904 3.8471 9.7423 3.8471 9.49541C3.8471 9.24853 3.94531 9.01179 4.12008 8.83742C4.48408 8.47342 5.07208 8.47342 5.43608 8.83742L8.13341 11.5254L14.5547 5.10408C14.9187 4.74008 15.5067 4.74008 15.8707 5.10408C16.2347 5.46808 16.2347 6.05608 15.8707 6.42008L8.78675 13.5041C8.43208 13.8681 7.83475 13.8681 7.47075 13.5041Z" fill="#4C6FFF"/>
                                        </svg>    
                                        <p>{selectedLanguage?.selectCard?.selectCardSelected}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : 
                    <div className="select-card__error-text">{selectedLanguage?.selectCard?.selectCardError}</div>
            }
        </div>
    );
}

export default SelectCard;
import './UserCategoriesView.css';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCategories, setRemoveCategory, setRemoveSubCategory, setSubCategories, setUpdataCategory } from '../../store/userSlice';
import editIcon from '../../assets/images/editIcon.svg';
import editIconHover from '../../assets/images/editIconHover.svg';
import deleteImg from '../../assets/images/deleteImg.svg';
import deleteImgHover from '../../assets/images/deleteImgHover.svg';
import upload from '../../assets/images/upload.svg';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import noPhotos from '../../assets/images/noPhotos.svg';
import { fetchRequest, showMessage } from '../../helpers/Utils';
import plus2 from './../../assets/images/plus2.svg';
import plusImg from './../../assets/images/plusImg.svg';
import eye from './../../assets/images/eye.svg';
import eyeHover from './../../assets/images/eyeHover.svg';
import eyeHide from './../../assets/images/eyeHide.svg';
import eyeHideHover from './../../assets/images/eyeHideHover.svg';
import category from './../../assets/images/category.svg';
import mixpanel from 'mixpanel-browser';
import { TextField } from '@mui/material';
mixpanel.init('cbc9e4ee0d40873b5a2e3b5e39eedd33', { debug: true }); 
mixpanel.track('Visit categories page');

function UserCategoriesView() {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const isManagerRole = useSelector(state => state.userSlice.isManagerRole);
    const user = useSelector(state => state.userSlice.user);
    const shop = useSelector(state => state.userSlice.shop);
    const categories = useSelector(state => state.userSlice.categories);
    const isCleanInput = useSelector(state => state.userSlice.isCleanInput);
    const [isModalDelCategory, setIsModalDelCategory] = useState(false);
    const [isModalDelSubCategory, setIsModalDelSubCategory] = useState(false);
    const [isModalEditCategory, setIsModalEditCategory] = useState(false);
    const [isModalEditSubCategory, setIsModalEditSubCategory] = useState(false);
    const [isModalCreateSubCategory, setIsModalCreateSubCategory] = useState(false);
    const [createSubCategoryParentId, setCreateSubCategoryParentId] = useState('');
    const [deleteId, setDeleteId] = useState({});
    const [deleteCategory, setDeleteCategory] = useState({});
    const [editCategory, setEditCategory] = useState({});
    const [editSubCategory, setEditSubCategory] = useState({});
    const [name, setName] = useState('');
    const [image_url, setImage_url] = useState({});
    const [subCategory, setSubCategory] = useState('');
    const [delImgCategory, setDelImgCategory] = useState(false);
    const [isModalHideCategoriest, setIsModalHideCategoriest] = useState(false);
    const [hideCategoriest, setHideCategoriest] = useState({});
    const dispatch = useDispatch();
    const token = localStorage.getItem('token');

    const handleCreateSubCategory = (boolean) => {
        mixpanel.track('Create new subcategory');

        if (boolean && subCategory.length) {
            let data = {
                id: new Date().toString(),
                name: subCategory,
                image_url: null,
                parent_id: createSubCategoryParentId,
                // hide: false,
                shop_id: shop._id,
            }
    
            fetchRequest('POST', `${process.env.REACT_APP_BASE_URL}/categories/?token=${token}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
                .then(res => {
                    if (res.success && res.data) {
                        dispatch(setSubCategories(res.data))
                        showMessage('success', selectedLanguage?.userCategoriesView?.userCategoriesShowSuccessCreateSubCat)
                    } else {
                        console.log('POST UserCategories:', res)
                    }
                })
    
                // dispatch(setIsCleanInput(!isCleanInput))
            }
            
        setSubCategory('')
        setIsModalCreateSubCategory(false)
    }

    const handleDeleteCategories = (obj) => {
        mixpanel.track('Delete category');
        setIsModalDelCategory(true)
        setDeleteCategory(obj)
    }

    const handleDeleteSubCategories = (categoryId, subCategoryId) => {
        mixpanel.track('Delete subcategory');
        setIsModalDelSubCategory(true)
        setDeleteId({
            categoryId: categoryId,
            subCategoryId: subCategoryId
        })
    }

    const handleEditSubCategories = (obj) => {
        mixpanel.track('Edit subcategory');
        setIsModalEditSubCategory(true)
        setEditSubCategory(obj)
        setSubCategory(obj.name)
    }
    
    const handleIsCreateSubCategory = (id) => {
        setIsModalCreateSubCategory(true)
        setCreateSubCategoryParentId(id)
    }

    const handleEditCategories = (obj) => {
        mixpanel.track('Edit category');
        setIsModalEditCategory(true)
        setEditCategory(obj)
        setName(obj.name)
    }

    const handleHideCategories = (obj) => {
        mixpanel.track('Hide category');
        setIsModalHideCategoriest(true)
        setHideCategoriest(obj)
    }

    const handleIsDeleteCategory = (boolean) => {
        if (boolean) {
            const data = {
                token: token,
            }

            deleteCategory?.sub_categories?.map(el => {
                fetchRequest('DELETE', `${process.env.REACT_APP_BASE_URL}/categories/${el._id}`, data)
                    .then(res => {
                        if (res.success && res.data) {
                            console.log('del', res)

                        } else {
                            console.log('DELETE UserCategories', res)
                        }
                    })
            })

            fetchRequest('DELETE', `${process.env.REACT_APP_BASE_URL}/categories/${deleteCategory._id}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
                .then(res => {
                    if (res.success && res.data) {
                        dispatch(setRemoveCategory(deleteCategory._id))
                        showMessage('success', selectedLanguage?.userCategoriesView?.userCategoriesShowSuccessDelCat)
                    } else {
                        console.log('DELETE UserCategories', res)
                    }
                })
        }

        setIsModalDelCategory(false)
        setDeleteCategory({})
    }

    const handleIsDeleteSubCategory = (boolean) => {
        if (boolean) {
            const data = {
                token: token,
            }

            fetchRequest('DELETE', `${process.env.REACT_APP_BASE_URL}/categories/${deleteId.subCategoryId}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
                .then(res => {
                    if (res.success && res.data) {
                        dispatch(setRemoveSubCategory(deleteId))
                        showMessage('success', selectedLanguage?.userCategoriesView?.userCategoriesShowSuccessDelSubCat)
                    } else {
                        console.log('DELETE UserCategories', res)
                    }
                })
        }

        setIsModalDelSubCategory(false)
        setDeleteId({})
    }

    const handleIsEditCategory = (boolean) => {
        if (boolean) {
            if (editCategory?.name?.length) {
                if (name.length) {
                    let formData = new FormData();
                    formData.append('file', image_url?.name?.length ? image_url : null);
                    formData.append('imageName', image_url?.name?.length ? image_url?.name : '');
                    formData.append('id', new Date().toString());
                    formData.append('name', name);
                    formData.append('parent_id', 'null');
                    formData.append('shop_id', shop._id);
    
                    if (delImgCategory) {
                        formData.append('image_url', '');
                    }
    
                    fetch(`${process.env.REACT_APP_BASE_URL}/categories/${editCategory._id}?token=${token}`, {
                            method: 'PUT',
                            body: formData,
                        })
                        .then(res => res.json())
                        .then(res => {
                            if (res.success && res.data) {
                                dispatch(setUpdataCategory(res.data))
                                closeModEditCategory()
                                showMessage('success', selectedLanguage?.userCategoriesView?.userCategoriesShowSuccessEditCat)
                                console.log('Edit UserCategories', res)
                            } else {
                                console.log('Edit UserCategories', res)
                            }
                        })
                        .catch((error) => {
                            console.error('Error:', error);
                            showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError)
                        })
                }

            } else {
                if (shop.name) {
                    if (categories.length < 5) {
                        if (name.length > 0) {
                            let formData = new FormData();
                            formData.append('file', image_url?.name?.length ? image_url : null);
                            formData.append('id', new Date().toString());
                            formData.append('name', name);
                            formData.append('parent_id', 'null');
                            formData.append('shop_id', shop._id);
                            formData.append('imageName', image_url.name);
                            fetch(`${process.env.REACT_APP_BASE_URL}/categories/?token=${token}`, {
                                    method: 'POST',
                                    body: formData
                                })
                                .then(res => res.json())
                                .then(res => {
                                    if (res.success && res.data) {
                                        dispatch(setCategories(res.data))
                                        closeModEditCategory()
                                        showMessage('success', selectedLanguage?.userCategoriesView?.userCategoriesShowSuccessCreateCat)
                                    } else {
                                        console.log('POST UserCategories:', res)
                                    }
                                })
                                .catch((error) => {
                                    console.error('Error:', error);
                                    showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError)
                                })
                        }
                    }
                } 
            }
        } else {
            closeModEditCategory()
        }
    }
    
    const closeModEditCategory = () => {
        setIsModalEditCategory(false)
        setEditCategory({})
        setName('')
        setImage_url({})
        setDelImgCategory(false)
    }

    const handleIsEditSubCategory = (boolean) => {
        if (boolean) {
            if (subCategory?.length > 0) {
                const data = {
                    id: editSubCategory.id,
                    name: subCategory,
                    image_url: editSubCategory.image_url,
                    parent_id: editSubCategory.parent_id,
                    shop_id: editSubCategory.shop_id,
                }

                fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/categories/${editSubCategory._id}?token=${token}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
                    .then(res => {
                        if (res.success && res.data) {
                            dispatch(setUpdataCategory(res.data))
                            showMessage('success', selectedLanguage?.userCategoriesView?.userCategoriesShowSuccessEditSubCat)
                        } else {
                            console.log('PUT UserCategories', res)
                        }
                    })
            }
        }

        setIsModalEditSubCategory(false)
        setEditSubCategory({})
        setSubCategory('')
    }

    const handleIsHideCategoriest = (boolean) => {
        if (boolean) {
            const data = {
                ...hideCategoriest,
                hide: hideCategoriest.hide ? false : true,
            }

            fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/categories/${hideCategoriest._id}?token=${token}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
                .then(res => {
                    if (res.success && res.data) {
                        dispatch(setUpdataCategory(data))
                        showMessage('success', selectedLanguage?.userCategoriesView?.userCategoriesShowSuccessHideCat)
                    } else {
                        console.log('PUT UserCategories', res)
                    }
                })
        }

        setIsModalHideCategoriest(false)
        setHideCategoriest({})
    }

    const handleImageUpload = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file?.name?.length) {
            setImage_url(file) 
        }
    };
  
    return (
        <div className="user-categories">

            {
                isModalDelCategory && <ModalWindow title={selectedLanguage?.userCategoriesView?.userCategoriesModalDelCatTitle} text={selectedLanguage?.userCategoriesView?.userCategoriesModalDelCatText} handleClick={handleIsDeleteCategory} leftBtn={selectedLanguage?.userCategoriesView?.userCategoriesModalDelCatLeftBtn} rightBtn={selectedLanguage?.userCategoriesView?.userCategoriesModalDelCatRightBtn}/>
            }

            {
                isModalDelSubCategory && <ModalWindow title={selectedLanguage?.userCategoriesView?.userCategoriesModalDelSubCatTitle} text={selectedLanguage?.userCategoriesView?.userCategoriesModalDelSubCatText} handleClick={handleIsDeleteSubCategory} leftBtn={selectedLanguage?.userCategoriesView?.userCategoriesModalDelSubCatLeftBtn} rightBtn={selectedLanguage?.userCategoriesView?.userCategoriesModalDelSubCatRightBtn}/>
            }
            
            {
                isModalEditCategory && 
                    <ModalWindow title={editCategory?.name?.length ? selectedLanguage?.userCategoriesView?.userCategoriesModalEditSubCatTitle : 'Створіть категорію'}  text={selectedLanguage?.userCategoriesView?.userCategoriesCreateCatInfo} handleClick={handleIsEditCategory} leftBtn={selectedLanguage?.userCategoriesView?.userCategoriesModalEditCatLeftBtn} rightBtn={selectedLanguage?.userCategoriesView?.userCategoriesModalEditCatRightBtn}>
                        
                        <div
                            className='user-categories__mod-edite-category-create-img-wrap'
                            onDrop={(e) => handleImageUpload(e)}
                            onDragOver={(e) => e.preventDefault()}
                        >   
                            <div className='user-categories__mod-edite-category-create-img-text-wrap'>
                                <img className='user-categories__mod-edite-category-create-img' src={upload} alt='img'/>
                                <p>{selectedLanguage?.creationProduct?.creationProductCreateImgText}</p>
                                <input className="user-categories__mod-edite-category-create-img-link-upload-input" onChange={(e) => setImage_url(e.target.files[0])} type="file" accept="image/*" id="creationProductUserImages"/>
                                <label className='user-categories__mod-edite-category-create-img-link-upload-lable' htmlFor='creationProductUserImages'>{selectedLanguage?.creationProduct?.creationProductCreateImgLink}</label>
                            </div>
                        </div>

                        {
                            !!editCategory?.image_url?.length && !image_url.name?.length && !delImgCategory &&
                                <div className='user-categories__mod-edite-img--wrap'>
                                    <div className='user-categories__mod-edite-img-wrap'>
                                        <button className="user-categories__category-btn" onClick={() => setDelImgCategory(true)} disabled={isManagerRole}><img className="user-categories__category-btn-img" src={deleteImg} alt='img'/></button>
                                        <img className='user-categories__mod-edite-img' src={editCategory?.image_url} alt='img'/>
                                    </div>
                                </div>
                        }
                        {
                            !!image_url.name?.length &&
                                <div className='user-categories__mod-edite-img--wrap'>
                                    <div className='user-categories__mod-edite-img-wrap'>
                                        <button className="user-categories__category-btn" onClick={() => setImage_url({})} disabled={isManagerRole}><img className="user-categories__category-btn-img" src={deleteImg} alt='img'/></button>
                                        <img className='user-categories__mod-edite-img' src={URL.createObjectURL(image_url)} alt='img'/>
                                    </div>
                                </div>
                        }

                        <div className='user-categories__mod-edite-category-lable'>{selectedLanguage?.userCategoriesView?.userCategoriesModalEditCatName}</div>              
                        <TextField className='user-categories__mod-edite-category-input' value={name} onChange={(e) => setName(e.target.value)} label={selectedLanguage?.userCategoriesView?.userCategoriesModalEditCatName} variant="outlined" />
                    </ ModalWindow>
            }

            {
                isModalCreateSubCategory && 
                    <ModalWindow title='Створення підкатегорії' handleClick={handleCreateSubCategory} leftBtn={selectedLanguage?.userCategoriesView?.userCategoriesModalEditCatLeftBtn} rightBtn={selectedLanguage?.userCategoriesView?.userCategoriesModalEditCatRightBtn}>
                        <TextField className='' value={subCategory} onChange={(e) => setSubCategory(e.target.value)} label='Назва категорії' variant="outlined" />
                    </ ModalWindow>
            }
            {
                isModalEditSubCategory && 
                    <ModalWindow title={selectedLanguage?.userCategoriesView?.userCategoriesModalEditSubCatTitle}  text={selectedLanguage?.userCategoriesView?.userCategoriesModalEditSubCatText} handleClick={handleIsEditSubCategory} leftBtn={selectedLanguage?.userCategoriesView?.userCategoriesModalEditCatLeftBtn} rightBtn={selectedLanguage?.userCategoriesView?.userCategoriesModalEditCatRightBtn}>
                        <TextField className='' value={subCategory} onChange={(e) => setSubCategory(e.target.value)} label='Назва категорії' variant="outlined" />
                    </ ModalWindow>
            }

            {
                isModalHideCategoriest && <ModalWindow title={hideCategoriest?.hide ? selectedLanguage?.userCategoriesView?.userCategoriesModalHideCatTitle2 : selectedLanguage?.userCategoriesView?.userCategoriesModalHideCatTitle1}  text={hideCategoriest?.hide ? '' : hideCategoriest.parent_id == 'null' ? selectedLanguage?.userCategoriesView?.userCategoriesModalHideCatText : ''} handleClick={handleIsHideCategoriest} />
            }

            <div className="user-categories--wrpa">
                <div className="user-categories__header">
                    <div className="user-categories__header-count">
                        <img className="user-categories__header-count-img" src={category} alt='img'/>
                        <div className="user-categories__header-count-title">{selectedLanguage?.userCategoriesView?.userCategoriesTitle}&nbsp;{categories.length}/5</div>
                    </div>
                    <button className='user-categories__btn-create' onClick={() => setIsModalEditCategory(true)}>
                        <img className='user-categories__btn-create-img' src={plus2} alt='img' />
                        {selectedLanguage?.userCategoriesView?.userCategoriesCreateCatBtn}
                    </button>
                </div>

                <div className="user-categories__category-wrap">
                    {
                        !!categories?.length && categories.map(el => (
                            <div className="user-categories__category" key={el._id}>
                                <div className="user-categories__category-btn-wrap">
                                    <div className='user-categories__category-btn--wrap'>
                                        <button className="user-categories__category-btn user-categories__category-btn-hide" onClick={() => handleHideCategories(el)} disabled={isManagerRole}>
                                            <img src={el.hide ? eyeHide : eye} alt='img'/>
                                        </button>
                                        <button className="user-categories__category-btn user-categories__category-btn-hide--hover" onClick={() => handleHideCategories(el)} disabled={isManagerRole}>
                                            <img src={el.hide ? eyeHideHover : eyeHover} alt='img'/>
                                        </button>
                                    </div>

                                    <div className='user-categories__category-btn--wrap'>
                                        <button className="user-categories__category-btn user-categories__category-btn-edite" onClick={() => handleEditCategories(el)} disabled={isManagerRole}>
                                            <img src={editIcon} alt='img'/>
                                        </button>
                                        <button className="user-categories__category-btn user-categories__category-btn-edite--hover" onClick={() => handleEditCategories(el)} disabled={isManagerRole}>
                                            <img src={editIconHover} alt='img'/>
                                        </button>
                                    </div>

                                    <div className='user-categories__category-btn--wrap'>
                                        <button className="user-categories__category-btn user-categories__category-btn-delete" onClick={() => handleDeleteCategories(el)} disabled={isManagerRole}>
                                            <img src={deleteImg} alt='img'/>
                                        </button>
                                        <button className="user-categories__category-btn user-categories__category-btn-delete--hover" onClick={() => handleDeleteCategories(el)} disabled={isManagerRole}>
                                            <img src={deleteImgHover} alt='img'/>
                                        </button>
                                    </div>
                                </div>
                                <div className="user-categories__category-img-wrap">
                                    {
                                        el?.image_url ? <img className="user-categories__category-img" src={el?.image_url} alt='img'/>
                                            :  <img className="user-categories__category-img-none" src={noPhotos} alt='img'/>
                                    }
                                </div>
                                <div className="user-categories__category-name">{el?.name}</div>

                                <button className='user-categories__category-btn-create-sub' onClick={() => handleIsCreateSubCategory(el._id)}>
                                    <img className='user-categories__category-btn-create-sub-img' src={plusImg} alt='img'/>
                                    <div className='user-categories__category-btn-create-sub-text'>{selectedLanguage?.userCategoriesView?.userCategoriesCreateSubCatBtn}</div>
                                </button>

                                <div className="user-categories__category-sub-wrap">
                                    {
                                        !!el?.sub_categories?.length && el?.sub_categories?.map(subCategories => (
                                                <div className="user-categories__category-sub" key={subCategories._id}>
                                                    <div className="user-categories__category-sub-name">{subCategories.name}</div>
                                                    <div className='user-categories__category-sub-btn-wrap'>
                                                        <div className='user-categories__category-btn--wrap'>
                                                            <button className="user-categories__category-btn user-categories__category-btn-hide" onClick={() => handleHideCategories(subCategories)} disabled={isManagerRole}>
                                                                <img src={subCategories.hide ? eyeHide : eye} alt='img'/>
                                                            </button>
                                                            <button className="user-categories__category-btn user-categories__category-btn-hide--hover" onClick={() => handleHideCategories(subCategories)} disabled={isManagerRole}>
                                                                <img src={subCategories.hide ? eyeHideHover : eyeHover} alt='img'/>
                                                            </button>
                                                        </div>

                                                        <div className='user-categories__category-btn--wrap'>
                                                            <button className="user-categories__category-btn user-categories__category-btn-edite" onClick={() => handleEditSubCategories(subCategories)} disabled={isManagerRole}>
                                                                <img src={editIcon} alt='img'/>
                                                            </button>
                                                            <button className="user-categories__category-btn user-categories__category-btn-edite--hover" onClick={() => handleEditSubCategories(subCategories)} disabled={isManagerRole}>
                                                                <img src={editIconHover} alt='img'/>
                                                            </button>
                                                        </div>

                                                        <div className='user-categories__category-btn--wrap'>
                                                            <button className="user-categories__category-btn user-categories__category-btn-delete" onClick={() => handleDeleteSubCategories(el._id, subCategories._id)} disabled={isManagerRole}>
                                                                <img src={deleteImg} alt='img'/>
                                                            </button>
                                                            <button className="user-categories__category-btn user-categories__category-btn-delete--hover" onClick={() => handleDeleteSubCategories(el._id, subCategories._id)} disabled={isManagerRole}>
                                                                <img src={deleteImgHover} alt='img'/>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default UserCategoriesView;
import './ProductCardUser.css';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setEditProduct, setRemoveProduct, setUpdataProduct } from '../../store/userSlice';
import ModalWindow from '../ModalWindow/ModalWindow';
import { fetchGetData, fetchRequest, handleShopCurrency, showMessage } from '../../helpers/Utils';
import { Tooltip } from '@mui/material';
import deleteImg from '../../assets/images/deleteImg.svg';
import deleteImgHover from '../../assets/images/deleteImgHover.svg';
import editIcon from './../../assets/images/editIcon.svg';
import editIconHover from './../../assets/images/editIconHover.svg';
import eye from './../../assets/images/eye.svg';
import eyeHover from './../../assets/images/eyeHover.svg';
import eyeHide from './../../assets/images/eyeHide.svg';
import eyeHideHover from './../../assets/images/eyeHideHover.svg';
import noPhotos from '../../assets/images/noPhotos.svg';
import plusImg from '../../assets/images/plusImg.svg';
import productsImg from '../../assets/images/products.svg';
import minus from '../../assets/images/minus.svg';
import ColorResult from '../../components/ColorResult/ColorResult';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";

function ProductCardUser({product}) {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const isManagerRole = useSelector(state => state.userSlice.isManagerRole);
    const user = useSelector(state => state.userSlice.user);
    const shop = useSelector(state => state.userSlice.shop);
    const [isModalDelProduct, setIsModalDelProduct] = useState(false);
    const [isModalHideProduct, setIsModalHideProduct] = useState(false);
    const [isModalHideProductError, setIsModalHideProductError] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [hideProduct, setHideProduct] = useState({});
    const [countProducts, setCountProducts] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem('token')

    const handleEditProduct = (obj) => {
        dispatch(setEditProduct(obj))
        localStorage.setItem('editProduct', JSON.stringify(obj));
        navigate(`/auth/${user._id}/product/create`)
    }

    const handleDeleteProduct = (id) => {
        setIsModalDelProduct(true)
        setDeleteId(id)
    }
    
    const handleEditeCount = (num) => {
        if (product.quantity_in_stock || num == 1) {
            const data = {
                ...product,
                quantity_in_stock: product.quantity_in_stock + num,
                token: token,
            }
            
            fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/products/${product._id}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
                .then(res => {
                    if (res.success && res.data) {
                        dispatch(setUpdataProduct({...product, quantity_in_stock: product.quantity_in_stock + num}))
                        showMessage('success', selectedLanguage?.userProduct?.userProductShowMessageUpdataProduct)
                    } else {
                        console.log('PUT ProductCardUser', res)
                    }
                })
        }
    }

    const handleHideProduct = (obj) => {
        if (obj.hide) {
            fetchGetData(`${process.env.REACT_APP_BASE_URL}/categories/${obj.category_id}?token=${token}`)
                .then(res => {
                    if (res.success && res.data) {
                        if (res.data.hide) {
                            setIsModalHideProductError(true)
                        } else {
                            setIsModalHideProduct(true)
                            setHideProduct(obj)
                        }
                    } else {
                        setIsModalHideProduct(true)
                        setHideProduct(obj)
                        console.log('GET ProductCardUser:', res)
                    }
                })
        } else {
            setIsModalHideProduct(true)
            setHideProduct(obj)
        }

    }
    
    const handleIsHideProductError = (boolean) => {
        setIsModalHideProductError(false)
    }; 

    const handleIsDeleteProduct = (boolean) => {
        if (boolean) {
            const data = {
                token: token,
            }
            
            fetchRequest('DELETE', `${process.env.REACT_APP_BASE_URL}/products/${deleteId}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
                .then(res => {
                    if (res.success && res.data) {
                        dispatch(setRemoveProduct(deleteId))
                        setCountProducts(countProducts - 1)
                        showMessage('success', selectedLanguage?.userProduct?.userProductShowMessageDelProduct)
                    } else {
                        console.log('DELETE ProductCardUser', res)
                    }
                })
        }

        setIsModalDelProduct(false)
        setDeleteId('')
    }

    const handleIsHideProduct = (boolean) => {
        if (boolean) {
            const data = {
                ...hideProduct,
                hide: hideProduct.hide ? false : true,
                token: token,
            }
            
            fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/products/${hideProduct._id}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
                .then(res => {
                    if (res.success && res.data) {
                        dispatch(setRemoveProduct(hideProduct._id))
                        dispatch(setUpdataProduct({...hideProduct, hide: hideProduct.hide ? false : true}))
                        showMessage('success', selectedLanguage?.userProduct?.userProductShowMessageUpdataProduct)
                    } else {
                        console.log('PUT ProductCardUser', res)
                    }
                })
        }

        setIsModalHideProduct(false)
        setHideProduct({})
    }


    return (
        <div className='product-card-user__card' key={product._id}>
            {
                isModalDelProduct && <ModalWindow title={selectedLanguage?.userProduct?.userProductModalDelTitle}  text={selectedLanguage?.userProduct?.userProductModalDelText} handleClick={handleIsDeleteProduct}/>
            }
            {
                isModalHideProduct && <ModalWindow title={hideProduct?.hide ? selectedLanguage?.userProduct?.userProductModalHideTitle2 : selectedLanguage?.userProduct?.userProductModalHideTitle1}  text={hideProduct?.hide ? selectedLanguage?.userProduct?.userProductModalHideText2 : selectedLanguage?.userProduct?.userProductModalHideText1} handleClick={handleIsHideProduct} />
            }
            {
                isModalHideProductError && <ModalWindow title={selectedLanguage?.userProduct?.userProductModalHideErrorTitle}  text={selectedLanguage?.userProduct?.userProductModalHideErrorText} handleClick={handleIsHideProductError} notBtn={true} />
            }
            <div className='product-card-user__card-wrap'>
                <div className='product-card-user__card-btn-wrap'>
                    <button className="product-card-user__card-btn product-card-user__card-btn-hide" onClick={() => handleHideProduct(product)} disabled={isManagerRole}>
                        <img src={product.hide ? eyeHide : eye} alt='img'/>
                    </button>
                    <button className="product-card-user__card-btn product-card-user__card-btn-hide--hover" onClick={() => handleHideProduct(product)} disabled={isManagerRole}>
                        <img src={product.hide ? eyeHideHover : eyeHover} alt='img'/>
                    </button>
                </div>

                <div className='product-card-user__card-btn-wrap'>
                    <button className="product-card-user__card-btn product-card-user__card-btn-edite" onClick={() => handleEditProduct(product)} disabled={isManagerRole}>
                        <img src={editIcon} alt='img'/>
                    </button>
                    <button className="product-card-user__card-btn product-card-user__card-btn-edite--hover" onClick={() => handleEditProduct(product)} disabled={isManagerRole}>
                        <img src={editIconHover} alt='img'/>
                    </button>
                </div>

                <div className='product-card-user__card-btn-wrap'>
                    <button className="product-card-user__card-btn product-card-user__card-btn-delete" onClick={() => handleDeleteProduct(product._id)} disabled={isManagerRole}>
                        <img src={deleteImg} alt='img'/>
                    </button>
                    <button className="product-card-user__card-btn product-card-user__card-btn-delete--hover" onClick={() => handleDeleteProduct(product._id)} disabled={isManagerRole}>
                        <img src={deleteImgHover} alt='img'/>
                    </button>
                </div>

                <Tooltip title={<div>{selectedLanguage?.productCardUser?.productCardUserBtnHover1}</div>} placement={'top'}>
                    <button className="product-card-user__card-btn product-card-user__card-btn-plus" onClick={() => handleEditeCount(1)}><img className="product-card-user__card-btn-img" src={plusImg} alt='img'/></button>
                </Tooltip>
                <Tooltip title={<div>{selectedLanguage?.productCardUser?.productCardUserBtnHover2}</div>} placement={'top'}>
                    <button className="product-card-user__card-btn product-card-user__card-btn-minus" onClick={() => handleEditeCount(-1)}><img className="product-card-user__card-btn-img" src={minus} alt='img'/></button>
                </Tooltip>

                <div className="product-card-user__card-swiper-wrap">
                    <Swiper
                        spaceBetween={90}
                        pagination={{
                            type: "fraction",
                        }}
                        navigation={true}
                        modules={[Navigation, Pagination]}
                        className="mySwiper product-card-user__card-swiper"
                        >
                        {
                            !!product?.images_URL?.length ? product?.images_URL.map((image, i) => <SwiperSlide key={image + i}><img className="product-card-user__card-swiper-img" src={image} alt='img'/></SwiperSlide>)
                            : <img className="product-card-user__card-swiper-img-none" src={noPhotos} alt='img'/> 
                        }
                    </Swiper>
                </div>
                <div className='product-card-user__card-info'>
                    <div className='product-card-user__card-info-name-wrap'>
                        <div className='product-card-user__card-info-name'>{product.name}</div>
                        <div className='product-card-user__card-info-count-wrap'>
                            <img className='product-card-user__card-info-count-img' src={productsImg} alt='img'/>
                            <div>
                                <span className='product-card-user__card-info-count'>{product.quantity_in_stock}&nbsp;</span>
                                <span className='product-card-user__card-info-count'>&nbsp;{selectedLanguage?.productCardUser?.productCardUserCount1} / </span>
                                <span className='product-card-user__card-info-text'>{product.number_of_orders}&nbsp;</span>
                                <span className='product-card-user__card-info-count'>{selectedLanguage?.productCardUser?.productCardUserCount2}</span>
                            </div>
                        </div>
                    </div>

                    <div className='product-card-user__card-info-details'>{product.details?.length ? product.details : selectedLanguage?.productCardUser?.productCardUserDetailsNone}</div>

                    <div className='product-card-user__card-info-price-wrap'>
                        {!!product?.new_price && 
                            <span className='product-card-user__card-info-new-price'>&nbsp;{product.new_price}{handleShopCurrency(shop.currency)}</span>
                        }
                        <span className={`product-card-user__card-info-price ${product.new_price ? 'product-card-user__card-info-price-old' : ''}`}>&nbsp;{product.price}{handleShopCurrency(shop.currency)} {product.new_price ? selectedLanguage?.productCardUser?.productCardUserDiscount : ''}</span>
                    </div>

                    <div className="product-card-user__card-btn-see-wrap">
                        <button className="product-card-user__card-btn-see" onClick={() => navigate(`/auth/${user._id}/product/${product._id}`)}>{selectedLanguage?.productCardUser?.productCardUserBtnSee}</button>
                    </div>

                    <div className='product-card-user__card-info-category-wrap'>
                        <span className='product-card-user__card-info-category-title'>{selectedLanguage?.userProduct?.userProductCardCategory}</span>
                        <span className='product-card-user__card-info-category'>{product.category_name}</span>
                    </div>

                    <div className='product-card-user__card-info-color-wrap'>
                        <span className='product-card-user__card-info-color-title'>{selectedLanguage?.userProduct?.userProductCardColors}</span>
                        {
                            !!product.colors?.length && product.colors.map((el, i) => (
                                <ColorResult color={el} key={el + i}/>
                            ))
                        }
                    </div>

                    <div className='product-card-user__card-info-size-wrap'>
                        <span className='product-card-user__card-info-size-title'>{selectedLanguage?.userProduct?.userProductCardSizes}</span>
                        {
                            !!product.sizes?.length && product.sizes.map((el, i) => (
                                    <span className='product-card-user__card-info-size' key={el + i}>{el}</span>
                                ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductCardUser;
import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './AnimatedText.css';

function AnimatedText() {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);

    useEffect(() => {
        if (selectedLanguage?.animatedText?.animatedText1?.length && selectedLanguage?.animatedText?.animatedText2?.length) {
            // Get a reference to the last interval + 1
            const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);
            // Clear any timeout/interval up to that id
            for (let i = 1; i < interval_id; i++) {
            window.clearInterval(i);
            }

            consoleText([selectedLanguage?.animatedText?.animatedText1, selectedLanguage?.animatedText?.animatedText2], 'animatedTextText',['#ffffff','#ffffff']);
        } 
    }, [selectedLanguage])
    
    const consoleText = (words, id, colors) => {
        if (colors === undefined) colors = ['#ffffff'];
        let visible = true;
        let con = document.getElementById('animatedTextConsole');
        let letterCount = 1;
        let x = 1;
        let waiting = false;
        let target = document.getElementById(id)
        target.setAttribute('style', 'color:' + colors[0])
        window.setInterval(function() {
    
        if (letterCount === 0 && waiting === false) {
            waiting = true;
            target.innerHTML = words[0].substring(0, letterCount)
            window.setTimeout(function() {
            let usedColor = colors.shift();
            colors.push(usedColor);
            let usedWord = words.shift();
            words.push(usedWord);
            x = 1;
            target.setAttribute('style', 'color:' + colors[0])
            letterCount += x;
            waiting = false;
            }, 1000)
        } else if (letterCount === words[0]?.length + 1 && waiting === false) {
            waiting = true;
            window.setTimeout(function() {
            x = -1;
            letterCount += x;
            waiting = false;
            }, 1000)
        } else if (waiting === false) {
            target.innerHTML = words[0].substring(0, letterCount)
            letterCount += x;
        }
        }, 120)
        window.setInterval(function() {
        if (visible === true) {
            con.className = 'animated-text__underscore animated-text__hidden'
            visible = false;
    
        } else {
            con.className = 'animated-text__underscore'
            visible = true;
        }
        }, 400)
    }

    return (
        <div className="animated-text">
            <span id='animatedTextText'></span>
            <div className='animated-text__underscore' id='animatedTextConsole'>&#95;</div>
        </div>
    );
}

export default memo(AnimatedText);
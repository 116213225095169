import './ReadPurchasesView.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import editIcon from './../../assets/images/editIcon.svg';
import deleteImg from './../../assets/images/deleteImg.svg';
import cartUser2 from './../../assets/images/cartUser2.svg';
import arrow2 from './../../assets/images/arrow2.svg';
import close2 from './../../assets/images/close2.svg';
import phone1 from './../../assets/images/phone1.svg';
import letter from './../../assets/images/letter.svg';
import home from './../../assets/images/home.svg';
import post from './../../assets/images/post.svg';
import payment from './../../assets/images/payment.svg';
import dolar from './../../assets/images/dolar.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setPurchasesLength, setSeenPurchases, setShop } from '../../store/userSlice';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import PurchasesEditeArr from '../../components/PurchasesEditeArr/PurchasesEditeArr';
import InputNumber from '../../components/InputNumber/InputNumber';
import { fetchGetData, fetchRequest, handleShopCurrency, setProfitYear, setUserPurchasesLength, showMessage } from '../../helpers/Utils';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import ProductCardUser from '../../components/ProductCardUser/ProductCardUser';
import ColorResult from '../../components/ColorResult/ColorResult';

function ReadPurchasesView () {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const isManagerRole = useSelector(state => state.userSlice.isManagerRole);
    const user = useSelector(state => state.userSlice.user);
    const shop = useSelector(state => state.userSlice.shop);
    let { idPurchases } = useParams();
    const [status, setStatus] = useState('');
    const [purchaseContent, setPurchaseContent] = useState({});
    const [orderedProducts, setOrderedProducts] = useState([]);
    const [editProduct, setEditProduct] = useState({});
    const [newSize, setNewSize] = useState([]);
    const [newColorsArr, setNewColorsArr] = useState([]);
    const [newCount, setNewCount] = useState('');
    const [totalPrice, setTotalPrice] = useState('');
    const [isModalDelProduct, setIsModalDelProduct] = useState(false);
    const [isModalEditProductCount, setIsModalEditProductCount] = useState(false);
    const [isModalEditProductSize, setIsModalEditProductSize] = useState(false);
    const [isModalEditProductColors, setIsModalEditProductColors] = useState(false);
    const [isBannedCustomer, setIsBannedCustomer] = useState(false);
    const [newNote, setNewNote] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem('token')
    
    useEffect(() => {
        if (user?._id) {
            fetchGetData(`${process.env.REACT_APP_BASE_URL}/purchases/${idPurchases}?token=${token}`)
                .then(res => {
                    if (res.success && res.data) {
                        setPurchaseContent(res.data)
                        setIsSeen(res.data)
                        setOrderedProducts(res.data.product_ids)

                        handleIsBannedCustomer(res.data.phone)

                        if (res.data?._id) {
                            if (!status?.length) {
                                setStatus(res.data.status)
                            }
                        }
                    } else {
                        console.log('GET ReadPurchasesView:', res)
                    }
                })
        }
    }, [])

    const setIsSeen = (purchaseContent2) => {
        if (!purchaseContent2.isSeen) {
            let data = {
                ...purchaseContent2,
                isSeen: true,
            }
            
            fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/purchases/${purchaseContent2._id}?token=${token}`, data)
                .then(res => {
                    if (res.success && res.data) {
                        dispatch(setSeenPurchases({...purchaseContent2, isSeen: true}));
                        setUserPurchasesLength(shop._id, token, (res) => dispatch(setPurchasesLength(res)))
                    } else {
                        console.log('PUT ReadPurchasesView:', res)
                    }
                })
        }
    }

    useEffect(() => {
        if (orderedProducts?.length) {
            setTotalPrice(handleCalculationPrice(orderedProducts))
        }  
    }, [orderedProducts])

    const handleCalculationPrice = (productsArr) => {
        let res = productsArr.reduce((acc, el) => {
            if (!el.removed) {
                acc += (el.new_price == null || el.new_price == 0 ? el.price * el.count : el.new_price * el.count)
            }
            return acc
        }, 0)

        if (purchaseContent?.promoCode?.promo?.length && purchaseContent?.promoCode?.prefix == '%') {
            res = res - ((res * (+purchaseContent?.promoCode?.value)) / 100).toFixed()
        } else if (purchaseContent?.promoCode?.promo?.length && purchaseContent?.promoCode?.prefix === handleShopCurrency(shop.currency)) {
            res = res - (+purchaseContent?.promoCode?.value)
        } 
        return res
    }

    const handleChangeStatus = (str) => {
        setStatus(str)
        let data = {
            ...purchaseContent,
            status: str,
        }

        fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/purchases/${purchaseContent._id}/status?token=${token}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
            .then(res => {
                if (res.success && res.data) {
                    setOrderedProducts([...orderedProducts.map(el => {
                        if (purchaseContent.status === "in_process" && str === "done") {
                            el.quantity_in_stock = el.quantity_in_stock - el.count
                            el.number_of_orders = el.number_of_orders  - el.count
                        } else if (purchaseContent.status === "in_process" && str === "rejected") {
                            el.number_of_orders = el.number_of_orders - el.count
                        } else if (purchaseContent.status === "rejected" && str === "in_process") {
                            el.number_of_orders = el.number_of_orders + el.count
                        } else if (purchaseContent.status === "rejected" && str === "done") {
                            el.quantity_in_stock = el.quantity_in_stock - el.count
                        } else if (purchaseContent.status === "done" && str === "rejected") {
                            el.quantity_in_stock = el.quantity_in_stock + el.count
                        } else if (purchaseContent.status == "done" && str == "in_process") {
                            el.quantity_in_stock = el.quantity_in_stock + el.count
                            el.number_of_orders = el.number_of_orders + el.count
                        }
                        return el
                    })])
                    setPurchaseContent({...purchaseContent, status: str})
                    setProfitYear(str, shop, data, updatesShop)
                    showMessage('success', selectedLanguage?.readPurchasesView?.readPurchasesMessageUpdate)
                } else {
                    console.log('PUT ReadPurchasesView:', res)
                }
            })
    }

    const updatesShop = (data) => {
        dispatch(setShop(data));
    }

    const handleEditProductColors = (obj) => {
        setIsModalEditProductColors(true)
        setEditProduct(obj)
        if (purchaseContent.product_ids?.length) {
            let res = purchaseContent.product_ids.find(ell => ell._id === obj._id)
            if (res.selectColors?.length) {
                setNewColorsArr(res.selectColors)
            }
        }
    } 

    const handleAddNewColor = (str) => {
        if (newColorsArr.includes(str)) {
            setNewColorsArr([...newColorsArr.filter(el => el !== str)])
        } else {
            setNewColorsArr([...newColorsArr, str])
        }
    }

    const handleEditProductSize = (obj) => {
        setIsModalEditProductSize(true)
        setEditProduct(obj)
    } 

    const handleEditProductCount = (obj) => {
        setIsModalEditProductCount(true)
        setEditProduct(obj)
    } 

    const handleDeleteProduct = (obj) => {
        setIsModalDelProduct(true)
        setEditProduct(obj)
    } 
   
    const handleIsEditProductColors = (boolean) => {
        if (boolean) {
            const data = {
                ...purchaseContent,
                product_ids: purchaseContent.product_ids.map(el => {
                    if (el._id == editProduct._id) {
                        el.selectColors = [...newColorsArr]
                    }
                    return el
                }),
                token: token,
            }
            sendEdite(data)
        } 
        setIsModalEditProductColors(false)
        setEditProduct({})
    }

    const handleIsEditProductSize = (boolean) => {
        if (boolean) {
            const data = {
                ...purchaseContent,
                product_ids: purchaseContent.product_ids.map(el => {
                    if (el._id == editProduct._id) {
                        el.selectSizes = [...newSize]
                    }
                    return el
                }),
                token: token,
            }
            sendEdite(data)
        } 
        setIsModalEditProductSize(false)
        setEditProduct({})
    }

    const handleIsEditProductCount = (boolean) => {
        if (boolean) {
            let newArr = orderedProducts.map(el => {
                if (el._id === editProduct._id) {
                    el = {...el, count: +newCount}
                }
                return el
            })
            const data = {
                ...purchaseContent,
                product_ids: purchaseContent.product_ids.map(el => {
                    if (el._id == editProduct._id) {
                        el = {...el, count: +newCount}
                    }
                    return el
                }),
                totalPrice: handleCalculationPrice(newArr),
                token: token,
            }
            
            sendEdite(data, newArr)

        } 
        setIsModalEditProductCount(false)
        setEditProduct({})
    }

    const handleIsDeleteProduct = (boolean) => {
        if (boolean) {
            let newArr = orderedProducts.map(el => {
                if (el._id === editProduct._id) {
                    el = {...el, removed: !el.removed, count: editProduct.count > 0 ? 0 : 1}
                }
                return el
            })
            const data = {
                ...purchaseContent,
                product_ids: purchaseContent.product_ids.map(el => {
                    if (el._id == editProduct._id) {
                        el = {...el, removed: !el.removed, count: editProduct.count > 0 ? 0 : 1}
                    }
                    return el
                }),
                totalPrice: handleCalculationPrice(newArr),
                token: token,
            }

            sendEdite(data, newArr)
        } 
        setIsModalDelProduct(false)
        setEditProduct({})
    }

    const sendEdite = (data, newArr) => {
        fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/purchases/${purchaseContent._id}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
            .then(res => {
                if (res.success && res.data) {
                    setPurchaseContent(data)
                    if (newArr) {
                        setOrderedProducts(newArr)
                    }
                    showMessage('success', selectedLanguage?.readPurchasesView?.readPurchasesMessageUpdate)
                } else {
                    console.log('PUT ReadPurchasesView:', res)
                }
                if (newColorsArr?.length) { 
                    setNewColorsArr([])
                }
            })
    } 

    const selectedOrders = (id) => {
      return purchaseContent?.product_ids?.filter(ell => ell._id == id)[0]
    }
    
    const handleAddNone = () => {
        if (newNote?.length) {
            const data = {
                ...purchaseContent,
                notes: [...purchaseContent.notes, {_id: new Date().toString(), note: newNote}],
                token: token,
            }
            sendEdite(data)
        } 
        setNewNote('')
    }
 
    const handleDeleteNone = (id) => {
        const data = {
            ...purchaseContent,
            notes: [...purchaseContent.notes.filter(el => el._id !== id)],
            token: token,
        }
        sendEdite(data)
    }

    const handleIsBannedCustomer = (tel) => {
        fetchGetData(`${process.env.REACT_APP_BASE_URL}/clients/${shop._id}/banned?phone=${tel}`)
            .then(res => {
                if (res.success && res.data) {
                    setIsBannedCustomer(res.data)
                } else {
                    console.log('GET ReadPurchases:', res)
                }
            })
    }

    return (
        <div className={`read-purchases`}>
            {
                isModalDelProduct && <ModalWindow title={selectedLanguage?.readPurchasesView?.readPurchasesModDelTitle} text={purchaseContent?.product_ids.filter(ell => ell._id == editProduct._id)[0].removed ? selectedLanguage?.readPurchasesView?.readPurchasesModDelText1 : selectedLanguage?.readPurchasesView?.readPurchasesModDelText2} handleClick={handleIsDeleteProduct}/>
            }

            {
                isModalEditProductCount && <ModalWindow title={selectedLanguage?.readPurchasesView?.readPurchasesModEditTitle} text={selectedLanguage?.readPurchasesView?.readPurchasesModEditText} handleClick={handleIsEditProductCount} leftBtn={selectedLanguage?.readPurchasesView?.readPurchasesModEditLeftBtn} rightBtn={selectedLanguage?.readPurchasesView?.readPurchasesModEditRightBtn}>
                                            <InputNumber label={''} id={"readPurchasesnewCount"} name={"readPurchasesnewCount"} value={newCount} setValue={setNewCount} min={'1'}/>
                                          </ ModalWindow>
            }
           
            {
                isModalEditProductSize && <ModalWindow title={selectedLanguage?.readPurchasesView?.readPurchasesModEditTitle} text={selectedLanguage?.readPurchasesView?.readPurchasesModEditText} handleClick={handleIsEditProductSize} leftBtn={selectedLanguage?.readPurchasesView?.readPurchasesModEditLeftBtn} rightBtn={selectedLanguage?.readPurchasesView?.readPurchasesModEditRightBtn}>
                                            <PurchasesEditeArr handleChange={setNewSize} purchaseArr={selectedOrders(editProduct._id)?.selectSizes}/>
                                          </ ModalWindow>
            }
           
            {
                isModalEditProductColors && <ModalWindow title={'Виберіть потрібні кольори'} handleClick={handleIsEditProductColors} leftBtn={selectedLanguage?.readPurchasesView?.readPurchasesModEditLeftBtn} rightBtn={selectedLanguage?.readPurchasesView?.readPurchasesModEditRightBtn}>
                                            <div className='read-purchases__modal-color--wrap'>
                                                <span className='read-purchases__modal-color-title'>{selectedLanguage?.userProduct?.userProductCardColors}</span>
                                                {
                                                    !!editProduct.colors?.length && editProduct.colors.map((el, i) => (
                                                        <div onClick={() => handleAddNewColor(el)} className={`read-purchases__modal-color-wrap ${newColorsArr.includes(el) ? 'read-purchases__modal-color-select-wrap' : ''}`} key={el + i}>
                                                            <ColorResult color={el}/>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                          </ ModalWindow>
            }

            <div className="read-purchases--wrap">

                {
                    isBannedCustomer && <div className="read-purchases__banned">{selectedLanguage?.readPurchasesView?.readPurchasesBanTitle}</div>
                }

                <div className="read-purchases__header">
                    <img className="read-purchases__header-img" src={cartUser2} alt='img'/>
                    <div className="read-purchases__header-title">{selectedLanguage?.readPurchasesView?.readPurchasesTitle}</div>
                </div>
                <div className="read-purchases__header-btn-wrap">
                    <button className='read-purchases__header-btn' onClick={() => navigate(`/auth/${user._id}/purchases`)}>
                        <img className='read-purchases__header-btn-img' src={arrow2} alt='img'/>
                        <p>{selectedLanguage?.readPurchasesView?.readPurchasesBtnBack}</p>
                    </button>
                </div>

                <div className="read-purchases-wrap conteiner">
                    <div className="read-purchases__info">
                        <div className="read-purchases__info--wrap">
                            <div className="read-purchases__info-name">{purchaseContent?.full_name}</div>
                            <div className="read-purchases__info-status-wrap">
                                <div className="read-purchases__info-id">{purchaseContent?._id}</div>
                                <div className={`read-purchases__status read-purchases__status--${purchaseContent.status}`}>{purchaseContent.status === 'in_process' ? selectedLanguage?.selectStatus?.selectStatusOption1 : purchaseContent.status === 'done' ? selectedLanguage?.selectStatus?.selectStatusOption2 : purchaseContent.status === 'rejected' ? selectedLanguage?.selectStatus?.selectStatusOption3 : ''}</div>
                            </div>
                            <div className="read-purchases__info-date">{new Date(purchaseContent?.creation_time).toLocaleString()}</div>
                            {
                                !!purchaseContent?.promoCode?.promo?.length &&
                                    <div className="read-purchases__promo-wrap">{selectedLanguage?.readPurchasesView?.readPurchasesProductsPromoTotalTitle} <b>"{purchaseContent?.promoCode?.promo}"</b> {purchaseContent?.promoCode?.value}{purchaseContent?.promoCode?.prefix}&nbsp; {selectedLanguage?.readPurchasesView?.readPurchasesProductsPromoTotalText} <b>{totalPrice}{handleShopCurrency(shop.currency)}</b></div>
                            }
                            <div className="read-purchases__info-text-wrap">
                                <div className="read-purchases__info-text--wrap">
                                    <div className="read-purchases__info-text-img-wrap">
                                        <img className="read-purchases__info-text-img" src={phone1} alt='img'/>
                                    </div>
                                    <div className="read-purchases__info-text">{purchaseContent?.phone}</div>
                                </div>
                                <div className="read-purchases__info-text--wrap">
                                    <div className="read-purchases__info-text-img-wrap">
                                        <img className="read-purchases__info-text-img" src={letter} alt='img'/>
                                    </div>
                                    <div className="read-purchases__info-text">{purchaseContent?.email}</div>
                                </div>
                                <div className="read-purchases__info-text--wrap">
                                    <div className="read-purchases__info-text-img-wrap">
                                        <img className="read-purchases__info-text-img" src={home} alt='img'/>
                                    </div>
                                    <div className="read-purchases__info-text">{purchaseContent?.delivery_address}</div>
                                </div>
                                <div className="read-purchases__info-text--wrap">
                                    <div className="read-purchases__info-text-img-wrap">
                                        <img className="read-purchases__info-text-img" src={post} alt='img'/>
                                    </div>
                                    <div className="read-purchases__info-text">{purchaseContent?.delivery_method}</div>
                                </div>
                                <div className="read-purchases__info-text--wrap">
                                    <div className="read-purchases__info-text-img-wrap">
                                        <img className="read-purchases__info-text-img" src={payment} alt='img'/>
                                    </div>
                                    <div className="read-purchases__info-text">{purchaseContent?.payment_method}</div>
                                </div>
                                <div className="read-purchases__info-text--wrap">
                                    <div className="read-purchases__info-text-img-wrap">
                                        <img className="read-purchases__info-text-img" src={dolar} alt='img'/>
                                    </div>
                                    <div className="read-purchases__info-text">{totalPrice}{handleShopCurrency(shop.currency)} {selectedLanguage?.readPurchasesView?.readPurchasesProductsTotalTitle}</div>
                                </div>
                            </div>
                        </div>
                        <div className="read-purchases__info-comment-wrap">
                            <div className="read-purchases__info-comment--wrap">
                                <div className="read-purchases__info-comment-title">{selectedLanguage?.readPurchasesView?.readPurchasesInfoCommentTitle}</div>
                                <div className="read-purchases__info-comment">{purchaseContent?.comment}</div>
                            </div>
                            <FormControl style={{width: '330px'}}>
                                <InputLabel id="demo-simple-select-label">{selectedLanguage?.readPurchasesView?.readPurchasesStatusTitle}</InputLabel>
                                <Select
                                    labelId="cartFormstatus"
                                    value={status}
                                    label={selectedLanguage?.readPurchasesView?.readPurchasesStatusTitle}
                                    onChange={(e) => handleChangeStatus(e.target.value)}
                                    className='read-purchases__info-select-status'
                                >
                                    <MenuItem value='in_process'>{selectedLanguage?.readPurchasesView?.readPurchasesStatusOption1}</MenuItem>
                                    <MenuItem value='done'>{selectedLanguage?.readPurchasesView?.readPurchasesStatusOption2}</MenuItem>
                                    <MenuItem value='rejected'>{selectedLanguage?.readPurchasesView?.readPurchasesStatusOption3}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="read-purchases__info-notes-wrap">
                            <div className='creation-shop__create-wrap'>
                                <div className='creation-shop__create-title'>{selectedLanguage?.readPurchasesView?.readPurchasesTextareaLabel}</div>
                                <div className='creation-shop__btn-cancel-sizes-wrap'>
                                    <TextField className='creation-shop__create-input' value={newNote} onChange={(e) => setNewNote(e.target.value)} label={selectedLanguage?.readPurchasesView?.readPurchasesTextareaPlaceholder} variant="outlined" />
                                    <button onClick={handleAddNone} className='creation-shop__create-sizes-btn'>+</button>
                                </div>
                                {
                                    !!purchaseContent?.notes?.length && 
                                        <ul className='creation-shop__create-result-sizes-wrap'>
                                            {
                                                purchaseContent?.notes.map(el => (<li className='creation-shop__create-result-sizes' key={el}>
                                                    <div className='creation-shop__create-result-sizes-text'>{el.note}</div>
                                                    <button onClick={() => handleDeleteNone(el._id)}><img className='creation-shop__create-result-sizes-btn' src={close2} alt='img'/></button> 
                                                </li>))
                                            }
                                        </ul> 
                                }
                            </div>
                        </div>
                    </div>
                    <div className='read-purchases__cards'>
                        {
                            !!orderedProducts?.length && orderedProducts?.length < purchaseContent.product_ids?.length && <div>
                                <div>{selectedLanguage?.readPurchasesView?.readPurchasesCardOrderProductTitle}&nbsp;{purchaseContent.product_ids?.length}</div>
                                <div>{selectedLanguage?.readPurchasesView?.readPurchasesCardDelProductTitle}&nbsp;{purchaseContent.product_ids?.length - orderedProducts?.length}</div>
                            </div>
                        }
                        {
                            !!orderedProducts?.length ? orderedProducts.map(el => (
                                    <div className='read-purchases__card' key={el._id}> 
                                        <div className='read-purchases__count-wrap'>
                                            <div>
                                                <div className='read-purchases__count'>
                                                    <span className='read-purchases__count-title'><b>{selectedLanguage?.readPurchasesView?.readPurchasesCardCountTitle}</b></span>
                                                    {el.count}
                                                    <img className={`read-purchases__btn-edite ${purchaseContent.exucutor?._id?.length && isManagerRole && user._id !== purchaseContent.exucutor?._id || purchaseContent?.product_ids.filter(ell => ell._id == el._id)[0].removed || purchaseContent.status === 'done' ? 'read-purchases__disable' : ''}`} onClick={() => handleEditProductCount(el)} src={editIcon} alt='img'/>
                                                </div>
                                                <div className='read-purchases__count-price'>
                                                    <span className='read-purchases__count-price-title'><b>{selectedLanguage?.readPurchasesView?.readPurchasesCardSumTitle}</b></span>
                                                    {el.new_price == null || el.new_price == 0 ? el.price * el.count : el.new_price * el.count}
                                                    {shop?.currency}
                                                </div>
                                            </div>
                                            <div className='read-purchases__card-btn-delete-wrap'>
                                                <img className={`read-purchases__card-btn-delete ${purchaseContent.exucutor?._id?.length && isManagerRole && user._id !== purchaseContent.exucutor?._id || purchaseContent.status === 'done' ? 'read-purchases__disable' : ''}`} onClick={() => handleDeleteProduct(el)} src={deleteImg} alt='img'/>
                                            </div>
                                        </div>
                                        <div className='read-purchases__card-size-wrap'>
                                            <div className='read-purchases__card-size-title-wrap'>
                                                <span className='read-purchases__card-size-title'><b>{selectedLanguage?.readPurchasesView?.readPurchasesCardSizeTitle}</b></span>
                                                <img className={`read-purchases__btn-edite ${purchaseContent.exucutor?._id?.length && isManagerRole && user._id !== purchaseContent.exucutor?._id || purchaseContent?.product_ids.filter(ell => ell._id == el._id)[0].removed || purchaseContent.status === 'done' ? 'read-purchases__disable' : ''}`} onClick={() => handleEditProductSize(el)} src={editIcon} alt='img'/>
                                            </div>
                                            <span className='read-purchases__card-size-text'>&nbsp;{selectedOrders(el._id).selectSizes?.join(', ')}</span>
                                        </div>
                                        <div className='read-purchases__card-color-wrap'>
                                            <div className='read-purchases__card-color-title-wrap'>
                                                <span className='read-purchases__card-color-title'><b>{selectedLanguage?.readPurchasesView?.readPurchasesCardColorsTitle}</b></span>
                                                <img className={`read-purchases__btn-edite ${purchaseContent.exucutor?._id?.length && isManagerRole && user._id !== purchaseContent.exucutor?._id || purchaseContent?.product_ids.filter(ell => ell._id == el._id)[0].removed || purchaseContent.status === 'done' ? 'read-purchases__disable' : ''}`} onClick={() => handleEditProductColors(el)} src={editIcon} alt='img'/>
                                            </div>
                                            <div className='read-purchases__card-color-text'>{selectedOrders(el._id).selectColors?.map((color, i) => (
                                                <ColorResult color={color} key={color + i}/>
                                            ))}</div>
                                        </div>
                                        <ProductCardUser product={el} />
                                    </div>
                                )) : <div className='read-purchases__product-error'>{selectedLanguage?.readPurchasesView?.readPurchasesCardDelAllProductTitle}&nbsp;{purchaseContent.product_ids?.length}</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReadPurchasesView;
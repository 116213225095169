import './MotivationBlock.css';
import { useSelector } from 'react-redux';

function MotivationBlock() {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);

    return (
        <div className='motivation-block-wrap'>
            <div className='motivation-block container'>
                <div className='motivation-block__title'>{selectedLanguage?.motivationBlock?.landingMotivationTitle}</div>
                <a className='motivation-block__btn' href='https://auth.welcomenocode.com/?app=checkinshop'>{selectedLanguage?.motivationBlock?.landingMotivationBtn}</a>
            </div>
        </div>
    );
}

export default MotivationBlock
import './CustomersView.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import products2 from '../../assets/images/products2.svg';
import letter1 from '../../assets/images/letter1.svg';
import download from '../../assets/images/download.svg';
import PaginationItems from '../../components/PaginationItems/PaginationItems';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import { validEmail, showMessage, fetchGetData, fetchRequest } from '../../helpers/Utils';
import CustomerCard from '../../components/CustomerCard/CustomerCard';
import { setClients, setSelectClients } from '../../store/userSlice';
import Preloader from '../../components/Preloader/Preloader';

function CustomersView() {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const selectClients = useSelector(state => state.userSlice.selectClients);
    const clients = useSelector(state => state.userSlice.clients);
    const shop = useSelector(state => state.userSlice.shop);
    const [isPreloader, setIsPreloader] = useState(true);
    const [isWriteMessage, setIsWriteMessage] = useState(false);
    const [isSelectAllClients, setIsSelectAllClients] = useState(false);
    const [isDownloadCustomers, setIsDownloadCustomers] = useState(false);
    const [selectedPaget, setSelectedPaget] = useState('0');
    const [quantityAllClients, setQuantityAllClients] = useState('');
    const [searchCustomers, setSearchCustomers] = useState('');
    const [messageText, setMessageText] = useState('');
    const [messageTitle, setMessageTitle] = useState('');
    const [allClients, setAllClients] = useState('');
    const [fileUrl, setFileUrl] = useState('');
    const dispatch = useDispatch();
    const token = localStorage.getItem('token')

    useEffect(() => {
        handleGetCustomers()
    }, [])
    
    useEffect(() => {
        if (!searchCustomers?.length) {
            handleGetCustomers()
        }
    }, [searchCustomers])
    
    useEffect(() => {
        handleGetCustomers()
    }, [selectedPaget])
    
    useEffect(() => {
        if (isSelectAllClients) {
            let arr = []
            let res = clients.map(el => {
                if (el?.email?.length && validEmail(el.email)) {
                    arr.push(el.email) 
                }
            })
            dispatch(setSelectClients(arr))
        } else {
            dispatch(setSelectClients([]))
        }
    }, [isSelectAllClients])

    const deleteExcel = () => {
        setFileUrl('')
        setIsDownloadCustomers(false)
    };

    const handleSearchCustomers = () => {
        if (searchCustomers?.length) {
            handleGetCustomers()
        }
    }
    
    const handleSearchCustomersEnter = (e) => {
        if(e.key == 'Enter' && searchCustomers?.length) {
            handleGetCustomers()
        }
    }
        
    const handleSendMessage = (boolean) => {
        if (boolean) {
            if ((messageText?.length || messageTitle?.length) && selectClients?.length) {
                const data = {
                    clients: selectClients,
                    subject: messageTitle,
                    message: messageText,
                }
                
                fetchRequest('POST', `${process.env.REACT_APP_BASE_URL}/clients/email`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
                    .then(res => {
                        if (res.success && res.data) {
                            showMessage('success', selectedLanguage?.customersView?.customersViewShowSuccess1)
                        } else {
                            console.log('POST CustomersView', res)
                        }
                    })
            }
        } 
        setIsWriteMessage(false)
        setIsSelectAllClients(false)
        setSearchCustomers('')
        setMessageText('')
        setMessageTitle('')
        dispatch(setSelectClients([]))
    }

    const handleDownloadCustomers = (boolean) => {
        if (boolean) {
            fetchGetData(`${process.env.REACT_APP_BASE_URL}/clients/${shop._id}/excel`)
                .then(res => {
                    if (res.success && res.data) {
                        setFileUrl(res.data)
                    } else {
                        console.log('GET CustomersView', res)
                    }
                })
            } else {
                deleteExcel()
        }
    }

    const handleGetCustomers = () => {
        setIsPreloader(true)
        fetchGetData(`${process.env.REACT_APP_BASE_URL}/clients/${shop._id}/all?page=${selectedPaget}&search_query=${searchCustomers}&token=${token}`)
            .then(res => {
                if (res.success && res.data) {
                    dispatch(setClients(res.data))
                    setQuantityAllClients(res.count % 10 == 0 ? res.count / 10 : Math.ceil(res.count / 10))
                    setAllClients(res.count)
                } else {
                    console.log('GET CustomersView', res)
                }
                setIsPreloader(false)
            })
    }

    return (
        <div className="customers-view">

            {
                isDownloadCustomers && <ModalWindow title={selectedLanguage?.customersView?.customersViewModalDownloadTitle} handleClick={handleDownloadCustomers} >
                    {!!fileUrl?.length && (
                        <a className='customers-view__btn-download' onClick={deleteExcel} href={fileUrl} download>
                            Download File
                        </a>
                    )}
                </ModalWindow>
            }

            {
                isWriteMessage && <ModalWindow title={selectedLanguage?.customersView?.customersViewModalWriteTitle} handleClick={handleSendMessage} addStyles={{display: 'flex', flexDirection: 'column', height: '100vh', maxWidth: '90%', padding: '30px 15px 60px'}} leftBtn={selectedLanguage?.customersView?.customersViewModalLeftBtn} rightBtn={selectedLanguage?.customersView?.customersViewModaRightBtn} >
                    <div>
                        <div className='customers-view__modal-input-wrap'>
                            <TextField className='customers-view__modal-input' value={messageTitle} onChange={(e) => setMessageTitle(e.target.value)} label={selectedLanguage?.customersView?.customersViewModaInput1} variant="outlined" />
                            <TextField className='customers-view__modal-input' value={messageText} onChange={(e) => setMessageText(e.target.value)} label={selectedLanguage?.customersView?.customersViewModaInput2} rows={3} variant="outlined" multiline />
                        </div>

                        <div className='customers-view__filter'>
                            <TextField className='customers-view__filter-input' value={searchCustomers} onChange={(e) => setSearchCustomers(e.target.value)} onKeyDown={(e) => handleSearchCustomersEnter(e)} label={selectedLanguage?.customersView?.customersViewModaInput3} variant="outlined" />
                            <button className='customers-view__filter-btn' onClick={(e) => handleSearchCustomers(e)}>{selectedLanguage?.customersView?.customersViewBtnSearch}</button>
                        </div>
                        <FormControlLabel control={<Checkbox checked={isSelectAllClients} onClick={(e) => e.stopPropagation} onChange={() => setIsSelectAllClients(!isSelectAllClients)}/>} label={selectedLanguage?.customersView?.customersViewModaInput4} />
                        {
                            !!clients?.length ?
                            <div className='customers-view__result'>
                                {
                                    clients.map(el => (
                                        <CustomerCard customer={el} isWriteCheckbox={isWriteMessage} isSelectAllClients={isSelectAllClients} key={el._id}/>
                                    ))
                                }
                            </div>
                            :
                            <div className='customers-view__error'>{selectedLanguage?.customersView?.customersViewError}</div>
                        }

                        <PaginationItems selectedPaget={selectedPaget} setSelectedPaget={setSelectedPaget} pageRangeDisplayed={5} itemsPerPage={10} quantityAllProducts={quantityAllClients}/>
                        
                    </div>
                </ModalWindow>
            }

            <div className="customers-view__header-wrap">
                <div className="customers-view__header">
                    <img className="customers-view__header-img" src={products2} alt='img'/>
                    <div className="customers-view__header-title">{selectedLanguage?.customersView?.customersViewTitle} ({allClients})</div>
                </div>
                <button className='customers-view__header-btn' onClick={() => setIsWriteMessage(true)}>
                    <img className='customers-view__header-btn-img' src={letter1} alt='img'/>
                    <p>{selectedLanguage?.customersView?.customersViewBtnWrite}</p>
                </button>
            </div>
            <div className="customers-view__header-btn-wrap">
                <div className='customers-view__filter'>
                    <TextField className='customers-view__filter-input' value={searchCustomers} onChange={(e) => setSearchCustomers(e.target.value)} onKeyDown={(e) => handleSearchCustomersEnter(e)} label={selectedLanguage?.customersView?.customersViewSearchCustomers} variant="outlined" />
                    <button className='customers-view__filter-btn' onClick={(e) => handleSearchCustomers(e)}>{selectedLanguage?.customersView?.customersViewBtnSearch}</button>
                </div>
                <button className='customers-view__header-btn' onClick={() => setIsDownloadCustomers(true)}>
                    <img className='customers-view__header-btn-img' src={download} alt='img'/>
                    <p>{selectedLanguage?.customersView?.customersViewBtnDownload}</p>
                </button>
            </div>

            <div className='customers-view__result-wrap container'>
                {
                    isPreloader ? <Preloader /> : !!clients?.length ?
                        <div className='customers-view__result'>
                            {
                                clients.map(el => (
                                    <div key={el._id}>
                                        <CustomerCard customer={el} bannedBtn={true}/>
                                    </div>
                                ))
                            }
                        </div>
                        :
                        <div className='customers-view__error'>{selectedLanguage?.customersView?.customersViewError}</div>
                }

                <PaginationItems selectedPaget={selectedPaget} setSelectedPaget={setSelectedPaget} pageRangeDisplayed={5} itemsPerPage={10} quantityAllProducts={quantityAllClients}/>
            </div>
        </div>
    );
}

export default CustomersView;
import { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import './PricingView.css';
import { useDispatch, useSelector } from 'react-redux';
import HeaderLanding from '../../components/HeaderLanding/HeaderLanding';
import FooterLanding from '../../components/FooterLanding/FooterLanding';
import check1 from '../../assets/images/check1.svg';
import check2 from '../../assets/images/check2.svg';
import personal from '../../assets/images/personal.svg';
import integration from '../../assets/images/integration.svg';
import category from '../../assets/images/category.svg';
import chart from '../../assets/images/chart.svg';
import MotivationBlock from '../../components/MotivationBlock/MotivationBlock';

function PricingView() {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const userLanguage = useSelector(state => state.userSlice.userLanguage);
    const [isMonthPricing, setIsMonthPricing] = useState(true);

    const handlePrice = (num) => {
        if (num == "1") {
            return isMonthPricing ? userLanguage === 'UA'? '400' : '30' : userLanguage === 'UA'? '320' : '20'
        }
        if (num == "2") {
            return isMonthPricing ? userLanguage === 'UA'? '800' : '50' : userLanguage === 'UA'? '640' : '40'
        }
        if (num == "3") {
            return isMonthPricing ? userLanguage === 'UA'? '1300' : '70' : userLanguage === 'UA'? '1040' : '50'
        }
    }

    const openDashboard = () => {
        window.location.href = 'https://auth.welcomenocode.com/?app=checkinshop'
    }

    return (
        <div className='pricing-view-wrap'>
            <HeaderLanding />
            <div className='pricing-view'>
                <div className='container'>
                    <h3 className='pricing-view__title'>{selectedLanguage?.pricingView?.pricingViewTitle}</h3>
                    <div className='pricing-view__sub-title'>{selectedLanguage?.pricingView?.pricingViewSubTitle}</div>
                    <div className='pricing-view__month-btn-wrap'>
                        <button className={`pricing-view__month-btn pricing-view__month-btn-month ${isMonthPricing ? 'pricing-view__month-btn--active' : ''}`} onClick={() => setIsMonthPricing(true)}>{selectedLanguage?.pricingView?.pricingViewMonthBtn1}</button>
                        <button className={`pricing-view__month-btn pricing-view__month-btn-year ${!isMonthPricing ? 'pricing-view__month-btn--active' : ''}`} onClick={() => setIsMonthPricing(false)}>{selectedLanguage?.pricingView?.pricingViewMonthBtn2}</button>
                    </div>
                    <div className='pricing-view__items'>
                        <div className='pricing-view__item'>
                            <div className='pricing-view__item--wrap'>
                                <div className='pricing-view__item-title'>{selectedLanguage?.pricingView?.pricingViewItemTitle1}</div>
                                <div className='pricing-view__item-sub-title'>{selectedLanguage?.pricingView?.pricingViewItemSubTitle1}</div>
                                <div className='pricing-view__item-price-wrap'>
                                    <div className='pricing-view__item-price'>{handlePrice('1')}</div>
                                    <div className='pricing-view__item-price-currency'>{userLanguage === 'UA' ? 'грн/мс' : 'USD/month'}</div>
                                </div>
                                <div className='pricing-view__item-info'>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check1} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText1}</div>
                                    </div>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check1} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText2}</div>
                                    </div>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check1} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText3}</div>
                                    </div>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check1} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText4}</div>
                                    </div>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check1} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText5}</div>
                                    </div>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check1} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText6}</div>
                                    </div>
                                </div>
                            </div>
                            <button className='pricing-view__item-btn' onClick={() => {openDashboard()}}>{selectedLanguage?.pricingView?.pricingViewItemBtn}</button>
                        </div>
                        <div className='pricing-view__item pricing-view__item-base'>
                            <div className='pricing-view__item--wrap'>
                                <div className='pricing-view__item-title'>{selectedLanguage?.pricingView?.pricingViewItemTitle2}</div>
                                <div className='pricing-view__item-sub-title pricing-view__item-sub-title-base'>{selectedLanguage?.pricingView?.pricingViewItemSubTitle2}</div>
                                <div className='pricing-view__item-price-wrap'>
                                    <div className='pricing-view__item-price'>{handlePrice('2')}</div>
                                    <div className='pricing-view__item-price-currency'>{userLanguage === 'UA' ? 'грн/мс' : 'USD/month'}</div>
                                </div>
                                <div className='pricing-view__item-info'>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check2} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText7}</div>
                                    </div>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check2} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText12}</div>
                                    </div>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check2} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText3}</div>
                                    </div>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check2} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText4}</div>
                                    </div>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check2} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText5}</div>
                                    </div>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check2} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText6}</div>
                                    </div>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check2} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText8}</div>
                                    </div>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check2} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText9}</div>
                                    </div>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check2} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText10}</div>
                                    </div>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check2} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText11}</div>
                                    </div>
                                </div>
                            </div>
                            <button className='pricing-view__item-btn' onClick={() => {openDashboard()}}>{selectedLanguage?.pricingView?.pricingViewItemBtn}</button>
                        </div>
                        <div className='pricing-view__item'>
                            <div className='pricing-view__item--wrap'>
                                <div className='pricing-view__item-title'>{selectedLanguage?.pricingView?.pricingViewItemTitle3}</div>
                                <div className='pricing-view__item-sub-title'>{selectedLanguage?.pricingView?.pricingViewItemSubTitle3}</div>
                                <div className='pricing-view__item-price-wrap'>
                                    <div className='pricing-view__item-price'>{handlePrice('3')}</div>
                                    <div className='pricing-view__item-price-currency'>{userLanguage === 'UA' ? 'грн/мс' : 'USD/month'}</div>
                                </div>
                                <div className='pricing-view__item-info'>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check1} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText7}</div>
                                    </div>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check1} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText12}</div>
                                    </div>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check1} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText3}</div>
                                    </div>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check1} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText4}</div>
                                    </div>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check1} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText5}</div>
                                    </div>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check1} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText6}</div>
                                    </div>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check1} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText8}</div>
                                    </div>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check1} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText9}</div>
                                    </div>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check1} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText10}</div>
                                    </div>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check1} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText11}</div>
                                    </div>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check1} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText13}</div>
                                    </div>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check1} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText14}</div>
                                    </div>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check1} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText15}</div>
                                    </div>
                                    <div className='pricing-view__item-info-text-wrap'>
                                        <img className='pricing-view__item-info-text-img' src={check1} alt='img'/>
                                        <div className='pricing-view__item-info-text'>{selectedLanguage?.pricingView?.pricingViewItemText16}</div>
                                    </div>
                                </div>
                            </div>
                            <button className='pricing-view__item-btn' onClick={() => {openDashboard()}}>{selectedLanguage?.pricingView?.pricingViewItemBtn}</button>
                        </div>
                    </div>

                    <div className='pricing-view__item-sub-title'>{selectedLanguage?.pricingView?.pricingViewItemSubTitle4}</div>
                    
                </div>
            </div>

            <div className='pricing-view__info'>
                <div className='container'>
                    <div className='pricing-view__info-title'>{selectedLanguage?.pricingView?.pricingViewInfoTitle}</div>
                    <div className='pricing-view__info-items'>
                        <div className='pricing-view__info-item'>
                            <img className='pricing-view__info-item-img' src={personal} alt='img'/>
                            <div className='pricing-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText6}</div>
                        </div>
                        <div className='pricing-view__info-item'>
                            <img className='pricing-view__info-item-img' src={integration} alt='img'/>
                            <div className='pricing-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText4}</div>
                        </div>
                        <div className='pricing-view__info-item'>
                            <img className='pricing-view__info-item-img' src={category} alt='img'/>
                            <div className='pricing-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText5}</div>
                        </div>
                        <div className='pricing-view__info-item'>
                            <img className='pricing-view__info-item-img' src={chart} alt='img'/>
                            <div className='pricing-view__info-item-text'>{selectedLanguage?.pricingView?.pricingViewItemText2}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='question__question'>
                <div className='container'>
                    <div className='question__question-title'>{selectedLanguage?.pricingView?.pricingViewQuestionTitle}</div>
                    <div className='question__question-items'>
                        <div className='question__question-item'>
                            <div className='question__question-item-title'>{selectedLanguage?.pricingView?.pricingViewQuestion1}</div>
                            <div className='question__question-item-text'>{selectedLanguage?.pricingView?.pricingViewQuestionText1}</div>
                        </div>
                        <div className='question__question-item'>
                            <div className='question__question-item-title'>{selectedLanguage?.pricingView?.pricingViewQuestion2}</div>
                            <div className='question__question-item-text'>{selectedLanguage?.pricingView?.pricingViewQuestionText2}</div>
                        </div>
                        <div className='question__question-item'>
                            <div className='question__question-item-title'>{selectedLanguage?.pricingView?.pricingViewQuestion3}</div>
                            <div className='question__question-item-text'>{selectedLanguage?.pricingView?.pricingViewQuestionText3}</div>
                        </div>
                        {/* <div className='question__question-item'>
                            <div className='question__question-item-title'>{selectedLanguage?.pricingView?.pricingViewQuestion4}</div>
                            <div className='question__question-item-text'>{selectedLanguage?.pricingView?.pricingViewQuestionText4}</div>
                        </div>
                        <div className='question__question-item'>
                            <div className='question__question-item-title'>{selectedLanguage?.pricingView?.pricingViewQuestion5}</div>
                            <div className='question__question-item-text'>{selectedLanguage?.pricingView?.pricingViewQuestionText5}</div>
                        </div>
                        <div className='question__question-item'>
                            <div className='question__question-item-title'>{selectedLanguage?.pricingView?.pricingViewQuestion6}</div>
                            <div className='question__question-item-text'>{selectedLanguage?.pricingView?.pricingViewQuestionText6}</div>
                        </div> */}
                    </div>
                </div>
            </div>

            <MotivationBlock />

            <FooterLanding />
        </div>
    );
}

export default PricingView;
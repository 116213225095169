import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import plane from '../../assets/images/plane.svg';
import './FooterLanding.css';
import axios from 'axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

function FooterLanding() {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const [fullYear, setFullYear] = useState(new Date().getFullYear());
    const [mail, setMail] = useState('');
    const footerLanding = useRef(null);

    const handleSendMail = async () => {
        if (validateEmail(mail)) {
            // Send telegram alert
            const alert = `📠 User ${mail} is asking for offer for Check-in Shop [Date: ${new Date()}].`
            await axios.get('https://api.telegram.org/bot' + process.env.REACT_APP_TELEGRAM_TOKEN + '/sendMessage?chat_id=' + process.env.REACT_APP_TELEGRAM_CHAT_ID + '&parse_mode=HTML&text=' + alert)
            setMail('')
            toast.success('Request is sent. Please wait for the our answer', {
                position: "bottom-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        } else {
            footerLanding.current.focus()
            toast.warn('Request is not sent. Email address is wrong', {
                position: "bottom-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }
    };

    return (
        <div className='footer-landing-wrap'>
            <ToastContainer/>
            <div className='footer-landing container'>
                <div className='footer-landing__top'>
                    <div className='footer-landing__product'>
                        <div className='footer-landing__product-title'>{selectedLanguage?.footerLanding?.footerLandingLinkFirstTitle}</div>
                        <ul className='footer-landing__product-linck-wrap'>
                            <li className='footer-landing__product-linck-li'><NavLink to={'/'}>{selectedLanguage?.footerLanding?.footerLandingLink1}</NavLink></li>
                            <li className='footer-landing__product-linck-li'><NavLink to={'/help'}>{selectedLanguage?.footerLanding?.footerLandingLink2}</NavLink></li>
                        </ul>
                    </div>

                    <div className='footer-landing__product'>
                        <div className='footer-landing__product-title'>{selectedLanguage?.footerLanding?.footerLandingLinkSecondTitle}</div>
                        <ul className='footer-landing__product-linck-wrap'>
                            <li className='footer-landing__product-linck-li'><NavLink to={'/blogs'}>{selectedLanguage?.footerLanding?.footerLandingLink3}</NavLink></li>
                            <li className='footer-landing__product-linck-li'><NavLink to={'/pricing'}>{selectedLanguage?.footerLanding?.footerLandingLink4}</NavLink></li>
                            <li className='footer-landing__product-linck-li'><NavLink to={'/partners'}>{selectedLanguage?.footerLanding?.footerLandingLink5}</NavLink></li>
                        </ul>
                    </div>

                    <div className='footer-landing__contact'>
                        <div className='footer-landing__contact-title'>{selectedLanguage?.footerLanding?.footerLandingContactTitle}</div>
                        <a className='footer-landing__contact-tel'>+380687589422</a>
                        <a className='footer-landing__contact-mail'>help@welcomenocode.com</a>
                    </div>
                    <div className='footer-landing__social-wrap'>
                        <div className='footer-landing__social-title'>{selectedLanguage?.footerLanding?.footerLandingSocialTitle}</div>
                        <input className="footer-landing__social-mail" onChange={(e) => setMail(e.target.value)} value={mail} type="mail" name="FooterLandingMail" placeholder="Email" ref={footerLanding}></input>
                        <button className='footer-landing__social-btn' onClick={handleSendMail}><img src={plane} alt='img'/></button>
                    </div>
                </div>
                <div className='footer-landing__bottom_row'>
                    <div className='footer-landing__bottom'>
                        <div className='footer-landing__bottom-text'>© {fullYear}&nbsp;{selectedLanguage?.footerLanding?.footerLandingBottomText1} {selectedLanguage?.footerLanding?.footerLandingBottomText2}<a className='footer-landing__bottom-link' href='https://www.welcomenocode.com/' target='_blank'>Welcome Nocode</a></div>
                    </div>
                    <div className='footer-landing__bottom'>
                        <a href='https://www.welcomenocode.com/privacy-policy?app=checkinshop'>Privacy Policy</a>
                        <a href='https://www.welcomenocode.com/refund-policy?app=checkinshop'>Refund Policy</a>
                        <a href='https://www.welcomenocode.com/terms-and-conditions?app=checkinshop'>Terms & Conditions</a>
                        <a href='https://www.welcomenocode.com?contacts'>Contacts</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterLanding;
import './SearchBtn.css';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setIsOpenMenu, setSearchProductsName } from '../../store/homeSlice';

function SearchBtn() {
    const selectedLanguage = useSelector(state => state.homeSlice.selectedLanguage);
    const searchProductsName = useSelector(state => state.homeSlice.searchProductsName);
    const shop = useSelector(state => state.homeSlice.shop);
    const [searchValue, setSearchValue] = useState('');
    const searchInputRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        setSearchValue(searchProductsName)
    }, [searchProductsName])

    const handleSearchValue = (value) => {
        dispatch(setSearchProductsName(value));
    };

    const handleClick = () => {
        if (searchValue?.length) {
            dispatch(setSearchProductsName(searchValue));
            navigate(`/${shop.name}/search`)
            dispatch(setIsOpenMenu(false))
        } else {
            navigate(`/${shop?.name}`)
            searchInputRef.current.focus()
        }
    };
    
    const handleKeyDown = (e) => {
        if(e.key == 'Enter') {
            if (searchValue?.length) {
                dispatch(setSearchProductsName(searchValue));
                navigate(`/${shop.name}/search`)
                dispatch(setIsOpenMenu(false))
            } else {
                navigate(`/${shop?.name}`)
            }
        }
    };

    return (
        <div className="search-btn">
            <input className="search-btn__input" 
                onChange={(e) => handleSearchValue(e.target.value)} 
                onKeyDown={(e) => handleKeyDown(e)} 
                value={searchValue} 
                ref={searchInputRef} 
                type="text" 
                name="search" 
                placeholder={selectedLanguage?.searchBtn?.placeholderSearch} 
                autoComplete='off' 
            />
            <svg className="search-btn__img" onClick={() => handleClick()} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 487.95 487.95" xmlSpace="preserve">
                <g>
                    <g>
                        <path d="M481.8,453l-140-140.1c27.6-33.1,44.2-75.4,44.2-121.6C386,85.9,299.5,0.2,193.1,0.2S0,86,0,191.4s86.5,191.1,192.9,191.1
                            c45.2,0,86.8-15.5,119.8-41.4l140.5,140.5c8.2,8.2,20.4,8.2,28.6,0C490,473.4,490,461.2,481.8,453z M41,191.4
                            c0-82.8,68.2-150.1,151.9-150.1s151.9,67.3,151.9,150.1s-68.2,150.1-151.9,150.1S41,274.1,41,191.4z"/>
                    </g>
                </g>
            </svg>
        </div>
    );
}

export default SearchBtn;
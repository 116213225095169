import './InputPassword.css';
import { memo, useEffect, useState } from 'react';

function InputPassword({setValue, value, id, label, name, placeholder='пароль...'}) {
    const [password, setPasswordt] = useState('');

    useEffect(() => {
        setPasswordt(value)
    }, [value])
    
    useEffect(() => {
        setValue(password)
    }, [password])

    return (
        <div className="input-passwor--wrap">
            <label className='input-passwor__label' htmlFor={id}>
                <span>{label}</span>
            </label>
            <input
                id={id}
                name={name}
                type="password"
                required
                className='input-passwor__input'
                onChange={(e) => setPasswordt(e.target.value)}
                value={password}
                placeholder={placeholder}
            />
        </div>
    );
}

export default memo(InputPassword);
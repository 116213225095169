
export const userTypeStore = {
    'UA': [
        {id: 2, name: 'Ноутбуки и компьютеры'},
        {id: 3, name: 'Компьютерные комплектующие і аксесуари'},
        {id: 4, name: 'Оргтехніка'},
        {id: 5, name: 'Смартфоны'},
        {id: 6, name: 'Планшети'},
        {id: 7, name: 'Телевізори'},
        {id: 8, name: 'Годиники'},
        {id: 9, name: 'Побутова техніка'},
        {id: 10, name: 'Електро техніка'},
        {id: 11, name: 'Електро інструменти'},
        {id: 12, name: 'Сантехніка'},
        {id: 13, name: 'Будівельні матеріали'},
        {id: 14, name: 'Дача, сад, огород'},
        {id: 15, name: 'Спортивні товари'},
        {id: 16, name: 'Рибалка'},
        {id: 17, name: 'Домашній текстиль'},
        {id: 18, name: 'Посуда'},
        {id: 19, name: 'Побутова хімія'},
        {id: 20, name: 'Освітлення'},
        {id: 21, name: 'Тренажери і фітнес'},
        {id: 22, name: 'Інвентарь для дому і офісу'},
        {id: 23, name: 'Зоотовари'},
        {id: 24, name: 'Ковані вироби'},
        {id: 25, name: 'Сувенірна продукція'},
        {id: 26, name: 'Хоббі, ручна робота'},
        {id: 27, name: 'Автотовари'},
        {id: 28, name: 'Одяг'},
        {id: 29, name: 'Жіночий одяг'},
        {id: 30, name: 'Чоловічий одяг'},
        {id: 31, name: 'Дитячий одяг'},
        {id: 32, name: 'Дитячі товари'},
        {id: 33, name: 'Дитяче харчування'},
        {id: 34, name: 'Взуття'},
        {id: 35, name: 'Рукзаки'},
        {id: 36, name: 'Товари для офісу, школи'},
        {id: 37, name: 'Книжки'},
        {id: 38, name: 'Косметика'},
        {id: 39, name: 'Товари гігієни'},
        {id: 40, name: 'Алкогольні напої і продукти'},
        {id: 41, name: 'Тютюнові вироби'},
        {id: 42, name: 'Електронні сигарети і аксесуари'},
        {id: 43, name: 'Бакалія'},
        {id: 44, name: 'Спортивна і здорова їжа'},
        {id: 45, name: 'Товари для бізнесу'},
        {id: 46, name: 'Складське обладнання'},
        {id: 47, name: 'Торгівельне обладнання'},
        {id: 48, name: 'Товари для геймерів'},

    ],
    'ENG': [
        {id: 2, name: 'Laptops and computers'},
        {id: 3, name: 'Computer components and accessories'},
        {id: 4, name: 'Office equipment'},
        {id: 5, name: 'Smartphones'},
        {id: 6, name: 'Tablets'},
        {id: 7, name: 'TVs'},
        {id: 8, name: 'Watches'},
        {id: 9, name: 'Household appliances'},
        {id: 10, name: 'Elektro tehnika'},
        {id: 11, name: 'Electric tools'},
        {id: 12, name: 'Plumbing'},
        {id: 13, name: 'Building materials'},
        {id: 14, name: 'Dacha, garden, vegetable garden'},
        {id: 15, name: 'Sporting goods'},
        {id: 16, name: 'Fishing'},
        {id: 17, name: 'Home textiles'},
        {id: 18, name: 'Tableware'},
        {id: 19, name: 'Household chemistry'},
        {id: 20, name: 'Lighting'},
        {id: 21, name: 'Trainers and fitness'},
        {id: 22, name: 'Inventory for home and office'},
        {id: 23, name: 'Pet Products'},
        {id: 24, name: 'Forged products'},
        {id: 25, name: 'Souvenir products'},
        {id: 26, name: 'Hobby, handwork'},
        {id: 27, name: 'Auto goods'},
        {id: 28, name: 'Clothes'},
        {id: 29, name: 'Women\'s clothing'},
        {id: 30, name: 'Men\'s clothing'},
        {id: 31, name: 'Children\'s clothes'},
        {id: 32, name: 'Children\'s products'},
        {id: 33, name: 'Baby food'},
        {id: 34, name: 'Shoes'},
        {id: 35, name: 'Rucksacks'},
        {id: 36, name: 'Products for office, school'},
        {id: 37, name: 'Books'},
        {id: 38, name: 'Cosmetics'},
        {id: 39, name: 'Hygiene goods'},
        {id: 40, name: 'Alcoholic beverages and products'},
        {id: 41, name: 'Tobacco products'},
        {id: 42, name: 'Electronic cigarettes and accessories'},
        {id: 43, name: 'Bakalia'},
        {id: 44, name: 'Sports and healthy food'},
        {id: 45, name: 'Business products'},
        {id: 46, name: 'Warehouse equipment'},
        {id: 47, name: 'Trade equipment'},
        {id: 48, name: 'Goods for gamers'},
    ],
  
}


import './MessageClient.css';
import { useState} from 'react';
import { useSelector } from 'react-redux';
import { TextField } from '@mui/material';
import send from '../../assets/images/send.svg';
import emoji from '../../assets/images/emoji.svg';
import pictures from '../../assets/images/pictures.svg';
import avatar from '../../assets/images/avatar.svg';
import close from '../../assets/images/close.svg';
import EmojiPicker from 'emoji-picker-react';

function MessageClient() {
    const shop = useSelector(state => state.homeSlice.shop);
    const buyer = useSelector(state => state.homeSlice.buyer);
    const [text, setText] = useState('');
    const [messageImage, setMessageImage] = useState({});
    const [isEmojiPicker, setIsEmojiPicker] = useState(false);

    let messages = [
        {
            _id: '12',
            sent_at: new Date(),
            sent_by: '5454545',
            message: 'sdf sdf sd fsdf  sdf',
        },
        {
            _id: '122',
            sent_at: new Date(),
            sent_by: shop._id,
            message: 'sdf sdf sddsf sdf sd fsdf fsdf  sdf',
        },
    ]

    const handleSendMessage = (e) => {
        
    }
    
    const uploadPhoto = (image_file) => {
        if (image_file?.name?.length) {
                // setProductImages([...productImages, image_file]) // new
                // setIsNewName(!isNewName)
                // creationProductUserImages.current.value = ""
                setMessageImage(image_file)
            }
    }

    const handleSetEmoji = (e) => {
        console.log('emoji', e)
        setText(text + ' ' + e.emoji)
    }

    return (
        <div className="message-client">
            <div className="message-client__message-wrap">
                {
                    !!messages?.length && messages.map(el => (
                        <div className={`${el.sent_by === shop._id ? 'message-client__message2' : 'message-client__message1'}`} key={el._id}>
                            <div className={`${el.sent_by === shop._id ? 'message-client__message2-client' : 'message-client__message1-client'}`}>{el.sent_by === shop._id ? shop.name : buyer.full_name}</div>
                            <div className={`${el.sent_by === shop._id ? "message-client__message2-text-wrap" : "message-client__message1-text-wrap"}`}>
                                <div className={`${el.sent_by === shop._id ? "message-client__message2-text--wrap" : "message-client__message1-text--wrap"}`}>
                                    <div className={`${el.sent_by === shop._id ? "message-client__message2-text" : "message-client__message1-text"}`}>{el.message}</div>
                                    <div className={`${el.sent_by === shop._id ? "message-client__message2-text-date" : "message-client__message1-text-date"}`}>{el.sent_at.toLocaleString()}</div>
                                    <svg className={`${el.sent_by === shop._id ? "message-client__message2-text-arrow" : "message-client__message1-text-arrow"}`} width="87" height="152" viewBox="0 0 87 152" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M42.5367 1.45479C42.8087 0.479555 44.1913 0.479561 44.4633 1.4548L86.0146 150.481C86.1923 151.119 85.713 151.75 85.0513 151.75H1.94871C1.28698 151.75 0.807724 151.119 0.985445 150.481L42.5367 1.45479Z" fill={`${el.sent_by === shop._id ? "#F3F5F7" : "#4C6FFF"}`}/>
                                    </svg>
                                </div>
                                <img className={`${el.sent_by === shop._id ? "message-client__message2-text-img" : "message-client__message1-text-img"}`} src={el.sent_by === shop._id ? shop.logo?.length ? shop.logo : avatar : avatar} alt='img'/>
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className="message-client__write">
                {
                    isEmojiPicker && 
                        <div className='message-client__write-emoji-wrap'>
                            <EmojiPicker onEmojiClick={(e) => handleSetEmoji(e)} />
                        </div>
                }
                <div className="message-client__write-input-wrap">
                    <img className="message-client__write-emoji-img" onClick={() => setIsEmojiPicker(!isEmojiPicker)} src={emoji} alt='img'/>
                    <TextField className='message-client__write-input' value={text} onChange={(e) => setText(e.target.value)} onKeyDown={(e) => handleSendMessage(e)} label='Введіть повідомлення' multiline maxRows={1} rows={1} variant="outlined" />
                    <div className='message-client__write-pictures-wrap'>
                        <input className="message-client__write-pictures-input" onChange={(e) => uploadPhoto(e.target.files[0])} type="file" accept="image/*" id="messageClientPicturesImages"/>
                        <label className='message-client__write-pictures-lable' htmlFor='messageClientPicturesImages'><img className="message-client__write-pictures-img" src={pictures} alt='img'/></label>
                    </div>
                </div>
                <button className="message-client__write-btn-send"><img className="message-client__write-btn-send-img" src={send} alt='img'/></button>
                {
                    !!messageImage?.name?.length && 
                        <div className='message-client__write-result-img-wrap'>
                             <img className='message-client__write-result-img' src={URL.createObjectURL(messageImage)} alt='img'/>
                             <img className='message-client__write-result-img-close' onClick={() => setMessageImage({})} src={close} alt='img'/>
                        </div>
                }
            </div>
        </div>
    );
}

export default MessageClient;
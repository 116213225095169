import './UserNotificationsView.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import messages2 from './../../assets/images/messages2.svg';
import deleteImg from './../../assets/images/deleteImg.svg';
import deleteImgHover from './../../assets/images/deleteImgHover.svg';
import phone3 from './../../assets/images/phone3.svg';
import at from './../../assets/images/at.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getNotifications, setFavoriteNotifications, setNotificationsLength, setUserNotificationsTabValue } from '../../store/userSlice';
import PaginationItems from '../../components/PaginationItems/PaginationItems';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css'; 
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import { fetchGetData, fetchRequest, setUserNotificationsLength, showMessage } from '../../helpers/Utils';
import mixpanel from 'mixpanel-browser';
import { FormControl, InputLabel, MenuItem, Select, Tab, Tabs, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import Preloader from '../../components/Preloader/Preloader';
mixpanel.init('cbc9e4ee0d40873b5a2e3b5e39eedd33', { debug: true }); 
mixpanel.track('Visit notifications page');

function UserNotificationsView() {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const isManagerRole = useSelector(state => state.userSlice.isManagerRole);
    const isTopManagerRole = useSelector(state => state.userSlice.isTopManagerRole);
    const userNotificationsTabValue = useSelector(state => state.userSlice.userNotificationsTabValue);
    const user = useSelector(state => state.userSlice.user);
    const shop = useSelector(state => state.userSlice.shop);
    const notifications = useSelector(state => state.userSlice.notifications);
    const [sortStatus, setSortStatus] = useState('');
    const [selectedPaget, setSelectedPaget] = useState('0');
    const [quantityAllProducts, setQuantityAllProducts] = useState('');
    const [allNotifications, setAllNotifications] = useState('');
    const [unseen, setUnseen] = useState('1');
    const [seen, setSeen] = useState('');
    const [favorite, setFavorite] = useState('');
    const [isSelectDate, setIsSelectDate] = useState(false);
    const [isSortByDate, setIsSortByDate] = useState(false);
    const [deleteNotifications, setDeleteNotifications] = useState('');
    const [isModalDeleteNotifications, setIsModalDeleteNotifications] = useState(false);
    const [isPreloader, setIsPreloader] = useState(true);
    const [stateDate, setStateDate] = useState([
        {
          startDate: null,
        //   endDate: new Date(),
          endDate: null,
          key: 'selection'
        }
      ]);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = localStorage.getItem('token')

    useEffect(() => {
        if (userNotificationsTabValue !== 0) {
            handleChange(null, userNotificationsTabValue) 
        }
    }, [])
    
    useEffect(() => {
        if (shop?._id) {
            setIsPreloader(true)
            setUserNotificationsLength(shop._id, token, (res) => dispatch(setNotificationsLength(res)))
                
            fetchGetData(`${process.env.REACT_APP_BASE_URL}/notifications/${shop._id}/all?page=${selectedPaget}&status=${sortStatus}&seen=${seen}&unseen=${unseen}&favorite=${favorite}&token=${token}&min_date=${stateDate[0].startDate ? stateDate[0].startDate : ''}&max_date=${stateDate[0].endDate ? stateDate[0].endDate : ''}`)
                .then(res => {
                    if (res.success && res.data) {
                        dispatch(getNotifications(res.data));
                        setQuantityAllProducts(res.count % 10 == 0 ? res.count / 10 : Math.ceil(res.count / 10))
                        if (!allNotifications?.length) {
                            setAllNotifications(res.allCount)
                        }
                    } else {
                        console.log('GET UserNotifications:', res)
                    }
                    setIsPreloader(false)
                })
        }
    }, [shop, isSortByDate, seen, unseen, favorite, sortStatus])

    const handleReadNotifications = (id) => {
        mixpanel.track('Open notification on notifications page');
        navigate(`/auth/${user._id}/notifications/${id}`)
    }

    const handleFavorite = (e, notifications) => {
        mixpanel.track('Make notification favorite on notifications page');
        e.stopPropagation()
        let data = {
            ...notifications,
            token: token,
            favorite: !notifications.favorite,
        }

        fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/notifications/${notifications._id}`, data)
            .then(res => {
                if (res.success && res.data) {
                    console.log('PUT CardSelect:', res)
                    dispatch(setFavoriteNotifications({...notifications, favorite: !notifications.favorite}));
                } else {
                    console.log('PUT UserNotifications:', res)
                }
            })
    }

    const handleSortDate = () => {
        mixpanel.track('Sort notifications by date on notifications page');
        if (stateDate[0].startDate) {
            setIsSelectDate(false)
            setIsSortByDate(!isSortByDate)
        }
    }
   
    const handleSortCleanDate = () => {
        mixpanel.track('Remove sorting of notifications by date on notifications page');
        setIsSelectDate(false)
        if (stateDate[0].startDate !== null) {
            setIsSortByDate(!isSortByDate)
            setStateDate([{
                startDate: null,
                endDate: null,
                key: 'selection'
              }])
        }
    }
   
    const handleIsDeleteNotifications = (id, e) => {
        e.stopPropagation()
        setDeleteNotifications(id)
        setIsModalDeleteNotifications(true)
    }

    const handleChange = (event, newValue) => {
        if (userNotificationsTabValue !== newValue) {
            dispatch(setUserNotificationsTabValue(newValue));
        }
        mixpanel.track('Use sort on notifications page');

        if (newValue == 0) {
            setUnseen('1')
            setSeen('')
            setFavorite('')
        } else if (newValue == 1) {
            setUnseen('')
            setSeen('1')
            setFavorite('')
        } else {
            setUnseen('')
            setSeen('')
            setFavorite('1')
        }
    };
    
    const handleDeleteNotifications = (boolean) => {
        mixpanel.track('Delete notification on notifications page');
        if (boolean) {
            let data = {
                id: deleteNotifications,
            }

            fetchRequest('DELETE', `${process.env.REACT_APP_BASE_URL}/notifications/${deleteNotifications}?token=${token}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
                .then(res => {
                    if (res.success) {
                        let res = notifications.filter(el => el._id !== deleteNotifications)
                        dispatch(getNotifications(res));
                        showMessage('success', 'Повідомлення видалено')
                    } else {
                        console.log('DELETE UserNotifications', res)
                    }
                })
        }
        setDeleteNotifications('')
        setIsModalDeleteNotifications(false)
    }
    
    return (
        <div className="user-notifications">

            {
                isModalDeleteNotifications && <ModalWindow title={selectedLanguage?.userNotifications?.userNotificationsModDelTitle} handleClick={handleDeleteNotifications} />
            }
            <div className="user-notifications--wrap">
                <div>
                    <div className="user-notifications__header">
                        <div className="user-notifications__header-count">
                            <img className="user-notifications__header-count-img" src={messages2} alt='img'/>
                            <div className="user-notifications__header-count-title">{selectedLanguage?.userNotifications?.userNotificationsTitle} {allNotifications}</div>
                        </div>
                        <Box className="user-notifications__header-tab-wrap" sx={{  }}>
                            <Tabs value={userNotificationsTabValue} onChange={handleChange} textColor='inherit'>
                                <Tab label={selectedLanguage?.userNotifications?.userNotificationsTab1}/>
                                <Tab label={selectedLanguage?.userNotifications?.userNotificationsTab2}/>
                                <Tab label={selectedLanguage?.userNotifications?.userNotificationsTab3}/>
                            </Tabs>
                        </Box>
                    </div>
                    
                    <div className="user-notifications__filter">
                        <div className="user-notifications__filter-type">
                            <FormControl fullWidth>
                                <InputLabel id="userNotificationsSort">{selectedLanguage?.userNotifications?.userNotificationsSortTitle}</InputLabel>
                                <Select
                                    labelId="userNotificationsSort"
                                    id="userNotificationsSort"
                                    value={sortStatus}
                                    label={selectedLanguage?.userNotifications?.userNotificationsSortTitle}
                                    onChange={(e) => setSortStatus(e.target.value)}
                                >
                                    <MenuItem value=''>{selectedLanguage?.userNotifications?.userNotificationsSortOption1}</MenuItem>
                                    <MenuItem value='callBack'>{selectedLanguage?.userNotifications?.userNotificationsSortOption2}</MenuItem>
                                    <MenuItem value='subscription'>{selectedLanguage?.userNotifications?.userNotificationsSortOption3}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div className="user-notifications__filter-date-wrap">
                            <div className='user-notifications__filter-date-btn-wrap'>
                                <button className='user-notifications__filter-date-btn' onClick={handleSortCleanDate}>{selectedLanguage?.userNotifications?.userNotificationsFilterDateBtnClean}</button>
                                <button className='user-notifications__filter-date-btn' onClick={handleSortDate}>{selectedLanguage?.userNotifications?.userNotificationsFilterDateBtnSort}</button>
                            </div>
                            <div className='user-notifications__filter-date' onClick={() => setIsSelectDate(!isSelectDate)}>
                                <div className='user-notifications__filter-date-select'>
                                    <div>{selectedLanguage?.userNotifications?.userNotificationsFilterDateSelect} {stateDate[0]?.startDate ? new Date(stateDate[0]?.startDate).toLocaleString().split(',')[0] : selectedLanguage?.userNotifications?.userNotificationsFilterDateFormatFrom} - {stateDate[0]?.endDate ? new Date(stateDate[0]?.endDate).toLocaleString().split(',')[0] : selectedLanguage?.userNotifications?.userNotificationsFilterDateFormatTo}</div>
                                </div>
                            </div>
                            <DateRange
                                className={`user-notifications__filter-date-drop ${isSelectDate ? 'user-notifications__filter-date-drop-active' : ''}`}
                                editableDateInputs={true}
                                onChange={item => setStateDate([item.selection])}
                                moveRangeOnFirstSelection={false}
                                ranges={stateDate}
                            />
                        </div>
                    </div>

                    <div className="user-notifications__items">
                        { 
                            isPreloader ? <Preloader /> : !!notifications?.length ? notifications.map(el => (
                                <div className={`user-notifications__item user-notifications__item-status--${el.status}`} key={el._id} onClick={() => handleReadNotifications(el._id)}>

                                    <div className={`user-notifications__item-name ${el.status == 'callBack' ? 'user-notifications__item-name--c' : 'user-notifications__item-name--s'}`}><img className="user-notifications__item-name-img" src={el.status == 'callBack' ? phone3 : at} alt='img'/></div>
                                    <div className="user-notifications__item-text-wrap">
                                        {
                                            !!el.phone?.length && <div className="user-notifications__item-text">{el.phone}</div>
                                        }
                                        {
                                            !!el.email?.length && <div className="user-notifications__item-text">{el.email}</div>
                                        }
                                        {
                                            !!el.creation_time?.length && <div className="user-notifications__item-text">{new Date(el.creation_time).toLocaleString()}</div>
                                        }
                                    </div>
                                    
                                    <div className='user-notifications__item-btn-wrap'>
                                        <Tooltip title={`${el.favorite ? selectedLanguage?.userNotifications?.userNotificationsToltip1 : selectedLanguage?.userNotifications?.userNotificationsToltip2}`} key={el._id} placement='top'>
                                            <svg className={`user-notifications__item-stars ${el.favorite ? 'user-notifications__item-stars-is-favorite' : ''}`} onClick={(e) => handleFavorite(e, el)} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                viewBox="0 0 404.204 404.204" xmlSpace="preserve">
                                                <g>
                                                    <g>
                                                        <path d="M316.953,395.112c-3.35,0-6.706-1.033-9.567-3.106l-105.279-76.5L96.829,392.001
                                                            c-5.706,4.15-13.429,4.15-19.14,0c-5.706-4.145-8.088-11.487-5.912-18.199l40.211-123.771L6.709,173.546
                                                            c-5.706-4.15-8.088-11.493-5.912-18.199s8.425-11.243,15.48-11.243h130.135l40.211-123.771c2.176-6.706,8.431-11.243,15.48-11.243
                                                            c7.049,0,13.304,4.536,15.48,11.243l40.211,123.771h130.135c7.054,0,13.304,4.536,15.48,11.243s-0.207,14.049-5.912,18.199
                                                            l-105.268,76.49l40.211,123.771c2.176,6.706-0.207,14.049-5.912,18.199C323.676,394.078,320.314,395.112,316.953,395.112z
                                                            M202.107,279.118c3.356,0,6.717,1.033,9.567,3.106l74.33,53.999l-28.397-87.373c-2.176-6.706,0.207-14.049,5.912-18.199
                                                            l74.33-53.999h-91.877c-7.044,0-13.293-4.536-15.48-11.243l-28.386-87.384l-28.386,87.384c-2.187,6.706-8.441,11.243-15.48,11.243
                                                            H66.364l74.33,53.999c5.706,4.15,8.088,11.493,5.912,18.199l-28.392,87.373l74.33-53.999
                                                            C195.39,280.152,198.757,279.118,202.107,279.118z"/>
                                                    </g>
                                                </g>
                                            </svg>
                                        </Tooltip>
                                        
                                        <div className='user-notifications__item-del-btn-wrap'>
                                            <button className='user-notifications__item-btn user-notifications__item-btn-del' onClick={(e) => handleIsDeleteNotifications(el._id, e)} disabled={isManagerRole || isTopManagerRole}>
                                                <img src={deleteImg} alt='img'/>
                                            </button>
                                            <button className='user-notifications__item-btn user-notifications__item-btn-del--hover' onClick={(e) => handleIsDeleteNotifications(el._id, e)} disabled={isManagerRole || isTopManagerRole}>
                                                <img src={deleteImgHover} alt='img'/>
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            )) : <div className="user-notifications__error-text">{selectedLanguage?.userPurchases?.userPurchasesErrorText}</div>
                        }
                    </div>
                </div>

                <PaginationItems selectedPaget={selectedPaget} setSelectedPaget={setSelectedPaget} pageRangeDisplayed={5} itemsPerPage={10} quantityAllProducts={quantityAllProducts}/>

            </div>
        </div>
    );
}

export default UserNotificationsView;
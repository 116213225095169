import './CreationProduct.css';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setEditProduct, setProduct } from '../../store/userSlice';
import deleteImg from '../../assets/images/deleteImg.svg';
import products from '../../assets/images/products.svg';
import close2 from '../../assets/images/close2.svg';
import plusImg from '../../assets/images/plusImg.svg';
import 'react-toastify/dist/ReactToastify.css';
import { handleCategoriesMap, showMessage } from '../../helpers/Utils';
import InputColor from '../InputColor/InputColor';
import ColorResult from '../ColorResult/ColorResult';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

function CreationProduct() {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const user = useSelector(state => state.userSlice.user);
    const shop = useSelector(state => state.userSlice.shop);
    const categories = useSelector(state => state.userSlice.categories);
    const editProduct = useSelector(state => state.userSlice.editProduct);
    const [name, setName] = useState('');
    const [selectCategory, setSelectCategory] = useState({});
    const [category_id, setCategory_id] = useState('');
    const [price, setPrice] = useState(0);
    const [new_price, setNew_price] = useState(0);    
    const [details, setDetails] = useState('');
    const [colors, setColors] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [userColors, setUserColors] = useState('');
    const [userSizes, setUserSizes] = useState('');
    const [barcode, setBarcode] = useState('');
    const [quantityInStock, setQuantityInStock] = useState(0);
    const [productImages, setProductImages] = useState([]);
    const [images_URL, setImages_URL] = useState([]);
    const [isNewName, setIsNewName] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const creationProductUserImages = useRef(null);
    const token = localStorage.getItem('token')

    useEffect(() => {
        if (editProduct?.name?.length) {
            setName(editProduct.name)
            setCategory_id(editProduct.category_id)
            setPrice(editProduct.price)
            setNew_price(editProduct.new_price)
            setDetails(editProduct.details)
            setColors(editProduct.colors)
            setSizes(editProduct.sizes)
            setBarcode(editProduct.bar_code)
            setQuantityInStock(editProduct.quantity_in_stock)
            setImages_URL(editProduct.images_URL)
        } 
        
        return removeEditProduct
    }, [])
    
    const uploadPhoto = (image_file) => {
        if (image_file?.name?.length) {
            setProductImages([...productImages, image_file]) 
            setIsNewName(!isNewName)
            creationProductUserImages.current.value = ""
        }
    }
    
    const handleImageUpload = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file.name?.length) {
            setProductImages([...productImages, file]) 
        }
    };

    const removeEditProduct = () => {
        dispatch(setEditProduct({}))
    }

    const handleSetColors = () => {
        if (userColors.length) {
            setColors([...colors, userColors])
            setUserColors('')
        }
    }
   
    const handleSetSizes = () => {
        if (userSizes.length) {
            setSizes([...sizes, userSizes])
            setUserSizes('')
        }
    }
    
    const handleDeleteColors = (color) => {
        setColors([...colors.filter(el => el !== color)])
    }
    
    const handleDeleteSizes = (size) => {
        setSizes([...sizes.filter(el => el !== size)])
    }
    
    const handleDeleteImages = (name) => {
        setProductImages([...productImages.filter(el => el.name !== name)])
    }
   
    const handleDeleteOldImages = (url) => {
        setImages_URL([...images_URL.filter(el => el !== url)])
    }
    
    const handleReturn = () => {
        navigate(`/auth/${user._id}/product`)
        dispatch(setEditProduct({}))
    }

    // create/edit product
    const handleSend = () => {
        if (name?.length && price !== 0 && category_id) {
            let formData = new FormData();

            if (productImages?.length) {
                productImages.map(img => {
                    formData.append('images', img)
                })
            }
            if (images_URL?.length) {
                images_URL.map(img => {
                    formData.append('images_URL[]', img)
                })
            } else {
                formData.append('images_URL[]', []);
            }

            formData.append('shop_id', shop._id);
            formData.append('category_id', selectCategory._id ? selectCategory._id : category_id);
            formData.append('name', name);
            formData.append('price', price);           
            formData.append('quantity_in_stock', quantityInStock ? +quantityInStock : 0);           
            formData.append('new_price', new_price ? new_price : 0);
            formData.append('bar_code', barcode);
            formData.append('details', details);
            if (colors.length) {
                colors.map(colors => {
                    formData.append('colors[]', colors)
                })
            } else {
                formData.append('colors[]', [])
            }
            if (sizes.length) {
                sizes.map(sizes => {
                    formData.append('sizes[]', sizes)
                })
            } else {
                formData.append('sizes[]', [])
            }
    
            if (editProduct?._id) {
                fetch(`${process.env.REACT_APP_BASE_URL}/products/${editProduct._id}?token=${token}`, {
                    method: 'PUT',
                    body: formData
                })
                    .then(res => res.json())
                    .then(res => {
                        if (res.success && res.data) {
                            // dispatch(setUpdataProduct({...data, _id: editProduct._id}))
                            showMessage('success', selectedLanguage?.creationProduct?.creationProductShowSuccessPut)
                        } else {
                            console.log('PUT CreationProduct', res)
                        }
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        showMessage('error', selectedLanguage?.creationProduct?.creationProductShowError)
                    })
                    .finally(() => {
                        navigate(`/auth/${user._id}/product`)
                        dispatch(setEditProduct({}))

                        setName('')
                        setSelectCategory({})
                        setCategory_id('')
                        setPrice(0)
                        setNew_price(0)
                        setQuantityInStock(0)
                        setProductImages([])
                        setDetails('')
                        setBarcode('')
                        setColors([])
                        setSizes([])
                    });
            } else {
                formData.append('number_of_orders', 0);  
                fetch(`${process.env.REACT_APP_BASE_URL}/products/?token=${token}`, {
                    method: 'POST',
                    body: formData
                })
                .then(res => res.json())
                .then(res => {
                    if (res.success && res.data) {
                        dispatch(setProduct(res.data))
                        showMessage('success', selectedLanguage?.creationProduct?.creationProductShowSuccessPost)
                    } else {
                        console.log('POST CreationProduct', res)
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                    showMessage('error', selectedLanguage?.creationProduct?.creationProductShowError)
                })
                .finally(() => {
                    navigate(`/auth/${user._id}/product`)
                    setName('')
                    setSelectCategory({})
                    setCategory_id('')
                    setPrice(0)
                    setNew_price(0)
                    setQuantityInStock(0)
                    setProductImages([])
                    setDetails('')
                    setBarcode('')
                    setColors([])
                    setSizes([])
                })
            }
        }
    }

    const handleChangeNumber = (fun, value) => {
        if  (/^\d+$/.test(value) || value === '') {
            fun(value)
        } 
    };
    
    return (
        <div className='creation-product'>
            <div className="creation-product__header">
                <div className="creation-product__header-title">
                    <img className="creation-product__header-title-img" src={products} alt='img'/>
                    <div className="creation-product__header-title-text">{!!editProduct?.name?.length ? selectedLanguage?.creationProduct?.creationProductTitle1 : selectedLanguage?.creationProduct?.creationProductTitle2}</div>
                </div>
                <div className="creation-product__header-btn-wrap">
                    {
                        editProduct?._id && <button className='creation-product__header-btn-close' onClick={handleReturn}>
                                <img className='creation-product__header-btn-close-img' src={plusImg} alt='img'/>
                                <span className='creation-product__header-btn-close-text'>{selectedLanguage?.creationProduct?.creationProductCancelBtn}</span> 
                            </button>
                    }
                  
                    <button className='creation-product__header-btn' onClick={handleSend}>
                        {
                            editProduct?._id ? selectedLanguage?.creationProduct?.creationProductUpdateBtn : selectedLanguage?.creationProduct?.creationProductCreateBtn
                        }
                    </button>
                </div>
            </div>

            <div className='creation-product--wrap'>
                <div className='creation-product__create--wrap'>

                    <div className='creation-product__warning-text'>{selectedLanguage?.creationProduct?.creationProductWarningText}</div>

                    <div className='creation-product__create-wrap'>
                        <div className='creation-product__create-title creation-product__create-title-name'>{selectedLanguage?.creationProduct?.creationProductNameLabel}</div>
                        <TextField className='creation-product__create-name-input' value={name} onChange={(e) => setName(e.target.value)} label={selectedLanguage?.creationProduct?.creationProductNameInputLabel} variant="outlined" />
                        <div className='creation-product__create-name-sub-title'>{selectedLanguage?.creationProduct?.creationProductNameSubTitle}</div>
                    </div>
                
                    <div className='creation-product__create-wrap'>
                        <div className='creation-product__create-title creation-product__create-title-category'>{selectedLanguage?.creationProduct?.creationProductCategoryTitle}</div>
                        <br/>
                        <FormControl className='creation-product__create-category-select' fullWidth>
                            <InputLabel id="userNotificationsSort">{selectedLanguage?.creationProduct?.creationProductCategoryLabel}</InputLabel>
                            <Select
                                labelId="userNotificationsSort"
                                id="userNotificationsSort"
                                value={category_id}
                                label={selectedLanguage?.creationProduct?.creationProductCategoryLabel}
                                onChange={(e) => setCategory_id(e.target.value)}
                            >
                                <MenuItem value=''>{selectedLanguage?.creationProduct?.creationProductCategoryLabel}</MenuItem>
                                {
                                    !!categories?.length && handleCategoriesMap(categories).map(category => (
                                            <MenuItem disabled={category.sub_categories?.length} value={category._id} key={category._id}>{category.name}</MenuItem>
                                        ))
                                }
                            </Select>
                        </FormControl>
                    </div>

                    <div className='creation-product__create-wrap'>
                        <div className='creation-product__create-title creation-product__create-title-price'>{selectedLanguage?.creationProduct?.creationProductPriceLabel}</div>
                        <br/>
                        <TextField className='creation-product__create-price-input' value={price} onChange={(e) => handleChangeNumber(setPrice, e.target.value)} label={selectedLanguage?.creationProduct?.creationProductPriceInputLabel} variant="outlined" />
                    </div>
                    
                    <div className='creation-product__create-wrap'>
                        <div className='creation-product__create-title'>{selectedLanguage?.creationProduct?.creationProductNewPriceLabel}</div>
                        <div className='creation-product__btn-cancel-new-price-wrap'>
                            <TextField className='creation-product__create-price-input' value={new_price} onChange={(e) => handleChangeNumber(setNew_price, e.target.value)} label={selectedLanguage?.creationProduct?.creationProductPriceInputLabel} variant="outlined" />
                            <button onClick={() => setNew_price(0)} className='creation-product__btn-cancel-new-price'>{selectedLanguage?.creationProduct?.creationProductNewPriceCancelBtn}</button>
                        </div>
                    </div>

                    <div className='creation-product__create-wrap'>
                        <div className='creation-product__create-title'>{selectedLanguage?.creationProduct?.creationProductDescriptionLabel}</div>
                        <TextField className='creation-product__create-details-input' value={details} onChange={(e) => setDetails(e.target.value)} label={selectedLanguage?.creationProduct?.creationProductDescriptionPlaceholder} rows={10} multiline variant="outlined" />
                    </div>

                    <div className='creation-product__create-wrap'>
                        <div className='creation-product__create-title'>{selectedLanguage?.creationProduct?.creationProductSizesLabel}</div>
                        <div className='creation-product__btn-cancel-sizes-wrap'>
                            <TextField className='creation-product__create-sizes-input' value={userSizes} onChange={(e) => setUserSizes(e.target.value)} label={selectedLanguage?.creationProduct?.creationProductSizesInputLabel} variant="outlined" />
                            <button onClick={handleSetSizes} className='creation-product__create-sizes-btn'>+</button>
                        </div>
                        {
                            !!sizes?.length && 
                                <ul className='creation-product__create-result-sizes-wrap'>
                                    {
                                        sizes.map(el => (<li className='creation-product__create-result-sizes' key={el}>
                                            <div className='creation-product__create-result-sizes-text'>{el}</div>
                                            <img className='creation-product__create-result-sizes-btn' onClick={() => handleDeleteSizes(el)} src={close2} alt='img'/>
                                        </li>))
                                    }
                                </ul> 
                        }
                    </div>

                    <div className='creation-product__create-wrap'>
                        <div className='creation-product__btn-cancel-colors-wrap'>
                            <InputColor setValue={setUserColors} value={userColors} id={'creationProductUserColors'} name={'creationProductUserColors'} label={selectedLanguage?.creationProduct?.creationProductColorsLabel}/>
                            <button onClick={handleSetColors} className='creation-product__create-colors-btn'>+</button>
                        </div>
                        {
                            !!colors?.length && 
                                <ul className='creation-product__result-colors-wrap'>
                                    {
                                        colors.map(el => (<li className='creation-product__result-colors' key={el}>
                                            <ColorResult color={el} key={el}/>
                                            <img className='creation-product__result-colors-btn' onClick={() => handleDeleteColors(el)} src={close2} alt='img'/>
                                        </li>))
                                    }
                                </ul> 
                        }
                    </div>

                    <div className='creation-product__create-wrap'>
                        <div className='creation-product__create-title'>{selectedLanguage?.creationProduct?.creationProductQuantityLable}</div>
                        <TextField className='creation-product__create-quantity-stock-input' value={quantityInStock} onChange={(e) => handleChangeNumber(setQuantityInStock, e.target.value)} label={selectedLanguage?.creationProduct?.creationProductQuantityInputLable} variant="outlined" />
                    </div>
                    
                    <div className='creation-product__create-wrap'>
                        <div className='creation-product__create-title'>{selectedLanguage?.creationProduct?.creationProductBarcodeLable}</div>
                        <TextField className='creation-product__create-barcode-input' value={barcode} onChange={(e) => setBarcode(e.target.value)} label={selectedLanguage?.creationProduct?.creationProductBarcodeInputLable} variant="outlined" />
                    </div>
                </div>

                <div className='creation-product__create-img--wrap'>
                    {
                        (productImages?.length + images_URL?.length) < 4 &&
                            <div
                                className='creation-product__create-img-wrap'
                                onDrop={(e) => handleImageUpload(e)}
                                onDragOver={(e) => e.preventDefault()}
                            >   
                                <div className='creation-product__create-img-text-wrap'>
                                    <p>{selectedLanguage?.creationProduct?.creationProductCreateImgText}</p>
                                    <input className="creation-product__create-img-link-upload-input" onChange={(e) => uploadPhoto(e.target.files[0])} ref={creationProductUserImages} type="file" accept="image/*" id="creationProductUserImages"/>
                                    <label className='creation-product__create-img-link-upload-lable' htmlFor='creationProductUserImages'>{selectedLanguage?.creationProduct?.creationProductCreateImgLink}</label>
                                </div>
                            </div>
                    }
                    {
                        (productImages?.length + images_URL?.length) < 3 &&
                            <div
                                className='creation-product__create-img-wrap'
                                onDrop={(e) => handleImageUpload(e)}
                                onDragOver={(e) => e.preventDefault()}
                            >   
                                <div className='creation-product__create-img-text-wrap'>
                                    <p>{selectedLanguage?.creationProduct?.creationProductCreateImgText}</p>
                                    <input className="creation-product__create-img-link-upload-input" onChange={(e) => uploadPhoto(e.target.files[0])} ref={creationProductUserImages} type="file" accept="image/*" id="creationProductUserImages"/>
                                    <label className='creation-product__create-img-link-upload-lable' htmlFor='creationProductUserImages'>{selectedLanguage?.creationProduct?.creationProductCreateImgLink}</label>
                                </div>
                            </div>
                    }
                    {
                        (productImages?.length + images_URL?.length) < 2 &&
                            <div
                                className='creation-product__create-img-wrap'
                                onDrop={(e) => handleImageUpload(e)}
                                onDragOver={(e) => e.preventDefault()}
                            >   
                                <div className='creation-product__create-img-text-wrap'>
                                    <p>{selectedLanguage?.creationProduct?.creationProductCreateImgText}</p>
                                    <input className="creation-product__create-img-link-upload-input" onChange={(e) => uploadPhoto(e.target.files[0])} ref={creationProductUserImages} type="file" accept="image/*" id="creationProductUserImages"/>
                                    <label className='creation-product__create-img-link-upload-lable' htmlFor='creationProductUserImages'>{selectedLanguage?.creationProduct?.creationProductCreateImgLink}</label>
                                </div>
                            </div>
                    }
                    {
                        (productImages?.length + images_URL?.length) < 1 &&
                            <div
                                className='creation-product__create-img-wrap'
                                onDrop={(e) => handleImageUpload(e)}
                                onDragOver={(e) => e.preventDefault()}
                            >   
                                <div className='creation-product__create-img-text-wrap'>
                                    <p>{selectedLanguage?.creationProduct?.creationProductCreateImgText}</p>
                                    <input className="creation-product__create-img-link-upload-input" onChange={(e) => uploadPhoto(e.target.files[0])} ref={creationProductUserImages} type="file" accept="image/*" id="creationProductUserImages"/>
                                    <label className='creation-product__create-img-link-upload-lable' htmlFor='creationProductUserImages'>{selectedLanguage?.creationProduct?.creationProductCreateImgLink}</label>
                                </div>
                            </div>
                    }
                    <div className='creation-product__section-img-wrap'> 
                        {
                            images_URL?.length > 0 && 
                                <ul className='creation-product__create-list-wrap'>
                                    {
                                        images_URL.map(el => (<li className='creation-product__create-list' key={el}>
                                            <img className='creation-product__create-list-img' src={el} alt='img'/>
                                            <img className='creation-product__create-list-btn' onClick={() => handleDeleteOldImages(el)} src={deleteImg} alt='img'/>
                                        </li>))
                                    }
                                </ul> 
                        }
                        {
                            productImages?.length > 0 && 
                                <ul className='creation-product__create-list-wrap'>
                                    {
                                        productImages.map(el => (<li className='creation-product__create-list' key={el.name}>
                                            <img className='creation-product__create-list-img' src={URL.createObjectURL(el)} alt='img'/>
                                            <img className='creation-product__create-list-btn' onClick={() => handleDeleteImages(el.name)} src={deleteImg} alt='img'/>
                                        </li>))
                                    }
                                </ul> 
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreationProduct;
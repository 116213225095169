import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './UserIntegrationView.css';
import { useDispatch, useSelector } from 'react-redux';
import { setIsNeedCreateShop, setSelectedLanguage, setShop, setUser } from '../../store/userSlice';
import code from './../../assets/images/code.png';
import { fetchRequest, showMessage } from '../../helpers/Utils';
import integration from './../../assets/images/integration.svg';
import deleteImg from './../../assets/images/deleteImg.svg';
import googleMerchant from './../../assets/images/googleMerchant.jpeg';
import integrationText from './../../assets/images/integrationText.svg';
import ModalWindow from '../../components/ModalWindow/ModalWindow';

import mixpanel from 'mixpanel-browser';
import { Box, Tab, Tabs, TextField } from '@mui/material';
mixpanel.init('cbc9e4ee0d40873b5a2e3b5e39eedd33', { debug: true }); 
mixpanel.track('Visit integrations page');

function UserIntegrationView() {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const shop = useSelector(state => state.userSlice.shop);
    const user = useSelector(state => state.userSlice.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [linck, setLinck] = useState('');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isZoom, setIsZoom] = useState(false);
    const [errorLinck, setErrorLinck] = useState(false);
    const [isDeleteChat, setIsDeleteChat] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [merchant_id, setMerchant_id] = useState('');
    const token = localStorage.getItem('token')

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        if (shop.merchant_id?.length) {
            setMerchant_id(shop.merchant_id)
        }

        return () => window.removeEventListener("resize", handleResize);
    }, [])

    useEffect(() => {
        if (!linck?.length) {
            setErrorLinck(false)
        }
    }, [linck])
   
    useEffect(() => {
        if (shop.merchant_id?.length) {
            setMerchant_id(shop.merchant_id)
        }
    }, [shop])

    const handleCreateLinck = () => {
        mixpanel.track('Add chat on integrations page');

        if (linck?.length && checkLinck(linck)) {
            const data = {
                ...shop,
                chat_url: linck,
            }
            
            sendUpdateShop(data)
            setErrorLinck(false)
            setLinck('')
        } else {
            setErrorLinck(true)
        }
    }

    const handleDeleteChat = (boolean) => {
        mixpanel.track('Delete chat on integrations page');

        if (boolean) {
            const data = {
                ...shop,
                chat_url: '',
            }
            
            sendUpdateShop(data)
        }

        setIsDeleteChat(false)
    }

    const checkLinck = (str) => {
        let arr = str.split('/')
        return arr.filter(el => el === 'https:' || el === 'embed.tawk.to')?.length === 2 ? true : false
    }

    const handleZoom = () => {
        if (windowWidth < 576) {
            setIsZoom(true)
        }
    }

    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    };

    const handleCreateMerchant = () => {
        const data = {
            ...shop,
            merchant_id: merchant_id,
        }
        
        sendUpdateShop(data)
    }
    
    const sendUpdateShop = (data) => {
        fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/shops/${shop._id}?token=${token}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
            .then(res => {
                if (res.success && res.data) {
                    dispatch(setShop(data));
                    showMessage('success', selectedLanguage?.creationShop?.creationShopShowSuccessUpdateShop)
                } else {
                    console.log('PUT UserShop:', res)
                }
            })
    }

       // для тестів         
    // <!--Start of Tawk.to Script-->
    // <script type="text/javascript">
    // var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    // (function(){
    // var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    // s1.async=true;
    // s1.src='https://embed.tawk.to/63dd24a9474251287911542a/1gobtedp2';
    // s1.charset='UTF-8';
    // s1.setAttribute('crossorigin','*');
    // s0.parentNode.insertBefore(s1,s0);
    // })();
    // </script>
    // <!--End of Tawk.to Script-->

    return (
        <div className='user-integration'>

            {
                isDeleteChat && <ModalWindow title={selectedLanguage?.userIntegration?.userIntegrationModDelChat} handleClick={handleDeleteChat}></ ModalWindow>
            }

            <div className="user-integration__header">
                <div className="user-integration__header-title">
                    <img className="user-integration__header-title-img" src={integration} alt='img'/>
                    <div className="user-integration__header-title-text">{selectedLanguage?.userIntegration?.userIntegrationTitle}</div>
                </div>
            </div>
            <Box className="user-integration__header-tab-wrap" sx={{  }}>
                <Tabs value={tabValue} onChange={(e, value) => setTabValue(value)} textColor='inherit'>
                    <Tab label='Інтеграція чату'/>
                    <Tab label='google merchant'/>
                </Tabs>
            </Box>

            {
                tabValue === 1 &&
                    <div className='user-integration__merchant-wrap'>
                        <img className='user-integration__merchant-img' src={googleMerchant} alt='img'/>
                        <div>
                            <div className='user-integration__merchant-input-lable'>{selectedLanguage?.userIntegration?.userIntegrationMerchantLable1}</div>
                            <div className='user-integration__chat-create-input-wrap'>
                                <TextField className='user-integration__chat-create-input' value={merchant_id} onChange={(e) => setMerchant_id(e.target.value)} label={selectedLanguage?.userIntegration?.userIntegrationMerchantLable2} variant="outlined" />
                                <button className='user-integration__chat-create-input-btn' onClick={() => handleCreateMerchant()}>{shop?.merchant_id?.length ? selectedLanguage?.userIntegration?.userIntegrationChatBtnCreate1 : selectedLanguage?.userIntegration?.userIntegrationChatBtnCreate2}</button>
                            </div>
                        </div>
                        <div className='user-integration__merchant-text'>
                            {selectedLanguage?.userIntegration?.userIntegrationMerchantText1}  
                            &nbsp;<a className='user-integration__merchant-linck' href='https://www.google.com/retail/solutions/merchant-center/' target='_blank'>https://www.google.com/retail/solutions/merchant-center/</a> 
                            &nbsp;{selectedLanguage?.userIntegration?.userIntegrationMerchantText2}
                        </div>
                        <div className='user-integration__merchant-text'>{selectedLanguage?.userIntegration?.userIntegrationMerchantText3}</div>
                        <div className='user-integration__merchant-text'>
                            {selectedLanguage?.userIntegration?.userIntegrationMerchantText4}
                            &nbsp;<a className='user-integration__merchant-linck' href='/instruction' target='_blank'>{selectedLanguage?.userIntegration?.userIntegrationMerchantText5}</a>
                        </div>
                        <div className='user-integration__merchant-text'>{selectedLanguage?.userIntegration?.userIntegrationMerchantText6}</div>
                    </div>
            }

            {
                tabValue === 0 &&
                    <div className='user-integration--wrap'>
                        <img className="user-integration__chat-title-img" src={integrationText} alt='img'/>
                        <div className='user-integration__chat-text'>{selectedLanguage?.userIntegration?.userIntegrationChatText1} <a className='user-integration__chat-linck' href='https://dashboard.tawk.to/login#/chat' target='_blank'>https://dashboard.tawk.to/login#/chat</a> {selectedLanguage?.userIntegration?.userIntegrationChatText2}</div>
                        <div className='user-integration__chat-create-wrap'>
                            <img onClick={handleZoom} className='user-integration__chat-img' src={code} alt='img'/>
                            {
                                isZoom && <img onClick={() => setIsZoom(false)} className='user-integration__chat-img-zoom' src={code} alt='img'/>
                            }
                            <h4 className='user-integration__chat-create-title'>{selectedLanguage?.userIntegration?.userIntegrationChatCreateTitle}</h4>
                            <h4 className='user-integration__chat-create-sub-title'>{selectedLanguage?.userIntegration?.userIntegrationChatCreateSubTitle}</h4>
                            <div className='user-integration__chat-create-input-wrap'>
                                <TextField className='user-integration__chat-create-input' value={linck} onChange={(e) => setLinck(e.target.value)} label={selectedLanguage?.userIntegration?.userIntegrationChatPlaceholder} variant="outlined" />
                                <button className='user-integration__chat-create-input-btn' onClick={() => handleCreateLinck()}>{shop?.chat_url?.length ? selectedLanguage?.userIntegration?.userIntegrationChatBtnCreate1 : selectedLanguage?.userIntegration?.userIntegrationChatBtnCreate2}</button>
                            </div>
                            {
                                errorLinck && <div className='user-integration__error-create'>{selectedLanguage?.userIntegration?.userIntegrationChatError}</div>
                            }
                            {
                                !!shop?.chat_url?.length && 
                                    <div className='user-integration__chat-res'>
                                        <p className='user-integration__chat-res-text'>{selectedLanguage?.userIntegration?.userIntegrationChatResText} </p>
                                        <div className='user-integration__chat-res-linck-wrap'>
                                            <p className='user-integration__chat-res-linck' title={shop?.chat_url}>{shop?.chat_url}</p>
                                            <img className='user-integration__chat-res-del-btn' onClick={() => setIsDeleteChat(true)} src={deleteImg} alt='img'/>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
            }
        </div>
    );
}

export default UserIntegrationView;
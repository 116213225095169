import './MobileMenu.css';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsOpenMenu } from '../../store/homeSlice';

function MobileMenu() {
    const isOpenMenu = useSelector(state => state.homeSlice.isOpenMenu);
    const shop = useSelector(state => state.homeSlice.shop);
    const dispatch = useDispatch();

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, [])

    useEffect(() => {
        if (!isOpenMenu) {
            document.body.style.overflow = "unset"
        } else {
            document.body.style.overflow = "hidden" 
        }
    }, [isOpenMenu])

    const handleClick = () => {
        if (isOpenMenu == true) {
            dispatch(setIsOpenMenu(false))
        } else {
            dispatch(setIsOpenMenu(true))
        }
        if (!isOpenMenu) {
            document.body.style.overflow = "hidden" 
        } else {
            document.body.style.overflow = "unset"
        }
    };

    const handleResize = () => {
        if (window.innerWidth < 1080) {
            dispatch(setIsOpenMenu(false))
        }
    };

    return (
        <div className={`mobile-menu--${shop?.colorSettings?.typeHeader?.length ? shop?.colorSettings?.typeHeader : '1'} ${isOpenMenu ? 'change': ''}`} onClick={handleClick}>
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
        </div>
    );
}

export default MobileMenu;
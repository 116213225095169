import './ShopTemplatesView.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logoLanding from './../../assets/images/logoLanding.png';
import plusImg from './../../assets/images/plusImg.svg';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import { fetchRequest, showMessage } from '../../helpers/Utils';
import { setShop } from '../../store/userSlice';
import { userShopTemplates } from '../../userShopTemplates';
import { setIsNeedCreateShop} from '../../store/userSlice';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { currencySigns } from '../../helpers/Config';

function ShopTemplatesView() {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const shop = useSelector(state => state.userSlice.shop);
    const user = useSelector(state => state.userSlice.user);
    const dispatch = useDispatch();
    const [isModalTemplates, setIsModalTemplates] = useState(false);
    const [isModalCreateShop, setIsModalCreateShop] = useState(false);
    const [isModalSelectNewTemplates, setIsModalSelectNewTemplates] = useState(false);
    const [openTemplate, setOpenTemplate] = useState({});
    const [selectConfig, setSelectConfig] = useState({});
    const [selectConfigId, setSelectConfigId] = useState('');
    const [shopName, setShopName] = useState('');
    const [currency, setCurrency] = useState('');
    const [step, setStep] = useState(1);
    const [stepCount, setStepCount] = useState(0);
    const token = localStorage.getItem('token')

    useEffect(() => {
        if (shop.colorSettings?.selectConfigId?.length) {
            setSelectConfigId(shop.colorSettings?.selectConfigId)
        }
    }, [shop])
    
    const handleSelectNewTemplates = (obj) => {
        setIsModalSelectNewTemplates(true)
        setSelectConfig(obj)
    }
    
    const handleIsSelectNewTemplates = (boolean) => {
        if (boolean) {
            handleIsSelectTemplates(shop)
        } 
        setIsModalSelectNewTemplates(false)
        setSelectConfig({})
    }

    const handleOpenModalTemplates = (obj) => {
        setIsModalTemplates(true)
        setOpenTemplate(obj)
    }
    
    const handleIsTemplates = (boolean) => {
        setIsModalTemplates(false)
        setOpenTemplate({})
    }
    
    const handleKeyDownNexStep = (e) => {
        if(e.key == 'Enter') {
            handleNexStep()
        }
    }
    
    const handleNexStep = () => {
        if (shopName?.length && step === 1) {
            setStepCount(stepCount + 33.3)
            setStep(step + 1)
        } else if (currency?.length && step === 2) {
            setStepCount(stepCount + 33.3)
            setStep(step + 1)
        }
    }
  
    const handleBackStep = () => {
        if (step === 3 && selectConfigId?.length) {
            setStepCount(stepCount - 66.6)
        } else {
            setStepCount(stepCount - 33.3)
        }
        setStep(step - 1)
        setSelectConfigId('')
    }
    
    const handleSelectTemplates = (obj) => {
        setStepCount(100)
        setSelectConfig(obj.config)
        setSelectConfigId(obj.id)
    }

    const handleIsSelectTemplates = (obj) => {
        let data = {
            ...obj,
            colorSettings: {
                ...selectConfig.config, 
                selectConfigId: selectConfig.id,
                textInfoTopBlock: obj?.colorSettings?.textInfoTopBlock?.length ? obj?.colorSettings?.textInfoTopBlock : ''
            },
        }

        fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/shops/${obj._id}?token=${token}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
            .then(res => {
                console.log('PUT ShopTemplatesView:', res)
                if (res.success && res.data) {
                    dispatch(setShop(data));
                    showMessage('success', selectedLanguage?.creationShop?.creationShopShowSuccessUpdateShop)
                } else {
                    console.log('PUT ShopTemplatesView:', res)
                }
            })
            
        dispatch(setIsNeedCreateShop(false));
        setSelectConfig({})
        setShopName('')
    }

    const handleCreateShop = () => {
        if (!selectConfigId?.length) {
            setIsModalCreateShop(true)
        } else {
            handleIsCreateShop(true)
        }
    }
    
    const handleIsCreateShop = (boolean) => {
        if (boolean) {
            let data = {
                name: shopName,
                owner_id: user._id,
                currency: currency,
                deliveryMethods: [selectedLanguage?.shopTemplates?.shopTemplatesDeliveryMethods],
                paymentMethods: [selectedLanguage?.shopTemplates?.shopTemplatesPaymentMethods],
                colorSettings: {
                    ...selectConfig.config, 
                },
            }

            fetchRequest('POST', `${process.env.REACT_APP_BASE_URL}/shops/?token=${token}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
                .then(res => {
                    console.log('POST ShopTemplatesView:', res)
                    if (res.success && res.data) {
                        dispatch(setShop(res.data));
                        handleIsSelectTemplates(res.data)
                        showMessage('success', selectedLanguage?.creationShop?.creationShopShowSuccessUpdateShop)
                    } else {
                        console.log('POST ShopTemplatesView:', res)
                    }
                })
        } 

        setIsModalCreateShop(false)
    }
   
   
    return (
        <div className={`shop-templates ${shop?._id?.length ? 'shop-templates--shop' : ''}`}>

            {
                isModalTemplates && 
                    <ModalWindow title={`${selectedLanguage?.shopTemplates?.shopTemplatesCardTitle} ${openTemplate.id}`}  handleClick={handleIsTemplates} notBtn={true} addStyles={{maxWidth: '70vw', height: '100vh'}}>
                        <div className="shop-templates__modal-templates">
                            <Swiper
                                // slidesPerView={vw > 500 ? vw > 768 ? 3 : 2 : 1}
                                slidesPerView={1}
                                spaceBetween={30}
                                // slidesPerGroup={vw > 500 ? vw > 768 ? 3 : 2 : 1}
                                slidesPerGroup={1}
                                loop={true}
                                loopFillGroupWithBlank={true}
                                pagination={{
                                clickable: true,
                                }}
                                navigation={true}
                                initialSlide='1'
                                modules={[Navigation]}
                                className="mySwiper shop-templates__modal-swiper"
                            >
                                {
                                    openTemplate.img.map(el => (
                                        <SwiperSlide key={el}><img className='shop-templates__modal-templates-img' src={el} alt='img'/></SwiperSlide>
                                    ))
                                }
                            </Swiper>
                        </div>
                    </ModalWindow>
            }

            {
                isModalCreateShop && <ModalWindow title={selectedLanguage?.shopTemplates?.shopTemplatesModCreateShopTitle} handleClick={handleIsCreateShop}></ ModalWindow>
            }
            
            {
                isModalSelectNewTemplates && <ModalWindow title={selectedLanguage?.shopTemplates?.shopTemplatesModSelectNewTemplatesTitle} handleClick={handleIsSelectNewTemplates}></ ModalWindow>
            }

            {
                !!shop?._id?.length ? 
                    <>
                        <h3 className="shop-templates__title">{selectedLanguage?.shopTemplates?.shopTemplatesTitle}</h3>
                        <div className="shop-templates__cards">
                            {
                                !!userShopTemplates.length && userShopTemplates.map(el =>(
                                    <div className={`shop-templates__card ${selectConfigId == el.id ? 'shop-templates__card-select' : ''}`} key={el.id}>
                                        <img className='shop-templates__card-img' onClick={() => handleOpenModalTemplates(el)} src={el.img[0]} alt='img'/>
                                        <div className='shop-templates__card-info'>
                                            <div className='shop-templates__card-title'>{selectedLanguage?.shopTemplates?.shopTemplatesCardTitle} {el.id}</div>
                                            <div className='shop-templates__card-text'>Write an amazing description in this dedicated card section. Each word counts. </div>
                                            <Button onClick={() => handleSelectNewTemplates(el)} variant="contained">{selectedLanguage?.shopTemplates?.shopTemplatesCardBtn}</Button>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </>
                :
                    <>
                        <div className='shop-templates__logo-wrap'>
                            <div className='container'>
                                <img className='shop-templates__logo' src={logoLanding} alt='img'/>
                            </div>
                        </div>

                        <div className='container'>
                            <div className='shop-templates__scale-wrap'>
                                <div className={`shop-templates__scale-black ${selectConfigId?.length ? 'shop-templates__scale-black--4' : ''}`} style={{width: `${stepCount}%`}}></div>
                                <div className='shop-templates__scale'></div>
                            </div>

                            {
                                step === 1 ? 
                                    <>
                                        <div className='shop-templates__title'>{selectedLanguage?.shopTemplates?.shopTemplatesStep1Title}</div>
                                        <div className='shop-templates__sub-title'>{selectedLanguage?.shopTemplates?.shopTemplatesStep1SubTitle}</div>

                                        <div className='shop-templates__input-wrap'>
                                            <TextField value={shopName} onChange={(e) => setShopName(e.target.value)} onKeyDown={(e) => handleKeyDownNexStep(e)} label={selectedLanguage?.shopTemplates?.shopTemplatesStep1Label} variant="outlined" />
                                        </div>

                                        <div className='shop-templates__btn-wrap'>
                                            <button className='shop-templates__next-btn' onClick={handleNexStep}>{selectedLanguage?.shopTemplates?.shopTemplatesStepBtnNext}</button>
                                        </div>
                                    </>
                                : step === 2 ? 
                                    <>
                                        <div className='shop-templates__title'>{selectedLanguage?.shopTemplates?.shopTemplatesStep2Title}</div>
                                        <div className='shop-templates__sub-title'>{selectedLanguage?.shopTemplates?.shopTemplatesStep2SubTitle}</div>

                                        <div className='shop-templates__input-wrap'>
                                            <FormControl className='creation-product__create-category-select' fullWidth>
                                                <InputLabel id="userNotificationsSort">{selectedLanguage?.shopTemplates?.shopTemplatesStep2Label}</InputLabel>
                                                <Select
                                                    labelId="userNotificationsSort"
                                                    id="shopTemplatesStep2LabelSort"
                                                    value={currency}
                                                    label={selectedLanguage?.shopTemplates?.shopTemplatesStep2Label}
                                                    onChange={(e) => setCurrency(e.target.value)}
                                                >
                                                    {
                                                        !!currencySigns?.length && currencySigns.map(el => (
                                                                <MenuItem value={el.value} key={el.title}>{el.title} {el.value}</MenuItem>
                                                            ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>

                                        <div className='shop-templates__btn-wrap'>
                                            <button className='shop-templates__next-btn' onClick={handleBackStep}>{selectedLanguage?.shopTemplates?.shopTemplatesStepBtnBack}</button>
                                            <button className='shop-templates__next-btn' onClick={handleNexStep}>{selectedLanguage?.shopTemplates?.shopTemplatesStepBtnNext}</button>
                                        </div>
                                    </>
                                : step === 3 && 
                                    <>
                                        <h3 className="shop-templates__title">{selectedLanguage?.shopTemplates?.shopTemplatesTitle}</h3>
                                        <div className='shop-templates__sub-title'>{selectedLanguage?.shopTemplates?.shopTemplatesStep3SubTitle}</div>
                                        <div className='shop-templates__btn-wrap'>
                                            <button className='shop-templates__next-btn' onClick={handleBackStep}>{selectedLanguage?.shopTemplates?.shopTemplatesStepBtnBack}</button>
                                            <button className='shop-templates__next-btn' onClick={handleCreateShop}>{selectConfigId?.length ? selectedLanguage?.shopTemplates?.shopTemplatesStepBtnCreate : selectedLanguage?.shopTemplates?.shopTemplatesStepBtnMiss}</button>
                                        </div>
                                        <div className="shop-templates__cards">
                                            <div className={`shop-templates__card  ${selectConfigId === '0' ? 'shop-templates__card-select' : ''}`} >
                                                <img className='shop-templates__card-img-none' src={plusImg} alt='img'/>
                                                <div className='shop-templates__card-info'>
                                                    <div className='shop-templates__card-title'>{selectedLanguage?.shopTemplates?.shopTemplatesCardTitleMy}</div>
                                                    <div className='shop-templates__card-text'>Write an amazing description in this dedicated card section. Each word counts. </div>
                                                    <Button onClick={() => handleSelectTemplates({id: '0'})} variant="contained">{selectedLanguage?.shopTemplates?.shopTemplatesStepBtnMyCreate}</Button>
                                                </div>
                                            </div>
                                            {
                                                !!userShopTemplates.length && userShopTemplates.map(el =>(
                                                    <div className={`shop-templates__card ${selectConfigId == el.id ? 'shop-templates__card-select' : ''}`} key={el.id}>
                                                        <img className='shop-templates__card-img' onClick={() => handleOpenModalTemplates(el)} src={el.img[0]} alt='img'/>
                                                        <div className='shop-templates__card-info'>
                                                            <div className='shop-templates__card-title'>{selectedLanguage?.shopTemplates?.shopTemplatesCardTitle} {el.id}</div>
                                                            <div className='shop-templates__card-text'>Write an amazing description in this dedicated card section. Each word counts. </div>
                                                            <Button onClick={() => handleSelectTemplates(el)} variant="contained">{selectedLanguage?.shopTemplates?.shopTemplatesCardBtn}</Button>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </>
                            }
                        </div>
                    </>
            }
        </div>
    );
}

export default ShopTemplatesView;
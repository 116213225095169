import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './BlogView.css';
import { useSelector } from 'react-redux';
import HeaderLanding from '../../components/HeaderLanding/HeaderLanding';
import FooterLanding from '../../components/FooterLanding/FooterLanding';

function BlogView() {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    let { idBlog } = useParams();
    const [blogNumer, setBlogNumer] = useState(null);

    useEffect(() => {
        setBlogNumer(idBlog - 1)
    }, [])

    return (
        <div className='blog-view-wrap'>
            <HeaderLanding />
            { selectedLanguage && <img className='blog-view__img' src={selectedLanguage?.blogView[blogNumer]?.blogImage} alt={'Check-in Shop Blog Image ' + selectedLanguage?.blogView[blogNumer]?.blogViewTitle}/> }
            <div className='blog-view container'>
                <div className='blog-view__title'>{selectedLanguage?.blogView[blogNumer]?.blogViewTitle}</div>
                <div className='blog-view__sub-title'>{selectedLanguage?.blogView[blogNumer]?.blogViewSubTitle1}</div>
                <div className='blog-view__text'>{selectedLanguage?.blogView[blogNumer]?.blogViewText1}</div>
                <div className='blog-view__sub-title'>{selectedLanguage?.blogView[blogNumer]?.blogViewSubTitle2}</div>
                <div className='blog-view__text'>{selectedLanguage?.blogView[blogNumer]?.blogViewText2}</div>
                <div className='blog-view__sub-title'>{selectedLanguage?.blogView[blogNumer]?.blogViewSubTitle3}</div>
                <div className='blog-view__text'>{selectedLanguage?.blogView[blogNumer]?.blogViewText3}</div>
            </div>
            <FooterLanding />
        </div>
    );
}

export default BlogView;
import './MoreAnalytics2.css';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Preloader from '../Preloader/Preloader';
import Chart from 'chart.js/auto';
import { fetchGetData } from '../../helpers/Utils';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

function MoreAnalytics2() {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const isNeedCreateShop = useSelector(state => state.userSlice.isNeedCreateShop);
    const shop = useSelector(state => state.userSlice.shop);
    const [vw, setVw] = useState(window.innerWidth);
    const [fullYear, setFullYear] = useState(new Date().getFullYear());
    const [isGraf3, setIsGraf3] = useState(false);
    const [isGraf4, setIsGraf4] = useState(false);
    const [isGraf7, setIsGraf7] = useState(false);
    const [isPreloader, setIsPreloader] = useState(true);
    const [visitorsForYear, setVisitorsForYear] = useState([]);
    const [ordersForYear, setOrdersForYear] = useState([]);
    const [visitorsYearAdvs, setVisitorsYearAdvs] = useState([]);
    const [yearmanagers, setYearmanagers] = useState([]);
    const token = localStorage.getItem('token')

    useEffect(() => {
        if (shop?._id) {
            fetchGetData(`${process.env.REACT_APP_BASE_URL}/analytics/${shop._id}/second?token=${token}`, () => setIsPreloader(false))
                .then(res => {
                    if (res.success && res.data) {
                        setOrdersForYear(res.data.yearlyPurchases)

                        let arr = res.data.yearlyAnalytics.sort((a, b) => a.month.slice(0, 2) - b.month.slice(0, 2))
                        setVisitorsForYear(arr)

                        setVisitorsYearAdvs(res.data.yearlyAdvs)

                        setYearmanagers(res.data.get_yearly_purchases_for_month)

                        setIsPreloader(false)
                    } else {
                        console.log('GET MoreAnalytics2', res)
                    }
                })
        } else {
            setIsPreloader(false)
        }

        window.addEventListener("resize", handleResize);
  
        return () => window.removeEventListener("resize", handleResize);
    }, [])
  
    useEffect(() => {
        // графік виконаних за рік
        if (ordersForYear?.length && !isGraf3 && document.getElementById('moreAnalytics2CompletedOrdersGraph') !== null) {
            setIsGraf3(true)
            let arrAll = []
            let arrInProcess = []
            let arrDone = []
            let arrRejected = []
            ordersForYear.forEach(el => {
                arrAll.push(el.count); 
                arrInProcess.push(el.status?.in_progress); 
                arrDone.push(el.status?.done)
                arrRejected.push(el.status?.rejected)
            })
            let barChartData = {
            labels: [...selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphVisitorsLabels],
            datasets: [
                {
                    label: selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphVisitorsLabelAll,
                    backgroundColor: "yellow",
                    borderColor: "orange",
                    borderWidth: 1,
                    data: [...arrAll]
                },
                {
                    label: selectedLanguage?.moreAnalytics2?.moreAnalytics2GraphVisitorsLabelInProcess,
                    backgroundColor: "lightblue",
                    borderColor: "blue",
                    borderWidth: 1,
                    data: [...arrInProcess]
                },
                {
                    label: selectedLanguage?.moreAnalytics2?.moreAnalytics2GraphVisitorsLabelDone,
                    backgroundColor: "lightgreen",
                    borderColor: "green",
                    borderWidth: 1,
                    data: [...arrDone]
                },
                {
                    label: selectedLanguage?.moreAnalytics2?.moreAnalytics2GraphVisitorsLabelRejected,
                    backgroundColor: "pink",
                    borderColor: "red",
                    borderWidth: 1,
                    data: [...arrRejected]
                },
            ]
            };
            
            let chartOptions = {
            legend: {
                position: "top"
            },
            maintainAspectRatio: false,
            scales: {
                y: {
                beginAtZero: true
                }
            },
            indexAxis: `${vw < 368 ? 'y' : ''}`,
            }
    
            const completedOrder = new Chart(
                document.getElementById('moreAnalytics2CompletedOrdersGraph'),
                {
                    type: "bar",
                    data: barChartData,
                    options: chartOptions
                }
            );
        }
    
        // графік відвідувачів за рік
        // переробити умову коли будуть дані
        if (visitorsForYear?.length && !isGraf4 && document.getElementById('moreAnalytics2AnalyticsForYearGraph') !== null) {
            setIsGraf4(true)
            let barChartDataVisitors = {
            labels: [...selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphVisitorsLabels],
            datasets: [
                {
                    label: selectedLanguage?.moreAnalytics2?.moreAnalytics2GraphVisitorsYearLabel,
                    backgroundColor: "yellow",
                    borderColor: "orange",
                    borderWidth: 1,
                    data: [...visitorsForYear.map(el => el.count)]
                },
            ]
            };
            
            let chartOptionsVisitors = {
            legend: {
                position: "top"
            },
            maintainAspectRatio: false,
            scales: {
                y: {
                beginAtZero: true
                }
            },
            indexAxis: `${vw < 368 ? 'y' : ''}`,
            }
        
            const completedOrder = new Chart(
                document.getElementById('moreAnalytics2AnalyticsForYearGraph'),
                {
                    type: "bar",
                    data: barChartDataVisitors,
                    options: chartOptionsVisitors
                }
            );
        }
        
        // // графік відвідувачів з інших магазинів
        if (visitorsYearAdvs?.length && !isGraf7 && document.getElementById('moreAnalytics2VisitorAromAnotherShopGraph') !== null) {
            setIsGraf7(true)
            let barChartDataVisitorFromAnotherShop = {
            labels: [...selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphVisitorsLabels],
            datasets: [
                {
                    label: 'відвідувачі',
                    backgroundColor: "yellow",
                    borderColor: "orange",
                    borderWidth: 1,
                    data: [...visitorsYearAdvs.map(el => el.count)]
                },
            ]
            };
            
            let chartOptionsVisitorFromAnotherShop = {
            legend: {
                position: "top"
            },
            maintainAspectRatio: false,
            scales: {
                y: {
                beginAtZero: true
                }
            },
            indexAxis: `${vw < 368 ? 'y' : ''}`,
            }
        
            const completedOrder = new Chart(
                document.getElementById('moreAnalytics2VisitorAromAnotherShopGraph'),
                {
                    type: "bar",
                    data: barChartDataVisitorFromAnotherShop,
                    options: chartOptionsVisitorFromAnotherShop
                }
            );
        }
    }, [visitorsYearAdvs, visitorsForYear, ordersForYear])


    // --------------------------------------------------
  
    const handleResize = () => {
        let res = document.getElementById('moreAnalytics2Swaper')
        if (res) {
            setVw(res.offsetWidth)
        }
        // setVw(window.innerWidth)
    }
   
    const isYearmanagers = () => {
      return [...yearmanagers.filter(el => el.data?.length > 0)]?.length > 0 ? true : false 
    }

    const analyticsError = () => {
        return !ordersForYear?.length && !visitorsForYear?.length && !visitorsYearAdvs?.length && !isYearmanagers() ? true : false
      }

    return (
        <div className="more-analytics2">

            {
                isPreloader ? <Preloader /> : isNeedCreateShop ? 
                    <div>
                        <div className="more-analytics2__analytics-error container">{selectedLanguage?.moreAnalytics1?.moreAnalytics1Error}</div>
                    </div>
                :
                <div className="more-analytics2--wrpa container">

                {
                    analyticsError() ? <div className="more-analytics1__error">Аналітика відсутня. Тут будуть відображатись дані за повний місяць, або рік.</div> 
                        :
                        <>
                            {    // доробити
                                !!ordersForYear?.length && 
                                    <div className="more-analytics2__completed-wrap">
                                        <h4 className="more-analytics2__completed-title">{selectedLanguage?.moreAnalytics2?.moreAnalytics2GraphCompletedTitle}</h4>
                                        {/* <div className="more-analytics2__completed-sub-title">{selectedLanguage?.moreAnalytics2?.moreAnalytics2GraphCompletedSubTitle}&nbsp;{fullYear}</div> */}
                                        <div className="more-analytics2__completed">
                                            <div className="more-analytics2__completed-graph">
                                                <canvas className='more-analytics2__completed-graph-canvas' id="moreAnalytics2CompletedOrdersGraph"></canvas>
                                            </div>
                                            <Accordion className='more-analytics2__accordion-result'>
                                                <AccordionSummary
                                                    expandIcon={<p>&#94;</p>}
                                                    aria-controls="panel1a-ordersForYear"
                                                    id="panel1a-ordersForYear"
                                                >
                                                    <Typography>{selectedLanguage?.moreAnalytics2?.moreAnalytics2ViewTable}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <p className="more-analytics2__completed-tabl">
                                                            {
                                                                ordersForYear?.map((el, i) => (
                                                                    <p className="more-analytics2__completed-tabl-item" key={el._id}>
                                                                        <p className="more-analytics2__completed-tabl-item-info"><b>{el.month}&nbsp;{selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphVisitorsLabels[i]}</b></p> 
                                                                        <p className="more-analytics2__completed-tabl-item-info"><b>{selectedLanguage?.moreAnalytics1?.moreAnalytics1AllOrderStatus}</b> {el?.count}</p> 
                                                                        <p className="more-analytics2__completed-tabl-item-info"><b>{selectedLanguage?.moreAnalytics1?.moreAnalytics1InProcessStatus}</b> {el?.status?.in_process}</p> 
                                                                        <p className="more-analytics2__completed-tabl-item-info"><b>{selectedLanguage?.moreAnalytics1?.moreAnalytics1DoneStatus}</b> {el?.status?.done}</p> 
                                                                        <p className="more-analytics2__completed-tabl-item-info"><b>{selectedLanguage?.moreAnalytics1?.moreAnalytics1RejectedStatus}</b> {el?.status?.rejected} </p> 
                                                                    </p>
                                                                ))
                                                            }
                                                        </p>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                    </div>
                            }
                                
                            {    // доробити відвідувачів за рік
                                !!visitorsForYear?.length && 
                                    <div className="more-analytics2__visitors-year-wrap">
                                        <h4 className="more-analytics2__visitors-year-title">{selectedLanguage?.moreAnalytics2?.moreAnalytics2GraphVisitorsYearTitle}</h4>
                                        {/* <div className="more-analytics2__visitors-year-sub-title">{selectedLanguage?.moreAnalytics2?.moreAnalytics2GraphVisitorsYearSubTitle}&nbsp;{fullYear}</div> */}
                                        <div className="more-analytics2__visitors-year">
                                            <div className="more-analytics2__visitors-year-graph">
                                                <canvas className="more-analytics2__visitors-year-graph-canvas" id="moreAnalytics2AnalyticsForYearGraph"></canvas>
                                            </div>
                                            <Accordion className='more-analytics2__accordion-result'>
                                                <AccordionSummary
                                                    expandIcon={<p>&#94;</p>}
                                                    aria-controls="panel1a-ordersForYear"
                                                    id="panel1a-ordersForYear"
                                                >
                                                    <Typography>{selectedLanguage?.moreAnalytics2?.moreAnalytics2ViewTable}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <p className="more-analytics2__visitors-year-tabl">
                                                            {
                                                                visitorsForYear.map((el, i) => (<p className="more-analytics2__visitors-year-tabl-item" key={el._id}>
                                                                    <p className="more-analytics2__visitors-year-tabl-item-info"><b>{el.month}</b></p> 
                                                                    {
                                                                        selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphVisitorsLabels?.length &&
                                                                            <p className="more-analytics2__visitors-year-tabl-item-info"><b>{selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphVisitorsLabels[i]}</b></p> 
                                                                    }
                                                                    <p className="more-analytics2__visitors-year-tabl-item-info"><b>{selectedLanguage?.moreAnalytics2?.moreAnalytics2VisitorsYearItemInfo}</b> {el.count}</p> 
                                                                </p>))
                                                            }
                                                        </p>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                    </div>
                            }
                            
                            {    // доробити кількість відвідувачів через блок реклами
                                !!visitorsYearAdvs?.length && 
                                    <div className="more-analytics2__visitors-year-wrap">
                                        <h4 className="more-analytics2__visitors-year-title">{selectedLanguage?.moreAnalytics2?.moreAnalytics2GraphVisitorsYearAdvsTitle}</h4>
                                        <div className="more-analytics2__visitors-year-sub-title">{selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphVisitorsYearSubTitle}&nbsp;{fullYear}</div>
                                        <div className="more-analytics2__visitors-year">
                                            <div className="more-analytics2__visitors-year-graph">
                                                <canvas className="more-analytics2__visitors-year-graph-canvas-ads" id="moreAnalytics2VisitorAromAnotherShopGraph"></canvas>
                                            </div>
                                            <Accordion className='more-analytics2__accordion-result'>
                                                <AccordionSummary
                                                    expandIcon={<p>&#94;</p>}
                                                    aria-controls="panel1a-ordersForYear"
                                                    id="panel1a-ordersForYear"
                                                >
                                                    <Typography>{selectedLanguage?.moreAnalytics2?.moreAnalytics2ViewTable}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <p className="more-analytics2__visitors-year-tabl">
                                                            {
                                                                visitorsYearAdvs.map((el, i) => (<p className="more-analytics2__visitors-year-tabl-item" key={el._id}>
                                                                    <p className="more-analytics2__visitors-year-tabl-item-info"><b>{el.month} </b><b>{selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphVisitorsLabels[i]}</b></p> 
                                                                    <p className="more-analytics2__visitors-year-tabl-item-info"><b>{selectedLanguage?.moreAnalytics2?.moreAnalytics2GraphVisitorsYearAdvsInfo}</b> {el.count}</p> 
                                                                </p>))
                                                            }
                                                        </p>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                    </div>
                            }

                            {    // табличка успішношті менеджерів за рік
                                !!yearmanagers?.length && isYearmanagers() && selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphVisitorsLabels?.length && 
                                    <div className="more-analytics2__year-managers-wrap">
                                        <h4 className="more-analytics2__year-managers-title">{selectedLanguage?.moreAnalytics2?.moreAnalytics2GraphYearManagersTitle}</h4>
                                        {/* <div className="more-analytics2__year-managers-sub-title">{selectedLanguage?.moreAnalytics2?.moreAnalytics2GraphCompletedSubTitle}&nbsp;{fullYear}</div> */}
                                        <div className="more-analytics2__year-managers-tabl">
                                            {
                                                yearmanagers.map((el, i) => (
                                                    <div className="more-analytics2__year-managers-tabl-item" key={i}>
                                                        <div className="more-analytics2__year-managers-tabl-item-title">{el.month} {selectedLanguage?.moreAnalytics1?.moreAnalytics1GraphVisitorsLabels[+el.month.split('-')[0] - 1]}</div>
                                                        <div className="more-analytics2__year-managers-tabl-item-managers">
                                                            {
                                                                !!el.data?.length && el.data.map((ell, i) => (
                                                                    <div className={`more-analytics2__year-managers-tabl-item-manager ${(i + 1)%2 ? 'more-analytics2__year-managers-tabl-item-manager--dark' : ''}`}>
                                                                        <div className="more-analytics2__year-managers-tabl-item-manager-name">{ell.data}</div>
                                                                        <div className="more-analytics2__year-managers-tabl-item-manager-data-wrap">
                                                                            <div className="more-analytics2__year-managers-tabl-item-manager-data">{selectedLanguage?.moreAnalytics2?.moreAnalytics2GraphYearManagersAll} {ell.count}</div>
                                                                            <div className="more-analytics2__year-managers-tabl-item-manager-data">{selectedLanguage?.moreAnalytics2?.moreAnalytics2GraphVisitorsLabelDone} {ell.status.done}</div>
                                                                            <div className="more-analytics2__year-managers-tabl-item-manager-data">{selectedLanguage?.moreAnalytics2?.moreAnalytics2GraphVisitorsLabelInProcess} {ell.status.in_progress}</div>
                                                                            <div className="more-analytics2__year-managers-tabl-item-manager-data">{selectedLanguage?.moreAnalytics2?.moreAnalytics2GraphVisitorsLabelRejected} {ell.status.rejected}</div>
                                                                        </div>
                                                                    </div>
                                                            ))
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                            }
                        </>
                }

                </div>
            }
        </div>
    );
}

export default MoreAnalytics2;
import template11 from '../src/assets/images/templates/template11.png'
import template12 from '../src/assets/images/templates/template12.png'
import template13 from '../src/assets/images/templates/template13.png'
import template14 from '../src/assets/images/templates/template14.png'
import template15 from '../src/assets/images/templates/template15.png'
import template21 from '../src/assets/images/templates/template21.png'
import template22 from '../src/assets/images/templates/template22.png'
import template23 from '../src/assets/images/templates/template23.png'
import template24 from '../src/assets/images/templates/template24.png'
import template25 from '../src/assets/images/templates/template25.png'
import template31 from '../src/assets/images/templates/template31.png'
import template32 from '../src/assets/images/templates/template32.png'
import template33 from '../src/assets/images/templates/template33.png'
import template34 from '../src/assets/images/templates/template34.png'
import template41 from '../src/assets/images/templates/template41.png'
import template42 from '../src/assets/images/templates/template42.png'
import template43 from '../src/assets/images/templates/template43.png'
import template51 from '../src/assets/images/templates/template51.png'
import template52 from '../src/assets/images/templates/template52.png'
import template53 from '../src/assets/images/templates/template53.png'
import template54 from '../src/assets/images/templates/template54.png'
import template61 from '../src/assets/images/templates/template61.png'
import template62 from '../src/assets/images/templates/template62.png'
import template63 from '../src/assets/images/templates/template63.png'
import template64 from '../src/assets/images/templates/template64.png'
export const userShopTemplates = [
    {
        id: '1',
        img: [
            template11,
            template12,
            template13,
            template14,
            template15,
        ],
        config: {
            textInfoTopBlock:"",
            bgColorTopBlockHeader:"#FFFFFF",
            isAnimationTopBlock:true,
            colorTopBlockHeader:"#000000",
            bgColorHeader:"inherit",
            colorHeaderText:"#AB0A8D",
            bgNewProductHeader:"#F71111",
            colorNewProductHeader:"#FFFFFF",
            bgSubCategories:"inherit",
            colorSubCategoriesText:"#FFFFFF",
            typeHeader:"1",
            bgColorFooter:"#F0F0F2",
            colorFooterText:"#444141",
            subscriptionInformation:"",
            isSubscriptionHide:true,
            bgColorMain:"#FFFFFF",
            colorMainText:"#474747",
            selectBtn:"3",
            colorTextBtn:"#FAFAFA",
            colorTextBtnHover:"#710E71",
            firstColorGradient:"#B54AB5",
            secondColorGradient:"#AB0A9A",
            firstColorGradientHover:"#AB0AA4",
            secondColorGradientHover:"#AB0AA3"
        }
    },
    {
        id: '2',
        img: [
            template21,
            template22,
            template23,
            template24,
            template25,
        ],
        config: {
            textInfoTopBlock: "Розпродаж", 
            bgColorTopBlockHeader: "#DFFF6B", 
            isAnimationTopBlock: true, 
            colorTopBlockHeader: "#000000", 
            bgColorHeader: "#000000", 
            colorHeaderText: "#FFFFFF", 
            bgNewProductHeader: "#F71111", 
            colorNewProductHeader: "#FFFFFF", 
            bgSubCategories: "#000000",
             colorSubCategoriesText: "#FFFFFF", 
             typeHeader: "1", 
             bgColorFooter: "#EBEBEB", 
             colorFooterText: "#444141", 
             subscriptionInformation: "", 
             isSubscriptionHide: true, 
             bgColorMain: "#FFFFFF", 
             colorMainText: "#474747", 
             selectBtn: "3", 
             colorTextBtn: "#FAFAFA", 
             colorTextBtnHover: "#417505", 
             firstColorGradient: "#0BA24D", 
             secondColorGradient: "#2ED139", 
             firstColorGradientHover: "#417505", 
             secondColorGradientHover: "#417505"
        }
    },
    {
        id: '3',
        img: [
            template31,
            template32,
            template33,
            template34,
        ],
        config: {
            textInfoTopBlock: "Розпродаж", 
            bgColorTopBlockHeader: "#dfff6b", 
            isAnimationTopBlock: true, 
            colorTopBlockHeader: "#000000", 
            bgColorHeader: "#f3f2f2", 
            colorHeaderText: "#454545", 
            bgNewProductHeader: "#f71111", 
            colorNewProductHeader: "#ffffff", 
            bgSubCategories: "#f3f2f2", 
            colorSubCategoriesText: "#292929", 
            typeHeader: "1", 
            bgColorFooter: "#ebebeb", 
            colorFooterText: "#444141", 
            subscriptionInformation: "", 
            isSubscriptionHide: true, 
            bgColorMain: "#ffffff", 
            colorMainText: "#474747", 
            selectBtn: "8", 
            colorTextBtn: "#6b6b6b", 
            colorTextBtnHover: "#383b2b", 
            firstColorGradient: "#efff14", 
            secondColorGradient: "#efff38", 
            firstColorGradientHover: "#f0ff1a", 
            secondColorGradientHover: "#f0ff1f"
        }
    },
    {
        id: '4',
        img: [
            template41,
            template42,
            template43,
        ],
        config: {
            textInfoTopBlock: "Акція -50%",
            bgColorTopBlockHeader: "#ff4751",
            isAnimationTopBlock: true,
            colorTopBlockHeader: "#000000",
            bgColorHeader: "#006141",
            colorHeaderText: "#ffffff",
            bgNewProductHeader: "#f71111",
            colorNewProductHeader: "#ffffff",
            bgSubCategories: "#4e4848",
            colorSubCategoriesText: "#ffffff",
            typeHeader: "1",
            bgColorFooter: "#006141",
            colorFooterText: "#000000",
            subscriptionInformation: "",
            isSubscriptionHide: false,
            bgColorMain: "#ffffff",
            colorMainText: "#000000",
            selectBtn: "7",
            colorTextBtn: "#ffffff",
            colorTextBtnHover: "#ffffff",
            firstColorGradient: "#09a537",
            secondColorGradient: "#06843c",
            firstColorGradientHover: "#067104",
            secondColorGradientHover: "#075a20"
        }
    },
    {
        id: '5',
        img: [
            template51,
            template52,
            template53,
            template54,
        ],
        config: {
            textInfoTopBlock: "Акція -50%",
            bgColorTopBlockHeader: "#ff4751",
            isAnimationTopBlock: true,
            colorTopBlockHeader: "#383838",
            bgColorHeader: "#e9d985",
            colorHeaderText: "#2b2b2b",
            bgNewProductHeader: "#f71111",
            colorNewProductHeader: "#ffffff",
            bgSubCategories: "#4e4848",
            colorSubCategoriesText: "#635f5f",
            typeHeader: "1",
            bgColorFooter: "#b2bd7e",
            colorFooterText: "#000000",
            subscriptionInformation: "",
            isSubscriptionHide: false,
            bgColorMain: "#ffffff",
            colorMainText: "#000000",
            selectBtn: "2",
            colorTextBtn: "#2c2b2b",
            colorTextBtnHover: "#ffffff",
            firstColorGradient: "#e9d985",
            secondColorGradient: "#b2bd7e",
            firstColorGradientHover: "#6a5d7b",
            secondColorGradientHover: "#5d4a66"
        }
    },
    {
        id: '6',
        img: [
            template61,
            template62,
            template63,
            template64,
        ],
        config: {
            bgColorFooter: "#d77947",
            bgColorHeader: "#e9d985",
            bgColorMain: "#9b9b9b",
            bgColorTopBlockHeader: "#d77947",
            bgNewProductHeader: "#f71111",
            bgSubCategories: "#4e4848",
            colorFooterText: "#000000",
            colorHeaderText: "#2b2b2b",
            colorMainText: "#ffffff",
            colorNewProductHeader: "#ffffff",
            colorSubCategoriesText: "#e1caca",
            colorTextBtn: "#2c2b2b",
            colorTextBtnHover: "#ffffff",
            colorTopBlockHeader: "#ffffff",
            firstColorGradient: "#e9d985",
            firstColorGradientHover: "#6a5d7b",
            isAnimationTopBlock: false,
            isSubscriptionHide: false,
            secondColorGradient: "#b2bd7e",
            secondColorGradientHover: "#5d4a66",
            selectBtn: "5",
            subscriptionInformation: "",
            textInfoTopBlock: "Сезон знижок!!!",
            typeHeader: "3"
        }
    },
]
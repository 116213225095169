import './PromoCode.css';
import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleShopCurrency, showMessage } from '../../helpers/Utils';
import { setShop } from '../../store/userSlice';
import InputNumber from '../InputNumber/InputNumber';
import ModalWindow from '../ModalWindow/ModalWindow';
import deleteImg from './../../assets/images/deleteImg.svg';
import editIcon from './../../assets/images/editIcon.svg';

function PromoCode() {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const shop = useSelector(state => state.userSlice.shop);
    const [isOpenInfo, setIsOpenInfo] = useState([]);
    const [promoCode, setPromoCode] = useState([]);
    const [newPromoError, setNewPromoError] = useState('');
    const [newPromo, setNewPromo] = useState('');
    const [countPromo, setCountPromo] = useState(null);
    const [valuePromo, setValuePromo] = useState(null);
    const [prefixPromo, setPrefixPromo] = useState('');
    const [editPromoName, setEditPromoName] = useState('');
    const [editCountPromo, setEditCountPromo] = useState(null);
    const [editValuePromo, setEditValuePromo] = useState(null);
    const [editPrefixPromo, setEditPrefixPromo] = useState('');
    const [deletePromo, setDeletePromo] = useState('');
    const [isDeletePromoModal, setIsDeletePromoModal] = useState(false);
    const [editPromo, setEditPromo] = useState('');
    const [isEditPromoModal, setIsEditPromoModal] = useState(false);
    const dispatch = useDispatch();
    const token = localStorage.getItem('token')
   
    useEffect(() => {
		if (shop?.promoCode?.length) {
			setPromoCode(shop.promoCode)
		} else {
			setPromoCode([])
		}
    }, [shop])
  
	const handleHelpOpen = (num) => {
        if (isOpenInfo.includes(num)) {
            setIsOpenInfo(isOpenInfo.filter(el => el !== num))
        } else {
            setIsOpenInfo([...isOpenInfo, num])
        }
    }
	
	const handleIsEditPromo = (obj) => {
		console.log(obj)
		setEditPromo(obj.promo)
		setEditPromoName(obj.promo)
		setEditCountPromo(obj.count)
		setEditValuePromo(obj.value)
		setEditPrefixPromo(obj.prefix)
		setIsEditPromoModal(true)
    }
	
	const handleIsDeletePromo = (name) => {
		setDeletePromo(name)
		setIsDeletePromoModal(true)
    }
	
	const handleEditPromo = (boolean) => {
		if (boolean) {
			let data = {
				...shop,
				promoCode: [...promoCode.map(el => {
					if (el.promo === editPromo) {
						el = {
							promo: editPromoName,
							count: editCountPromo,
							value: editValuePromo,
							prefix: editPrefixPromo,
						}
					}
					return el
				})],
			}

			updatesShop(data)
		}
		setEditPromo('')
		setEditPromoName('')
		setEditCountPromo(null)
		setEditValuePromo(null)
		setEditPrefixPromo('')
		setIsEditPromoModal(false)
    }

	const handleDeletePromo = (boolean) => {
		if (boolean) {
			let data = {
				...shop,
				promoCode: [...promoCode.filter(el => el.promo !== deletePromo)],
			}

			updatesShop(data)
		}
		setDeletePromo('')
		setIsDeletePromoModal(false)
    }
	
	const handleCreatePromo = () => {
		if (newPromo?.length && countPromo !==null && valuePromo !== null && prefixPromo?.length) {
			if (!shop.promoCode.filter(el => el.promo === newPromo).length) {
				let data = {
					...shop,
					promoCode: [
						...promoCode,
						{
							promo: newPromo,
							count: countPromo,
							value: valuePromo,
							prefix: prefixPromo,
						}
					],
				}
				
				updatesShop(data)
	
				setNewPromoError('')
				setNewPromo('')
				setPrefixPromo('')
				setCountPromo(0)
				setValuePromo(0)
			} else {
				setNewPromoError(selectedLanguage?.promoCode?.promoCodeNewPromoError2)
			}
		} else {
			setNewPromoError(selectedLanguage?.promoCode?.promoCodeNewPromoError1)
		}
	}
		
	const updatesShop = (data) => {
		fetch(`${process.env.REACT_APP_BASE_URL}/shops/${shop._id}?token=${token}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
			.then(res => res.json())
			.then(res => {
				if (res.success && res.data) {
					dispatch(setShop(data));
					showMessage('success', selectedLanguage?.creationShop?.creationShopShowSuccessUpdateShop)
				} else {
					console.log('PUT CreationShop:', res)
				}
			})
			.catch((error) => {
				console.error('Error:', error);
				showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError)
			})
    }

    return (
		<div className='promo-code'>

			{
                isDeletePromoModal && <ModalWindow title={selectedLanguage?.promoCode?.promoCodeDelModTitle} handleClick={handleDeletePromo} />
            }
			{
                isEditPromoModal && <ModalWindow title={selectedLanguage?.promoCode?.promoCodeEditModTitle} handleClick={handleEditPromo} >
										<div className='promo-code__modal-edit-input-wrap'>
											<label className='promo-code__modal-edit-label' htmlFor={'promoCodeEditPromoName'}>{selectedLanguage?.promoCode?.promoCodeEditModLabel1}</label>
											<input
												id={'promoCodeEditPromoName'}
												name={'promoCodeEditPromoName'}
												type="text"
												className='promo-code__modal-edit-input'
												onChange={(e) => setEditPromoName(e.target.value)}
												value={editPromoName}
												placeholder={selectedLanguage?.promoCode?.promoCodeEditModPlaceholder1}
											/>
										</div>
										<div className='promo-code__modal-edit-input-wrap'>
											<label className='promo-code__modal-edit-label' htmlFor={'promoCodeEditCountPromo'}>{selectedLanguage?.promoCode?.promoCodeEditModLabel2}</label>
											<input
												id={'promoCodeEditCountPromo'}
												name={'promoCodeEditCountPromo'}
												type="number"
												className='promo-code__modal-edit-input'
												onChange={(e) => setEditCountPromo(e.target.value)}
												value={editCountPromo}
												min={'1'}
											/>
										</div>
										<div className='promo-code__modal-edit-input-wrap'>
											<label className='promo-code__modal-edit-label' htmlFor={'promoCodeEditValuePromo'}>{selectedLanguage?.promoCode?.promoCodeEditModLabel3}</label>
											<input
												id={'promoCodeEditValuePromo'}
												name={'promoCodeEditValuePromo'}
												type="number"
												className='promo-code__modal-edit-input'
												onChange={(e) => setEditValuePromo(e.target.value)}
												value={editValuePromo}
												min={'1'}
											/>
										</div>
										<div>
											<p>{selectedLanguage?.promoCode?.promoCodeEditModText}</p>
												<input className="promo-code__create-checkbox" onChange={() => setEditPrefixPromo('%')} defaultChecked={editPrefixPromo == '%' ? true : false} type="radio" id="promoCodeEditPrefixPromo%" name="promoCodeEditPrefixPromo" />
												<label className="promo-code__create-checkbox-label" htmlFor="promoCodeEditPrefixPromo%">%</label>
												<input className="promo-code__create-checkbox" onChange={() => setEditPrefixPromo(`${shop?.currency?.length ? handleShopCurrency(shop.currency) : '%'}`)} defaultChecked={editPrefixPromo == handleShopCurrency(shop.currency) ? true : false} type="radio" id="promoCodeEditPrefixPromoShop" name="promoCodeEditPrefixPromo" />
												<label className="promo-code__create-checkbox-label" htmlFor="promoCodeEditPrefixPromoShop">{handleShopCurrency(shop.currency)}</label>
										</div>
									</ ModalWindow>
            }

			<div className="promo-code__create">
				<div className="promo-code__create-input-wrap">
					<div className='promo-code__create-input--wrap'>
                        <div className='promo-code__create-input-title'>{selectedLanguage?.promoCode?.promoCodeCreateLabel1}</div>
                        <TextField className='promo-code__create-input' value={newPromo} onChange={(e) => setNewPromo(e.target.value)} label={selectedLanguage?.promoCode?.promoCodeEditModPlaceholder1} variant="outlined" />
                    </div>
					<InputNumber label={selectedLanguage?.promoCode?.promoCodeEditModLabel2} id={"promoCodeCountPromo"} name={"promoCodeCountPromo"} value={countPromo} setValue={setCountPromo} min={'1'}/>
					<InputNumber label={selectedLanguage?.promoCode?.promoCodeEditModLabel3} id={"promoCodeValuePromo"} name={"promoCodeValuePromo"} value={valuePromo} setValue={setValuePromo} min={'1'}/>
					<div>
						<p>{selectedLanguage?.promoCode?.promoCodeEditModText}</p>
							<input className="promo-code__create-checkbox" onChange={() => setPrefixPromo('%')} type="radio" value={prefixPromo?.length ? prefixPromo : ''} id="promoCodePrefixPromo%" name="promoCodePrefixPromo" />
							<label className="promo-code__create-checkbox-label" htmlFor="promoCodePrefixPromo%">%</label>
							<input className="promo-code__create-checkbox" onChange={() => setPrefixPromo(`${shop?.currency?.length ? handleShopCurrency(shop.currency) : '%'}`)} type="radio" value={prefixPromo?.length ? prefixPromo : ''} id="promoCodePrefixPromoShop" name="promoCodePrefixPromo" />
							<label className="promo-code__create-checkbox-label" htmlFor="promoCodePrefixPromoShop">{handleShopCurrency(shop.currency)}</label>
					</div>
					{
						!!newPromoError?.length && <p className="promo-code__create-error">{newPromoError}</p>
					}
				</div>

                <div className="promo-code__create-btn-wrap">
					<button onClick={() => handleCreatePromo()} className="promo-code__create-btn">{selectedLanguage?.promoCode?.promoCodeCreateBtn}</button>
                </div>
            </div>

			{
				!!promoCode?.length && promoCode.map((el, i) => (
					<div className="promo-code__created-promos">
						<div className="promo-code__created-promo-wrap" key={el?.promo}>
							<div className="promo-code__created-promo">
								<div className="promo-code__created-promo-items">
									<span className="promo-code__created-promo-item">№{i + 1} </span>
									<span className="promo-code__created-promo-item">{selectedLanguage?.promoCode?.promoCodePromoName} <b>{el?.promo}</b></span>
								</div>
								<div className="promo-code__created-promo-items">
									<span className="promo-code__created-promo-item">{selectedLanguage?.promoCode?.promoCodePromoValue} <b>{el?.value}</b></span>
									<span className="promo-code__created-promo-item"><b>{el?.prefix}</b></span>
								</div>
								<span className="promo-code__created-promo-item">{selectedLanguage?.promoCode?.promoCodePromoCount} <b>{el?.count}</b></span>
							</div>
							<div className='promo-code__created-promo-btn-wrap'>
								<img className='promo-code__created-promo-del-btn' onClick={() => handleIsEditPromo(el)} src={editIcon} alt='img'/>
								<img className='promo-code__created-promo-del-btn' onClick={() => handleIsDeletePromo(el.promo)} src={deleteImg} alt='img'/>
							</div>
						</div>
					</div>
				))
			}
		</div>
    );
}

export default PromoCode;
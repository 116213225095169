import './ReadNotificationsView.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import arrow2 from './../../assets/images/arrow2.svg';
import { setNotificationsLength, setSeenNotifications } from '../../store/userSlice';
import { fetchGetData, fetchRequest, setUserNotificationsLength } from '../../helpers/Utils';

function ReadNotificationsView () {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const user = useSelector(state => state.userSlice.user);
    const shop = useSelector(state => state.userSlice.shop);
    let { idNotifications } = useParams();
    const [notificationsContent, setNotificationsContent] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem('token')
    
    useEffect(() => {
        if (user?._id) {
            fetchGetData(`${process.env.REACT_APP_BASE_URL}/notifications/${idNotifications}?token=${token}`)
                .then(res => {
                    if (res.success && res.data) {
                        setNotificationsContent(res.data)
                        setIsSeen(res.data)
                    } else {
                        console.log('GET ReadNotificationsView:', res)
                    }
            })
        }
    }, [user])

    const setIsSeen = (notificationsContent2) => {
        let data = {
            ...notificationsContent2,
            token: token,
            isSeen: true,
        }

        fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/notifications/${notificationsContent2._id}`, data)
            .then(res => {
                if (res.success && res.data) {
                    dispatch(setSeenNotifications({...notificationsContent2, isSeen: true}));
                    setUserNotificationsLength(shop._id, token, (res) => dispatch(setNotificationsLength(res)))
                } else {
                    console.log('PUT ReadNotificationsView:', res)
                }
            })
    }

    return (
        <div className='read-notifications'>
            <div className='read-notifications--wrap container'>
                {
                    notificationsContent?.status == 'callBack' ? <div>
                        <div className='read-notifications__title-wrap'>
                            <button className='read-notifications__btn-back' onClick={() => navigate(`/auth/${user._id}/notifications`)}>
                                <img className='read-notifications__btn-back-img' src={arrow2} alt='img'/>
                                <p>{selectedLanguage?.readNotificationsView?.readNotificationsBtnBack}</p>
                            </button>
                            <h4 className='read-notifications__title'>{selectedLanguage?.readNotificationsView?.readNotificationsTitle1}</h4>
                        </div>
                        <div className='read-notifications__creation-time-title'>{selectedLanguage?.readNotificationsView?.readNotificationsTitleDate}&nbsp;{new Date(notificationsContent?.creation_time).toLocaleString()}</div>
                        <div className='read-notifications__text'>
                            {selectedLanguage?.readNotificationsView?.readNotificationsText1}
                            &nbsp;<a className='read-notifications__text-link' href={`tel:${notificationsContent?.phone}`}>{notificationsContent?.phone}</a>&nbsp;
                            {selectedLanguage?.readNotificationsView?.readNotificationsText2}
                        </div>
                        <div className='read-notifications__comment'><b>{selectedLanguage?.readNotificationsView?.readNotificationsComment}</b>&nbsp;{notificationsContent?.comment}</div>
                    </div> : <div>
                        <div className='read-notifications__title-wrap'>
                            <button className='read-notifications__btn-back' onClick={() => navigate(`/auth/${user._id}/notifications`)}>{selectedLanguage?.readNotificationsView?.readNotificationsBtnBack}</button>
                            <h4 className='read-notifications__title'>{selectedLanguage?.readNotificationsView?.readNotificationsTitle2}</h4>
                        </div>
                        <div className='read-notifications__creation-time-title'>{selectedLanguage?.readNotificationsView?.readNotificationsTitleDate}&nbsp;{new Date(notificationsContent?.creation_time).toLocaleString()}</div>
                        <div className='read-notifications__text'>
                            {selectedLanguage?.readNotificationsView?.readNotificationsText3}
                            &nbsp;<a className='read-notifications__text-link' href={`mailto:${notificationsContent?.email}`}>{notificationsContent?.email}</a>&nbsp;
                            {selectedLanguage?.readNotificationsView?.readNotificationsText4}
                        </div>
                    </div> 
                }
            </div>
        </div>
    );
}

export default ReadNotificationsView;
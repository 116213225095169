import './Layout.css';
import React, { useEffect, useRef, useState } from "react";
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts, setCategories, setFavoriteProduct, setSelectedLanguage, setShop, setShoppingProduct } from '../store/homeSlice';
import { languageShop } from '../languageShop';
import Preloader from '../components/Preloader/Preloader';
import { fetchGetData, sendAnalytics } from '../helpers/Utils';
import HomeView from '../views/HomeView/HomeView';


function Layout({shopSettingTest=null}) {
    const shop = useSelector(state => state.homeSlice.shop);
    const products = useSelector(state => state.homeSlice.products);
    const shoppingProduct = useSelector(state => state.homeSlice.shoppingProduct);
    let { shopName } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

// console.log(locationUser)
    // const selectedLanguage = useSelector(state => state.homeSlice.selectedLanguage);

    useEffect(() => {
        // shopByShopNameParams()

        if (shopSettingTest?._id?.length) {
            dispatch(setShop(shopSettingTest));
        }
    }, [])
    
    useEffect(() => {
        dispatch(setShop(shopSettingTest));
    }, [shopSettingTest])
    
    useEffect(() => {
        if (!shopSettingTest) {
            shopByShopNameParams()
        }
    }, [shopName])

    const shopByShopNameParams = () => {
        if (shopName?.length) {
            fetchGetData(`${process.env.REACT_APP_BASE_URL}/shops/${shopName}/name`)
                .then(res => {
                    if (res?.success && res?.data?._id) {
                        dispatch(setShop(res.data));
                    } else {
                        navigate('/')           // ?????? не працює
                        console.log('GET Layout', res)
                    }
                })
        } 
    }

    const visitorRecord = (city) => {
        let data = {
            shop_id: shop._id,
            data: city,            
            action: 'shop_visit',
            time: new Date().getTime(),
            // token: user.token,
        }

        sendAnalytics(data)
    }
  
    const findLocation = () => {
        fetchGetData(`https://ipapi.co/json`)    // https://ipapi.co/json
            .then(res => {
                if (res?.city?.length) {
                    visitorRecord(res.city)
                } else {
                    console.log('GET Layout', res)
                }
            })
    }

    useEffect(() => {
        if (shop?._id?.length) {
            fetchGetData(`${process.env.REACT_APP_BASE_URL}/categories/${shop._id}/all`)
                .then(res => {
                    if (res.success && res.data.length) {
                        dispatch(setCategories(res.data));
                    } else {
                        console.log('GET Layout', res)
                    }
                })
        }

        let shopLanguage = localStorage.getItem('shopLanguage');
        if (shopLanguage?.length) {
            dispatch(setSelectedLanguage(languageShop[shopLanguage]));
        } else if (shop?.language?.length) {
            dispatch(setSelectedLanguage(languageShop[shop?.language]));
        } else {
            dispatch(setSelectedLanguage(languageShop['ENG']));
        }

        if (shop?.colorSettings) {
            const root = document.documentElement; 
            root.style.setProperty(`--bgColorTopBlockHeader`, shop?.colorSettings.bgColorTopBlockHeader); 
            root.style.setProperty(`--colorTopBlockHeader`, shop?.colorSettings.colorTopBlockHeader); 
            root.style.setProperty(`--bgColorHeader`, shop?.colorSettings.bgColorHeader); 
            root.style.setProperty(`--colorHeaderText`, shop?.colorSettings.colorHeaderText); 
            root.style.setProperty(`--bgNewProductHeader`, shop?.colorSettings.bgNewProductHeader); 
            root.style.setProperty(`--colorNewProductHeader`, shop?.colorSettings.colorNewProductHeader); 
            root.style.setProperty(`--bgSubCategories`, shop?.colorSettings.bgSubCategories); 
            root.style.setProperty(`--colorSubCategoriesText`, shop?.colorSettings.colorSubCategoriesText); 
            root.style.setProperty(`--bgColorFooter`, shop?.colorSettings.bgColorFooter); 
            root.style.setProperty(`--colorFooterText`, shop?.colorSettings.colorFooterText); 
            root.style.setProperty(`--bgColorMain`, shop?.colorSettings.bgColorMain); 
            root.style.setProperty(`--colorMainText`, shop?.colorSettings.colorMainText); 
            root.style.setProperty(`--colorTextBtn`, shop?.colorSettings.colorTextBtn); 
            root.style.setProperty(`--colorTextBtnHover`, shop?.colorSettings.colorTextBtnHover); 
            root.style.setProperty(`--firstColorGradient`, shop?.colorSettings.firstColorGradient); 
            root.style.setProperty(`--secondColorGradient`, shop?.colorSettings.secondColorGradient); 
            root.style.setProperty(`--firstColorGradientHover`, shop?.colorSettings.firstColorGradientHover); 
            root.style.setProperty(`--secondColorGradientHover`, shop?.colorSettings.secondColorGradientHover); 
        }

        if (shop?._id) {
            let res = JSON.parse(localStorage.getItem(`visit-${shop._id}`))  
            if (res?.day?.length) {
                let today = new Date().getTime()
                let todayYear = new Date(today).getFullYear()
                let todayMonth = new Date(today).getMonth()
                let todayDay = new Date(today).getDate()
                let other = new Date(res.day).getTime()
                let otherYear = new Date(other).getFullYear()
                let otherMonth = new Date(other).getMonth()
                let otherDay = new Date(other).getDate()
                if (otherYear < todayYear) { 
                    findLocation()
                    localStorage.setItem(`visit-${shop._id}`, JSON.stringify({day: new Date(), shopId: shop._id}));
                } else if (otherMonth < todayMonth) {
                    findLocation()
                    localStorage.setItem(`visit-${shop._id}`, JSON.stringify({day: new Date(), shopId: shop._id}));
                } else if (otherDay < todayDay) {
                    findLocation()
                    localStorage.setItem(`visit-${shop._id}`, JSON.stringify({day: new Date(), shopId: shop._id}));
                } else if (other < today - 86400000) {
                    findLocation()
                    localStorage.setItem(`visit-${shop._id}`, JSON.stringify({day: new Date(), shopId: shop._id}));
                }
            } else {
                localStorage.setItem(`visit-${shop._id}`, JSON.stringify({day: new Date(), shopId: shop._id}));
                findLocation()
            }
        }

        if (shop?._id?.length) {
            let res = JSON.parse(localStorage.getItem(`shoppingProducts-${shop._id}`))
            if (res?.length) {
                dispatch(setShoppingProduct(res));
            } else {
                dispatch(setShoppingProduct([]));
            }
            
            let res2 = JSON.parse(localStorage.getItem(`favoriteProduct-${shop._id}`))
            if (res2?.length) {
                dispatch(setFavoriteProduct(res2));
            } else {
                dispatch(setFavoriteProduct([]));
            }
        }
    }, [shop])

    return (
        <>
            {
                shop?._id ? (
                    <div className="layout">
                        <Header />
                        {
                            !!shopSettingTest?._id?.length && <HomeView />
                        }
                        <Outlet />
                        <Footer />
                    </div>
                ) : <Preloader />
            }
        </>
    );
}

export default Layout;
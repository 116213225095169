import './MerchantInstructionView.css';
import merchant from './merchant.pdf';

function MerchantInstructionView() {
   
    return (
        <div className="merchant-instruction">
            <div className="merchant-instruction--wrap">
                <iframe className="merchant-instruction__public-offer" src={merchant}></iframe>
            </div>
        </div>
    );
}

export default MerchantInstructionView;
import { memo, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalWindow from '../ModalWindow/ModalWindow';
import './CustomerCard.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRequest, handleShopCurrency, showMessage, validEmail } from '../../helpers/Utils';
import { setClients, setSelectClients } from '../../store/userSlice';
import { Checkbox } from '@mui/material';

function CustomerCard({customer, bannedBtn=false, isWriteCheckbox=false}) {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const selectClients = useSelector(state => state.userSlice.selectClients);
    const clients = useSelector(state => state.userSlice.clients);
    const user = useSelector(state => state.userSlice.user);
    const shop = useSelector(state => state.userSlice.shop);
    const [isModalBan, setIsModalBan] = useState(false);
    const [banClients, setBanClients] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleBan = (e, obj) => {
        e.stopPropagation()
        setIsModalBan(true)
        setBanClients(obj)
    }

    const handleCustomersImg = useMemo(() => {
        if (customer?.full_name?.length) {
            let res = customer.full_name.split(' ');
            return <div className='customer-card__card-text-img-name'>{res[0][0]}{res[1]?.length ? res[1][0] : ''}</div>;
        }
    }, [customer.full_name]);

    const handleChangeCustomers = (email) => {
        if (selectClients.includes(email)) {
            dispatch(setSelectClients([...selectClients.filter(el => el !== email)]))
        } else {
            dispatch(setSelectClients([...selectClients, email]))
        }
    }

    const handleIsBan = (boolean) => {
        if (boolean) {
            const data = {
                ...banClients,
                is_banned: !banClients.is_banned
            }
            
            fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/clients/${banClients._id}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
                .then(res => {
                    if (res.success && res.data) {
                        dispatch(setClients([...clients.map(el => {
                            if (el._id === banClients._id) {
                                el = data
                            }
                            return el
                        })]))
                        
                        showMessage('success', selectedLanguage?.customerCard?.customerCardShowSuccess2)
                    } else {
                        console.log('PUT CustomerCard', res)
                    }
                })
        }
        setIsModalBan(false)
        setBanClients({})
    }

    return (
        <div className="customer-card">
            {
                isModalBan && <ModalWindow title={selectedLanguage?.customerCard?.customerCardModalBanTitle} handleClick={handleIsBan} />
            }

            <div onClick={() => navigate(`/auth/${user._id}/customers/${customer._id}`)} className='customer-card__card'>
                <div className='customer-card__card-text-img-wrap'>
                    {
                        isWriteCheckbox &&  
                            <Checkbox
                                className={`${!customer.email?.length || !validEmail(customer.email) ? 'customer-card__checkbox-disabled' : ''}`}
                                checked={selectClients.includes(customer.email)}
                                onClick={(e) => e.stopPropagation()}
                                onChange={() => handleChangeCustomers(customer.email)}
                                disabled={customer.email?.length && validEmail(customer.email) ? false : true}
                            /> 
                    }
                    {handleCustomersImg}
                    <div className='customer-card__card-text-name-wrap'>
                        <div className='customer-card__card-text-name'>{customer.full_name}</div>
                        <div className='customer-card__card-text-count-wrap'>
                            <div className='customer-card__card-text-count'>{selectedLanguage?.customerCard?.customerCardTitleCount}</div>
                            <div>{customer.count}</div>
                        </div>
                    </div>
                </div>
                <div className='customer-card__card-text-wrap'>
                    <div className='customer-card__card-text'>
                        <div className='customer-card__card-title'>{selectedLanguage?.customerCard?.customerCardTitleTel}</div>
                        <div>{customer.phone}</div>
                    </div>
                    <div className='customer-card__card-text'>
                        <div className='customer-card__card-title'>{selectedLanguage?.customerCard?.customerCardTitleMeil}</div>
                        <div className='customer-card__card-title-email'>{customer.email}</div>
                    </div>
                    <div className='customer-card__card-text'>
                        <div className='customer-card__card-title'>{selectedLanguage?.customerCard?.customerCardTitleCosts}</div>
                        <div>{customer.costs}{handleShopCurrency(shop.currency)}</div>
                    </div>
                    <div className='customer-card__card-text'>
                        <div className='customer-card__card-title'><b>{selectedLanguage?.customerCard?.customerCardLastOrder}</b></div>
                        <div>{new Date(customer.updated_at).toLocaleString()}</div>
                    </div>
                </div>

                {
                    bannedBtn &&
                        <button onClick={(e) => handleBan(e, customer)} className={`customer-card__card-btn ${customer.is_banned ? 'customer-card__card-btn-no-ban' : ''}`}>{customer.is_banned ? selectedLanguage?.customerCard?.customerCardBtnBanned1 : selectedLanguage?.customerCard?.customerCardBtnBanned2}</button>
                }
            </div>
           
        </div>
    );
}

export default memo(CustomerCard);
import { useEffect, useState, useMemo } from 'react';
import './CustomerView.css';
import { useNavigate, useParams } from 'react-router-dom';
import dolar from './../../assets/images/dolar.svg';
import circle1 from './../../assets/images/circle1.svg';
import phone1 from './../../assets/images/phone1.svg';
import cartUser2 from './../../assets/images/cartUser2.svg';
import letter1 from './../../assets/images/letter1.svg';
import { useSelector } from 'react-redux';
import PaginationItems from '../../components/PaginationItems/PaginationItems';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import { fetchGetData, fetchRequest, handleShopCurrency, showMessage } from '../../helpers/Utils';
import { TextField } from '@mui/material';
import Preloader from '../../components/Preloader/Preloader';

function CustomerView() {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const user = useSelector(state => state.userSlice.user);
    const shop = useSelector(state => state.userSlice.shop);
    const [customerPurchases, setCustomerPurchases] = useState([]);
    const [customer, setCustomer] = useState({});
    const [selectedPaget, setSelectedPaget] = useState('0');
    const [quantityAllPurchases, setQuantityAllPurchases] = useState('');
    const [isModalBan, setIsModalBan] = useState(false);
    const [isModalWrite, setIsModalWrite] = useState(false);
    const [isPreloader, setIsPreloader] = useState(true);
    const [banClients, setBanClients] = useState({});
    const [messageTitle, setMessageTitle] = useState('');
    const [messageText, setMessageText] = useState('');
    const navigate = useNavigate();
    let { idCustomer } = useParams();
    const token = localStorage.getItem('token')
    
    useEffect(() => {
        setIsPreloader(true)
        fetchGetData(`${process.env.REACT_APP_BASE_URL}/clients/${idCustomer}`)
            .then(res => {
                if (res.success && res.data) {
                    setCustomer(res.data)
                } else {
                    console.log('GET CustomerView:', res)
                }
                setIsPreloader(false)
                })
    }, [])

    useEffect(() => {
        if (customer._id) {
            fetchGetData(`${process.env.REACT_APP_BASE_URL}/purchases/${shop._id}/all?page=${selectedPaget}&token=${token}&search_query=${customer.phone}`)
                .then(res => {
                    if (res.success && res.data) {
                        setCustomerPurchases(res.data)
                        setQuantityAllPurchases(res.count % 10 == 0 ? res.count / 10 : Math.ceil(res.count / 10))
                    } else {
                        console.log('GET CustomerView:', res)
                    }
                })
        }
    }, [customer])

    const handleBan = (e, obj) => {
        e.stopPropagation()
        setIsModalBan(true)
        setBanClients(obj)
    }
    
    const handleIsBan = (boolean) => {
        if (boolean) {
            const data = {
                ...banClients,
                is_banned: !banClients.is_banned
            }
            
            fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/clients/${banClients._id}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
                .then(res => {
                    console.log(res)
                    if (res.success && res.data) {
                        setCustomer(data)
                        showMessage('success', selectedLanguage?.customerView?.customerViewShowSuccess1)
                    } else {
                        console.log('PUT CustomerView', res)
                    }
                })
        }
        setIsModalBan(false)
        setBanClients({})
    }

    const handleCustomersImg = useMemo(() => {
        if (customer?.full_name?.length) {
            let res = customer.full_name.split(' ');
            return <div className='customer-card__info-img-name'>{res[0][0]}{res[1]?.length ? res[1][0] : ''}</div>;
        }
    }, [customer.full_name]);

    const handleSendMessage = (boolean) => {
        if (boolean) {
            if (messageText?.length || messageTitle?.length) {
                const data = {
                    clients: [customer._id],
                    subject: messageTitle,
                    message: messageText,
                }
                
                fetchRequest('POST', `${process.env.REACT_APP_BASE_URL}/clients/email`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
                    .then(res => {
                        if (res.success && res.data) {
                            showMessage('success', selectedLanguage?.customersView?.customersViewShowSuccess1)
                        } else {
                            console.log('POST CustomersView', res)
                        }
                    })
            }
        } 
        setIsModalWrite(false)
        setMessageTitle('')
        setMessageText('')
    }
    
    return (
        <div className="customer-view">
            {
                isModalBan && <ModalWindow title={selectedLanguage?.customerView?.customerViewModlTitle} handleClick={handleIsBan} />
            }
            
            {
                isModalWrite && <ModalWindow title='' handleClick={handleSendMessage} leftBtn={selectedLanguage?.readPurchasesView?.readPurchasesModEditLeftBtn} rightBtn={selectedLanguage?.readPurchasesView?.readPurchasesModEditRightBtn} >
                    <div className='customer-view__modal-input-wrap'>
                        <TextField className='customer-view__modal-input' value={messageTitle} onChange={(e) => setMessageTitle(e.target.value)} label={selectedLanguage?.customersView?.customersViewModaInput1} variant="outlined" />
                        <TextField className='customer-view__modal-input' value={messageText} onChange={(e) => setMessageText(e.target.value)} label={selectedLanguage?.customersView?.customersViewModaInput2} rows={3} variant="outlined" multiline />
                    </div>
                </ModalWindow>
            }

            {
                isPreloader ? <Preloader /> : 
                    <>
                        <div className="customer-view__header-wrap">
                            <div className="customer-view__header">
                                <img className="customer-view__header-img" src={cartUser2} alt='img'/>
                                <div className="customer-view__header-title">{selectedLanguage?.customersView?.customersViewTitle}</div>
                            </div>
                            <button className='customer-view__header-btn' onClick={() => setIsModalWrite(true)}>
                                <img className='customer-view__header-btn-img' src={letter1} alt='img'/>
                                <p>{selectedLanguage?.customersView?.customersViewBtnWrite}</p>
                            </button>
                        </div>

                        <div className="customer-view--wrap container">
                            <div className='customer-view__info'>
                                <div className='customer-view__info-img-wrap'>
                                    {handleCustomersImg}
                                    <div className='customer-view__info-img-count' style={{width: `${customer.count > 999 ? 35 : 30}px`, height: `${customer.count > 999 ? 35 : 30}px`}}>{customer.count}</div>
                                </div>
                                <div className='customer-view__info-text-wrap'>
                                    <div className='customer-view__info-text-name'>{customer.full_name}</div>
                                    <a className='customer-view__info-text-email' href={customer.email}>{customer.email}</a>
                                    <div className='customer-view__info-text-phone--wrap'>
                                        <div className='customer-view__info-text-phone-wrap'>
                                            <img className='customer-view__info-text-phone-img' src={phone1} alt='img'/>
                                            <a href="tel:+1234567890" target='_blank'>{customer.phone}</a>
                                        </div>
                                    </div>
                                    <div className='customer-view__info-text-costs-wrap'>
                                        <img className='customer-view__info-text-phone-img' src={dolar} alt='img'/>
                                        <p>{customer.costs}</p>
                                    </div>
                                    <div className='customer-view__info-text-date--wrap'>
                                        <div className='customer-view__info-text-date-wrap'>
                                            <div className='customer-view__info-text-date-title'>{selectedLanguage?.customerView?.customerViewPurchasesRegistrDate}</div>
                                            <div className='customer-view__info-text-date'>{new Date(customer.date).toLocaleString()}</div>
                                        </div>
                                        <button className='customer-view__info-btn-ban-wrap' onClick={(e) => handleBan(e, customer)} >
                                            <img className='customer-view__info-btn-ban-img' src={circle1} alt='img'/>
                                            <p>{customer.is_banned ? selectedLanguage?.customerView?.customerViewBtnBanned1 : selectedLanguage?.customerView?.customerViewBtnBanned2}</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='customer-view__purchases--wrap'>
                                <div className='customer-view__purchases-wrap'>
                                    {
                                        !!customerPurchases?.length && customerPurchases.map(el => (
                                            <div className='customer-view__purchases-text-wrap' onClick={() => navigate(`/auth/${user._id}/purchases/${el._id}`)} key={el._id}>
                                                <div className='customer-view__purchases-text'>
                                                    <div className='customer-view__purchases-title'>{selectedLanguage?.customerView?.customerViewPurchasesTitleStatus}</div>
                                                    <div>{el.status === 'in_process' ? selectedLanguage?.userPurchases?.userPurchasesSortOption2 : el.status === 'done' ? selectedLanguage?.userPurchases?.userPurchasesSortOption3 : el.status === 'rejected' ? selectedLanguage?.userPurchases?.userPurchasesSortOption4 : ''}</div>
                                                </div>
                                                <div className='customer-view__purchases-text'>
                                                    <div className='customer-view__purchases-title'>{selectedLanguage?.customerCard?.customerCardTitleMeil}</div>
                                                    <div className='customer-view__purchases-title-email'>{el.email}</div>
                                                </div>
                                                <div className='customer-view__purchases-text'>
                                                    <div className='customer-view__purchases-title'>{selectedLanguage?.customerCard?.customerCardTitleCosts}</div>
                                                    <div>{el.totalPrice}{handleShopCurrency(shop.currency)}</div>
                                                </div>
                                                <div className='customer-view__purchases-text'>
                                                    <div className='customer-view__purchases-title'><b>{selectedLanguage?.customerView?.customerViewPurchasesTitleDate}</b></div>
                                                    <div>{new Date(el.creation_time).toLocaleString()}</div>
                                                </div>
                                            </div>
                                            ))
                                        }
                                </div>

                                {
                                    !!customerPurchases?.length && 
                                        <PaginationItems selectedPaget={selectedPaget} setSelectedPaget={setSelectedPaget} pageRangeDisplayed={5} itemsPerPage={10} quantityAllProducts={quantityAllPurchases}/>
                                }
                            </div>                                      
                        </div>
                    </>
            }
        </div>
    );
}

export default CustomerView;
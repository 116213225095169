import './UserPurchasesView.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPurchases, setFavoritePurchases, setPurchasesLength, setSearchPurchases, setUserPurchasesTabValue } from '../../store/userSlice';
import SelectStatus from '../../components/SelectStatus/SelectStatus';
import PaginationItems from '../../components/PaginationItems/PaginationItems';
import plus from './../../assets/images/plus.svg';
import check from './../../assets/images/check.svg';
import cartUser2 from './../../assets/images/cartUser2.svg';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css'; 
import { fetchGetData, fetchRequest, handleShopCurrency, setUserPurchasesLength, showMessage } from '../../helpers/Utils';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import mixpanel from 'mixpanel-browser';
import { Box, FormControl, InputLabel, MenuItem, Select, Tab, Tabs } from '@mui/material';
import Preloader from '../../components/Preloader/Preloader';
mixpanel.init('cbc9e4ee0d40873b5a2e3b5e39eedd33', { debug: true }); 
mixpanel.track('Visit purchases page');

function UserPurchasesView() {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const searchPurchases = useSelector(state => state.userSlice.searchPurchases);
    const userPurchasesTabValue = useSelector(state => state.userSlice.userPurchasesTabValue);
    const user = useSelector(state => state.userSlice.user);
    const shop = useSelector(state => state.userSlice.shop);
    const purchases = useSelector(state => state.userSlice.purchases);
    const [filterPurchases, setFilterPurchases] = useState([]);
    const [selectedPaget, setSelectedPaget] = useState('0');
    const [quantityAllProducts, setQuantityAllProducts] = useState('');
    const [isSelectDate, setIsSelectDate] = useState(false);
    const [sortStatus, setSortStatus] = useState('');
    const [sortExucutor, setSortExucutor] = useState('');
    const [isSortByDate, setIsSortByDate] = useState(false);
    const [isExucutorModal, setIsExucutorModal] = useState(false);
    const [exucutorOfPurchases, setExucutorOfPurchases] = useState({});
    const [managers, setManagers] = useState([]);
    const [unseen, setUnseen] = useState('1');
    const [seen, setSeen] = useState('');
    const [favorite, setFavorite] = useState('');
    const [reserved, setReserved] = useState('');
    const [unreserved, setUnreserved] = useState('');
    const [isDownloadPurchases, setIsDownloadPurchases] = useState(false);
    const [fileUrl, setFileUrl] = useState('');
    const [isPreloader, setIsPreloader] = useState(true);
    const [stateDate, setStateDate] = useState([
        {
          startDate: null,
        //   endDate: new Date(),
          endDate: null,
          key: 'selection'
        }
      ]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = localStorage.getItem('token')

    useEffect(() => {
        getManagers()  

        if (userPurchasesTabValue !== 0) {
            handleChange(null, userPurchasesTabValue) 
        }

        return () => dispatch(setSearchPurchases(''))
    }, [])

    useEffect(() => {
        setFilterPurchases([...purchases])  
    }, [purchases])

    useEffect(() => {
        if (shop?._id) {
            setIsPreloader(true)
            setUserPurchasesLength(shop._id, token, (res) => dispatch(setPurchasesLength(res)))

            fetchGetData(`${process.env.REACT_APP_BASE_URL}/purchases/${shop._id}/all?search_query=${searchPurchases}&page=${selectedPaget}&reserved=${reserved}&unreserved=${unreserved}&exucutor=${sortExucutor}&status=${sortStatus}&seen=${seen}&unseen=${unseen}&favorite=${favorite}&token=${token}&min_date=${stateDate[0].startDate ? stateDate[0].startDate : ''}&max_date=${stateDate[0].endDate ? stateDate[0].endDate : ''}`)
                .then(res => {
                    if (res.success && res.data) {
                        dispatch(getPurchases(res.data));
                        setQuantityAllProducts(res.count % 10 == 0 ? res.count / 10 : Math.ceil(res.count / 10))
                    } else {
                        console.log('GET UserPurchases:', res)
                    }
                setIsPreloader(false)
                })
        }
    }, [shop, searchPurchases, selectedPaget, isSortByDate, seen, unseen, favorite, sortStatus, sortExucutor, unreserved, reserved])

    const handleSortPurchases = (str) => { 
        mixpanel.track('Sort purchases on purchases page'); 
        if (str == 'unreserved') {
            setReserved('')
            if (unreserved == '') {
                setUnreserved('1')
            } else {
                setUnreserved('')
            }
        }
        if (str == 'reserved') {
            setUnreserved('')
            if (reserved == '') {
                setReserved('1')
            } else {
                setReserved('')
            }
        }
    }
    
    const handleReadPurchases = (id) => {
        mixpanel.track('Open purchase on purchases page'); 
        navigate(`/auth/${user._id}/purchases/${id}`)
    }
   
    const handleFavorite = (e, purchases) => {
        mixpanel.track('Make purchase favorite on purchases page'); 
        e.stopPropagation()
        let data = {
            ...purchases,
            favorite: !purchases.favorite,
        }

        fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/purchases/${purchases._id}?token=${token}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
            .then(res => {
                if (res.success && res.data) {
                    dispatch(setFavoritePurchases({...purchases, favorite: !purchases.favorite}));
                } else {
                    console.log('PUT UserPurchases:', res)
                }
            })
    }

    const handleSortDate = () => {
        mixpanel.track('Sort purchases by date on purchases page'); 
        if (stateDate[0].startDate) {
            setIsSelectDate(false)
            setIsSortByDate(!isSortByDate)
        }
    }
    
    const handleSortCleanDate = () => {
        mixpanel.track('Remove sorting by date on purchases page'); 
        setIsSelectDate(false)
        if (stateDate[0].startDate !== null) {
            setIsSortByDate(!isSortByDate)
            setStateDate([{
                startDate: null,
                endDate: null,
                key: 'selection'
            }])
        }
    }

    const getManagers = () => {
        mixpanel.track('Get managers on purchases page');
        fetchGetData(`https://zx8hsmle2j.execute-api.eu-central-1.amazonaws.com/production/api/v1/roles/${shop._id}/asid?token=${token}`)
            .then(res => {
                if (res.success && res?.data) {
                    setManagers(res.data)
                } else {
                    console.log('GET UserPurchasesView', res)
                }
            })
    }
   
    const handleExucutorPurchases = (boolean) => {
        mixpanel.track('Make manager an executor on purchases page');
        if (boolean) {
            let data = {
                ...exucutorOfPurchases,
                exucutor: exucutorOfPurchases.exucutor?._id?.length ? '' : user,
            }

            sendEdite(data)
        }

        setIsExucutorModal(false)
        setExucutorOfPurchases({})
    }

    const handleDownloadPurchases = (boolean) => {
        if (boolean) {
            fetchGetData(`${process.env.REACT_APP_BASE_URL}/purchases/${shop._id}/excel?token=${token}`)
                .then(res => {
                    if (res.success && res.data) {
                        setFileUrl(res.data)
                    } else {
                        console.log('GET UserPurchasesView', res)
                    }
                })
            } else {
                deleteExcel()
        }

    }

    const deleteExcel = () => {
        setFileUrl('')
        setIsDownloadPurchases(false)
    };

    const sendEdite = (data) => {
        mixpanel.track('Edit purchase on purchases page');

        fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/purchases/${exucutorOfPurchases._id}?token=${token}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
            .then(res => {
                if (res.success && res.data) {
                    let arr = purchases.map(el => {
                        if (el._id === exucutorOfPurchases._id) {
                            el = data
                        }
                        return el;
                    })
                    dispatch(getPurchases(arr));
                    showMessage('success', selectedLanguage?.readPurchasesView?.readPurchasesMessageUpdate)
                } else {
                    console.log('PUT UserPurchasesView:', res)
                }
            })
    } 

    const handleChange = (event, newValue) => {
        if (userPurchasesTabValue !== newValue) {
            dispatch(setUserPurchasesTabValue(newValue));
        }

        if (newValue == 0) {
            setUnseen('1')
            setSeen('')
            setFavorite('')
        } else if (newValue == 1) {
            setUnseen('')
            setSeen('1')
            setFavorite('')
        } else {
            setUnseen('')
            setSeen('')
            setFavorite('1')
        }
    };

    return (
        <div className="user-purchases">

            {
                isDownloadPurchases && <ModalWindow title={selectedLanguage?.userPurchases?.userPurchasesModlDownloadTitle} handleClick={handleDownloadPurchases} >
                    {!!fileUrl?.length && (
                        <a className='customers-view__btn-download' onClick={deleteExcel} href={fileUrl} download>
                            Download File
                        </a>
                    )}
                </ModalWindow>
            }

            {
                isExucutorModal && <ModalWindow title={exucutorOfPurchases.exucutor?._id?.length ? selectedLanguage?.userPurchases?.userPurchasesExucutorModalTitle1 : selectedLanguage?.userPurchases?.userPurchasesExucutorModalTitle2} handleClick={handleExucutorPurchases} />
            }

            <div className="user-purchases--wrap">
                <div className="user-purchases__header">
                    <div className="user-purchases__header-count">
                        <img className="user-purchases__header-count-img" src={cartUser2} alt='img'/>
                        <div className="user-purchases__header-count-title">{selectedLanguage?.userPurchases?.userPurchasesTitle}</div>
                    </div>
                    <Box className="user-purchases__header-tab-wrap" sx={{  }}>
                        <Tabs value={userPurchasesTabValue} onChange={handleChange} textColor='inherit'>
                            <Tab label={selectedLanguage?.userPurchases?.userPurchasesTab1}/>
                            <Tab label={selectedLanguage?.userPurchases?.userPurchasesTab2}/>
                            <Tab label={selectedLanguage?.userPurchases?.userPurchasesTab3}/>
                        </Tabs>
                    </Box>
                    <button onClick={() => setIsDownloadPurchases(true)} className='user-purchases__btn-download'>{selectedLanguage?.userPurchases?.userPurchasesBtnDownload}</button>
                </div>

                <div className="user-purchases__filter">
                    <div className="user-purchases__filter-type">
                        <FormControl fullWidth>
                            <InputLabel id="userPurchasesSort">{selectedLanguage?.userPurchases?.userPurchasesSortTitle}</InputLabel>
                            <Select
                                labelId="userPurchasesSort"
                                id="userPurchasesSort"
                                value={sortStatus}
                                label={selectedLanguage?.userPurchases?.userPurchasesSortTitle}
                                onChange={(e) => setSortStatus(e.target.value)}
                            >
                                <MenuItem value=''>{selectedLanguage?.userPurchases?.userPurchasesSortOption1}</MenuItem>
                                <MenuItem value='in_process'>{selectedLanguage?.userPurchases?.userPurchasesSortOption2}</MenuItem>
                                <MenuItem value='done'>{selectedLanguage?.userPurchases?.userPurchasesSortOption3}</MenuItem>
                                <MenuItem value='rejected'>{selectedLanguage?.userPurchases?.userPurchasesSortOption4}</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div className="user-purchases__filter-type">
                        <FormControl fullWidth>
                            <InputLabel id="userPurchasesSort">{selectedLanguage?.userPurchases?.userPurchasesSortExucutorTitle}</InputLabel>
                            <Select
                                labelId="userPurchasesSort"
                                id="userPurchasesSort"
                                value={sortExucutor}
                                label={selectedLanguage?.userPurchases?.userPurchasesSortExucutorTitle}
                                onChange={(e) => setSortExucutor(e.target.value)}
                            >
                                <MenuItem value=''>{selectedLanguage?.userPurchases?.userPurchasesSortExucutorAll}</MenuItem>
                                {
                                    !!managers?.length && managers.map((el, i) => (
                                        <MenuItem value={el._id} key={el._id + i}>{el.email}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </div>

                    <div className="user-purchases__filter-sort-wrap">
                        <div className="user-purchases__filter-sort-title">{selectedLanguage?.userPurchases?.userPurchasesFilterTitle}</div>
                        <div className={`user-purchases__filter-sort-btn-wrap ${sortExucutor?.length ? 'user-product__disable' : ''} ${unreserved?.length ? 'user-purchases__filter-sort-btn-wrap--active' : ''}`}>
                            <img className="user-purchases__filter-sort-btn" onClick={() => handleSortPurchases('unreserved')} src={plus} alt='img'/>
                        </div>
                        <div className={`user-purchases__filter-sort-btn-wrap ${sortExucutor?.length ? 'user-product__disable' : ''} ${reserved?.length ? 'user-purchases__filter-sort-btn-wrap--active' : ''}`}>
                            <img className="user-purchases__filter-sort-btn" onClick={() => handleSortPurchases('reserved')} src={check} alt='img'/>
                        </div>
                    </div>

                    <div className="user-purchases__filter-date-wrap">
                        <div className='user-purchases__filter-date-btn-wrap'>
                            <button className='user-purchases__filter-date-btn' onClick={handleSortCleanDate}>{selectedLanguage?.userPurchases?.userPurchasesFilterDateBtnClean}</button>
                            <button className='user-purchases__filter-date-btn' onClick={handleSortDate}>{selectedLanguage?.userPurchases?.userPurchasesFilterDateBtnSort}</button>
                        </div>
                        <div className='user-purchases__filter-date' onClick={() => setIsSelectDate(!isSelectDate)}>
                            <div className='user-purchases__filter-date-select'>
                                <div>{selectedLanguage?.userPurchases?.userPurchasesFilterDateSelect} {stateDate[0]?.startDate ? new Date(stateDate[0]?.startDate).toLocaleString().split(',')[0] : selectedLanguage?.userNotifications?.userNotificationsFilterDateFormatFrom} - {stateDate[0]?.startDate ? new Date(stateDate[0]?.startDate).toLocaleString().split(',')[0] : selectedLanguage?.userNotifications?.userNotificationsFilterDateFormatTo}</div>
                            </div>
                        </div>
                        <DateRange
                            className={`user-purchases__filter-date-drop ${isSelectDate ? 'user-purchases__filter-date-drop-active' : ''}`}
                            editableDateInputs={true}
                            onChange={item => setStateDate([item.selection])}
                            moveRangeOnFirstSelection={false}
                            ranges={stateDate}
                        />
                    </div>
                </div>

                <div className="user-purchases__items">
                    {
                        isPreloader ? <Preloader /> : !!filterPurchases?.length ? filterPurchases.map((el, i) => (
                            <div className="user-purchases__item" key={el._id + i}>
                                <div className="user-purchases__item--wrap" onClick={() => handleReadPurchases(el._id)}>
                                    <div className="user-purchases__item--left">
                                        <div className="user-purchases__item-product-wrap">
                                            <img className="user-purchases__item-product-img" src={el.first_product?.img?.length ? el.first_product.img : ''} alt='img'/>
                                            <div className="user-purchases__item-product-name">{el.first_product?.name?.length ? el.first_product?.name : ''}</div>
                                        </div>
                                        <div className={`user-purchases__item-status user-purchases__item-status--${el.status}`}>{el.status === 'in_process' ? selectedLanguage?.selectStatus?.selectStatusOption1 : el.status === 'done' ? selectedLanguage?.selectStatus?.selectStatusOption2 : el.status === 'rejected' ? selectedLanguage?.selectStatus?.selectStatusOption3 : ''}</div>
                                    </div>

                                    <div className="user-purchases__item--right">
                                        <div className="user-purchases__item-product-category-wrap">
                                            <div className="user-purchases__item-product-category-title">{selectedLanguage?.userPurchases?.userPurchasesCategory}</div>
                                            <div className="user-purchases__item-product-category">{el.first_product?.categoryName?.length ? el.first_product?.categoryName : ''}</div>
                                        </div>
                                        <div className="user-purchases__item-exucutor-wrap">
                                            <div className="user-purchases__item-exucutor-title">{selectedLanguage?.userPurchases?.userPurchasesExucutor}</div>
                                            {
                                                el.exucutor?.email?.length && <div className="user-purchases__item-exucutor">{el.exucutor.email}</div>
                                            }
                                        </div>
                                        <div className="user-purchases__item-name-wrap">
                                            <div className="user-purchases__item-name-title">{selectedLanguage?.userPurchases?.userPurchasesCustomer}</div>
                                            <div className="user-purchases__item-name">{el.full_name}</div>
                                        </div>

                                        <div className="user-purchases__item-total-price-wrap">
                                            <div className="user-purchases__item-total-price-title">{selectedLanguage?.userPurchases?.userPurchasesCount}</div>
                                            <div className="user-purchases__item-total-price">{el.totalPrice}{handleShopCurrency(shop.currency)}</div> 
                                        </div> 
                                        <div className="user-purchases__item-date-wrap">
                                            <div className="user-purchases__item-date-title">{selectedLanguage?.userPurchases?.userPurchasesData}</div>
                                            <div className="user-purchases__item-date">{new Date(el.creation_time).toLocaleDateString()}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="user-purchases__item-btn-wrap">
                                    <svg className={`user-purchases__item-stars ${el.favorite ? 'user-purchases__item-stars-is-favorite' : ''}`} onClick={(e) => handleFavorite(e, el)} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        viewBox="0 0 404.204 404.204" xmlSpace="preserve">
                                        <g>
                                            <g>
                                                <path d="M316.953,395.112c-3.35,0-6.706-1.033-9.567-3.106l-105.279-76.5L96.829,392.001
                                                    c-5.706,4.15-13.429,4.15-19.14,0c-5.706-4.145-8.088-11.487-5.912-18.199l40.211-123.771L6.709,173.546
                                                    c-5.706-4.15-8.088-11.493-5.912-18.199s8.425-11.243,15.48-11.243h130.135l40.211-123.771c2.176-6.706,8.431-11.243,15.48-11.243
                                                    c7.049,0,13.304,4.536,15.48,11.243l40.211,123.771h130.135c7.054,0,13.304,4.536,15.48,11.243s-0.207,14.049-5.912,18.199
                                                    l-105.268,76.49l40.211,123.771c2.176,6.706-0.207,14.049-5.912,18.199C323.676,394.078,320.314,395.112,316.953,395.112z
                                                    M202.107,279.118c3.356,0,6.717,1.033,9.567,3.106l74.33,53.999l-28.397-87.373c-2.176-6.706,0.207-14.049,5.912-18.199
                                                    l74.33-53.999h-91.877c-7.044,0-13.293-4.536-15.48-11.243l-28.386-87.384l-28.386,87.384c-2.187,6.706-8.441,11.243-15.48,11.243
                                                    H66.364l74.33,53.999c5.706,4.15,8.088,11.493,5.912,18.199l-28.392,87.373l74.33-53.999
                                                    C195.39,280.152,198.757,279.118,202.107,279.118z"/>
                                            </g>
                                        </g>
                                    </svg>  

                                    <div>
                                        <SelectStatus purchases={el} managers={managers}/>
                                    </div> 
                                </div>   
                           
                            </div>
                        ))
                        : <div className="user-purchases__error-text">{selectedLanguage?.userPurchases?.userPurchasesErrorText}</div>
                    }
                </div>

                <PaginationItems selectedPaget={selectedPaget} setSelectedPaget={setSelectedPaget} pageRangeDisplayed={5} itemsPerPage={10} quantityAllProducts={quantityAllProducts}/>

            </div>
        </div>
    );
}

export default UserPurchasesView;
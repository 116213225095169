import './ModalWindow.css';
import { useSelector } from 'react-redux';

function ModalWindow({title='', text='', handleClick, leftBtn='', rightBtn='', notBtn=false, addStyles={}, children}) {
    const selectedLanguageUser = useSelector(state => state.userSlice.selectedLanguage);
    const selectedLanguageHome = useSelector(state => state.homeSlice.selectedLanguage);

    const handleClickBtn = (boolean) => {
        handleClick(boolean)
    };
    
    const handleClose = () => {
        handleClick(false)
    };

    return (
        <div className="modal-window">
            <div className="modal-window-wrap" onClick={handleClose}></div>
            <div className='modal-window--wrap' style={addStyles} onClick={(e) => e.stopPropagation()}>
                {!!title.length && <h3 className="modal-window__title">{title}</h3>}
                {!!text?.length && <div className="modal-window__text">{text}</div>}
                {children}
                {
                    !notBtn && selectedLanguageUser?.modalWindow?.modalWindowLeftBtn?.length && 
                        <div className="modal-window__btn-wrap">
                            <button onClick={() => handleClickBtn(false)} className="modal-window__btn modal-window__btn2">{leftBtn?.length ? leftBtn : selectedLanguageUser.modalWindow.modalWindowLeftBtn}</button>
                            <button onClick={() => handleClickBtn(true)} className="modal-window__btn modal-window__btn1">{rightBtn?.length ? rightBtn : selectedLanguageUser.modalWindow.modalWindowRightBtn}</button>
                        </div>
                }     
                {
                    !notBtn && selectedLanguageHome?.modalWindow?.modalWindowLeftBtn?.length && 
                        <div className="modal-window__btn-wrap">
                            <button onClick={() => handleClickBtn(false)} className="modal-window__btn modal-window__btn2">{leftBtn?.length ? leftBtn : selectedLanguageHome.modalWindow.modalWindowLeftBtn}</button>
                            <button onClick={() => handleClickBtn(true)} className="modal-window__btn modal-window__btn1">{rightBtn?.length ? rightBtn : selectedLanguageHome.modalWindow.modalWindowRightBtn}</button>
                        </div>
                }
            </div>
        </div>
    );
}

export default ModalWindow;
import './HomeView.css';
import { useEffect, useState } from 'react';
import SwiperCards from '../../components/SwiperCards/SwiperCards';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay} from "swiper";
import { fetchGetData } from '../../helpers/Utils';
import { setHomeViewData, setLastUpdatedHomeView } from '../../store/homeSlice';


function HomeView() {
    const selectedLanguage = useSelector(state => state.homeSlice.selectedLanguage);
    const lastUpdatedHomeView = useSelector(state => state.homeSlice.lastUpdatedHomeView);
    const homeViewData = useSelector(state => state.homeSlice.homeViewData);
    const shop = useSelector(state => state.homeSlice.shop);
    const categories = useSelector(state => state.homeSlice.categories);
    const [productsNew, setProductsNew] = useState([]);
    const [productsOld, setProductsOld] = useState([]);
    const [productsPopular, setProductsPopular] = useState([]);
    const [advertisingProducts, setAdvertisingProducts] = useState([]);
    const [isOpenInfo, setIsOpenInfo] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (shop?._id) {
            if (!lastUpdatedHomeView || new Date().getTime() - lastUpdatedHomeView > 300000) {
                getHomeView(shop._id)
            }
        }
    }, [])
   
   
    useEffect(() => {
        if (shop?._id) {
            getHomeView(shop._id)
        }
    }, [shop])
    
    useEffect(() => {
        if (lastUpdatedHomeView) {
            setProductsNew(homeViewData?.products_with_discounts?.length ? homeViewData.products_with_discounts : [])
            setProductsOld(homeViewData?.products_new_six?.length ? homeViewData.products_new_six : [])
            setProductsPopular(homeViewData?.popular_products?.length ? homeViewData.popular_products : [])
            setAdvertisingProducts(homeViewData?.products_for_adv?.length ? homeViewData.products_for_adv : [])
        }
    }, [lastUpdatedHomeView])

    const handleInfoOpen = (num) => {
        if (isOpenInfo.includes(num)) {
            setIsOpenInfo(isOpenInfo.filter(el => el !== num))
        } else {
            setIsOpenInfo([...isOpenInfo, num])
        }
    }
    
    const getHomeView = (shopId) => {
        fetchGetData(`${process.env.REACT_APP_BASE_URL}/products/${shopId}/main`)
        .then(res => {
            if (res.success && res.data) {
                dispatch(setHomeViewData(res.data))
                dispatch(setLastUpdatedHomeView(new Date().getTime()))
                    
                    setProductsNew(res.data.products_with_discounts?.length ? res.data.products_with_discounts : [])
                    setProductsOld(res.data.products_new_six?.length ? res.data.products_new_six : [])
                    setProductsPopular(res.data.popular_products?.length ? res.data.popular_products : [])
                    setAdvertisingProducts(res.data.products_for_adv?.length ? res.data.products_for_adv : [])
                } else {
                    console.log('GET HomeView:', res)
                }
            })
        }
   
    return (
        // <>
        //     {
                // productsOld?.length ?
                    <div className="home-view hidden">
                        {
                            !!shop?.banners?.length && (
                                <Swiper
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                    }}
                                    modules={[Autoplay]}
                                    className="mySwiper"
                                >
                                    {
                                       shop.banners.map((el, i) => (<SwiperSlide key={el + i}><img className="home-view__swiper-img" src={el} alt='img'/></SwiperSlide>))
                                    }
                                </Swiper>
                            )
                        }

                        <div className="home-view__blocks-wrap">
                            {/* слайдер нових товарів */}
                            {
                                !!productsOld.length && !!shop?.selectedBlocks?.length && <div className={`container home-view__block-order--${shop?.selectedBlocks?.findIndex(el => el.id == 1 && el.isChecked == true)}`}><SwiperCards title={selectedLanguage?.homePage?.titleSwiperNew} products={productsOld}/></div>
                            }   
                            
                            {/* слайдер товарів зі знижкою*/}
                            {
                                !!productsNew.length && !!shop?.selectedBlocks?.length && (<div  className={`container home-view__block-order--${shop?.selectedBlocks?.findIndex(el => el.id == 2 && el.isChecked == true)}`}><SwiperCards title={selectedLanguage?.homePage?.titleSwiperDiscounts} products={productsNew}/></div>)
                            }
                           
                            {/* слайдер популярних товарів*/}
                            {
                                !!productsPopular.length && !!shop?.selectedBlocks?.length && <div className={`container home-view__block-order--${shop?.selectedBlocks?.findIndex(el => el.id == 3 && el.isChecked == true)}`}><SwiperCards title={'Популярні'} products={productsPopular}/></div>
                            }  
                            
                            {/* опис магазину*/}
                            {
                                !!shop?.selectedBlocks?.length &&
                                <div className={`home-view__description container home-view__block-order--${shop?.selectedBlocks?.findIndex(el => el.id == 4 && el.isChecked == true)}`}>
                                    <h2 className="home-view__info-title">{selectedLanguage?.homePage?.homeInfoTitle} {shop.name}</h2>
                                    <div className="home-view__info-text-wrap">
                                        <p className={`home-view__info-text ${isOpenInfo.includes(1) ? 'home-view__info-text--active' : ''}`}>{shop.descriptionShop}</p>
                                        <div onClick={() => handleInfoOpen(1)} className='home-view__btn-info-wrap'>
                                            <div className={`home-view__btn-info ${isOpenInfo.includes(1) ? 'home-view__btn-info--active' : ''}`}></div>
                                        </div>
                                    </div>
                                </div>
                            } 


                            {/* Блок інформації*/}
                            {
                                !!shop?.name?.length && !!shop?.selectedBlocks?.length && (
                                    <div className={`home-view__info container home-view__block-order--${shop?.selectedBlocks?.findIndex(el => el.id == 5 && el.isChecked == true)}`}>

                                        {
                                            !!shop?.informationBlock?.length && shop?.informationBlock.map(el => (
                                                <NavLink
                                                    to={el.description?.length ? `/${shop.name}/advertisement` : ''}
                                                    className={`home-view__information-block ${shop?.informationBlock[0]?.description?.length ? '' : 'home-view__information-block-not-active'}`} 
                                                    style={{background: `${el.colorBackground}`}}
                                                    key={el._id}
                                                >
                                                    <div 
                                                        className='home-view__information-block-title' 
                                                        style={{color: `${el.colorTitle}`, fontSize: `${el.sizeTitle}px`, fontWeight: `${el.fontWeightTitle}`, textShadow: `${el.shadowTitleX}px ${el.shadowTitleY}px ${el.shadowTitleZ}px rgb(0 0 0 / ${el.shadowTitleTransparency}%)`}}
                                                    >
                                                        {el.title}
                                                    </div>
                                                    <div 
                                                        className='home-view__information-block-text' 
                                                        style={{color: `${el.colorText}`, fontSize: `${el.sizeText}px`, textAlign: `${el.textAlign}`, fontWeight: `${el.fontWeightText}`, textShadow: `${el.shadowTextX}px ${el.shadowTextY}px ${el.shadowTextZ}px rgb(0 0 0 / ${el.shadowTextTransparency}%)`}}
                                                    >
                                                        {el.text}
                                                    </div>
                                                </NavLink>
                                            ))
                                        }
                                    </div>
                                )
                            }

                            {/* Блок реклами*/}
                            {
                                !!shop?.typeStore?.length && !!advertisingProducts?.length && <div className={`container home-view__block-order--${shop?.selectedBlocks?.findIndex(el => el.id == 6 && el.isChecked == true)}`}><SwiperCards title={selectedLanguage?.homePage?.titleSwiperAdvertising} products={advertisingProducts} isAdvertising={true}/></div>
                            }

                            {   
                                !!categories?.length && categories.filter(el => el.image_url?.length)?.length >=2 &&
                                    <div className="home-view__categories--wrap container">
                                        <h3 className="home-view__categories-title">{selectedLanguage?.homePage?.homeCategoriesTitle}</h3>
                                        <div className="home-view__categories-wrap">
                                            {
                                                categories.map(el => (
                                                    <NavLink className="home-view__categories-link" to={`/${shop.name}/category/${el._id}`} key={el._id}>
                                                        <img className="home-view__categories-img" src={el.image_url} alt='img'/>
                                                        <div className="home-view__categories-name">{el.name}</div>
                                                    </NavLink>
                                                ))
                                            }
                                        </div>
                                    </div>
                            }
                        </div>
                        
                    </div>
                    // : (<Preloader/>) 
        //     }
        // </>
    );
}

export default HomeView;
import { toast } from "react-toastify";
import { currencySigns } from "./Config";


export function showMessage (event, message){
    if (event == "success") {
        toast.success(message, {
            position: "bottom-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        })
    } else {
        toast.error(message, {
            position: "bottom-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export const setUserPurchasesLength = async (_id, token, fun) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/purchases/${_id}/number?token=${token}`)
        .then(res => res.json())
        .then(res => {
            if (res.success) {
                fun(res.data)
                console.log('GET setUserPurchasesLength:', res)
            } else {
                console.log('GET setUserPurchasesLength:', res)
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        })
}

export const setUserNotificationsLength = async (_id, token, fun) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/notifications/${_id}/number?token=${token}`)
        .then(res => res.json())
        .then(res => {
            if (res.success) {
                fun(res.data)
                console.log('GET setUserNotificationsLength:', res)
            } else {
                console.log('GET setUserNotificationsLength:', res)
            }
        })
        .catch((error) => {
            console.error('Error setUserNotificationsLength:', error);
        })
}

export const verifyToken = async (fun) => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get('token')
    
    if (token) {
        const oldToken = localStorage.getItem('token');
        
        if (oldToken) {
            const res = await fetch("https://zx8hsmle2j.execute-api.eu-central-1.amazonaws.com/production/api/v1/auth/verify?token=" + oldToken, {
                method: "GET"
            })
            
            const data = await res.json()
            
            if (!data.success) {
                localStorage.removeItem('token')
                window.location.assign('/')
                // window.location.href = "https://auth.welcomenocode.com/?app=checkinshop"
            } else {
                fun(oldToken)
            }
        } else {
            const res = await fetch("https://zx8hsmle2j.execute-api.eu-central-1.amazonaws.com/production/api/v1/auth/verify?token=" + token, {
                method: "GET"
            })
            
            const data = await res.json()
    
            if (!data.success) {
                localStorage.removeItem('token')
                window.location.assign('/')
                // window.location.href = "https://auth.welcomenocode.com/?app=checkinshop"
            } else {
                localStorage.setItem('token', token)
                fun(token)
            }
        }
        
        // fun()
    } else {
        const oldToken = localStorage.getItem('token');

        if (oldToken) {
            const res = await fetch("https://zx8hsmle2j.execute-api.eu-central-1.amazonaws.com/production/api/v1/auth/verify?token=" + oldToken, {
                method: "GET"
            })
    
            const data = await res.json()
    
            if (!data.success) {
                localStorage.removeItem('token')
                window.location.assign('/')
                // window.location.href = "https://auth.welcomenocode.com/?app=checkinshop"
            } else{
                fun(oldToken)
            }
        } else {
            window.location.href = "https://auth.welcomenocode.com/?app=checkinshop"
        }
    }
}

export const validEmail = (value) => {
    return  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(value);
}

export const validTelephon = (value, setValue) => {
    if  (/^\d+$/.test(value)) {
        setValue(value)
    } else {
        setValue('')
    }
}

// export const isValidEmail = (email) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return emailRegex.test(email);
// }

export const setProfitYear = (option, shop, purchases, fun) => {
    let month = new Date().getMonth()
        if (option == 'done') {
            let data = {
                ...shop,
                profit: [...shop.profit.map((el, index) => {
                    if (index == month) {
                        el = +el + purchases.totalPrice
                    }
                    return el
                })]
            }

            fun(data)
        } else if (purchases.status == 'done' && option !== 'done') {
            let data = {
                ...shop,
                profit: [...shop.profit.map((el, index) => {
                    if (index == month) {
                        el = +el - purchases.totalPrice
                    }
                    return el
                })]
            }

            fun(data)
        }
}

export const sendAnalytics = (data) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/analytics`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then(res => res.json())
        .then(res => {
            if (res.success && res.data) {
                console.log('PUT sendAnalytics:', res)
            } else {
                console.log('PUT sendAnalytics:', res)
            }
        })
        .catch((error) => {
            console.error('Error sendAnalytics:', error);
        })
}

export const fetchGetData = async (url, finallyFun=null) => {
    try {
        const response = await fetch(url);
        const data = await response.json();
        // console.log('Data:', data);
        return data;
      } catch (error) {
        console.error('Error:', error);
        return null;
      }
      finally {
        if (finallyFun) {
            finallyFun()
        }
    }
}

export const fetchRequest = async ( method, url, data, catchFunc=null, finallyFunc=null) => {
    try {
        const response = await fetch(url, {
          method: method,
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        });

        const res = await response.json();
        return res;
    } catch (error) {
        console.error('Error:', error);
        if (catchFunc) {
            catchFunc(error);
        }
      } finally {
        if (finallyFunc) {
          finallyFunc();
        }
      }
}

export const handleCategoriesMap = (cat) => {
    let res = []
    let res2 = cat.map(el => {
        res.push(el)
        if (el.sub_categories?.length) {
            el.sub_categories.map(ell => {
                res.push(ell)
            })
        }
    })
    return res
}

export const handleShopCurrency = (str) => {
    if (currencySigns?.length) {
        let res = currencySigns.find(el => el.value === str)
        return res?.title?.length ? res.title : '₴'
    }
}

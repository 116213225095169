import './SelectStatus.css';
import { Button, Menu, MenuItem } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRequest, setProfitYear, showMessage } from '../../helpers/Utils';
import { getPurchases, setShop, setStatusPurchases } from '../../store/userSlice';

function SelectStatus({purchases, managers}) {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const isManagerRole = useSelector(state => state.userSlice.isManagerRole);
    const purchasesAll = useSelector(state => state.userSlice.purchases);
    const shop = useSelector(state => state.userSlice.shop);
    const [newStatus, setNewStatus] = useState(purchases.status);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl)
    const dispatch = useDispatch();
    const token = localStorage.getItem('token')

    useEffect(() => {
        if (newStatus?.length && newStatus !== purchases.status) {
            let data = {
                ...purchases,
                status: newStatus,
            }
            
            fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/purchases/${purchases._id}/status?token=${token}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
                .then(res => {
                    if (res.success && res.data) {
                        dispatch(setStatusPurchases({...purchases, status: newStatus}));
                        showMessage('success', selectedLanguage?.selectStatus?.selectStatusShowMesEditPurchases)
                    } else {
                        console.log('PUT SelectStatus:', res)
                    }
                })

                setProfitYear(newStatus, shop, purchases, updatesShop)
        }

        handleCloseSettings()
    }, [newStatus])

    const updatesShop = (data) => {
        dispatch(setShop(data));
    }

    const handleNewStatus = (status) => {
        setNewStatus(status)
        handleCloseSettings()
    }

    const handleClickSettings = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseSettings = () => {
        setAnchorEl(null);
    };

    const handleChangeExucutor = (obj) => {
        let data = {
            ...purchases,
            exucutor: obj,
        }

        fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/purchases/${purchases._id}?token=${token}`, data, () => showMessage('error', selectedLanguage?.userProduct?.userProductShowMessageError))
            .then(res => {
                if (res.success && res.data) {
                    dispatch(getPurchases([...purchasesAll.map(el => {
                        if (el._id === purchases._id) {
                                    el = data
                            }
                            return el;
                    })]));

                    showMessage('success', selectedLanguage?.readPurchasesView?.readPurchasesMessageUpdate)
                } else {
                    console.log('PUT ReadPurchasesView:', res)
                }
            })

        handleCloseSettings()
    }

    return (
        <div className="select-status">
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClickSettings}
                className='select-status__setting-btn'
            >
                ...
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                // onClose={handleCloseSettings}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <div className='select-status__setting-title'>Змінити статус</div>
                <MenuItem onClick={() => handleNewStatus('in_process')}>{selectedLanguage?.selectStatus?.selectStatusOption1}</MenuItem>
                <MenuItem onClick={() => handleNewStatus('done')}>{selectedLanguage?.selectStatus?.selectStatusOption2}</MenuItem>
                <MenuItem onClick={() => handleNewStatus('rejected')}>{selectedLanguage?.selectStatus?.selectStatusOption3}</MenuItem>
                <div className="select-status__setting-title">Вибрати виконавця</div>
                <MenuItem className={`${isManagerRole ? 'user-product__disable' : ''}`} onClick={() => handleChangeExucutor({})}>Без виконавця</MenuItem>
                {
                    (!!managers?.length && !isManagerRole) && managers.map((el, i) => (
                        <MenuItem onClick={() => handleChangeExucutor(el)} key={el + i}>{el?.email}</MenuItem>
                    ))
                }
            </Menu>
        </div>
    );
}

export default memo(SelectStatus);
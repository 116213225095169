
import './MobileMenuLanding.css';

function MobileMenuLanding({handleOpenMenu, isOpenMenu}) {
    return (
        <div className="mobile-menu-landing-wrap">
            <div className="mobile-menu-landing" onClick={handleOpenMenu}>
                <span className={`mobile-menu-landing__global mobile-menu-landing__top ${isOpenMenu ? 'mobile-menu-landing__top-active' : ''}`}></span>
                <span className={`mobile-menu-landing__global mobile-menu-landing__middle ${isOpenMenu ? 'mobile-menu-landing__middle-active' : ''}`}></span>
                <span className={`mobile-menu-landing__global mobile-menu-landing__bottom ${isOpenMenu ? 'mobile-menu-landing__bottom-active' : ''}`}></span>
            </div>
        </div>
    );
}

export default MobileMenuLanding;
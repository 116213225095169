import { useEffect, useState } from 'react';
import { NavLink, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import './LayoutUser.css';
import bell from '../assets/images/bell.svg';
import bell2 from '../assets/images/bell2.svg';
import bell1 from '../assets/images/bell1.svg';
import chart from '../assets/images/chart.svg';
import chart1 from '../assets/images/chart1.svg';
import cartUser from '../assets/images/cartUser.svg';
import cartUser1 from '../assets/images/cartUser1.svg';
import customers from '../assets/images/customers.svg';
import customers1 from '../assets/images/customers1.svg';
import avatar from '../assets/images/avatar.svg';
import personal from '../assets/images/personal.svg';
import personal1 from '../assets/images/personal1.svg';
import shopImg from '../assets/images/shopImg.svg';
import shopImg1 from '../assets/images/shopImg1.svg';
import shape from '../assets/images/shape.svg';
import close from '../assets/images/close.svg';
import category from '../assets/images/category.svg';
import category1 from '../assets/images/category1.svg';
import house from '../assets/images/house.svg';
import integration from '../assets/images/integration.svg';
import integration1 from '../assets/images/integration1.svg';
import barcodeImg from '../assets/images/barcodeImg.svg';
import products from '../assets/images/products.svg';
import products1 from '../assets/images/products1.svg';
import messages from '../assets/images/messages.svg';
import messages1 from '../assets/images/messages1.svg';
import logoLanding from '../assets/images/logoLanding.png';
import { useSelector, useDispatch } from 'react-redux';
import { getCategories, setIsTopManagerRole, setIsManagerRole, setIsNeedCreateShop, setIsNeedUpdateShop, setNotificationsLength, setPurchasesLength, setSelectedLanguage, setShop, setUser, setSearchPurchases, setUserLanguage, setSearchBarcode } from '../store/userSlice';
import LoginBtn from '../components/LoginBtn/LoginBtn';
import ModalWindow from '../components/ModalWindow/ModalWindow';
import Preloader from '../components/Preloader/Preloader';
import { languageUser } from '../languageUser';
import { verifyToken, setUserPurchasesLength, setUserNotificationsLength, fetchGetData } from '../helpers/Utils';
import { MenuItem, Select, TextField } from '@mui/material';

import mixpanel from 'mixpanel-browser';
import BarcodeScanner from '../components/BarcodeScanner/BarcodeScanner';
import SubscriptionView from '../views/SubscriptionView/SubscriptionView';
import { Quagga } from 'quagga';
mixpanel.init('cbc9e4ee0d40873b5a2e3b5e39eedd33', { debug: true }); 

function LayoutUser() {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const purchasesLength = useSelector(state => state.userSlice.purchasesLength);
    const notificationsLength = useSelector(state => state.userSlice.notificationsLength);
    const isTopManagerRole = useSelector(state => state.userSlice.isTopManagerRole);
    const isManagerRole = useSelector(state => state.userSlice.isManagerRole);
    const isNeedCreateShop = useSelector(state => state.userSlice.isNeedCreateShop);
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [isModalWindow, setModalWindow] = useState(false);
    const [isScanner, setIsScanner] = useState(false);
    const [isModalSearchBarcode, setIsModalSearchBarcode] = useState(false);
    const [isPreloader, setIsPreloader] = useState(true);
    const [barcode, setBarcode] = useState('');
    const [searchTel, setSearchTel] = useState('');
    const [isTelSearch, setIsTelSearch] = useState(false);
    const user = useSelector(state => state.userSlice.user);
    const shop = useSelector(state => state.userSlice.shop);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [isOpenSettings, setIsOpenSettings] = useState(false);
    const [isModalSubscription, setIsModalSubscription] = useState(false);

    const token = localStorage.getItem('token')
    // let { userId } = useParams();

    useEffect(() => {
        if (window.innerWidth < 760) {
            setIsOpenMenu(true)
        } 
        
    }, [location])
    
    const handleOpenSelectSort = (e) => {
        e.stopPropagation()
        setIsOpenSettings(!isOpenSettings)
    }
    
    const handleSeachPurchases = () => {
        mixpanel.track('Handle purchases search on dashboard page');

        if (searchTel?.length) {
            dispatch(setSearchPurchases(searchTel))
            navigate(`/auth/${user._id}/purchases`)
        }
    }
    
    const handleSearchTel = (e) => {
        mixpanel.track('Handle phone number search on dashboard page');

        if(e.key == 'Enter' && searchTel?.length) {
            dispatch(setSearchPurchases(searchTel))
            navigate(`/auth/${user._id}/purchases`)
        }
    }
    
    useEffect(() => {
        if (!searchTel?.length) {
            dispatch(setSearchPurchases(''))
        }
    }, [searchTel])
    
    useEffect(() => {
        if (!user?.email?.length) {
            let auth = JSON.parse(localStorage.getItem('auth'));
            if (auth?.email) {
                dispatch(setUser(auth))

                getShopsData(auth._id)
            } else {
                navigate('/')
            }
        } else {
            getShopsData(user._id)
        }

        let res = (JSON.parse(localStorage.getItem('userLanguage')));
        if (res?.length) {
            dispatch(setSelectedLanguage(languageUser[res]));
            dispatch(setUserLanguage(res));
        } else {
            fetchGetData(`https://ipapi.co/json`)    // https://ipapi.co/json
                .then(res => {
                    if (res?.country_code?.length && res?.country_code === 'UA') {
                        dispatch(setSelectedLanguage(languageUser['UA']));
                        dispatch(setUserLanguage('UA'));
                        localStorage.setItem('userLanguage', JSON.stringify('UA'));
                    } else {
                        dispatch(setSelectedLanguage(languageUser['ENG']));
                        dispatch(setUserLanguage('ENG'));
                        localStorage.setItem('userLanguage', JSON.stringify('ENG'));
                        console.log('GET LayoutUser', res)
                    }
                })
        }

        verifyToken(getUserData)

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, [])

    useEffect(() => {
        if (shop?._id && token) {
            getMyRole()

            setUserPurchasesLength(shop._id, token, (res) => dispatch(setPurchasesLength(res)))
            setUserNotificationsLength(shop._id, token, (res) => dispatch(setNotificationsLength(res)))

            fetchGetData(`${process.env.REACT_APP_BASE_URL}/categories/${shop._id}/all?token=${token}`)
                .then(res => {
                    if (res.success && res.data) {
                        dispatch(getCategories(res.data));
                    } else {
                        console.log('GET LayoutUser', res)
                    }
                })
        }
    }, [shop])

    const handleResize = () => {
        if (window.innerWidth < 768) {
            setIsOpenMenu(true)
        } else if (window.innerWidth > 960) { 
            setIsOpenMenu(false)
        }
    }

    const openNav = () => {
        setIsOpenMenu(!isOpenMenu)
    };

    const getShopsData = (userId) => {
        fetchGetData(`${process.env.REACT_APP_BASE_URL}/shops/${userId}/owner?token=${token}`, () => setIsPreloader(false))
            .then(res => {
                // console.log(res)
                if (res.success && res.data) {
                    dispatch(setShop(res.data));
                    dispatch(setIsNeedCreateShop(false));
                } else {
                    dispatch(setIsNeedCreateShop(true));
                    console.log('GET LayoutUser', res)
                }
            })
    }

    const getUserData = async (token) => {
        // const token = localStorage.getItem('token')
        if (token?.length) {
            const jobsRes = await fetch('https://zx8hsmle2j.execute-api.eu-central-1.amazonaws.com/production/api/v1/user/profile?token=' + token, { method: 'GET' })
            const jobsData = await jobsRes.json()
            if (jobsData?.data?._id?.length) {
                dispatch(setUser(jobsData?.data))
                localStorage.setItem('auth', JSON.stringify(jobsData?.data));
                navigate(`/auth/${jobsData?.data?._id}/`)
            }
        }
    }
    
    const handleisCloseBarcode = () => {
        setIsModalSearchBarcode(false)
        setBarcode('')
        setIsScanner(false)
    }

    const handleisSearchBarcode = (boolean) => {
        if (boolean) {
            dispatch(setSearchBarcode(barcode))
            navigate(`/auth/${user._id}/product`)
        }

        handleisCloseBarcode()
    }

    const handleCloseTelSearch = () => {
        setIsTelSearch(false)
        dispatch(setSearchPurchases(''))
        setSearchTel('')
    }

    const handleCloseModalSubscription = (boolean) => {
        if (boolean) {
        }
        setIsModalSubscription(false)
    }

    const handleExit = (boolean) => {
        setModalWindow(!isModalWindow)

        mixpanel.track('Exit from dashboard page');

        if (boolean) {
            localStorage.removeItem('auth');
            setModalWindow(!isModalWindow)
            navigate('/')
            localStorage.removeItem('token')
        } else {
            setModalWindow(!isModalWindow)
        }
    };

    const getMyRole = () => {
        fetchGetData(`https://zx8hsmle2j.execute-api.eu-central-1.amazonaws.com/production/api/v1/roles/${shop._id}/my?token=${token}`)
            .then(res => {
                if (res?.success && res?.data?.role) {
                    if (res?.data?.role === 'topManager') {
                        dispatch(setIsTopManagerRole(true))
                    }
                    if (res?.data?.role === 'manager') {
                        dispatch(setIsManagerRole(true))
                    }
                } else {
                    console.log('GET LayoutUser', res)
                }
            })
    }

    return (
        <div className='layout-user'>
            
            {
                isModalSubscription && <ModalWindow title='' handleClick={handleCloseModalSubscription} notBtn={true} addStyles={{maxWidth: '90%', width: '100%'}}>
                    <SubscriptionView handleCloseModal={handleCloseModalSubscription}/>
                </ModalWindow>
            }

            {
                isModalWindow && <ModalWindow title={selectedLanguage?.layoutUser?.layoutModExitTitle} text={selectedLanguage?.layoutUser?.layoutModExitText} handleClick={handleExit} />
            }
            
            {
                isModalSearchBarcode && <ModalWindow title={selectedLanguage?.layoutUser?.layoutModSearchBarcodeTitle} handleClick={handleisSearchBarcode} leftBtn={selectedLanguage?.layoutUser?.layoutModSearchBarcodeLeftBtn} rightBtn={selectedLanguage?.layoutUser?.layoutModSearchBarcodeRightBtn} addStyles={isScanner ? { width: '100%', maxWidth: '703px', minHeight: '500px', maxHeight: '100vh'} : {}}>
                    <TextField className='layout-user__search-barcode-input' value={barcode} onChange={(e) => setBarcode(e.target.value)} label='Barcode' variant="outlined" />
                    
                    {
                        !isScanner ? 
                        <button className='layout-user__search-barcode-btn' onClick={() => setIsScanner(true)}>Сканувати</button>
                        :
                        <BarcodeScanner setIsScanner={setIsScanner} isScanner={isScanner} closeModal={handleisCloseBarcode}/>
                    }

                </ModalWindow>
            }

            {
                isPreloader ? <Preloader /> :
                <div className='layout-user--wrpa'>
                    <div className='layout-user__header-wrap'>
                        <div className='layout-user__header container'>
                            <NavLink to={`/auth/${user._id}/`}><img className='layout-user__header-logo' src={logoLanding} alt='img'/></NavLink>

                            <div className={`layout-user__header-search-wrap ${isTelSearch ? 'layout-user__header-search-tel-wrap' : ''}`}>
                                <div className='layout-user__header-search--wrap' onClick={() => window.innerWidth < 420 ? setIsTelSearch(true) : ''}>
                                    <TextField className='layout-user__header-search' value={searchTel} onChange={(e) => setSearchTel(e.target.value)} onKeyDown={(e) => handleSearchTel(e)} label={selectedLanguage?.layoutUser?.layoutSearchLabel} variant="outlined" />
                                    <svg onClick={handleSeachPurchases} className='layout-user__header-search-img' fill='#545D69' version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        viewBox="0 0 487.95 487.95" xmlSpace="preserve">
                                        <g>
                                            <g>
                                                <path d="M481.8,453l-140-140.1c27.6-33.1,44.2-75.4,44.2-121.6C386,85.9,299.5,0.2,193.1,0.2S0,86,0,191.4s86.5,191.1,192.9,191.1
                                                    c45.2,0,86.8-15.5,119.8-41.4l140.5,140.5c8.2,8.2,20.4,8.2,28.6,0C490,473.4,490,461.2,481.8,453z M41,191.4
                                                    c0-82.8,68.2-150.1,151.9-150.1s151.9,67.3,151.9,150.1s-68.2,150.1-151.9,150.1S41,274.1,41,191.4z"/>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                {
                                    isTelSearch && <button className='layout-user__header-search-btn-close' onClick={handleCloseTelSearch}><img className='layout-user__header-search-btn-close-img' src={close} alt='img'/></button>
                                }
                            </div>

                            <div className='layout-user__header-btn--wrap'>
                                <div className='layout-user__header-btn'>
                                    <div className='layout-user__header-btn-linck'>
                                        <img className='layout-user__header-btn-img-barcode' onClick={() => setIsModalSearchBarcode(true)} src={barcodeImg} alt='img' />
                                        <div className='layout-user__header-btn-img-text'>{selectedLanguage?.layoutUser?.layoutLinkBarcode}</div>
                                    </div>
                                    <NavLink className='layout-user__header-btn-linck' to={`/auth/${user._id}/notifications`}>
                                        <div className='layout-user__header-btn-img-wrap'>
                                            <img className='layout-user__header-btn-img' src={bell2} alt='img' />
                                            {
                                                !!notificationsLength && <div className='layout-user__header-btn-circle'>{notificationsLength}</div>
                                            }
                                        </div>
                                        <div className='layout-user__header-btn-img-text'>{selectedLanguage?.layoutUser?.layoutLinkNotifications}</div>
                                    </NavLink>
                                </div>

                                <NavLink to={`/auth/${user._id}`} className={`layout-user__header-avatar-wrap ${shop?.logo?.length ? 'layout-user__header-logo-wrap' : ''}`}>
                                    <img className='layout-user__header-avatar-img' src={user.picture?.length ? user.picture : avatar} alt='img' />
                                    {/* <img className='layout-user__header-avatar-img' src={avatar} alt='img' /> */}
                                </NavLink>

                                <div className="layout-user__select--wrap">
                                    <div className="layout-user__select-wrap">
                                        <div className="layout-user__select" onClick={(e) => handleOpenSelectSort(e)}>
                                            <div className='layout-user__select-btn-wrap'>
                                                <div className={`layout-user__select-btn ${isOpenSettings ? 'layout-user__select-btn--active' : ''}`}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`layout-user__option-wrap ${isOpenSettings ? 'layout-user__option-wrap--active' : ''}`}>
                                        <div className='layout-user__option-wrap-close' onClick={() => setIsOpenSettings(false)}></div>
                                        <div className="layout-user__option-name">{user.first_name} {user.last_name}</div>
                                        <div className="layout-user__option-name">{user.email}</div>
                                        <NavLink className='layout-user__option' onClick={() => setIsOpenSettings(false)} to={`/auth/${user._id}/settings`}>{selectedLanguage?.layoutUser?.layoutLinkSettings}</NavLink>
                                        {/* <NavLink className='layout-user__option' onClick={() => setIsOpenSettings(false)} to={`/auth/${user._id}/subscription`}>{selectedLanguage?.layoutUser?.layoutLinkSubscription}</NavLink> */}
                                        <div className='layout-user__option' onClick={() => {setIsOpenSettings(false); setIsModalSubscription(true)}}>{selectedLanguage?.layoutUser?.layoutLinkSubscription}</div>
                                        <div onClick={() => {setModalWindow(!isModalWindow); setIsOpenSettings(false)}} className='layout-user__option layout-user__option-exit'>
                                            <svg className="layout-user__option-img-exit" fill='#545D69' version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    viewBox="0 0 512 512" xmlSpace="preserve">
                                                <g>
                                                    <g>
                                                        <path d="M327.287,246.852l-74.931-76.595c-4.941-5.06-13.039-5.146-18.099-0.205c-5.06,4.941-5.146,13.056-0.205,18.099
                                                            l53.854,55.057H12.8C5.734,243.2,0,248.934,0,256c0,7.066,5.734,12.8,12.8,12.8h275.098l-53.845,55.057
                                                            c-4.941,5.043-4.855,13.158,0.205,18.099c5.06,4.941,13.158,4.855,18.099-0.205l75.128-76.8
                                                            C332.424,259.908,332.339,251.793,327.287,246.852z"/>
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <path d="M499.2,0H166.4c-7.066,0-12.8,5.734-12.8,12.8V192h25.6V25.6h307.2v460.8H179.2V320h-25.6v179.2
                                                            c0,7.066,5.734,12.8,12.8,12.8h332.8c7.066,0,12.8-5.734,12.8-12.8V12.8C512,5.734,506.266,0,499.2,0z"/>
                                                    </g>
                                                </g>
                                            </svg>
                                            {selectedLanguage?.layoutUser?.layoutLinkExit}
                                        </div>
                                    </div >
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='layout-user__main container'>
                        <div className={`layout-user__sidenav ${!isOpenMenu ? "layout-user__sidenav--open" : "layout-user__sidenav--close"}`}>
                            <div className='layout-user__header-menu' onClick={() => openNav()}>
                                <span className={`layout-user__header-menu-span ${!isOpenMenu ? "layout-user__header-menu-span--open1" : ""}`}></span>
                                <span className='layout-user__header-menu-span layout-user__header-menu-span2'></span>
                                <span className={`layout-user__header-menu-span ${!isOpenMenu ? "layout-user__header-menu-span--open3" : ""}`}></span>
                            </div>
                            
                            <NavLink className='layout-user__sidenav-link-shop' to={`/${shop?.name ? shop.name : `auth/${user?._id}/shop`}`} target='_blank'>
                                <svg className='layout-user__sidenav-link-shop-house' width="13" height="13" viewBox="0 0 13 13" fill="#EBEEF2" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.98271 12.03C1.68193 12.03 1.42627 11.9306 1.21572 11.7319C1.00518 11.5332 0.899902 11.2918 0.899902 11.0079V4.36406C0.899902 4.20506 0.939004 4.05174 1.01721 3.9041C1.09541 3.75646 1.20068 3.63722 1.33303 3.54636L6.02522 0.224445C6.12147 0.156303 6.22373 0.105197 6.33201 0.0711259C6.44029 0.037055 6.55459 0.0200195 6.6749 0.0200195C6.79522 0.0200195 6.90951 0.037055 7.01779 0.0711259C7.12608 0.105197 7.22834 0.156303 7.32459 0.224445L12.0168 3.54636C12.1491 3.63722 12.2544 3.75646 12.3326 3.9041C12.4108 4.05174 12.4499 4.20506 12.4499 4.36406V11.0079C12.4499 11.2918 12.3446 11.5332 12.1341 11.7319C11.9235 11.9306 11.6679 12.03 11.3671 12.03H8.11865V7.26009H5.23115V12.03H1.98271Z" fill="#fff" fillOpacity="0.54"/>
                                </svg>
                                <div className={`layout-user__sidenav-link-shop-text-wrap ${isOpenMenu ? 'layout-user__sidenav-link-shop-text-wrap--close' : 'layout-user__sidenav-link-shop-text-wrap--open'}`}>
                                    <p className='layout-user__sidenav-link-shop-title'>{shop.name}</p>
                                    <p className='layout-user__sidenav-link-shop-text'>{selectedLanguage?.layoutUser?.layoutLinkVisitShop}</p>
                                </div>
                                <img className={`layout-user__sidenav-link-shop-arow ${isOpenMenu ? 'layout-user__sidenav-link-shop-arow--close' : 'layout-user__sidenav-link-shop-arow--open'}`} src={shape} alt='img'/>
                            </NavLink>

                            {
                                !isTopManagerRole && !isManagerRole &&
                                <>
                                    <NavLink className='layout-user__sidenav-link' to={`/auth/${user._id}/`}>
                                        <img className='layout-user__sidenav-link-icon' src={chart} alt='img'/>
                                        <img className='layout-user__sidenav-link-icon-hover' src={chart1} alt='img'/>
                                        <div className={`layout-user__sidenav-link-text ${!isOpenMenu ? 'layout-user__sidenav-link-text--open' : 'layout-user__sidenav-link-text--close'}`}>{selectedLanguage?.layoutUser?.layoutLinkAnalytics}</div>
                                    </NavLink>
                                    <NavLink className='layout-user__sidenav-link' to={`/auth/${user._id}/shop`} onClick={() => dispatch(setIsNeedUpdateShop(false))}>
                                        <img className='layout-user__sidenav-link-icon' src={shopImg} alt='img'/>
                                        <img className='layout-user__sidenav-link-icon-hover' src={shopImg1} alt='img'/>
                                        <div className={`layout-user__sidenav-link-text ${!isOpenMenu ? 'layout-user__sidenav-link-text--open' : 'layout-user__sidenav-link-text--close'}`}>{selectedLanguage?.layoutUser?.layoutLinkShop}</div>
                                    </NavLink>
                                </>
                            }

                            <NavLink className='layout-user__sidenav-link' to={`/auth/${user._id}/categories`}>
                                <img className='layout-user__sidenav-link-icon' src={category} alt='img'/>
                                <img className='layout-user__sidenav-link-icon-hover' src={category1} alt='img'/>
                                <div className={`layout-user__sidenav-link-text ${!isOpenMenu ? 'layout-user__sidenav-link-text--open' : 'layout-user__sidenav-link-text--close'}`}>{selectedLanguage?.layoutUser?.layoutLinkCategories}</div>    
                            </NavLink>
                            
                            <NavLink className='layout-user__sidenav-link' to={`/auth/${user._id}/product`}>
                                <img className='layout-user__sidenav-link-icon' src={products} alt='img'/>
                                <img className='layout-user__sidenav-link-icon-hover' src={products1} alt='img'/>
                                <div className={`layout-user__sidenav-link-text ${!isOpenMenu ? 'layout-user__sidenav-link-text--open' : 'layout-user__sidenav-link-text--close'}`}>{selectedLanguage?.layoutUser?.layoutLinkProducts}</div>
                            </NavLink>
                            <NavLink className='layout-user__sidenav-link' to={`/auth/${user._id}/purchases`}>
                                <img className='layout-user__sidenav-link-icon' src={cartUser} alt='img'/>
                                <img className='layout-user__sidenav-link-icon-hover' src={cartUser1} alt='img'/>
                                <div className={`layout-user__sidenav-link-message-wrap ${!isOpenMenu ? 'layout-user__sidenav-link-text--open' : 'layout-user__sidenav-link-text--close'}`}>
                                    {selectedLanguage?.layoutUser?.layoutLinkOrders}
                                </div>
                                {
                                    !!purchasesLength && <div className='layout-user__sidenav-link-message-circle'>{purchasesLength}</div>
                                }
                            </NavLink>
                            <NavLink className='layout-user__sidenav-link' to={`/auth/${user._id}/notifications`}>
                                <img className='layout-user__sidenav-link-icon' src={bell} alt='img'/>
                                <img className='layout-user__sidenav-link-icon-hover' src={bell1} alt='img'/>
                                <div className={`layout-user__sidenav-link-message-wrap ${!isOpenMenu ? 'layout-user__sidenav-link-text--open' : 'layout-user__sidenav-link-text--close'}`}>
                                    {selectedLanguage?.layoutUser?.layoutLinkNotifications}
                                </div>
                                {
                                    !!notificationsLength && <div className='layout-user__sidenav-link-message-circle'>{notificationsLength}</div>
                                }
                            </NavLink>
                            {/* <NavLink className='layout-user__sidenav-link' to={`/auth/${user._id}/message`}>
                                <img className='layout-user__sidenav-link-icon' src={messages} alt='img'/>
                                <img className='layout-user__sidenav-link-icon-hover' src={messages1} alt='img'/>
                                <div className={`layout-user__sidenav-link-message-wrap ${!isOpenMenu ? 'layout-user__sidenav-link-text--open' : 'layout-user__sidenav-link-text--close'}`}>
                                    {selectedLanguage?.layoutUser?.layoutLinkMessage}
                                </div>
                            </NavLink> */}
                            <NavLink className='layout-user__sidenav-link' to={`/auth/${user._id}/customers`}>
                                <img className='layout-user__sidenav-link-icon' src={customers} alt='img'/>
                                <img className='layout-user__sidenav-link-icon-hover' src={customers1} alt='img'/>
                                <div className={`layout-user__sidenav-link-text ${!isOpenMenu ? 'layout-user__sidenav-link-text--open' : 'layout-user__sidenav-link-text--close'}`}>{selectedLanguage?.layoutUser?.layoutLinkCustomers}</div>
                            </NavLink>
                            {
                                !isTopManagerRole && !isManagerRole &&
                                <>
                                    <NavLink className='layout-user__sidenav-link' to={`/auth/${user._id}/personnel`}>
                                        <img className='layout-user__sidenav-link-icon' src={personal} alt='img'/>
                                        <img className='layout-user__sidenav-link-icon-hover' src={personal1} alt='img'/>
                                        <div className={`layout-user__sidenav-link-text ${!isOpenMenu ? 'layout-user__sidenav-link-text--open' : 'layout-user__sidenav-link-text--close'}`}>{selectedLanguage?.layoutUser?.layoutLinkPersonnel}</div>
                                    </NavLink>
                                    <NavLink className='layout-user__sidenav-link' to={`/auth/${user._id}/integration`}>
                                        <img className='layout-user__sidenav-link-icon' src={integration} alt='img'/>
                                        <img className='layout-user__sidenav-link-icon-hover' src={integration1} alt='img'/>
                                        <div className={`layout-user__sidenav-link-text ${!isOpenMenu ? 'layout-user__sidenav-link-text--open' : 'layout-user__sidenav-link-text--close'}`}>{selectedLanguage?.layoutUser?.layoutLinkIntegration}</div>
                                    </NavLink>
                                </>
                            }
                        </div>
                        
                        <div className='layout-user__outlet-wrap' id='moreAnalytics1Swaper'>
                            <Outlet />
                        </div>
                    </div>
                </div>
            }

        </div>
    );
}

export default LayoutUser;
import './SignInView.css';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import singInSwiper1 from '../../assets/images/singInSwiper1.svg';
import singInSwiper2 from '../../assets/images/singInSwiper2.svg';
import singInSwiper3 from '../../assets/images/singInSwiper3.svg';
import { setUser } from '../../store/userSlice';
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper";
import { setSelectedLanguage } from '../../store/userSlice';
import { languageUser } from '../../languageUser';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import { showMessage } from '../../helpers/Utils';

function SignInView() {
    const selectedLanguage = useSelector(state => state.userSlice.selectedLanguage);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const [errorText, setErrorText] = useState('');
    const [leavePassword, setLeavePassword] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        let res = JSON.parse(localStorage.getItem('userLanguage'));
        if (res?.length) {
            dispatch(setSelectedLanguage(languageUser[res]));
        } else {
            dispatch(setSelectedLanguage(languageUser['ENG']));
        }
        setErrorText('')
    }, [])
   
    useEffect(() => {
        setErrorText('')
    }, [newEmail, leavePassword])

    const handleChange = () => {
        let data = {
            email: email,
            password: password
        }

         fetch(`${process.env.REACT_APP_BASE_URL}/auth/login`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
            .then(res => res.json())
            .then(res => {
                if (res.success && res.data) {
                    dispatch(setUser(res.data))
                    localStorage.setItem('auth', JSON.stringify(res.data));
                    navigate(`/auth/${res.data._id}`)
                } else {
                    setLoginError(res.message)
                }
            })
    }

    const handleResetPassword = (boolean) => {
        if (boolean) {
            fetch(`${process.env.REACT_APP_BASE_URL}/auth/recovery`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify(
                    {"email": newEmail}
                ),
            })
                .then(res => res.json())
                .then(res => {
                    if (res.success == true && res?.data?.email?.length) {
                        navigate(`/recovery`)
                        setLeavePassword(false)
                        localStorage.removeItem('startRecovery')
                        showMessage('success', 'Номер відправлено')
                    } else {
                        setErrorText(res.message)
                        showMessage('error', 'Сталася помилка')
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                    showMessage('error', 'Сталася помилка')
                })
        } else {
            setLeavePassword(false)
        }

        setNewEmail('')
    }

    return (
        <div className="sign-in">
            {
                leavePassword && <ModalWindow title={'Введіть адресу електронної пошти'} text={'Пароль буде відправлений на електронну адресу'} handleClick={handleResetPassword} leftBtn={'Відмінити'}  rightBtn={'Підтвердити'}>
                        <label className='sign-in__label' htmlFor="signInViewResetPassword">
                            <span>{selectedLanguage?.SignInView?.signInEmailTitle}</span>
                        </label>
                        <input
                            id="signInViewResetPassword"
                            name="signInViewResetPassword"
                            type="email"
                            required
                            className='sign-in__input'
                            onChange={(e) => setNewEmail(e.target.value)}
                            value={newEmail}
                            placeholder={selectedLanguage?.SignInView?.signInEmailPlaceholder}
                        />
                        {
                            errorText.length > 0 && <div className='sign-in__error-text'>{errorText}</div>
                        }
                    </ModalWindow >
            }

            <div className="sign-in-wrap">
                <div className="sign-in__swiper">
                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                        delay: 4500,
                        disableOnInteraction: false,
                        }}
                        pagination={{
                        clickable: true,
                        }}
                        navigation={true}
                        modules={[Autoplay, Pagination]}
                        className="mySwiper sign-in__mySwiper"
                    >
                        <SwiperSlide><div className="sign-in__slider-wrap"><img className="sign-in__slider-img" src={singInSwiper1} alt='img'/><div className="sign-in__slider-title">{selectedLanguage?.SignInView?.signInSliderTitle1}</div><div className="sign-in__slider-text">{selectedLanguage?.SignInView?.signInSliderSubTitle1}</div></div></SwiperSlide>
                        <SwiperSlide><div className="sign-in__slider-wrap"><img className="sign-in__slider-img" src={singInSwiper2} alt='img'/><div className="sign-in__slider-title">{selectedLanguage?.SignInView?.signInSliderTitle2}</div><div className="sign-in__slider-text">{selectedLanguage?.SignInView?.signInSliderSubTitle2}</div></div></SwiperSlide>
                        <SwiperSlide><div className="sign-in__slider-wrap"><img className="sign-in__slider-img" src={singInSwiper3} alt='img'/><div className="sign-in__slider-title">{selectedLanguage?.SignInView?.signInSliderTitle3}</div><div className="sign-in__slider-text">{selectedLanguage?.SignInView?.signInSliderSubTitle3}</div></div></SwiperSlide>
                    </Swiper>
                </div>

                <div className="sign-in__form-wrap">
                    <div className="sign-in__form">
                        <h2 className="sign-in__company-name">{selectedLanguage?.SignInView?.signInCompanyName}</h2>
                        <h3 className="sign-in__form-title">{selectedLanguage?.SignInView?.signInTitle}</h3>
                        <p className="sign-in__form-sub-title"><span>{selectedLanguage?.SignInView?.signInSubTitle}&nbsp;</span><NavLink className="sign-in__form-sub-title-link" to='/auth/register'>Sign up now</NavLink></p>

                        <label className='sign-in__label' htmlFor="email">
                            <span>{selectedLanguage?.SignInView?.signInEmailTitle}</span>
                        </label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            required
                            className='sign-in__input'
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            placeholder={selectedLanguage?.SignInView?.signInEmailPlaceholder}
                        />
                        <label className='sign-in__label' htmlFor="password">
                            <span>{selectedLanguage?.SignInView?.signInPasswordTitle}</span>
                        </label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            required
                            className='sign-in__input'
                            onKeyDown={(e) => e.key == "Enter" ? handleChange() : ''}
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            placeholder={selectedLanguage?.SignInView?.signInPasswordPlaceholder}
                        />

                        <p className='sign-in__leave-password' onClick={() => setLeavePassword(true)}>{selectedLanguage?.SignInView?.signInLeavePassword}</p>

                        {
                            !!loginError?.length && <p className='sign-in__login-error'>{loginError}</p>
                        }

                        <button className='sign-in__btn' type="submit" onClick={handleChange}>{selectedLanguage?.SignInView?.signInLogiBtn}</button>
                        
                    <div><span>{selectedLanguage?.SignInView?.signInReturnToTitle}&nbsp;</span><NavLink className='sign-in__link-to-main' to='/'>{selectedLanguage?.SignInView?.signInMainPage}</NavLink></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignInView;
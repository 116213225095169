import { useEffect, useState } from 'react';
import {  useLocation, useNavigate, useParams } from 'react-router-dom';
import './VerificationView.css';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/userSlice';
import { verifyToken } from '../../helpers/Utils';
import { showMessage } from '../../helpers/Utils';
import mixpanel from 'mixpanel-browser';
mixpanel.init('cbc9e4ee0d40873b5a2e3b5e39eedd33', { debug: true }); 
mixpanel.track('Visit verification page');

function VerificationView() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [count, setCount] = useState(0)
    const location = useLocation();
    const { token } = useParams();
    const token2 = localStorage.getItem('token')
   
    useEffect(() => {
        verifyToken(getUserData)
        setCount(count + 1)
    }, [])
    
    useEffect(() => {
        verifyToken(getUserData)
    }, [location, token, token2])

    const getUserData = async (token) => {
        if (token && token.length) {
            const jobsRes = await fetch('https://zx8hsmle2j.execute-api.eu-central-1.amazonaws.com/production/api/v1/user/profile?token=' + token, { method: 'GET' })
            const jobsData = await jobsRes.json()
            if (jobsData?.data?._id?.length) {
                dispatch(setUser(jobsData?.data))
                localStorage.setItem('auth', JSON.stringify(jobsData?.data));
                mixpanel.track('Auth verification is successful');
                navigate(`/auth/${jobsData?.data?._id}`)
            } else {
                mixpanel.track('Auth verification is failed');
                showMessage('error', 'Please, reload the page')
            }
        }
    }
    
    return (
        <>
        </>
    );
}

export default VerificationView;